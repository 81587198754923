import * as React from 'react';

import { Icon, TextColors } from 'modules/presentation/enums';
import { DmsIcon } from 'modules/presentation/components/DmsIcon/DmsIcon';

export type TIconButtonSize = 'small' | 'regular' | 'large';
export type TIconButtonDisplay = 'row' | 'column';
export type TIconButtonProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  display?: TIconButtonDisplay;
  icon: Icon;
  onClick?: (evt?: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (evt?: React.MouseEvent<HTMLButtonElement>) => void;
  size?: TIconButtonSize;
  text?: string;
  textColor?: TextColors;
  title?: string;
};

function getTextColor(color: TextColors) {
  switch (color) {
    case TextColors.Blue:
      return 'iconButton_text--blue';
    default:
      return '';
  }
}
function getSizeClass(size: TIconButtonSize) {
  switch (size) {
    case 'small':
      return 'iconButton--small';
    case 'large':
      return 'iconButton--large';
    default:
      return '';
  }
}

function IconButton(props: TIconButtonProps): JSX.Element {
  const {
    children,
    className,
    disabled,
    icon,
    onClick,
    size,
    text,
    textColor,
    display,
    title,
    ...rest
  } = props;

  const displayClass = display === 'column' ? 'iconButton--displayColumn' : '';

  return (
    <button
      className={`iconButton ${getSizeClass(size)} ${displayClass} ${className}`}
      type="button"
      disabled={disabled}
      title={title}
      onClick={onClick}
      {...rest}
    >
      <DmsIcon className="iconButton_icon" icon={icon} />
      {text && <div className={`iconButton_text ${getTextColor(textColor)}`}>{text}</div>}
      {children}
    </button>
  );
}

IconButton.defaultProps = {
  size: 'regular',
  disabled: false,
  display: 'row',
};

export { IconButton };
