import * as PropTypes from 'prop-types';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { requestInterval, clearRequestInterval } from 'lib/request-interval';
import * as _ from 'lodash';

import { PopoverArrowLocation, PopoverLocation, PopoverShowOn } from 'modules/presentation/enums';
import { maxWidth } from '@material-ui/system';

type TPopoverArrowPosition = {
  left: number;
  top: number;
};

/** TODO: Change the name from IPopoverProps to TPopoverProps. */
export type IPopoverProps = React.DOMAttributes<HTMLSpanElement> & {
  /** Desired location of the popover arrow */
  arrowLocation?: PopoverArrowLocation;
  className?: string;
  /** Any content within the popover. */
  content: JSX.Element;
  /** Disable the padding within the component around the content */
  disableDefaultPadding?: boolean;
  /** Sets the popover to open if `true`.
   *
   * `isOpen` will override the internal isOpen state,
   * if not provided internal state will be used */

  isOpen?: boolean;
  /** Ensures the component does not exceed a set width */
  maxWidth?: number;
  onClose?: () => void;
  onOpen?: () => void;
  /** Gives the option to show either on click or on hover */
  showOn?: PopoverShowOn;
  /** The component can be shown on any side of the trigger. */
  popoverLocation?: PopoverLocation;
  wrapperClassName?: string;
};

type TPopoverState = {
  isOpen: boolean;
  /**
   * This is in order to compare the prevProps of isOpen to the current prop in
   * getDerivedStateFromProps. I hate this and I feel bad. See the following links for more info
   * https://github.com/reactjs/rfcs/blob/master/text/0006-static-lifecycle-methods.md#state-derived-from-propsstate
   * https://github.com/reactjs/rfcs/pull/6#discussion_r162865372
   */
  isOpenProp: boolean;
  popoverPosition: { left: number; top: number };
};

interface TPopoverArrowPositioningMap {
  [key: string]: (popoverRect: ClientRect, wrapperRect: ClientRect) => number;
}

interface IPopoverPositioningMap {
  [key: string]: (popoverRect: ClientRect, wrapperRect: ClientRect) => TPopoverArrowPosition;
}

function IframeZindexHack(): JSX.Element {
  return <iframe tabIndex={-1} src="about:blank" className="ie-pdf-iframe-overlay-fix" />;
}

export class Popover extends React.Component<IPopoverProps, TPopoverState> {
  private positionInterval: number;
  private popoverElement = React.createRef<HTMLDivElement>();
  private wrapperElement = React.createRef<HTMLSpanElement>();

  private horizontalArrowPositioningMap: TPopoverArrowPositioningMap = {
    [PopoverArrowLocation.Center]: (popoverRect: ClientRect, wrapperRect: ClientRect) => {
      return wrapperRect.left + (wrapperRect.width - popoverRect.width) / 2;
    },
    [PopoverArrowLocation.End]: (popoverRect: ClientRect, wrapperRect: ClientRect) => {
      return wrapperRect.width - popoverRect.width + wrapperRect.left;
    },
    [PopoverArrowLocation.Start]: (popoverRect: ClientRect, wrapperRect: ClientRect) => {
      return wrapperRect.left;
    },
  };

  private verticalArrowPositioningMap: TPopoverArrowPositioningMap = {
    [PopoverArrowLocation.Center]: (popoverRect: ClientRect, wrapperRect: ClientRect) => {
      return wrapperRect.top + (wrapperRect.height - popoverRect.height) / 2;
    },
    [PopoverArrowLocation.End]: (popoverRect: ClientRect, wrapperRect: ClientRect) => {
      return wrapperRect.height - popoverRect.height + wrapperRect.top;
    },
    [PopoverArrowLocation.Start]: (popoverRect: ClientRect, wrapperRect: ClientRect) => {
      return wrapperRect.top;
    },
  };

  private popoverPositionMap: IPopoverPositioningMap = {
    [PopoverLocation.Above]: (
      popoverRect: ClientRect,
      wrapperRect: ClientRect,
    ): TPopoverArrowPosition => {
      const arrowLocation = this.props.arrowLocation;
      const map = this.horizontalArrowPositioningMap;

      return {
        left: map[arrowLocation](popoverRect, wrapperRect),
        top: wrapperRect.top - popoverRect.height,
      };
    },
    [PopoverLocation.Below]: (
      popoverRect: ClientRect,
      wrapperRect: ClientRect,
    ): TPopoverArrowPosition => {
      const arrowLocation = this.props.arrowLocation;
      const map = this.horizontalArrowPositioningMap;

      return {
        left: map[arrowLocation](popoverRect, wrapperRect),
        top: wrapperRect.top + wrapperRect.height,
      };
    },
    [PopoverLocation.Left]: (
      popoverRect: ClientRect,
      wrapperRect: ClientRect,
    ): TPopoverArrowPosition => {
      const arrowLocation = this.props.arrowLocation;
      const map = this.verticalArrowPositioningMap;

      return {
        left: wrapperRect.left - popoverRect.width,
        top: map[arrowLocation](popoverRect, wrapperRect),
      };
    },
    [PopoverLocation.Right]: (
      popoverRect: ClientRect,
      wrapperRect: ClientRect,
    ): TPopoverArrowPosition => {
      const arrowLocation = this.props.arrowLocation;
      const map = this.verticalArrowPositioningMap;

      return {
        left: wrapperRect.left + wrapperRect.width,
        top: map[arrowLocation](popoverRect, wrapperRect),
      };
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen) {
      return { isOpen: true, isOpenProp: true };
      // Explicitly check for false so we don't evaluate undefined as false
    } else if (nextProps.isOpen === false && prevState.isOpen) {
      return { isOpen: false, isOpenProp: false };
    } else if (
      nextProps.isOpen === undefined &&
      prevState.isOpenProp &&
      nextProps.showOn !== undefined &&
      /**
       * This functionality currently ignores hover to preserve
       * the UX of the new folder menu in tree item
       */
      nextProps.showOn !== PopoverShowOn.Hover
    ) {
      return { isOpen: true, isOpenProp: nextProps.isOpen };
    } else if (nextProps.isOpen === undefined && prevState.isOpenProp) {
      return { isOpen: false, isOpenProp: nextProps.isOpen };
    } else {
      return null;
    }
  }

  static defaultProps = {
    arrowLocation: PopoverArrowLocation.Center,
    popoverLocation: PopoverLocation.Above,
  };

  static propTypes = {
    arrowLocation: PropTypes.string,
    content: PropTypes.element.isRequired,
    disableDefaultPadding: PropTypes.bool,
    isOpen: PropTypes.bool,
    maxWidth: PropTypes.number,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    showOn: PropTypes.string,
    popoverLocation: PropTypes.string,
  };

  constructor(props: IPopoverProps) {
    super(props);

    this.state = {
      isOpen: false,
      isOpenProp: undefined,
      popoverPosition: {
        left: -9999,
        top: -9999,
      },
    };

    this.clickHandler = this.clickHandler.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
    this.mouseOutHandler = this.mouseOutHandler.bind(this);
    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
    this.setPopoverPosition = this.setPopoverPosition.bind(this);
  }

  public componentDidMount() {
    this.updateInterval();

    window.addEventListener('keyup', this.keyUpHandler);
  }

  public componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
      // Set Popover Open
      _.isFunction(this.props.onOpen) && this.props.onOpen();
    } else if (!this.state.isOpen && prevState.isOpen) {
      // Set Popover Closed
      _.isFunction(this.props.onClose) && this.props.onClose();
    }

    this.updateInterval();
  }

  public componentWillUnmount(): void {
    this.clearPositionInterval();
    this.setIsOpenDebounced.cancel();

    window.removeEventListener('keyup', this.keyUpHandler);
  }

  public render(): JSX.Element {
    const {
      arrowLocation,
      children,
      className = '',
      content,
      disableDefaultPadding,
      maxWidth,
      isOpen,
      showOn,
      popoverLocation,
      wrapperClassName = '',
      ...eventProps
    } = this.props;

    const popoverStyle = {
      left: `${this.state.popoverPosition.left}px`,
      top: `${this.state.popoverPosition.top}px`,
    };

    const contentPaddingModifier = disableDefaultPadding ? 'popover_content--disablePadding' : '';

    const popover = (
      <div
        aria-modal="true"
        className={this.getPopoverClass()}
        data-automation-id="popover"
        ref={this.popoverElement}
        role="dialog"
        style={popoverStyle}
      >
        <IframeZindexHack />
        <div className="popover_arrow">
          <div className="outer" />
          <div className="inner" />
        </div>
        <div className={`popover_content ${contentPaddingModifier}`} style={{ maxWidth }}>
          {content}
        </div>
      </div>
    );

    return (
      <span
        className={`popoverWrapper ${wrapperClassName}`}
        ref={this.wrapperElement}
        {...this.getEventProps(eventProps)}
      >
        {children}
        {ReactDOM.createPortal(popover, this.getPopoverPlaceholder())}
      </span>
    );
  }

  private clearPositionInterval() {
    if (this.positionInterval !== undefined) {
      clearRequestInterval(this.positionInterval);
      this.positionInterval = undefined;
    }
  }

  private clickHandler(): void {
    // no delay, open immediatly when user clicks
    this.setIsOpen(true);
  }

  private getPopoverPlaceholder() {
    let popoverPlaceholder = document.getElementById('popoverPlaceholder');

    if (!popoverPlaceholder) {
      popoverPlaceholder = document.createElement('div');
      popoverPlaceholder.id = 'popoverPlaceholder';

      document.getElementsByTagName('body')[0].appendChild(popoverPlaceholder);
    }

    return popoverPlaceholder;
  }

  private getEventProps(eventProps: React.DOMAttributes<{}>): React.DOMAttributes<{}> {
    if (!_.isEmpty(eventProps) && _.isUndefined(this.props.showOn)) {
      return eventProps;
    }

    switch (this.props.showOn) {
      case PopoverShowOn.Click: {
        const events = {
          onBlur: this.mouseOutHandler,
          onClick: this.clickHandler,
          onMouseOut: this.mouseOutHandler,
        };

        // Once it's open, we need to add onMouseOver to help prevent closing of the popOver since
        // onMouseOut gets called when switching between popOver and the target hover/click area.
        if (this.state.isOpen) {
          return { ...events, onFocus: this.mouseOverHandler, onMouseOver: this.mouseOverHandler };
        }

        return events;
      }
      case PopoverShowOn.Hover:
      default: {
        return {
          onBlur: this.mouseOutHandler,
          onFocus: this.mouseOverHandler,
          onMouseOver: this.mouseOverHandler,
          onMouseOut: this.mouseOutHandler,
        };
      }
    }
  }

  private getPopoverClass(): string {
    const baseClass = `popover`;
    const arrowLocation = `${baseClass}--${this.props.arrowLocation.toLowerCase()}`;
    const popoverLocation = `${baseClass}--${this.props.popoverLocation.toLowerCase()}`;
    const visibility = this.state.isOpen ? `${baseClass}--visible` : '';

    return (
      `${baseClass} ${this.props.className}` + ` ${arrowLocation} ${popoverLocation} ${visibility}`
    );
  }

  private keyUpHandler({ keyCode }: KeyboardEvent): void {
    if (keyCode === 27) {
      this.mouseOutHandler();
    }
  }

  private mouseOutHandler(): void {
    this.setIsOpenDebounced(false);
  }

  private mouseOverHandler(): void {
    this.setIsOpenDebounced(true);
  }

  private setIsOpen(isOpen: boolean) {
    if (this.state.isOpen !== isOpen) {
      this.setState({ isOpen });
    }
  }

  private setIsOpenDebounced = _.debounce(this.setIsOpen, 100);

  private setPopoverPosition(): void {
    if (this.popoverElement.current === null) {
      return;
    }

    const popoverRect = this.popoverElement.current.getBoundingClientRect();
    const wrapperRect = this.wrapperElement.current.getBoundingClientRect();
    const popoverLocation = this.props.popoverLocation;
    const popoverPosition = this.popoverPositionMap[popoverLocation](popoverRect, wrapperRect);

    this.setState({ popoverPosition });
  }

  private updateInterval(): void {
    if (this.state.isOpen && this.positionInterval === undefined) {
      this.positionInterval = requestInterval(this.setPopoverPosition, 10);
    } else if (!this.state.isOpen) {
      this.clearPositionInterval();
    }
  }
}
