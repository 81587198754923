import * as React from 'react';
import * as PropTypes from 'prop-types';

import { Keys } from 'locales/keys';
import { ObjectType } from 'modules/dms-object/enums';
import { LocalizationService } from 'modules/main/services/localization-service';
import { DmsIcon } from 'modules/presentation/components/DmsIcon';
import { Icon } from 'modules/presentation/enums';

interface IImportIndicatorProps {
  className: string;
  objectType: ObjectType;
}

const getIconTitle = (objectType: ObjectType) => {
  switch (objectType) {
    case ObjectType.StorageProviderDocument:
      return LocalizationService.localize(Keys.ContentHub.DocumentImported, {});
    case ObjectType.StorageProviderFolder:
      return LocalizationService.localize(Keys.ContentHub.AllDocumentsImported, {});
    default:
      return null;
  }
};

const ImportIndicator: React.SFC<IImportIndicatorProps> = (props): JSX.Element => {
  return (
    <DmsIcon
      className={props.className}
      icon={Icon.Imported}
      iconTitle={getIconTitle(props.objectType)}
    />
  );
};

ImportIndicator.propTypes = {
  className: PropTypes.string.isRequired,
  objectType: PropTypes.oneOf(Object.values(ObjectType)).isRequired,
};

export { ImportIndicator };
