import * as _ from 'lodash';

import { Keys } from 'locales/keys';
import { LocalizationService } from 'modules/main/services/localization-service';
import { isNil } from 'modules/main/services/lodash-extended';
import { Logger } from 'modules/main/services/js-logger-extended';
import { SearchType } from 'modules/search/enums/search-type';

import { ObjectType } from 'modules/dms-object/enums/object-type';

export class ObjectTypeService {
  public static $inject = ['LocalizationService'];

  constructor(private localizationService: LocalizationService) {
    this.getLocalizedText = this.getLocalizedText.bind(this);
  }

  public convertSubTypeFromServerToClient(dmsObject) {
    if (dmsObject.objectType == ObjectType.SubType) {
      if (dmsObject.superType.toLowerCase() == ObjectType.Document.toLowerCase()) {
        dmsObject.objectType = ObjectType.DocumentType;
      } else if (dmsObject.superType.toLowerCase() == ObjectType.Certificate.toLowerCase()) {
        dmsObject.objectType = ObjectType.CertificateType;
      } else if (dmsObject.superType.toLowerCase() == ObjectType.Course.toLowerCase()) {
        dmsObject.objectType = ObjectType.CourseType;
      }
    }
  }

  public convertToSearchType = (objectType: ObjectType): SearchType => {
    switch (objectType) {
      case ObjectType.Document:
        return SearchType.Documents;
      case ObjectType.Manual:
        return SearchType.Manuals;
      case ObjectType.StandardsNode:
        return SearchType.StandardsNodes;
      case ObjectType.Assessment:
        return SearchType.Assessments;
      case ObjectType.Certificate:
        return SearchType.Certificates;
      case ObjectType.Course:
        return SearchType.Courses;
      case ObjectType.Test:
        return SearchType.Tests;
      case ObjectType.Survey:
        return SearchType.Surveys;
      case ObjectType.User:
        return SearchType.Users;
      case ObjectType.Group:
        return SearchType.Groups;
      case ObjectType.Folder:
        return SearchType.FoldersAndDocs;
      case ObjectType.CertificateType:
        return SearchType.CertificateTypes;
      case ObjectType.CourseType:
        return SearchType.CourseTypes;
      case ObjectType.DocumentType:
        return SearchType.DocumentTypes;
      case ObjectType.Workflow:
        return SearchType.Workflows;
      case ObjectType.Discussion:
        return SearchType.Discussions;
      case ObjectType.EventLog:
        return SearchType.EventLog;
      case ObjectType.StorageProvider:
      case ObjectType.StorageProviderFolder:
        return SearchType.Folders;
      case ObjectType.StorageProviderDocument:
        return SearchType.Documents;
    }

    Logger.error('Cannot convert to SearchType', objectType);
    return undefined;
  };

  public getChildTypeofContainer(containerObjectType: ObjectType): ObjectType {
    switch (containerObjectType) {
      case ObjectType.DocumentContainer:
        return ObjectType.Folder;
      case ObjectType.Folder:
        return ObjectType.Document;
      case ObjectType.ManualContainer:
        return ObjectType.Manual;
      case ObjectType.Manual:
        return ObjectType.StandardsNode;
      case ObjectType.StandardsNode:
        return ObjectType.StandardsNode;
      case ObjectType.AssessmentContainer:
        return ObjectType.Assessment;
      case ObjectType.Assessment:
        return ObjectType.StandardsNode;
      case ObjectType.CertificateContainer:
        return ObjectType.CertificateType;
      case ObjectType.CourseContainer:
        return ObjectType.CourseType;
      case ObjectType.TestContainer:
        return ObjectType.Test;
      case ObjectType.SurveyContainer:
        return ObjectType.Survey;
      case ObjectType.DocumentType:
        return ObjectType.Document;
      case ObjectType.CertificateType:
        return ObjectType.Certificate;
      case ObjectType.CourseType:
        return ObjectType.Course;
      case ObjectType.GroupsContainer:
        return ObjectType.Group;
      case ObjectType.Group:
        return ObjectType.User;
      case ObjectType.StorageProvidersRoot:
        return ObjectType.StorageProvider;
      case ObjectType.StorageProvider:
        return ObjectType.StorageProviderFolder;
      case ObjectType.StorageProviderFolder:
        return ObjectType.StorageProviderDocument;
    }

    Logger.error('Cannot find child ObjectType for container', containerObjectType);
  }

  public getLocalizedText(objectType: ObjectType): string {
    return getLocalizedText(objectType);
  }

  public getRootContainerType(objectType: ObjectType): ObjectType {
    switch (objectType) {
      case ObjectType.Document:
      case ObjectType.DocumentType:
      case ObjectType.Folder:
        return ObjectType.Folder;
      case ObjectType.Certificate:
      case ObjectType.CertificateType:
        return ObjectType.CertificateContainer;
      case ObjectType.Course:
      case ObjectType.CourseType:
        return ObjectType.CourseContainer;
      case ObjectType.Manual:
        return ObjectType.ManualContainer;
      case ObjectType.Assessment:
        return ObjectType.AssessmentContainer;
      case ObjectType.Test:
        return ObjectType.TestContainer;
      case ObjectType.Survey:
        return ObjectType.SurveyContainer;
      case ObjectType.User:
      case ObjectType.Group:
        return ObjectType.GroupsContainer;
      case ObjectType.StorageProviderFolder:
      case ObjectType.StorageProviderDocument:
        return ObjectType.StorageProvider;
      case ObjectType.StorageProvider:
        return ObjectType.StorageProvidersRoot;
      default:
        // Pass through anything unrecognized because it's a root type already.
        return objectType;
    }
  }

  public getViewObjectType = (dmsObject, revisionId?): ObjectType => {
    if (!dmsObject) return undefined;

    var objectType = dmsObject.objectType;

    if (objectType === ObjectType.Group && (dmsObject.linkedJobTitle || dmsObject.linkedLocation)) {
      return ObjectType.LinkedGroup;
    }

    if (objectType === ObjectType.Document) {
      if (revisionId) {
        if (dmsObject.publishedRevisionId === revisionId) {
          return ObjectType.Document;
        } else if (dmsObject.draftRevisionId === revisionId) {
          return ObjectType.DraftRevision;
        } else if (
          dmsObject.publishedRevisionId === undefined &&
          dmsObject.draftRevisionId === undefined
        ) {
          return ObjectType.ArchivedRevision;
        }
      }

      if (dmsObject.publishedRevisionId) {
        return ObjectType.Document;
      } else if (dmsObject.draftRevisionId) {
        return ObjectType.DraftRevision;
      } else {
        return ObjectType.ArchivedRevision;
      }
    }

    if (objectType === ObjectType.Folder && dmsObject.isSystem) {
      return ObjectType.DocumentContainer;
    }

    return objectType;
  };

  public isContainerType(objectType: ObjectType): boolean {
    switch (objectType) {
      case ObjectType.Folder:
      case ObjectType.Group:
      case ObjectType.StorageProviderFolder:
        return true;
    }

    return false;
  }

  public isMockRootObjectType(objectType: ObjectType): boolean {
    switch (objectType) {
      case ObjectType.DocumentContainer:
      case ObjectType.ManualContainer:
      case ObjectType.AssessmentContainer:
      case ObjectType.CertificateContainer:
      case ObjectType.CourseContainer:
      case ObjectType.TestContainer:
      case ObjectType.SurveyContainer:
      case ObjectType.GroupsContainer:
        return true;
      default:
        return false;
    }
  }

  public isSubType(objectType: ObjectType): boolean {
    switch (objectType) {
      case ObjectType.DocumentType:
      case ObjectType.CertificateType:
      case ObjectType.CourseType:
        return true;
      default:
        return false;
    }
  }
}

export function getLocalizedText(objectType: ObjectType): string {
  if (isNil(objectType)) {
    return '';
  }

  const matchingKey = _.find(Object.keys(ObjectType), (key) => ObjectType[key] === objectType);
  const key = Keys.ObjectTypes[matchingKey];

  if (!isNil(key)) {
    return LocalizationService.localize(key, {});
  }

  return '';
}
