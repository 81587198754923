import { Keys } from 'locales/keys';
import { isNil, isNonEmptyString } from 'modules/main/services/lodash-extended';

enum ErrorCode {
  ActionCannotBePerformedOnArchivedAssessment = 'action_cannot_be_performed_on_archived_assessment',
  AdminRequired = 'admin_required',
  AssessmentAuditPrivilegesRequired = 'assessment_audit_privileges_required',
  AssessmentAuditStandardsViewPrivilegesRequired = 'assessment_audit_standards_view_privileges_required',
  AssessmentContentCannotBeAttachedToNode = 'assessment_content_cannot_be_attached_to_node',
  AssessmentRecurringTaskSaveError = 'assessment_recurring_task_save_error',
  BadRequest = 'bad_request',
  BankResultNotFinished = 'bank_result_not_finished',
  BankResultNotStarted = 'bank_result_not_started',
  BankResultTimeExpired = 'bank_result_time_expired',
  CannotAddGroupToSelf = 'cannot_add_group_to_self',
  CannotBindUserToDefault = 'cannot_bind_user_to_default',
  CertificateLabelsTooLong = 'certificate_labels_too_long',
  ContentHubUserAlreadyLinked = 'powerdms_user_already_linked_to',
  CouldNotAccessFile = 'could_not_access_file',
  CreateDocumentPrivileges = 'create_document_privileges',
  CreateDocumentTypePrivileges = 'create_document_type_privileges',
  CrossOrigin = 'cross_origin',
  DefaultError = 'default_error',
  DocumentDescriptionTooLong = 'document_description_too_long',
  DocumentNameExists = 'document_name_exists',
  DocumentNameExistsInFolder = 'document_name_exists_in_folder',
  DocumentNameExistsInSite = 'document_name_exists_in_site',
  DocumentNameTooLong = 'document_name_too_long',
  DocumentNotFound = 'document_not_found',
  DocumentRevisionIsLocked = 'document_revision_is_locked',
  DraftAlreadyExists = 'draft_already_exists',
  DeepLinkError = 'deep_link_error',
  EditSitePrivileges = 'edit_site_privileges',
  EmailAlreadyValidated = 'email_already_validated',
  EmailTooLong = 'email_too_long',
  EntityValidationError = 'entity_validation_error',
  ExceededRequestLength = 'exceeded_request_length',
  FeatureNotEnabled = 'feature_not_enabled',
  FileNotFound = 'file_not_found',
  FileSizeExceeded = 'file_size_exceeded',
  FileIsRequired = 'file_is_required',
  FileNoExtension = 'file_no_extension',
  FileSizeUnsupported = 'file_size_unsupported',
  FiveHundredError = '500_error',
  FolderDoesNotExist = 'folder_does_not_exist',
  FolderNameInUse = 'folder_name_in_use',
  FolderNameInvalid = 'folder_name_invalid',
  FourOhFourError = '404_error',
  IdentityProviderAccountAlreadyExists = 'identity_provider_account_already_exists',
  InsufficientEditPermissionsOverDocument = 'insufficient_edit_permissions_over_document',
  InsufficientInfoToMakeGetRequest = 'insufficient_info_to_make_getRequest',
  InsufficientPermissionsOverObject = 'insufficient_permissions_over_object',
  InsufficientPrivileges = 'insufficient_privileges',
  IntegrityMismatch = 'integrity_mismatch',
  InvalidDocumentCreationSource = 'invalid_document_creation_source',
  KeyNotFound = 'key_not_found',
  LoadMoreTimedOut = 'load_more_timed_out',
  MembershipNotFound = 'membership_not_found',
  MembershipWouldCreateCycle = 'membership_would_create_cycle',
  OtpInvalidUserEmail = 'otp_invalid_user_email',
  MultipleFilesUnsupported = 'multiple_files_unsupported',
  MultipleHtmlFilesFound = 'multiple_html_files_found',
  NoAccessTokenFound = 'no_access_token_found',
  NoCertificateTypes = 'no_certificate_types',
  NoConnection = 'no_connection',
  NoCourseTypes = 'no_course_types',
  NoInternet = 'no_internet',
  NoNameSpecified = 'no_name_specified',
  NoRightsToProviderFile = 'no_rights_to_provider_file',
  NodesNotFoundForAssessment = 'nodes_not_found_for_assessment',
  None = 'none',
  NotFound = 'not_found',
  ObjectDoesNotExist = 'object_does_not_exist',
  ObjectNotFound = 'object_not_found',
  OperationCancelled = 'operation_cancelled',
  OtpInvalid = 'otp_invalid',
  PageNotFound = 'page_not_found',
  ProductNotPurchased = 'product_not_purchased',
  PropertyValidation = 'property_validation',
  ProviderAccessRevoked = 'provider_access_revoked',
  ProviderAuthenticationFailed = 'provider_authentication_failed',
  ProviderNotFound = 'provider_not_found',
  PublicationDoesNotExist = 'publication_does_not_exist',
  ReachedMaxUserCount = 'reached_max_user_count',
  RequiredParametersNotFound = 'required_parameters_not_found',
  RequiresAdminPrivileges = 'requires_admin_privileges',
  RequiresParticipationAssignment = 'requires_participation_assignment',
  RevisionNotFound = 'revision_not_found',
  ServerTimeout = 'server_timeout',
  SiteAuthenticationFailed = 'site_authentication_failed',
  SourceDocumentIdUnspecified = 'source_document_id_unspecified',
  StandardsManualViewPrivilegesRequired = 'standards_manual_view_privileges_required',
  StorageProviderFileNotFound = 'storage_provider_file_not_found',
  SubscriberUserIsAlreadyConnectedToPublisherSite = 'subscriber_user_is_already_connected_to_publisher_site',
  TagAlreadyExists = 'tag_already_exists',
  TimezoneNotFound = 'timezone_not_found',
  UnableToCalculateFileHash = 'unable_to_calculate_file_hash',
  UnauthorizedRequest = 'unauthorized_request',
  UndoFailedPermissions = 'undo_failed_permissions',
  UnifiedPasswordResetFailed = 'unified_password_reset_failed',
  Unknown = 'unknown',
  UnknownError = 'unknown_error',
  UnsupportedFileType = 'unsupported_file_type',
  UnknownUnsupportedFileType = 'unknonwn_unsupported_file_type',
  UserNotAssignedSubmission = 'user_not_assigned_submission',
  UsernameAlreadyExists = 'username_already_exists',
  UsernameRequired = 'username_required',
  WrongPassword = 'wrong_password',
}

namespace ErrorCode {
  const toLocalizationKeyMap = Object.keys(ErrorCode).reduce((map, key) => {
    const errorKey = Keys.Errors[key];

    if (!isNil(errorKey)) {
      map[ErrorCode[key]] = errorKey;
    }

    return map;
  }, {});

  /**
   * Converts an error code to its mapped localization key.
   * If the code is not mapped it will return "Keys.Errors.UnknownError" by default.
   */
  export function toLocalizationKey(code: ErrorCode) {
    if (isNonEmptyString(code)) {
      return toLocalizationKeyMap[code] || Keys.Errors.UnknownError;
    }

    return Keys.Errors.UnknownError;
  }
}

export { ErrorCode };
