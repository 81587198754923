import * as _ from 'lodash';
import * as React from 'react';
import { ReactDirective } from 'ngreact';

import * as PropTypes from 'prop-types';
import { Keys } from 'locales/keys';
import { LocalizationService } from 'modules/main/services/localization-service';

export type TDmsObjectNameProps = {
  name: string;
  username?: string;
};

const renderDisplayUsername = (username): JSX.Element => {
  if (!_.isNonEmptyString(username)) return;

  const displayUsername = LocalizationService.localize(Keys.CommonTerms.Parentheses, {
    text: username,
  });

  return <span className="dmsObjectName_Username">{displayUsername}</span>;
};

const DmsObjectName: React.SFC<TDmsObjectNameProps> = ({
  name,
  username,
}: TDmsObjectNameProps): JSX.Element => {
  return (
    <span className="dmsObjectName" title={name}>
      {name}
      {renderDisplayUsername(username)}
    </span>
  );
};

export { DmsObjectName };

export class dmsObjectNameDirective implements ng.IDirective {
  public static $inject = ['reactDirective'];

  constructor(reactDirective: ReactDirective) {
    return reactDirective(DmsObjectName as any);
  }
}

DmsObjectName.propTypes = {
  name: PropTypes.string.isRequired,
  username: PropTypes.string,
};
