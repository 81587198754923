import * as React from 'react';
import { default as MaterialTypography, TypographyProps } from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as clsx from 'clsx/dist/clsx';
import defaultTheme from 'modules/presentation/themes/defaultTheme';

import { DefaultPalette } from 'modules/presentation/enums/DefaultPalette';

type TLimitedVariance = Exclude<TypographyProps['variant'], 'subtitle1' | 'subtitle2'>;
export type TTypographyProps = Omit<TypographyProps, 'color' | 'variant'> & {
  color?: 'initial' | 'inherit' | DefaultPalette;
  variant?: TLimitedVariance | 'buttonCompact';
  /**
   * A hack to get around difficulty in typing component prop for a wrapper component. This hacks
   * forgoes compile-time type-safety. React will still warn in development mode if an invalid HTML
   * tag is rendered. If there's a better way to handle this in the future, please revise.
   * More context: https://github.com/mui-org/material-ui/issues/15827
   */
  component?: string;
  ellipse?: boolean;
};

/**
 * TODO: options.defaultTheme in the second argument is needed for the styling to work outside React
 * where we can't use <ThemeProvider />. When this component is not used outside React anymore,
 * remove it.
 */
const useStyles = makeStyles<typeof defaultTheme, TTypographyProps>(
  (theme) => {
    return {
      root: { color: (props) => props.color },
      /**
       * TODO: These classes are needed to apply the right style in AngularJS where we can't use
       * <ThemeProvider />. They are not needed for React app. When this component is not used
       * outside of React anymore, remove these.
       */
      h1: { ...theme.typography.h1 },
      h2: { ...theme.typography.h2 },
      h3: { ...theme.typography.h3 },
      h4: { ...theme.typography.h4 },
      h5: { ...theme.typography.h5 },
      h6: { ...theme.typography.h6 },
      body1: { ...theme.typography.body1 },
      body2: { ...theme.typography.body2 },
      button: { ...theme.typography.button },
      caption: { ...theme.typography.caption },
      overline: { ...theme.typography.overline },
      /**
       * `buttonCompact' variant is custom and material-ui won't know how to handle it so we'll
       *  apply the styling via CSS class instead.
       */
      buttonCompact: { ...theme.typography.buttonCompact },
      body1Paragraph: { lineHeight: 1.25 },
    };
  },
  { defaultTheme },
);

const Typography: React.FC<TTypographyProps> = (props) => {
  const { color, variant = 'body1', ellipse, ...rest } = props;
  const styles = useStyles(props);
  const className = clsx({
    [styles.root]: color,
    [styles.buttonCompact]: variant === 'buttonCompact',
    [styles.body1Paragraph]: variant === 'body1' && props.paragraph,
    /**
     * TODO: These classes are needed to apply the right style in AngularJS where we can't use
     * <ThemeProvider />. When this component is not used outside of React anymore, remove these.
     */
    [styles.h1]: variant === 'h1',
    [styles.h2]: variant === 'h2',
    [styles.h3]: variant === 'h3',
    [styles.h4]: variant === 'h4',
    [styles.h5]: variant === 'h5',
    [styles.h6]: variant === 'h6',
    [styles.body1]: variant === 'body1',
    [styles.body2]: variant === 'body2',
    [styles.button]: variant === 'button',
    [styles.caption]: variant === 'caption',
    [styles.overline]: variant === 'overline',
  });

  return (
    <MaterialTypography
      className={`${className} ${ellipse ? 'ellipse' : ''}`}
      variant={mapCustomVariantToMaterial(props.variant)}
      {...rest}
    />
  );
};

function mapCustomVariantToMaterial(variant: TTypographyProps['variant']): TLimitedVariance {
  /**
   * `button` is the closest to `buttonCompact` so we'll use that as a base and cascade a style
   * sheet on top of it for styling.
   */
  return variant === 'buttonCompact' ? 'button' : variant;
}

// TODO: Create Angular directive if you want to use this there.

export { Typography };
