import * as H from 'history';
import { isEmpty } from 'lodash';
import { isNonEmptyString } from 'modules/main/services/lodash-extended';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setScrollToKey, selectDocument, toggleFolder } from 'modules/pub-docs/actions/treeActions';

import { fetchDocs } from 'modules/pub-docs/actions/documentsActions';
import { fetchSiteInfo } from 'modules/pub-docs/actions/siteInfoActions';
import { initApp } from 'modules/pub-docs/actions/mainActions';
import { DocumentMissingMessageBox } from 'modules/pub-docs/components/DocumentMissingMessageBox';
import { LoadFailureMessageBox } from 'modules/pub-docs/components/LoadFailureMessageBox';
import { TreeView } from 'modules/pub-docs/components/TreeView';
import {
  getSelectedDocument,
  treeViewHeaderIsLoading,
  treeViewSideBarIsLoading,
} from 'modules/pub-docs/selectors';
import { TApplicationState } from 'modules/pub-docs/store';

type TPropsFromRedux = ReturnType<typeof mapStateToProps> &
  Partial<ReturnType<typeof mapDispatchToProps>>;

export type TPropsFromRoute = {
  history: H.History;
  documentId?: string;
  siteKey: string;
};

type TPubDocsTreeContainerProps = TPropsFromRoute & TPropsFromRedux;

const mapStateToProps = (state: TApplicationState, props: TPropsFromRoute) => ({
  documentDataStatus: state.documents.status,
  headerIsLoading: treeViewHeaderIsLoading(state, props),
  openedFolders: state.tree.openedFolders,
  documents: state.documents.list,
  errorCode: state.documents.errorCode,
  hasDocumentsError: state.documents.errorMessage !== undefined,
  scrollToKey: state.tree.scrollToKey,
  selectedDocument: getSelectedDocument(state, props),
  sideBarIsLoading: treeViewSideBarIsLoading(state),
  siteName: state.siteInfo.data.siteName,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDocs,
      fetchSiteInfo,
      initApp,
      setScrollToKey,
      selectDocument,
      toggleFolder,
    },
    dispatch,
  );

function noop() {}

export class TreeViewContainer extends React.Component<TPubDocsTreeContainerProps, {}> {
  static defaultProps = {
    fetchDocs: noop,
    fetchSiteInfo: noop,
    initApp: noop,
    selectDocument: noop,
    setScrollToKey: noop,
    toggleFolder: noop,
  };

  constructor(props) {
    super(props);

    this.handleScrollToTreeItemEvent = this.handleScrollToTreeItemEvent.bind(this);
    this.onScrollEnd = this.onScrollEnd.bind(this);
    this.reload = this.reload.bind(this);
  }

  public render(): JSX.Element {
    return (
      <TreeView
        headerIsLoading={this.props.headerIsLoading}
        history={this.props.history}
        onScrollEnd={this.onScrollEnd}
        openedFolders={this.props.openedFolders}
        documentId={this.props.documentId}
        documents={this.props.documents}
        hasDocumentsError={this.props.hasDocumentsError}
        scrollToKey={this.props.scrollToKey}
        selectDocument={this.props.selectDocument}
        selectedDocument={this.props.selectedDocument}
        sideBarIsLoading={this.props.sideBarIsLoading}
        siteKey={this.props.siteKey}
        siteName={this.props.siteName}
        toggleFolder={this.props.toggleFolder}
        topMessage={this.showMessage()}
      />
    );
  }

  public componentDidMount(): void {
    this.init();
    window.addEventListener('scrollToTreeItem', this.handleScrollToTreeItemEvent);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scrollToTreeItem', this.handleScrollToTreeItemEvent);
  }

  private handleScrollToTreeItemEvent({ detail }: CustomEvent): void {
    if (!isEmpty(detail) && isNonEmptyString(detail.treeItemKey)) {
      this.props.setScrollToKey(detail.treeItemKey);
    }
  }

  private onScrollEnd(): void {
    this.props.setScrollToKey(null);
  }

  private reload(): void {
    this.init();
  }

  private init() {
    const alreadyHasPubDocs = this.props.documents.length > 0;
    const hasSiteName = this.props.siteName !== undefined;

    if (!alreadyHasPubDocs && !hasSiteName) {
      this.props.initApp(this.props.siteKey, this.props.documentId);
    } else if (!alreadyHasPubDocs) {
      this.props.fetchDocs(this.props.siteKey);
    } else if (!hasSiteName) {
      this.props.fetchSiteInfo(this.props.siteKey);
    }
  }

  private showMessage(): React.ReactNode {
    const { documentDataStatus, selectedDocument, documentId, sideBarIsLoading } = this.props;

    if (sideBarIsLoading) {
      return;
    }

    if (documentDataStatus === 'error') {
      return <LoadFailureMessageBox errorCode={this.props.errorCode} onRetry={this.reload} />;
    }

    const cannotFindDocument =
      documentDataStatus === 'success' &&
      documentId !== undefined &&
      selectedDocument === undefined;

    if (cannotFindDocument) {
      return <DocumentMissingMessageBox />;
    }

    return;
  }
}

export const EnhancedTreeViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TreeViewContainer);
