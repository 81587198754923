import 'core-js';

// TODO: Minify these for prod using our custom
import 'locales/lib/translations.en.js';
import 'script-loader!custom-event-polyfill';

import * as React from 'react';
import { render } from 'react-dom';

import { App } from 'modules/pub-docs/app';

render(<App />, document.getElementById('root'));
