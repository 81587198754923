import * as React from 'react';
import ReactGa from 'react-ga4';

import { TrackingId } from 'modules/analytics/models/TrackingId';

type TGoogleAnalyticProps = {
  trackers?: any[];
};

export class GoogleAnalytics extends React.Component<TGoogleAnalyticProps, {}> {
  private isInProd: boolean = window.location.hostname.indexOf('powerdms.com') > -1;

  constructor(props: TGoogleAnalyticProps) {
    super(props);
    ReactGa.initialize(this.isInProd ? TrackingId.prodApp : TrackingId.devApp);
  }

  render() {
    return null;
  }
}
