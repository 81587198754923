import * as React from 'react';
import * as H from 'history';
import { isEmpty } from 'lodash';

import { EllipsisLoader } from 'modules/presentation/components/EllipsisLoader';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';
import { DataFetchState } from 'modules/pub-docs/enums/DataFetchState';
import { useIsPdfDocument } from 'modules/pub-docs/services';
import { PdfViewer } from 'modules/pub-docs/components/PdfViewer';
import { PoweredByDms } from 'modules/pub-docs/components/PoweredByDms';
import { Header } from '../Header/Header';
import { IFrame } from './Iframe';

type TFileViewerProps = {
  breadcrumbTrail?: ISearchResult[];
  disablePointerEvents?: boolean;
  documentName?: string;
  documentUrl?: string;
  fileFetchState?: DataFetchState;
  hasDocumentsError?: boolean;
  headerIsLoading?: boolean;
  history?: H.History;
  setFileFetchState?: (fetchState: DataFetchState) => void;
  siteKey?: string;
  siteName?: string;
};

const FileViewer: React.FC<TFileViewerProps> = (props) => {
  const {
    breadcrumbTrail,
    disablePointerEvents,
    documentName,
    documentUrl,
    fileFetchState,
    setFileFetchState,
    hasDocumentsError,
    headerIsLoading,
    history,
    siteKey,
    siteName,
  } = props;

  const isPdfDocument = useIsPdfDocument(documentUrl);
  const shouldShowEmptyState =
    (fileFetchState === undefined || fileFetchState === DataFetchState.Empty) &&
    isEmpty(documentUrl);

  const shouldShowEllipsisLoader = fileFetchState === DataFetchState.Loading;

  return (
    <div data-automation-id="pubDocsFileViewer" className="pubDocsFileViewer">
      <Header
        breadcrumbTrail={breadcrumbTrail}
        documentName={documentName}
        hasDocumentsError={hasDocumentsError}
        headerIsLoading={headerIsLoading}
        history={history}
        siteKey={siteKey}
        siteName={siteName}
      />

      <main className="pubDocsFileViewer_content">
        {shouldShowEmptyState ? (
          <div className="pubDocsFileViewer_emptySection">
            <div className="pubDocsFileViewer_emptySectionImage" />
            <div className="pubDocsFileViewer_footer">
              <PoweredByDms />
            </div>
          </div>
        ) : isPdfDocument ? (
          <PdfViewer documentUrl={documentUrl} setFileFetchState={setFileFetchState} />
        ) : (
          <IFrame
            documentUrl={documentUrl}
            disablePointerEvents={disablePointerEvents}
            setFileFetchState={setFileFetchState}
          />
        )}

        {shouldShowEllipsisLoader && (
          <div className="pubDocsFileViewer_ellipsisLoader">
            <EllipsisLoader />
          </div>
        )}
      </main>
    </div>
  );
};

export { FileViewer };
