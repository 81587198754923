import * as React from 'react';

import { Keys } from 'locales/keys';

import { Localizer } from 'modules/main/components/Localizer';
import { NotificationStyle } from 'modules/notification/enums';
import { Icon } from 'modules/presentation/enums/icon';
import { MessageBox } from 'modules/presentation/components/MessageBox';

// TODO: Consider making this an internal component of TreeViewContainer.
const DocumentMissingMessageBox: React.SFC<{}> = () => {
  return (
    <MessageBox icon={Icon.Error} messageStyle={NotificationStyle.Error}>
      <Localizer translation={Keys.PublicDocs.DocumentMissingMessage} />
    </MessageBox>
  );
};

export { DocumentMissingMessageBox };
