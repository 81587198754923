import * as React from 'react';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import { Icon } from 'modules/presentation/enums';
import { DmsIcon } from '../DmsIcon';

export type TSidebarProps = DrawerProps & {
  onResize?: (xDelta: number) => void;
  onResizeDone?: () => void;
  isResizable?: boolean;
};

export type TSidebarState = {
  isResizing: boolean;
};

export class Sidebar extends React.Component<TSidebarProps, TSidebarState> {
  private clientXOnMouseDown: number = undefined;

  static defaultProps = {
    isResizable: true,
  };

  constructor(props) {
    super(props);
    this.state = { isResizing: false };

    this.startResizing = this.startResizing.bind(this);
    this.stopResizing = this.stopResizing.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentDidMount() {
    /**
     * This was originally added inside mousedown handler, however, IE11 seems not to register it
     * fast enough sometimes resulting in user geting stuck in resizing mode until the next click.
     * Adding it here will ensure user is not stuck.
     */
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  startResizing() {
    this.setState((prevState) => {
      return prevState.isResizing ? undefined : { isResizing: true };
    });
  }

  stopResizing() {
    this.setState((prevState) => {
      return prevState.isResizing ? { isResizing: false } : undefined;
    });
  }

  handleMouseDown(event: React.MouseEvent<HTMLDivElement>) {
    this.startResizing();
    this.clientXOnMouseDown = event.clientX;

    document.addEventListener('mousemove', this.handleMouseMove, true);

    if (this.props.onResize !== undefined) {
      this.props.onResize(event.clientX - this.clientXOnMouseDown);
    }
  }

  handleMouseUp(event: MouseEvent) {
    // Since this handler got registered on cDM, we need to make sure it only runs when necessary.
    if (!this.state.isResizing) {
      return;
    }

    this.stopResizing();
    this.clientXOnMouseDown = undefined;

    document.removeEventListener('mousemove', this.handleMouseMove, true);

    if (this.props.onResizeDone !== undefined) {
      this.props.onResizeDone();
    }

    // this prevents IE11 and firefox from selecting text on the page while dragging
    window.getSelection().removeAllRanges();
  }

  handleMouseMove(event: MouseEvent) {
    /**
     * This prevents area/text selection while dragging in Chrome. Unfortunately, this doesn't seem
     * to work for IE11.
     */
    this.stopEvent(event);

    if (this.props.onResize !== undefined) {
      this.props.onResize(event.clientX - this.clientXOnMouseDown);
    }

    // this prevents IE11 and firefox from selecting text on the page while dragging
    window.getSelection().removeAllRanges();
  }

  private stopEvent(event: Event) {
    const e = event || window.event;

    e.stopPropagation();
    e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;

    return false;
  }

  render() {
    const { onResize, onResizeDone, isResizable, children, ...rest } = this.props;

    return (
      <div className="sidebar">
        <Drawer
          variant="permanent"
          classes={{
            docked: 'sidebar_docked',
            paperAnchorDockedLeft: 'sidebar_paper',
          }}
          {...rest}
        >
          {children}
        </Drawer>
        {isResizable && (
          <div className="sidebar_handle" onMouseDown={this.handleMouseDown}>
            <DmsIcon className="sidebar_handleIcon" icon={Icon.DropDownEllipsis} />
          </div>
        )}
      </div>
    );
  }
}
