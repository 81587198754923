export enum LanguageDatepickerFormat {
  SiteDefault = 'M/d/yyyy',
  English = 'M/d/yyyy',
  Spanish = 'd/M/yyyy',
}

export enum LanguageMomentDateFormat {
  SiteDefault = 'M/D/YYYY',
  English = 'M/D/YYYY',
  Spanish = 'D/M/YYYY',
}

export enum LanguageMaterialDateFormat {
  SiteDefault = 'MM/dd/yyyy',
  English = 'MM/dd/yyyy',
  Spanish = 'dd/MM/yyyy',
}
