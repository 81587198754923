import * as _ from 'lodash';
import { isNonEmptyString } from 'lodash';
import { TDocumentCreateType } from 'modules/document/models/TDocumentCreateType';
import { ErrorCode } from 'modules/error/enums/ErrorCode';
import { AccountSettingsTab } from 'modules/user/enums/AccountSettingsTab';

import { ExternalProviderParam } from '../containers/ExternalProviderProductDetailsContainer/ExternalProviderProductDetailsContainer';
import { isNil } from './lodash-extended';

const version = 'v1';

export function getApiBaseUrl(): string {
  const baseUrl = window.apiBaseUrl || window.top.apiBaseUrl;

  if (_.isString(baseUrl) && baseUrl.length > 0) {
    return `${baseUrl}/${version}`;
  }
  throw 'ERROR: "baseUrl" is not set.';
}

export function getSearchClientApiBaseUrl(): string {
  const searchClientApiBaseUrl = window.searchClientApiBaseUrl || window.top.searchClientApiBaseUrl;

  if (_.isString(searchClientApiBaseUrl) && searchClientApiBaseUrl.length > 0) {
    return `${searchClientApiBaseUrl}`;
  }
  throw 'ERROR: "searchClientApiBaseUrl" is not set.';
}

export function getApiBaseUrlWithoutVersionNumber(): string {
  const baseUrl = getApiBaseUrl();

  return `${baseUrl.replace(`/${version}`, '')}`;
}

export function getUrlSearchParam(url: string, parameter: string) {
  return new URLSearchParams(url?.substring(url.indexOf('?'))).get(parameter);
}

export function getWebsocketsUrl(): string {
  // For readability.
  const websocketsUrl = window.websocketsUrl || window.top.websocketsUrl;

  if (!_.isString(websocketsUrl) || websocketsUrl.length === 0) {
    throw 'ERROR: websocketsUrl is not set.';
  }

  return websocketsUrl;
}

export function getGraphQlApiBaseUrl(): string {
  const graphQlApiBaseUrl = window.graphQlApiBaseUrl || window.top.graphQlApiBaseUrl;

  if (!_.isString(graphQlApiBaseUrl) || graphQlApiBaseUrl.length === 0) {
    throw 'ERROR: graphQlApiBaseUrl is not set.';
  }

  return graphQlApiBaseUrl;
}

export function getStandardsGraphQlApiBaseUrl(): string {
  const standardsGraphQlApiBaseUrl =
    window.standardsGraphQlApiBaseUrl || window.top.standardsGraphQlApiBaseUrl;

  if (!_.isString(standardsGraphQlApiBaseUrl) || standardsGraphQlApiBaseUrl.length === 0) {
    throw 'ERROR: standardsGraphQlApiBaseUrl is not set.';
  }

  return standardsGraphQlApiBaseUrl;
}

export function removeQueryParameter(url: string, parameter: string): string {
  // http://stackoverflow.com/questions/1634748/how-can-i-delete-a-query-string-parameter-in-javascript
  const urlParts = url.split('?');

  if (urlParts.length >= 2) {
    const parts = urlParts[1].split(/[&;]/g);
    const prefix = `${encodeURIComponent(parameter)}=`;

    for (let i = parts.length; i-- > 0; ) {
      if (parts[i].lastIndexOf(prefix, 0) !== -1) {
        parts.splice(i, 1);
      }
    }

    const foo = parts.length > 0 ? `?${parts.join('&')}` : '';

    url = urlParts[0] + foo;

    return url;
  }
  return url;
}

export const apiUrls = {
  documents: {
    createDraftFromPublication: (documentId: string) => {
      return `${getApiBaseUrl()}/documents/${documentId}/revisions?source=current_publication`;
    },
    get: (
      documentId: string,
      includeCallingContext: boolean = false,
      useNewDocumentMetadataApi: boolean = false,
    ) => {
      const callingContext = includeCallingContext ? '?calling_context' : '';

      if (useNewDocumentMetadataApi) {
        return `${getApiBaseUrl()}/documents/${documentId}/metadata${callingContext}`;
      }

      return `${getApiBaseUrl()}/documents/${documentId}${callingContext}`;
    },
    upload: (folderId: string, documentId?: string, revisionId?: string) => {
      let url = `${getApiBaseUrl()}/documents`;

      if (!isNil(documentId)) {
        url = `${url}/${documentId}/revisions`;
      }

      if (!isNil(documentId) && !isNil(revisionId)) {
        url = `${url}/${revisionId}/content`;
      }

      if (!isNil(folderId)) {
        url = `${url}?folderId=${encodeURIComponent(folderId)}`;
      }

      return url;
    },
    uploadFromSource: (
      folderId: string,
      source: string,
      documentId?: string,
      revisionId?: string,
    ) => {
      const url = apiUrls.documents.upload(folderId, documentId, revisionId);

      if (isNil(folderId)) {
        return `${url}?source=${source}`;
      }

      return `${url}&source=${source}`;
    },
    uploadFromTemplate: (
      folderId: string,
      documentTemplateId: string,
      documentId?: string,
      revisionId?: string,
    ) => {
      const url = apiUrls.documents.uploadFromSource(
        folderId,
        'existing_document',
        documentId,
        revisionId,
      );

      return `${url}&sourceId=${documentTemplateId}`;
    },
  },
  folders: {
    get: (folderId: string, includeCallingContext: boolean = false) => {
      const callingContext = includeCallingContext ? '?calling_context' : '';

      return `${getApiBaseUrl()}/folders/${folderId}${callingContext}`;
    },
    rootFolders: (includeCallingContext: boolean = false) => {
      const callingContext = includeCallingContext ? '?calling_context' : '';

      return `${getApiBaseUrl()}/folders/site-root/content${callingContext}`;
    },
  },
};

export const pageUrls = {
  // TODO: We should relocate all the logic of "advanced-search-url-service.ts" here.
  advancedSearch: {
    documents: '/advancedSearch/documents',
    everything: '/advancedSearch/everything',
    tags: (tags: string[]) => {
      const filters = encodeURIComponent(`{"tags":["${tags.join(',')}"]}`);

      return `${pageUrls.advancedSearch.everything}?filters=${filters}`;
    },
  },
  accountSettings: {
    default: '/accountSettings',
    tab: (tab: AccountSettingsTab) => `${pageUrls.accountSettings.default}/${tab}`,
  },
  apiDocumentation: 'https://apidocs.powerdms.com/',
  documents: {
    default: (
      id: string,
      revisionId?: string,
      queryProps?: {
        edit?: boolean;
        infoEdit?: boolean;
        nameTruncated?: boolean;
        workflowId?: string;
      },
    ) => {
      let url = `/docs/${id}`;

      if (isNonEmptyString(revisionId)) {
        url += `/revisions/${revisionId}`;
      }

      if (!isNil(queryProps)) {
        const queryProps2: any = {};

        if (queryProps?.edit) {
          queryProps2.edit = true;
        }
        if (queryProps?.infoEdit) {
          queryProps2.infoEdit = true;
        }
        if (queryProps?.nameTruncated) {
          queryProps2.nameTruncated = true;
        }
        if (isNonEmptyString(queryProps?.workflowId)) {
          queryProps2.workflowId = queryProps.workflowId;
        }

        const query = new URLSearchParams(queryProps2).toString();
        if (isNonEmptyString(query)) {
          url += `?${query}`;
        }
      }

      return url;
    },
    download: (revisionId: string) => `/client/downloadDocument.aspx?RevID=${revisionId}`,
    viewFile: (documentId: string, revisionId: string, fileId: string) =>
      `/client/ViewFile.aspx?FullScreen=true&DocumentRevisionID=${revisionId}&DocumentID=${documentId}&FileID=${fileId}&context=document`,
    manage: {
      activity: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvActivity`,
      assignments: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvAssignments`,
      general: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvGeneral`,
      review: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvReview`,
      revisions: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvRevisions`,
      security: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvSecurity`,
      signatures: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvSignatures`,
      standards: (id: string, revisionId?: string) =>
        `${pageUrls.documents.default(id, revisionId)}/admin/cvStandards`,
    },
    new: {
      default: (folderId?: string) => {
        const folderQueryParam = isNonEmptyString(folderId) ? `?folderId=${folderId}` : '';

        return `/docs/new${folderQueryParam}`;
      },
      draft: (id: string) => `${pageUrls.documents.default(id)}/revisions/new-draft`,
      draftActiveFile: (id: string, revisionId: string) =>
        `${pageUrls.documents.default(id, revisionId)}/new-draft`,
      getNewDocumentUrlFromCreateType: (
        createType: TDocumentCreateType,
        documentId: string,
        revisionId?: string,
      ) => {
        switch (createType) {
          case 'draft':
            return pageUrls.documents.new.draft(documentId);
          case 'draftActiveFile':
            return pageUrls.documents.new.draftActiveFile(documentId, revisionId);
          case 'publishedActiveFile':
            return pageUrls.documents.new.publishedActiveFile(documentId, revisionId);
          case 'publishedRevision':
            return pageUrls.documents.new.publishedRevision(documentId);
        }
      },
      publishedActiveFile: (id: string, revisionId: string) =>
        `${pageUrls.documents.default(id, revisionId)}/new-publish`,
      publishedRevision: (id: string) => `${pageUrls.documents.default(id)}/revisions/new-publish`,
      workflow: (id: string, revisionId: string) =>
        `${pageUrls.documents.default(id, revisionId)}/new-workflow`,
    },
    publicFacingLink: (siteKey, documentId) => `/link/${siteKey}/document/?id=${documentId}`,
  },
  error: '/ui/Error.aspx',
  errorCode: (code: ErrorCode) => `${pageUrls.error}?code=${code}`,
  folders: {
    default: (id: string) => `/folders/${id}`,
    content: (id: string) => `${pageUrls.folders.default(id)}/content`,
    security: (id: string) => `${pageUrls.folders.default(id)}/security`,
  },
  home: '/home',
  inbox: {
    inbox: '/inbox',
    documents: '/inbox/documents',
    messages: '/inbox/messages',
    certificates: '/inbox/certificates',
    courses: '/inbox/courses',
    tests: '/inbox/tests',
    surveys: '/inbox/surveys',
    standards: '/inbox/standards',
    recall: '/inbox/recall',
  },
  licensing: `/admin/Configuration/Registration.aspx`,
  login: {
    login: '/ui/Login.aspx',
    logout: '/ui/Logout.aspx',
    setPassword: '/client/SetPassword.aspx',
    siteAgreement: '/client/SiteAgreement.aspx',
    updateRequiredSecuritySettings: '/client/UpdateRequiredSecuritySettings.aspx',
  },
  manual: {
    default: (id: string) => `/assessments/${id}`,
    node: (id: string, nodeId: string) => `${pageUrls.manual.default(id)}/node/${nodeId}`,
    proofs: (id: string, nodeId: string) => `${pageUrls.manual.node(id, nodeId)}?ToolTabID=Proofs`,
  },
  notification: '/admin/Notify.aspx',
  powerdms: 'https://www.powerdms.com',
  privacyPolicy: 'https://www.powerdms.com/privacy-policy/',
  productDetails: (app: ExternalProviderParam) => `/apps/${app}`,
  reports: {
    documents: '/reports/Documents',
    certificates: '/reports/Certificates',
    courses: '/reports/Courses',
    standards: '/reports/Standards',
    standardStatusTimeline: (
      manualId: string,
      assessmentId: string,
      roleId: string,
      startDate: string,
      intervalCount: string,
    ) => {
      return (
        `/standards/StandardStatusTimeline.aspx?mode=Standards` +
        `&ReportType=StatusTimeline` +
        `&ManualID=${manualId}` +
        `&AssessmentID=${assessmentId}` +
        `&RoleID=${roleId}` +
        `&StartDate=${startDate}` +
        `&IntervalCount=${intervalCount}` +
        `&DisplayMode=FullTimeline` +
        `&ColumnCount=6` +
        `&Export=WebPage`
      );
    },
    analyticsStandardStatusTimeline: (
      manualName: string,
      assessmentName: string,
      roleName: string,
      startDate: string,
      intervalCount: string,
    ) => {
      const filters = [
        {
          key: 'Manual Title',
          value: encodeURIComponent(manualName),
        },
        {
          key: 'Assessment Name',
          value: encodeURIComponent(assessmentName),
        },
        {
          key: 'Role Name',
          value: encodeURIComponent(roleName),
        },
        {
          key: 'Stard Date',
          value: encodeURIComponent(startDate),
        },
        {
          key: 'Number of Years',
          value: encodeURIComponent(intervalCount),
        },
      ];

      return `/analytics/standard_status_timeline?filter=${JSON.stringify(filters)}`;
    },
    tests: '/reports/Tests',
    surveys: '/reports/Surveys',
    users: '/reports/Users',
    contentHub: '/reports/ContentHub',
    embeddedAnalytics: '/reports/EmbeddedAnalytics',
  },
  signup: {
    signup: '/signup',
    step1: '/signup/step1',
    step2: '/signup/step2',
    step3: '/signup/step3',
  },
  ssa: {
    error: '/ssa/error',
    createAdmin: '/ssa/createAdmin',
    downloadApp: '/ssa/downloadApp',
  },
  standards: {
    assessments: {
      assessmentNodeProofTab: (assessmentId: string, nodeId: string) =>
        `/assessments/${assessmentId}/node/${nodeId}?tooltabid=Proofs`,
    },
  },
  storageProvider: {
    document: (documentId: string, providerId: string) => {
      const encodedId = encodeURIComponent(documentId);
      const encodedAccountId = encodeURIComponent(providerId);
      return `/providers/${encodedAccountId}/docs/${encodedId}`;
    },
  },
  subscriberManagement: '/admin/Configuration/SubscriberUserManagement.aspx',
  successCommunity: {
    contentHub:
      'https://success.neogov.com/quick-help-for-powerpolicy-7/part-2-of-2-content-hub-series-importing-documents-and-folders-3855?tid=3855&fid=7',
    externalIntegration:
      'https://success.neogov.com/quick-help-for-powerpolicy-7/integrating-with-google-drive-and-onedrive-4146',
  },
  termsOfService: 'https://www.powerdms.com/terms-and-conditions',
  termsOfServiceApi: 'https://www.powerdms.com/api-terms-and-conditions',
  university: 'https://www.powerdmsuniversity.com',
  user: {
    profile: (userId: string) => {
      return `/users/${userId}`;
    },
  },
};

export const notMainAppUrls = [
  pageUrls.apiDocumentation,
  pageUrls.login.login,
  pageUrls.login.logout,
  pageUrls.login.setPassword,
  pageUrls.login.siteAgreement,
  pageUrls.login.updateRequiredSecuritySettings,
  pageUrls.privacyPolicy,
  pageUrls.signup.signup,
  pageUrls.signup.step1,
  pageUrls.signup.step2,
  pageUrls.signup.step3,
  pageUrls.termsOfService,
  pageUrls.university,
];

export default {
  apiUrls,
  getApiBaseUrl,
  getApiBaseUrlWithoutVersionNumber,
  pageUrls,
  notMainAppUrls,
};
