'use strict';

export enum FileExtension {
  Avi = 'Avi',
  Bmp = 'Bmp',
  Doc = 'Doc',
  Docm = 'Docm',
  Docx = 'Docx',
  Dot = 'Dot',
  Dotx = 'Dotx',
  Flv = 'Flv',
  Gif = 'Gif',
  Htm = 'Htm',
  Html = 'Html',
  Jpeg = 'Jpeg',
  Jpg = 'Jpg',
  M4v = 'M4v',
  Mov = 'Mov',
  Mp3 = 'Mp3',
  Mpeg = 'Mpeg',
  Mpg = 'Mpg',
  Pdf = 'Pdf',
  Png = 'Png',
  Pps = 'Pps',
  Ppsx = 'Ppsx',
  Ppt = 'Ppt',
  Pptx = 'Pptx',
  Swf = 'Swf',
  Wav = 'Wav',
  Wma = 'Wma',
  Wmv = 'Wmv',
  Wpd = 'Wpd',
  Xls = 'Xls',
  Xlsm = 'Xlsm',
  Xlsx = 'Xlsx',
  Xlt = 'Xlt',
}
