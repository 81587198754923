import { ConnectDropTarget, DropTarget, DropTargetConnector, DropTargetMonitor } from 'react-dnd';

import { DRAG_TYPE } from '../DmsObjectTreeItemDragSource';
import {
  IDmsObjectTreeItem,
  IDmsObjectTreeItemBase,
} from 'modules/dms-object/models/IDmsObjectTreeItem';
import { IFlatListItem, isIFlatListItem } from 'modules/presentation/services/dms-tree-service';

export interface IDropTargetContentProps {
  draggedItem: IFlatListItem<IDmsObjectTreeItem>;
  isOver: boolean;
}

interface IDropTargetComponentProps {
  canDrop: (
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>,
    draggedItem: IFlatListItem<IDmsObjectTreeItemBase>,
  ) => boolean;
  dropData: IFlatListItem<IDmsObjectTreeItemBase>;
  render: (props: IDropTargetContentProps) => JSX.Element;
}

interface IDropTargetCollectorProps extends IDropTargetComponentProps, IDropTargetContentProps {
  connectDropTarget: ConnectDropTarget;
}

const dropSourceCollector = (connect: DropTargetConnector, monitor: DropTargetMonitor) => {
  const draggedItem = monitor.getItem();

  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    draggedItem: isIFlatListItem(draggedItem) ? draggedItem : undefined,
  };
};

const dropSource = {
  canDrop(props: IDropTargetCollectorProps, monitor: DropTargetMonitor): boolean {
    const draggedItem = monitor.getItem() as IFlatListItem<IDmsObjectTreeItemBase>;

    return props.canDrop(props.dropData, draggedItem);
  },
  drop(props: IDropTargetCollectorProps) {
    /**
     * By return this, it becomes available in the endDrag method in the drag source through
     * monitor.getDropResult()
     */
    return props.dropData;
  },
};

const _DmsObjectTreeItemDropTarget = (props: IDropTargetCollectorProps) => {
  return props.connectDropTarget !== undefined
    ? props.connectDropTarget(props.render(props))
    : props.render(props);
};

export const DmsObjectTreeItemDropTarget = DropTarget<IDropTargetComponentProps>(
  DRAG_TYPE,
  dropSource,
  dropSourceCollector,
)(_DmsObjectTreeItemDropTarget);
