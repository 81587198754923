import * as React from 'react';
import * as PropTypes from 'prop-types';

import { Keys } from 'locales/keys';
import { ErrorCode } from 'modules/error/enums/ErrorCode';
import { Localizer } from 'modules/main/components/Localizer';
import { DataFetchState } from 'modules/pub-docs/enums/DataFetchState';
import { isNil } from 'modules/main/services/lodash-extended';

import { FileViewer } from 'modules/pub-docs/components/FileViewer';
import { PoweredByDms } from 'modules/pub-docs/components/PoweredByDms';
import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';
import { Column } from './Column';
import { LoadFailureMessageBox } from '../LoadFailureMessageBox/LoadFailureMessageBox';

type TListViewProps = {
  headerIsLoading: boolean;
  selectDocument: (document: TPublicDoc) => void;

  documents?: TPublicDoc[];
  errorCode?: ErrorCode;
  hasError?: boolean;
  onRetry?: () => void;
  selectedDocument?: TPublicDoc;
  siteName?: string;
  siteKey?: string;
};

/**
 * TODO: This was componentized for testing purposes. Try testing for the existence of
 * `PublicDocs.EmptyMessage` instead. e.g.
 *
 * window.localStrings['PublicDocs.EmptyMessage'] = 'blah blah';
 *
 * shallow(<PubDocsListView dataFetchState="empty" />);
 *
 * expect 'blah blah` text to exist.
 */
const EmptyMessage: React.FC<{}> = () => (
  <span className="pubDocsListView_empty">
    <Localizer translation={Keys.PublicDocs.EmptyMessage} />
  </span>
);

function useListViewActiveContent(selectedDocument: TPublicDoc) {
  const [activeContent, setActiveContent] = React.useState<'list' | 'fileViewer'>('list');

  React.useEffect(() => {
    if (activeContent === 'fileViewer' && isNil(selectedDocument)) {
      setActiveContent('list');
    }

    if (activeContent === 'list' && !isNil(selectedDocument)) {
      setActiveContent('fileViewer');
    }
  }, [activeContent, selectedDocument]);

  return activeContent;
}

const ListView: React.FC<TListViewProps> = (props: TListViewProps) => {
  const {
    headerIsLoading,
    selectDocument,
    documents,
    errorCode,
    hasError,
    onRetry,
    selectedDocument,
    siteKey,
    siteName,
  } = props;

  const activeContent = useListViewActiveContent(selectedDocument);
  const [fileFetchState, setFileFetchState] = React.useState<DataFetchState>(DataFetchState.Empty);

  if (activeContent === 'fileViewer' && !isNil(selectedDocument)) {
    const { breadcrumbs, name, publicUrl } = selectedDocument;
    const trail = (breadcrumbs && breadcrumbs[0] && breadcrumbs[0].trail) || [];

    return (
      <FileViewer
        breadcrumbTrail={trail}
        disablePointerEvents={false}
        documentName={name}
        documentUrl={publicUrl}
        fileFetchState={fileFetchState}
        headerIsLoading={headerIsLoading}
        setFileFetchState={setFileFetchState}
        siteKey={siteKey}
        siteName={siteName}
      />
    );
  }

  if (activeContent === 'list') {
    return (
      <div className="pubDocsListView">
        <h1 className="pubDocsListView_header page-header-font ellipse">
          {!hasError && siteName ? (
            siteName
          ) : (
            <Localizer translation={Keys.PublicDocs.PageHeader} />
          )}
        </h1>

        <div className="pubDocsListView_body">
          {hasError ? (
            <LoadFailureMessageBox errorCode={errorCode} onRetry={onRetry} />
          ) : documents.length === 0 ? (
            <EmptyMessage />
          ) : (
            <Column documents={documents} selectDocument={(doc) => selectDocument(doc)} />
          )}
        </div>

        <div className="pubDocsListView_footer">
          <PoweredByDms />
        </div>
      </div>
    );
  }

  return null;
};

ListView.propTypes = {
  siteName: PropTypes.string,
  hasError: PropTypes.bool,
  onRetry: PropTypes.func,
  documents: PropTypes.array,
};

ListView.defaultProps = {
  hasError: false,
  onRetry: () => {},
  documents: [],
};

export { ListView };
