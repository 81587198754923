import * as React from 'react';
import { isNonEmptyString } from 'modules/main/services/lodash-extended';
import { BrowserRouter as Router } from 'react-router-dom';

const PubDocsRouter: React.SFC<{}> = ({ children }) => (
  <Router basename={getBasename()}>{children}</Router>
);

function getBasename(): string {
  const pubDocsPageUrl = (window as any).pubDocsPageUrl;

  if (!isNonEmptyString(pubDocsPageUrl)) {
    return undefined;
  }

  const basenameRegex: RegExp = new RegExp(/(?:net|com)((\/[\w\-]+)+)/g);
  const match: RegExpExecArray = basenameRegex.exec((window as any).pubDocsPageUrl);

  return match === null ? undefined : match[1];
}

export { PubDocsRouter };
