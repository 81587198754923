import { SplitFactory } from '@splitsoftware/splitio';
import { IPromise } from 'angular';
import { isNil } from './lodash-extended';

export class SplitFeatureToggleService {
  //site split
  siteClientPromise: Promise<unknown>;
  siteSplitClient: SplitIO.IClient;

  //user site split
  siteUserClientPromise: Promise<unknown>;
  siteUserSplitClient: SplitIO.IClient;

  authorizationKey: string = window.top.variablesFromServer.splitIoAuthKey;

  /**
   * This service needs to be a singleton in order to utilize SplitIO's caching feature properly.
   * If it's not a singleton then every time a new instance of SplitFeatureToggleService gets
   * created, it will also create a new SplitIO client, which in turn invalides the caching feature.
   * Refactor Note: This should have never been an Angular Service. If we choose to continue using
   * SplitIO we'll want to follow Functional Programming practices and refactor this class into
   * functions.
   */
  private static _singleton: SplitFeatureToggleService;

  /* istanbul ignore next */
  constructor() {
    if (!isNil(SplitFeatureToggleService._singleton)) {
      return SplitFeatureToggleService._singleton;
    }

    SplitFeatureToggleService._singleton = this;

    this.initSiteSdk();
    this.initSiteUserSdk();
  }

  private initSiteSdk(): void {
    const siteSplitIo = SplitFactory({
      core: {
        authorizationKey: this.authorizationKey,
        key: `${window.siteProfile.siteId}`,
      },
    });
    this.siteSplitClient = siteSplitIo.client();
    this.siteClientPromise = this.createSplitIoPromise(this.siteSplitClient);
  }

  private initSiteUserSdk(): void {
    const siteUserSplitIo = SplitFactory({
      core: {
        authorizationKey: this.authorizationKey,
        key: `${window.siteProfile.siteId}|${window.userProfile.userId}`,
      },
    });

    this.siteUserSplitClient = siteUserSplitIo.client();
    this.siteUserClientPromise = this.createSplitIoPromise(this.siteUserSplitClient);
  }

  private createSplitIoPromise(splitClient: SplitIO.IClient) {
    const clientPromise: Promise<void> = new Promise((resolve, reject) => {
      const client = splitClient;

      client.once(client.Event.SDK_READY, () => {
        resolve();
      });

      client.once(client.Event.SDK_READY_TIMED_OUT, () => {
        reject(new Error('Client SDK Ready Timed Out'));
      });
    });

    return clientPromise;
  }

  public userSiteHasTreatment(user_site_split_name: string) {
    return this.siteUserClientPromise
      .catch(() => {})
      .then(() => this.siteUserSplitClient.getTreatment(user_site_split_name))
      .then((x) => {
        return x === 'on';
      });
  }

  public siteHasTreatment(site_split_name: string) {
    return this.siteClientPromise
      .catch(() => {})
      .then(() => this.siteSplitClient.getTreatment(site_split_name))
      .then((x) => {
        return x === 'on';
      });
  }

  public isFeatureEnabled(userSiteSplitName: string, siteSplitName: string): IPromise<boolean> {
    const sitePromise = this.siteHasTreatment(siteSplitName);

    const userSitePromise = this.userSiteHasTreatment(userSiteSplitName);

    return Promise.all([sitePromise, userSitePromise]).then((result) => {
      return result[0] || result[1];
    });
  }

  // Consider failure to reach split.io as not enabled
  public gracefulIsEnabled(treatment: string): Promise<boolean> {
    return this.siteHasTreatment(treatment)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.error('Failed to retrieve treatment from split.io: ', error);
        return false;
      });
  }
}

export function isFeatureEnabledForSite(splitName: string): Promise<boolean> {
  return new SplitFeatureToggleService().siteHasTreatment(splitName).then((result) => {
    return result;
  });
}
