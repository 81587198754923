import * as _ from 'lodash';
import { ReactDirective } from 'ngreact';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { DragDropContext } from 'react-dnd';
import reactDndHtml5Backend from 'react-dnd-html5-backend';

import { DataStatus } from 'modules/main/enums';
import { Icon } from 'modules/presentation/enums';
import { LoadingOverlayStates, ObjectType } from 'modules/dms-object/enums';
import { TreeVirtualized } from 'modules/presentation/components/TreeVirtualized/TreeVirtualized';
import { DmsIconMappings } from 'modules/presentation/services/DmsIconMappings';
import {
  DmsTreeService,
  getTreeItemKey,
  IFlatListItem,
} from 'modules/presentation/services/dms-tree-service';

import { DmsObjectTreeItem } from '../DmsObjectTreeItem';
import { DmsObjectTreeItemDragLayer } from '../DmsObjectTreeItemDragLayer';
import { DmsObjectTreeItemDragSource } from '../DmsObjectTreeItemDragSource';
import {
  DmsObjectTreeItemDropTarget,
  IDropTargetContentProps,
} from '../DmsObjectTreeItemDropTarget/DmsObjectTreeItemDropTarget';
import { AutoReloadErrorMessage } from '../AutoReloadErrorMessage/AutoReloadErrorMessage';
import { DmsObjectPlaceholder } from '../DmsObjectPlaceholder/DmsObjectPlaceholder';
import {
  IDmsObjectTreeItem,
  isIDefaultDmsObjectTreeItem,
  isIDmsObjectTreeItem,
  isIEmptyDmsObjectTreeItem,
  isIErrorDmsObjectTreeItem,
  isILoadingDmsObjectTreeItem,
  IDefaultDmsObjectTreeItem,
  IDmsObjectTreeItemBase,
} from 'modules/dms-object/models/IDmsObjectTreeItem';
import {
  IDmsObjectTreeRoot,
  isIDmsObjectTreeRoot,
  IDmsObjectTreeContainerItem,
  isIDmsObjectTreeContainerItem,
} from 'modules/dms-object/models/IDmsObjectTreeRoot';
import { DmsIcon } from 'modules/presentation/components/DmsIcon';
import { TreeHeader } from 'modules/presentation/components/TreeHeader';
import {
  navigateTreeWithKeyboard,
  useDefaultTabIndex,
  getAriaLiveNotice,
} from 'modules/dms-object/services/keyboardNavigationService';
import { isNil } from 'modules/main/services/lodash-extended';

export const getDefaultTreeItemHeight = (isCompact: boolean): number => (isCompact ? 28 : 38);
export const getDefaultOpenStandardsMenuHeight = (isCompact: boolean): number =>
  isCompact ? 43 : 52;
export const getDepthBlockWidth = (isCompact: boolean): number => (isCompact ? 25.5 : 27.5);

// The only reason for this is to get generics to work with JSX
class DmsObjectTreeVirtualized extends TreeVirtualized<IDmsObjectTreeItem> {}

interface IDmsObjectTreeProps {
  canDragToRoot: boolean;
  isCompact: boolean;
  isVisible?: boolean;
  loadingItemsCount: number;
  onScrollEnd?: () => void;
  onBeginDrag?: (draggedItem: IDefaultDmsObjectTreeItem) => void;
  onCancelDrag?: () => void;
  onEndDrag?: () => void;
  scrollToKey?: string;
  selectedDocumentId?: string;
  shouldTriggerFocus?: boolean;
  tree: IDmsObjectTreeRoot;
  treeId: string;
}

interface IDmsObjectTreeState {
  ariaLiveNotice: {
    alternate: boolean;
    message: string;
  };
  focusedItemKey: string;
  loadingOverlayState: string;
  shouldRefocusFocusedItem: boolean;
  treeHeaderIndices: number[];
}

interface ITreeItemContent {
  flatTreeItem: IFlatListItem<IDmsObjectTreeItem>;
  shouldRenderPlaceholder: boolean;
  isDragging: boolean;
  isDraggingOverDroppableTarget: boolean;
}

const getChildren = (treeItem) => isIDmsObjectTreeContainerItem(treeItem) && treeItem.content;

const getVisibleChildren = (treeItem: IDmsObjectTreeItem): IDmsObjectTreeItem[] => {
  if (isIDefaultDmsObjectTreeItem(treeItem) && !treeItem.isCollapsed) {
    return getChildren(treeItem);
  }

  return [];
};

const getTreeItemsRequiringLoadMore = (
  tree: IDmsObjectTreeRoot,
  renderedList: IFlatListItem<IDmsObjectTreeItem>[],
): IDmsObjectTreeContainerItem[] => {
  /**
   * We only want to load more the parents (that require load more) of rendered items that are
   * the last item of the parent. Phew! That was a mouthful. I dare you to condense it!
   */
  return renderedList
    .filter((renderedItem) => isLastItemOfParentThatRequiresLoadMore(renderedItem, tree))
    .map((renderedItem) => getParentTreeItem(renderedItem, tree));
};

const getParentTreeItem = (
  treeItem: IFlatListItem<IDmsObjectTreeItem>,
  tree: IDmsObjectTreeRoot,
): IDmsObjectTreeContainerItem => {
  return getTreeItem(tree, DmsTreeService.getParentIndices(treeItem.treeItemIndices));
};

const getTreeItem = (treeRoot: IDmsObjectTreeRoot, indices: number[]) => {
  return indices.length === 0
    ? treeRoot
    : DmsTreeService.getTreeItem(treeRoot.content, getChildren, indices);
};

const isLastItemOfParentThatRequiresLoadMore = (
  treeItem: IFlatListItem<IDmsObjectTreeItem>,
  tree: IDmsObjectTreeRoot,
): boolean => {
  if (!isIDefaultDmsObjectTreeItem(treeItem.data)) {
    return false;
  }

  const parent = getParentTreeItem(treeItem, tree);

  return isLoadMoreRequired(parent) && treeItem.data === parent.content[parent.content.length - 1];
};

const isLoadMoreRequired = (treeItem: IDmsObjectTreeContainerItem): boolean => {
  if (!isIDefaultDmsObjectTreeItem(treeItem) && !isIDmsObjectTreeRoot(treeItem)) {
    return false;
  }

  const isCollapsed = isIDmsObjectTreeItem(treeItem) && treeItem.isCollapsed;

  return !isCollapsed && treeItem.hasMore && treeItem.status !== DataStatus.updating;
};

function scrollToTreeItem(treeId: string, treeItem: IDefaultDmsObjectTreeItem) {
  const key = getTreeItemKey(treeItem);

  DmsTreeService.scrollToTreeItem(treeId, key);
}

export class _DmsObjectTree extends React.Component<IDmsObjectTreeProps, IDmsObjectTreeState, {}> {
  static propTypes = {
    canDragToRoot: PropTypes.bool,
    isCompact: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool,
    loadingItemsCount: PropTypes.number,
    onScrollEnd: PropTypes.func,
    onBeginDrag: PropTypes.func,
    onCancelDrag: PropTypes.func,
    onEndDrag: PropTypes.func,
    scrollToKey: PropTypes.string,
    shouldTriggerFocus: PropTypes.bool,
    selectedDocumentId: PropTypes.string,
    tree: PropTypes.any.isRequired,
    treeId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    canDragToRoot: false,
  };

  private canvas: HTMLCanvasElement;
  private dropDestination: IFlatListItem<IDmsObjectTreeItemBase>;
  private flatList: IFlatListItem<IDmsObjectTreeItem>[];
  private isCopying: boolean;
  private scrollTop: number;
  private rootDropDestination: IFlatListItem<IDmsObjectTreeItemBase>;
  private treeElementRef: React.RefObject<HTMLDivElement>;
  private treeWrapperRef: React.RefObject<HTMLDivElement>;

  static getDerivedStateFromProps(nextProps: IDmsObjectTreeProps, prevState: IDmsObjectTreeState) {
    /**
     * Hopefully this is only a temporary solution..
     * This solution is used to augment the loadingOverlayState
     * based on the incoming amount of loading items.
     * Used as a workaround for the loadingOverlayState when not
     * rendering new rows.
     */

    if (nextProps.loadingItemsCount < 1) {
      return {
        loadingOverlayState: LoadingOverlayStates.Idle,
      };
    }

    return null;
  }

  constructor(props: IDmsObjectTreeProps) {
    super(props);

    this.state = {
      ariaLiveNotice: {
        alternate: true,
        message: undefined,
      },
      focusedItemKey: undefined,
      loadingOverlayState: LoadingOverlayStates.Idle,
      shouldRefocusFocusedItem: false,
      treeHeaderIndices: [],
    };

    this.canvas = document.createElement('canvas');
    this.treeElementRef = React.createRef();
    this.treeWrapperRef = React.createRef();

    this.canDrop = this.canDrop.bind(this);
    this.findReturnPosition = this.findReturnPosition.bind(this);
    this.onBeginDrag = this.onBeginDrag.bind(this);
    this.onEndDrag = this.onEndDrag.bind(this);
    this.onItemsRendered = this.onItemsRendered.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.renderRootDropTargetContent = this.renderRootDropTargetContent.bind(this);
    this.setLoadingOverlayState = this.setLoadingOverlayState.bind(this);
    this.setTreeHeaderIndicesState = this.setTreeHeaderIndicesState.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  public componentDidMount() {
    /**
     * We want to trigger a refocus on a selected document if a
     * shouldTriggerFocus is truthy and a selected document id is provided
     *
     * This commonly occurs in the Sidebar view mode of public facing documents
     * When the document is displayed the <DmsObjectTree/> is not rendered, but
     * when we return to the <TreeView/> we re-render the <DmsObjectTree/>.
     * Because we don't have a focused document internally we provide these props
     * to focus the tree item.
     */
    if (this.props.shouldTriggerFocus && this.props.selectedDocumentId) {
      const documentItemKeyToFocus = `${ObjectType.Document}|${this.props.selectedDocumentId}`;
      this.triggerFocus(documentItemKeyToFocus);
    }
  }

  public render() {
    this.rootDropDestination = { data: this.props.tree, treeItemIndices: [] };
    this.flatList = DmsTreeService.convertTreeToFlatList(
      this.props.tree.content,
      getVisibleChildren,
    );
    const isCollapsed = (treeItem) => isIDefaultDmsObjectTreeItem(treeItem) && treeItem.isCollapsed;

    const treeHeaderTreeItem = DmsTreeService.getTreeItem(
      this.props.tree.content,
      getChildren,
      this.state.treeHeaderIndices,
    );

    const draggedItemClassModifier = this.props.isCompact
      ? 'dmsObjectTree_draggedItem--compact'
      : '';

    return (
      <div className="dmsObjectTree" ref={this.treeElementRef}>
        {/**
         * Only render/mount when visible to minimize conflict with other instances of
         * drag layer (e.g. Files and Users/Groups panel) since the component utilizes
         * a shared mutable state/variable.
         */}
        {this.props.isVisible && (
          <DmsObjectTreeItemDragLayer
            renderDraggedItem={(draggedItem, widthOffset) => (
              <div
                className={`dmsObjectTree_draggedItem ${draggedItemClassModifier}`}
                style={{ width: `calc(100% - ${widthOffset}px)` }}
              >
                {this.renderTreeItemContent({ flatTreeItem: draggedItem })}
              </div>
            )}
            width={this.getTreeInnerWidth()}
            topOffset={this.getTreeTopOffset()}
            depthBlockWidth={getDepthBlockWidth(this.props.isCompact)}
            getReturnPosition={this.findReturnPosition}
          />
        )}

        {isIDefaultDmsObjectTreeItem(treeHeaderTreeItem) ? (
          <TreeHeader
            status="visible"
            icon={<DmsIcon {...treeHeaderTreeItem.objectIcon} />}
            name={treeHeaderTreeItem.objectName.name}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.preventDefault();

              scrollToTreeItem(this.props.treeId, treeHeaderTreeItem);
            }}
          />
        ) : (
          <TreeHeader status="empty" />
        )}

        <div className="dmsObjectTree_Wrapper" role="region" ref={this.treeWrapperRef}>
          <DmsObjectTreeItemDropTarget
            dropData={this.rootDropDestination}
            canDrop={this.canDrop}
            render={this.renderRootDropTargetContent}
          />

          <ul className="dmsObjectTree_Container" role="tree">
            <DmsObjectTreeVirtualized
              listClassName="dmsObjectTree_List"
              getChildren={getChildren}
              isCollapsed={isCollapsed}
              isVisible={this.props.isVisible}
              getItemHeight={(treeItemVirtualized) => {
                return treeItemVirtualized.data.height;
              }}
              overscanRowCount={10}
              onItemsRendered={(renderedList) => this.onItemsRendered(this.props, renderedList)}
              onScrollEnd={() => {
                this.triggerFocus(this.props.scrollToKey);
                this.props.onScrollEnd();
              }}
              renderTreeItem={(item, shouldRenderPlaceholder) => {
                return this.renderTreeItem(item, this.props.tree, shouldRenderPlaceholder);
              }}
              scrollToAlignment="start"
              scrollToKey={this.props.scrollToKey}
              onScroll={this.onScroll}
              flatTree={this.flatList}
            />
          </ul>
        </div>
        {this.state.loadingOverlayState === LoadingOverlayStates.Loading && (
          <div className="dmsObjectTree_LoadingOverlay">
            <DmsIcon icon={Icon.TripleDotLoading} />
          </div>
        )}
        {/**
         * React is very clever about how it updates the DOM. If you had one aria-live region and
         * it contained the text "Opened folder", and then you updated it to say "Opened document",
         * React will not replace the entire text contents. It will send multiple updates to the
         * text nodes. (See https://github.com/nvaccess/nvda/issues/7996#issuecomment-413641709 for
         * a better explanation.) What this means for the screenreader is that it will read the
         * multiple updates to the text nodes as separate events rather than reading the entire new
         * text as one update. (Also relevant is that this only happens in Firefox.) To mitigate
         * the issue, it is suggested to use two aria-live regions and alternate between them,
         * wiping each region clean when it is not being used so that its next update will read as
         * entirely new text.
         */}
        <span
          aria-live="polite"
          data-automation-id="dmsObjectTree_ariaLiveRegion1"
          className="screenReaderOnly"
        >
          {!this.state.ariaLiveNotice.alternate ? this.state.ariaLiveNotice.message : null}
        </span>
        <span
          aria-live="polite"
          data-automation-id="dmsObjectTree_ariaLiveRegion2"
          className="screenReaderOnly"
        >
          {this.state.ariaLiveNotice.alternate ? this.state.ariaLiveNotice.message : null}
        </span>
      </div>
    );
  }

  private findReturnPosition(
    draggedItem: IFlatListItem<IDmsObjectTreeItem>,
  ): __ReactDnd.ClientOffset {
    if (isNil(draggedItem)) return;

    const draggedItemObjectKey = draggedItem.data.key;
    const destinationObjectKey = this.dropDestination
      ? this.dropDestination.data.key
      : // Trash string so that it doesn't match with any item that has undefined key
        '!@#NoKey$%^';

    const destinationInList = _.find(this.flatList, (item) => {
      const itemObjectKey = item.data.key;
      const parentIsClosedFolder =
        itemObjectKey === destinationObjectKey &&
        (item.data as IDefaultDmsObjectTreeItem).isCollapsed;
      const foundSelf = itemObjectKey === draggedItemObjectKey;

      if (parentIsClosedFolder || foundSelf) {
        return true;
      }

      return false;
    });

    return this.calculateItemOffset(destinationInList);
  }

  private calculateItemOffset(item: IFlatListItem<IDmsObjectTreeItem>): __ReactDnd.ClientOffset {
    if (isNil(this.treeWrapperRef.current)) {
      console.warn('treeWrapperRef.current cannot be null when calling calculateItemOffset');
      return;
    }

    const treeWrapperRect = this.treeWrapperRef.current.getBoundingClientRect();

    return {
      x:
        treeWrapperRect.left +
        DmsTreeService.getDepth(item) * getDepthBlockWidth(this.props.isCompact),
      y:
        treeWrapperRect.top -
        (this.scrollTop || 0) +
        DmsTreeService.getHeightFromTop(this.flatList, item),
    };
  }

  private getDropContainerStyle(
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>,
    dropTarget: IFlatListItem<IDmsObjectTreeItemBase>,
    depthOffsetAmount: number,
  ) {
    return {
      height: DmsTreeService.getTotalHeight(
        dropDestination.data,
        getVisibleChildren,
        (treeItem) => isIDmsObjectTreeItem(treeItem) && treeItem.height,
      ),
      top: -DmsTreeService.getHeightBetweenTwoItems(
        this.flatList,
        dropDestination,
        dropTarget,
        (treeItem) => isIDmsObjectTreeItem(treeItem) && treeItem.height,
      ),
      left: depthOffsetAmount * getDepthBlockWidth(this.props.isCompact),
      right: 0,
    };
  }

  private getTreeInnerWidth(): number {
    if (isNil(this.treeElementRef.current)) return 0;

    const treeScrollBarWidth = 40;

    return this.treeElementRef.current.clientWidth - treeScrollBarWidth;
  }

  private getTreeTopOffset(): number {
    if (isNil(this.treeElementRef.current)) return 0;

    return this.treeElementRef.current.getBoundingClientRect().top;
  }

  private handleKeyDown(
    event: React.KeyboardEvent<HTMLButtonElement | HTMLSpanElement>,
    treeItemKey: string,
    treeItemContentRef: HTMLButtonElement | HTMLSpanElement,
    anchorRef: HTMLButtonElement | HTMLAnchorElement,
  ): void {
    const itemToFocus = navigateTreeWithKeyboard({
      anchorRef,
      getParentTreeItem,
      treeItemContentRef,
      treeItemKey,
      key: event.key,
      flatList: this.flatList,
      tree: this.props.tree,
    });

    // Space and Enter trigger click events, which are already handled
    if (['Home', 'End', '*'].includes(event.key)) {
      this.updateAriaLiveNoticeState(event.key, treeItemKey);
    }

    /**
     * Re: asterisk key:
     * There's a good chance that the user is focused on an item with folders above it that will be
     * opened when the asterisk is hit. This will cause a remount, so we want to make sure we
     * trigger a refocus on the item after it mounts. Otherwise the user will lose focus in the tree
     *
     * Re: space and Enter keys:
     * when you select an item with space or enter, the item is already focused (via keyboard nav),
     * vs when you click on an item, something else is currently focused prior to the click.
     * in pubdocs, the item retains its focus after space or enter is hit, but for some reason, the
     * main app seems to be stealing the focus for a millisecond after the doc is selected (via
     * click/enter/space), which removes the existing visual focus, so we need to tell it to refocus
     */
    if (['*', ' ', 'Enter'].includes(event.key)) {
      this.setState({ shouldRefocusFocusedItem: true });
    }

    if (itemToFocus) {
      /**
       * It's possible that the new item will be out of sight. We need to scroll to it first
       * (focus is handled in the onScrollEnd that we pass to the TreeVirtualized). The scroll
       * method also works for items that don't need to be scrolled to
       */
      DmsTreeService.scrollToTreeItem(this.props.treeId, itemToFocus);
    }
  }

  private updateAriaLiveNoticeState(eventKey: string, treeItemKey: string) {
    this.setState((prevState) => ({
      ariaLiveNotice: {
        message: getAriaLiveNotice(eventKey, treeItemKey, this.flatList),
        alternate: !prevState.ariaLiveNotice.alternate,
      },
    }));
  }

  private isContainerCollapsed(container: IFlatListItem<IDmsObjectTreeItem>): boolean {
    return (
      isIDmsObjectTreeContainerItem(container.data) &&
      isIDefaultDmsObjectTreeItem(container.data) &&
      container.data.isCollapsed
    );
  }

  private onBeginDrag(draggedItem: IFlatListItem<IDmsObjectTreeItem>, isCopying: boolean) {
    this.isCopying = isCopying;

    if (isIDefaultDmsObjectTreeItem(draggedItem.data)) {
      if (_.isFunction(this.props.onBeginDrag)) {
        this.props.onBeginDrag(draggedItem.data);
      }
    }
  }

  private onEndDrag(
    draggedItem: IFlatListItem<IDmsObjectTreeItem>,
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>,
  ) {
    this.dropDestination = dropDestination;

    if (_DmsObjectTree.isInvalidDrop(draggedItem, dropDestination)) {
      if (_.isFunction(this.props.onCancelDrag)) {
        this.props.onCancelDrag();
      }

      return;
    }

    if (_.isFunction(draggedItem.data.onDraggedItemDropped)) {
      draggedItem.data.onDraggedItemDropped(draggedItem.data, dropDestination.data, this.isCopying);
    }

    if (_.isFunction(this.props.onEndDrag)) {
      this.props.onEndDrag();
    }
  }

  private triggerFocus(key: string) {
    this.setState({
      focusedItemKey: key,
      shouldRefocusFocusedItem: true,
    });
  }

  /*
   * TODO: This seems to overlap with `canDrop`. See if we can consolidate the logic. One thing to
   * note is the distinction between view and business logic. View logic determines what's
   * possible or impossible based on the UI without regards for business logic. For example: An
   * item cannot be possibly dropped into itself, etc.
   */
  static isInvalidDrop(
    draggedItem: IFlatListItem<IDmsObjectTreeItem>,
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>,
  ) {
    return (
      // Dropped outside of any target
      draggedItem === undefined ||
      dropDestination === undefined ||
      // Destination is itself
      draggedItem.data.key === dropDestination.data.key ||
      // Destination is the immediate parent of dragged item
      _.isEqual(
        draggedItem.treeItemIndices.slice(0, draggedItem.treeItemIndices.length - 1),
        dropDestination.treeItemIndices,
      ) ||
      // Destination is a descendant of dragged item
      DmsTreeService.isChildOfContainerByIndices(
        dropDestination.treeItemIndices,
        draggedItem.treeItemIndices,
      )
    );
  }

  private onItemsRendered(
    props: IDmsObjectTreeProps,
    renderedList: IFlatListItem<IDmsObjectTreeItem>[],
  ) {
    const loadMoreItems = getTreeItemsRequiringLoadMore(props.tree, renderedList);
    const renderedLoadingItems = renderedList.filter((treeItemVirtualized) =>
      isILoadingDmsObjectTreeItem(treeItemVirtualized.data),
    );

    for (const treeItem of loadMoreItems) {
      treeItem.onLoadMore();
    }

    this.setTreeHeaderIndicesState(
      DmsTreeService.getParentIndices(renderedList[0].treeItemIndices),
    );
    this.setLoadingOverlayState(this.props.loadingItemsCount, renderedLoadingItems.length);
  }

  private getDmsObjectTreeItemClassName(): string {
    const baseClass = 'dmsObjectTreeItem';
    const smallTextClass = this.props.isCompact ? `${baseClass}--compact` : '';

    return `${baseClass} ${smallTextClass}`;
  }

  private getDropDestinationOf(
    flatTreeItem: IFlatListItem<IDmsObjectTreeItemBase>,
  ): IFlatListItem<IDmsObjectTreeItemBase> {
    if (isIDmsObjectTreeContainerItem(flatTreeItem.data)) {
      return flatTreeItem;
    }

    const parentIndices = DmsTreeService.getParentIndices(flatTreeItem.treeItemIndices);

    return {
      data: getTreeItem(this.props.tree, parentIndices),
      treeItemIndices: parentIndices,
    };
  }

  private onScroll({ scrollTop }) {
    this.scrollTop = scrollTop;
  }

  private canDrop(
    dropTarget: IFlatListItem<IDmsObjectTreeItemBase>,
    draggedItem: IFlatListItem<IDmsObjectTreeItemBase>,
  ): boolean {
    if (isNil(draggedItem) || isNil(dropTarget)) {
      return false;
    }

    const dropDestination = this.getDropDestinationOf(dropTarget);

    const isDroppingOntoSelf = _.isEqual(
      dropDestination.treeItemIndices,
      draggedItem.treeItemIndices,
    );

    const isDroppingOntoOwnChildren = DmsTreeService.isChildOfContainerByIndices(
      dropDestination.treeItemIndices,
      draggedItem.treeItemIndices,
    );

    return (
      !isDroppingOntoSelf &&
      !isDroppingOntoOwnChildren &&
      _.isFunction(dropDestination.data.canDrop) &&
      dropDestination.data.canDrop(draggedItem.data, this.isCopying)
    );
  }

  private setLoadingOverlayState(
    loadingItemsCount: number,
    renderedLoadingItemsCount: number,
  ): void {
    if (
      this.state.loadingOverlayState !== LoadingOverlayStates.Loading &&
      loadingItemsCount > renderedLoadingItemsCount
    ) {
      this.setState(() => ({ loadingOverlayState: LoadingOverlayStates.Loading }));
    } else if (
      this.state.loadingOverlayState !== LoadingOverlayStates.Idle &&
      loadingItemsCount <= renderedLoadingItemsCount
    ) {
      this.setState(() => ({ loadingOverlayState: LoadingOverlayStates.Idle }));
    }
  }

  private setTreeHeaderIndicesState(treeHeaderIndices: number[]): void {
    if (!_.isEqual(this.state.treeHeaderIndices, treeHeaderIndices)) {
      this.setState(() => ({ treeHeaderIndices }));
    }
  }

  private renderDepth(
    flatTreeItem: IFlatListItem<IDmsObjectTreeItem>,
    tree: IDmsObjectTreeRoot,
  ): JSX.Element[] {
    const depth = flatTreeItem.treeItemIndices.length - 1;
    // tslint:disable-next-line:prefer-array-literal
    return Array.from(new Array(depth)).map((__, i) => {
      const depthBlockId = `dmsObjectTreeItem_depthBlock_${flatTreeItem.treeItemIndices}${i}`;
      const indices = flatTreeItem.treeItemIndices.slice(0, i + 1);
      const dropDestination: IFlatListItem<IDmsObjectTreeItem> = this.props.canDragToRoot
        ? this.rootDropDestination
        : { data: getTreeItem(tree, indices), treeItemIndices: indices };

      return (
        <DmsObjectTreeItemDropTarget
          key={i}
          dropData={dropDestination}
          canDrop={this.canDrop}
          render={(props: IDropTargetContentProps) =>
            this.renderDepthDropTargetContent({
              dropDestination,
              flatTreeItem,
              depthBlockId,
              depthOffsetAmount: i,
              ...props,
            })
          }
        />
      );
    });
  }

  private renderDepthDropTargetContent(props: {
    depthBlockId: string;
    depthOffsetAmount: number;
    draggedItem: IFlatListItem<IDmsObjectTreeItem>;
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>;
    flatTreeItem: IFlatListItem<IDmsObjectTreeItem>;
    isOver: boolean;
  }): JSX.Element {
    return (
      <div
        className={`dmsObjectTreeItem_depthBlock ${props.depthBlockId}`}
        style={{ flexBasis: getDepthBlockWidth(this.props.isCompact) }}
      >
        <div className="dmsObjectTreeItem_depthBlockCollapseline" />
        {props.isOver && this.canDrop(props.dropDestination, props.draggedItem)
          ? this.renderDropContainerInDepthBlock(props)
          : null}
      </div>
    );
  }

  private renderDropContainer(style) {
    return <div className="dmsObjectTreeItem_dropTargetContainer" style={style} />;
  }

  private renderDropContainerAroundTree(elementClassName: string) {
    if (!this.treeWrapperRef.current) {
      return null;
    }

    const treeBounds = this.treeWrapperRef.current.getBoundingClientRect();
    const elementBounds = this.treeWrapperRef.current
      .getElementsByClassName(elementClassName)[0]
      .getBoundingClientRect();

    return this.renderDropContainer({
      height: treeBounds.height,
      top: treeBounds.top - elementBounds.top,
      left: 0,
      right: 0,
    });
  }

  private renderDropContainerInDepthBlock(props: {
    depthBlockId: string;
    depthOffsetAmount: number;
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>;
    flatTreeItem: IFlatListItem<IDmsObjectTreeItem>;
  }) {
    const isRootDropDestination = props.dropDestination.treeItemIndices.length === 0;
    return isRootDropDestination
      ? this.renderDropContainerAroundTree(props.depthBlockId)
      : this.renderDropContainer(
          this.getDropContainerStyle(
            props.dropDestination,
            props.flatTreeItem,
            props.depthOffsetAmount,
          ),
        );
  }

  private renderRootDropTargetContent(props: {
    draggedItem: IFlatListItem<IDmsObjectTreeItem>;
    isOver: boolean;
  }): JSX.Element {
    return (
      <div className="dmsObjectTree_rootDropTarget">
        {props.isOver && this.canDrop(this.rootDropDestination, props.draggedItem) ? (
          <div className="dmsObjectTree_rootDropTargetContainer" />
        ) : null}
      </div>
    );
  }

  private renderTreeItem(
    treeItemVirtualized: IFlatListItem<IDmsObjectTreeItem>,
    tree: IDmsObjectTreeRoot,
    shouldRenderPlaceholder: boolean,
  ): JSX.Element {
    return (
      <div
        className={this.getDmsObjectTreeItemClassName()}
        style={{ height: treeItemVirtualized.data.height }}
      >
        {this.renderDepth(treeItemVirtualized, tree)}
        {this.renderTreeItemWithDnD(treeItemVirtualized, shouldRenderPlaceholder)}
      </div>
    );
  }

  /* istanbul ignore next */
  private getTextWidthPercentage(treeItem: IDmsObjectTreeItem) {
    const context = this.canvas.getContext('2d');
    const treeWidth = this.getTreeInnerWidth();

    context.font = !isNil(this.treeElementRef.current)
      ? window.getComputedStyle(this.treeElementRef.current).font
      : context.font;
    let text = '';

    if (isIDefaultDmsObjectTreeItem(treeItem)) {
      text = `${treeItem.objectName.name} ${treeItem.objectName.username}`;
    } else if (isIEmptyDmsObjectTreeItem(treeItem)) {
      text = treeItem.text;
    }

    const metrics = context.measureText(text);
    const textWidth = metrics.width;

    return Math.min((textWidth / treeWidth) * 100, 100);
  }

  public renderTreeItemContent({
    flatTreeItem,
    shouldRenderPlaceholder = false,
    isDraggingOverDroppableTarget = false,
    isDragging = false,
  }: Partial<ITreeItemContent>): JSX.Element {
    if (isNil(flatTreeItem)) {
      return;
    }

    const treeItem = flatTreeItem.data;
    if (shouldRenderPlaceholder) {
      let iconColorHex;
      let showCollapseIcon = false;
      if (isIDefaultDmsObjectTreeItem(treeItem)) {
        iconColorHex = DmsIconMappings.iconColorHexes[treeItem.objectIcon.icon];
        showCollapseIcon = !_.isUndefined(treeItem.content);
      }

      return (
        <DmsObjectPlaceholder
          iconColorHex={iconColorHex}
          showCollapseIcon={showCollapseIcon}
          widthPercentage={this.getTextWidthPercentage(treeItem)}
        />
      );
    }

    if (isIDefaultDmsObjectTreeItem(treeItem)) {
      return (
        <DmsObjectTreeItem
          autoOpenContainer={
            this.isContainerCollapsed(flatTreeItem) && isDraggingOverDroppableTarget
          }
          disableCssHoverStyle={isDragging}
          isCompact={this.props.isCompact}
          item={treeItem}
          handleKeyDown={this.handleKeyDown}
          isFocused={this.state.focusedItemKey === treeItem.key}
          resetAfterRefocus={() => this.setState({ shouldRefocusFocusedItem: false })}
          setAriaLiveNotice={(treeItemKey: string) => {
            this.updateAriaLiveNoticeState('Click', treeItemKey);
          }}
          setFocused={(key: string) => this.setState({ focusedItemKey: key })}
          shouldRefocus={this.state.shouldRefocusFocusedItem}
          useDefaultTabIndex={useDefaultTabIndex(
            this.flatList[0].data.key,
            treeItem.key,
            this.state.focusedItemKey,
          )}
        />
      );
    }

    if (isILoadingDmsObjectTreeItem(treeItem)) {
      return (
        <div className="loadingDmsObjectTreeItem">
          <DmsIcon icon={treeItem.loadingIcon} />
        </div>
      );
    }

    if (isIEmptyDmsObjectTreeItem(treeItem)) {
      return <div className="emptyDmsObjectTreeItem">{treeItem.text}</div>;
    }

    if (isIErrorDmsObjectTreeItem(treeItem)) {
      return (
        <div className="errorDmsObjectTreeItem">
          <AutoReloadErrorMessage {...treeItem} />
        </div>
      );
    }
  }

  private renderTreeItemDropTargetContent(props: {
    draggedItem: IFlatListItem<IDmsObjectTreeItem>;
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>;
    flatTreeItem: IFlatListItem<IDmsObjectTreeItem>;
    isOver: boolean;
    shouldRenderPlaceholder: boolean;
  }): JSX.Element {
    const isDragging = !isNil(props.draggedItem);
    const canDrop = this.canDrop(props.dropDestination, props.draggedItem);
    const isDraggingOverDroppableTarget = props.isOver && canDrop;
    const getDepthBlockOffsetAmount = () => {
      return _.isEqual(props.dropDestination.treeItemIndices, props.flatTreeItem.treeItemIndices)
        ? 0
        : -1;
    };
    const disableClass = isDragging && !canDrop ? 'dmsObjectTreeItem_dragSource--disable' : '';

    return (
      <div className={`dmsObjectTreeItem_dragSource ${disableClass}`}>
        <DmsObjectTreeItemDragSource
          dragData={props.flatTreeItem}
          canDrag={(treeItem) => {
            return this.isCopying ? treeItem.data.canCopy : treeItem.data.canDrag;
          }}
          onBeginDrag={this.onBeginDrag}
          onEndDrag={this.onEndDrag}
        >
          {
            // react-dnd connectors can only take in native DOM element.
            <div>
              {this.renderTreeItemContent({
                isDragging,
                isDraggingOverDroppableTarget,
                flatTreeItem: props.flatTreeItem,
                shouldRenderPlaceholder: props.shouldRenderPlaceholder,
              })}
            </div>
          }
        </DmsObjectTreeItemDragSource>

        {isDraggingOverDroppableTarget
          ? this.renderDropContainer(
              this.getDropContainerStyle(
                props.dropDestination,
                props.flatTreeItem,
                getDepthBlockOffsetAmount(),
              ),
            )
          : null}
      </div>
    );
  }

  private renderTreeItemWithDnD(
    flatTreeItem: IFlatListItem<IDmsObjectTreeItem>,
    shouldRenderPlaceholder: boolean,
  ): JSX.Element {
    const dropDestination = this.getDropDestinationOf(flatTreeItem);
    return (
      <DmsObjectTreeItemDropTarget
        dropData={dropDestination}
        canDrop={this.canDrop}
        render={(props: IDropTargetContentProps) => {
          return this.renderTreeItemDropTargetContent({
            dropDestination,
            flatTreeItem,
            shouldRenderPlaceholder,
            ...props,
          });
        }}
      />
    );
  }
}

const DmsObjectTree = DragDropContext(reactDndHtml5Backend)(_DmsObjectTree);

export { DmsObjectTree };

export class dmsObjectTreeDirective implements ng.IDirective {
  public static $inject = ['reactDirective'];

  constructor(reactDirective: ReactDirective) {
    return reactDirective(DmsObjectTree as any);
  }
}
