'use strict';

import { DmsObject } from './dms-object';
import { ContainerNode } from './container-node';
import { RootContentManager } from 'modules/dms-object/managers';
import { IModifiedEventPayload } from 'modules/event/models/i-modified-event-payload';
import { DataStatus } from 'modules/main/enums';

export enum ContentNodeCreationState {
  Creating = 'creating',
  Error = 'error',
  Idle = 'idle',
}

export class ContentNode {
  public static EVENTS: { [key: string]: string } = {
    Modified: 'contentNodeModifiedEvent',
  };

  /**
   * NOTE: Private members (prepended with _) have a setter that broadcast an event with payload
   * whenever their values changed. If you need to do the same thing to the other public members,
   * follow the pattern.
   */
  public creationState: ContentNodeCreationState = ContentNodeCreationState.Idle;
  // The "dragDropCallsX" are used to show a loader for this item while the drag/drop calls are still out
  private _dragDropCallsSent: number = 0;
  public get dragDropCallsSent(): number {
    return this._dragDropCallsSent;
  }
  public set dragDropCallsSent(value: number) {
    this.setAndBroadcast('dragDropCallsSent', value);
  }
  private _dragDropCallsReturned: number = 0;
  public get dragDropCallsReturned(): number {
    return this._dragDropCallsReturned;
  }
  public set dragDropCallsReturned(value: number) {
    this.setAndBroadcast('dragDropCallsReturned', value);
  }
  public get dragDropLoadingStatus(): DataStatus {
    return this.dragDropCallsSent === this.dragDropCallsReturned
      ? DataStatus.success
      : DataStatus.updating;
  }
  public element: ng.IAugmentedJQuery;
  public errorText: string;
  // This prevents the user from interacting with this object until after it's created and has an id.
  // @TODO: remove isBeingCreated and isListLoadable when old tree is phased out
  public isBeingCreated: boolean = false;
  private _isCollapsed: boolean = true;
  public isEditable: boolean;
  public isListLoadable: boolean;
  // This is the same as "isBeingCreated". The reason we need it is for styling purposes for when the user is creating
  // a new document through the side menu.
  public isTemp: boolean = false;
  public parent: ContainerNode;

  protected $rootScope: ng.IRootScopeService;

  public static $inject = ['$injector', 'dmsObject', 'rootManager'];

  constructor(
    protected $injector: ng.auto.IInjectorService,
    public dmsObject: DmsObject,
    public rootManager: RootContentManager,
  ) {
    this.$rootScope = $injector.get('$rootScope');
  }

  // These are here even though they don't do anything so that we can
  //     call them on a node safely without checking its type.
  public destroy() {}
  public reset() {}

  protected setAndBroadcast<K extends keyof this>(publicProp: K, value: this[K]) {
    const privateProp = `_${String(publicProp)}`;

    if (value === this[privateProp]) return;

    const payload: IModifiedEventPayload<K, this[K]> = {
      objectName: 'ContentNode',
      prop: publicProp,
      oldValue: this[publicProp],
      newValue: value,
    };

    this[privateProp] = value;
    this.$rootScope.$broadcast(ContentNode.EVENTS.Modified, payload);
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }
  public set isCollapsed(value: boolean) {
    this.setAndBroadcast('isCollapsed', value);
  }
}
