'use strict';

import { DataStatus } from 'modules/main/enums';
import { getSingleErrorCodeFromResponse } from 'modules/main/utilities/utils';

import { ErrorCode } from 'modules/error/enums';

export class UpdateResource<T> {
  private lastUpdated: Date;
  private updatePromise: ng.IPromise<T>;
  private updatedResponse: T;
  private errorCode: ErrorCode;
  private status: DataStatus = DataStatus.initial;
  private getRequest: () => ng.IPromise<T>;

  public static $inject = ['$q'];

  public constructor(private $q: ng.IQService) {}

  public getUpdatedResponse(): T {
    return this.updatedResponse;
  }
  public getLastUpdated(): Date {
    return this.lastUpdated;
  }
  public getStatus(): DataStatus {
    return this.status;
  }
  public getErrorCode(): ErrorCode {
    return this.errorCode;
  }
  public setGetRequest(getRequest: () => ng.IPromise<T>) {
    this.getRequest = getRequest;
  }

  public get(): ng.IPromise<T> {
    if (this.getStatus() == DataStatus.updating) return this.updatePromise;

    this.status = DataStatus.updating;

    this.updatePromise = this.getRequest()
      .then((response) => {
        this.updatedResponse = response;
        this.lastUpdated = new Date();
        this.errorCode = undefined;
        this.status = DataStatus.success;

        return response;
      })
      .catch((errorResponse) => {
        this.errorCode = getSingleErrorCodeFromResponse(errorResponse);
        this.status = DataStatus.fail;

        return this.$q.reject(errorResponse);
      })
      .finally(() => {
        this.updatePromise = undefined;
      });

    return this.updatePromise;
  }

  public getIfStale(maxSecondsStale: number = 60): ng.IPromise<T> {
    if (maxSecondsStale === -1) {
      // Passing -1 means "give me cached data if available"
      maxSecondsStale = 99999999;
    }
    if (this.getStatus() === DataStatus.updating) return this.updatePromise;
    if (this.hasExpired(maxSecondsStale)) return this.get();

    const deferrred = this.$q.defer<T>();
    deferrred.resolve(this.updatedResponse);
    return deferrred.promise;
  }

  private hasExpired(maxSecondsExpired: number) {
    if (!this.lastUpdated) return true;

    var secondsExpired = (new Date().valueOf() - this.lastUpdated.valueOf()) / 1000;
    return secondsExpired > maxSecondsExpired;
  }
}
