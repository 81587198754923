import { TSiteInfo } from 'modules/pub-docs/models/TSiteInfo';

export enum SiteInfoActionType {
  Fetch = 'PubDocs.SiteInfoAction.Fetch',
  FetchStart = 'PubDocs.SiteInfoAction.FetchStart',
  FetchSuccess = 'PubDocs.SiteInfoAction.FetchSuccess',
  FetchFail = 'PubDocs.SiteInfoAction.FetchFail',
}

export function fetchSiteInfoFail(error: any) {
  return {
    type: SiteInfoActionType.FetchFail as const,
    payload: { error },
  };
}

export function fetchSiteInfoSuccess(data: TSiteInfo) {
  return {
    type: SiteInfoActionType.FetchSuccess as const,
    payload: { data },
  };
}

export function fetchSiteInfoStart() {
  return {
    type: SiteInfoActionType.FetchStart as const,
  };
}

export function fetchSiteInfo(siteKey: string) {
  return {
    type: SiteInfoActionType.Fetch as const,
    payload: { siteKey },
  };
}

export type TFetchSiteInfo = ReturnType<typeof fetchSiteInfo>;
export type TFetchSiteInfoFail = ReturnType<typeof fetchSiteInfoFail>;
export type TFetchSiteInfoSuccess = ReturnType<typeof fetchSiteInfoSuccess>;
export type TFetchSiteInfoStart = ReturnType<typeof fetchSiteInfoStart>;

export type TSiteInfoAction =
  | TFetchSiteInfo
  | TFetchSiteInfoFail
  | TFetchSiteInfoSuccess
  | TFetchSiteInfoStart;
