import { AxiosError } from 'axios';

import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';

export enum DocumentsActionType {
  Fetch = 'PubDocs.DocumentsAction.Fetch',
  FetchStart = 'PubDocs.DocumentsAction.FetchStart',
  FetchSuccess = 'PubDocs.DocumentsAction.FetchSuccess',
  FetchFail = 'PubDocs.DocumentsAction.FetchFail',
}

export function fetchDocsFail(error: AxiosError<ICcmpResponse<TPublicDoc[]>> | AxiosError) {
  return {
    type: DocumentsActionType.FetchFail as const,
    payload: { error },
  };
}

export function fetchDocsSuccess(list: TPublicDoc[]) {
  return {
    type: DocumentsActionType.FetchSuccess as const,
    payload: { list },
  };
}

export function fetchDocsStart() {
  return {
    type: DocumentsActionType.FetchStart as const,
  };
}

export function fetchDocs(siteKey: string) {
  return {
    type: DocumentsActionType.Fetch as const,
    payload: { siteKey },
  };
}

export type TFetchDocs = ReturnType<typeof fetchDocs>;
export type TFetchDocsFail = ReturnType<typeof fetchDocsFail>;
export type TFetchDocsSuccess = ReturnType<typeof fetchDocsSuccess>;
export type TFetchDocsStart = ReturnType<typeof fetchDocsStart>;

export type TDocumentsAction = TFetchDocs | TFetchDocsFail | TFetchDocsSuccess | TFetchDocsStart;
