import { isNil } from 'modules/main/services/lodash-extended';

const escapedHtmlChars: { [key: string]: string } = {
  '&': '&amp;',
  '>': '&gt;',
  '<': '&lt;',
  '"': '&quot;',
  "'": '&apos;',
};

const unsafeHtmlCharsRegex = new RegExp(`[${Object.keys(escapedHtmlChars).join('')}]`, 'g');

export function escapeHtml(str: string) {
  return isNil(str)
    ? ''
    : str.replace(unsafeHtmlCharsRegex, (unsafeChar) => escapedHtmlChars[unsafeChar]);
}
