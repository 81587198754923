import * as H from 'history';
import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';

export enum TreeActionType {
  ToggleFolder = 'PubDocs.TreeAction.ToggleFolder',
  OpenFolders = 'PubDocs.TreeAction.OpenFolder',
  SetScrollToKey = 'PubDocs.TreeAction.SetScrollToKey',
  SelectDocument = 'PubDocs.TreeAction.SelectDocument',
}

export function setScrollToKey(scrollToKey: string) {
  return {
    type: TreeActionType.SetScrollToKey as const,
    payload: { scrollToKey },
  };
}

export function selectDocument(document: TPublicDoc, spaUrl: string, history: H.History) {
  return {
    type: TreeActionType.SelectDocument as const,
    payload: { document, spaUrl, history },
  };
}

export function openFolders(folderIds: string[]) {
  return {
    type: TreeActionType.OpenFolders as const,
    payload: { folderIds },
  };
}

export function toggleFolder(folderId: string) {
  return {
    type: TreeActionType.ToggleFolder as const,
    payload: { folderId },
  };
}

export type TToggleFolder = ReturnType<typeof toggleFolder>;
export type TSelectDocument = ReturnType<typeof selectDocument>;
export type TOpenFolders = ReturnType<typeof openFolders>;
export type TSetScrollToKey = ReturnType<typeof setScrollToKey>;

export type TTreeAction = TToggleFolder | TSelectDocument | TOpenFolders | TSetScrollToKey;
