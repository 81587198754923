import * as React from 'react';
import * as PropTypes from 'prop-types';

import { LocalizationService } from 'modules/main/services/localization-service';
import { escapeHtml } from 'modules/main/services/sanitization-service';

export type TLocalizerProps = {
  /**
   * Typically used to add a screen reader only class.
   */
  className?: string;
  /**
   * Follows the pattern `Key.LocalizedId` where `LocalizedId` is the translation id in `default.xlf`.
   * For example: `Keys.CommonTerms.DownloadVerb`
   */
  translation: TTranslation;
  /** Can accept multiple strings.
   * Used to pass in variable values to the localized text.
   */
  values?: { [key: string]: string };
  /** Used to pass in safe HTML.
   * This includes elements
   */
  trustedHtml?: { [key: string]: string };
};

/**
 * Localizer is used throughout the application to translate copy into a user's preferred language
 */
export function Localizer({
  className,
  translation,
  values,
  trustedHtml,
  ...rest
}: TLocalizerProps) {
  const safeValues = Object.keys(values).reduce(
    (safeValues, key) => ({
      ...safeValues,
      [key]: escapeHtml(values[key]),
    }),
    {},
  );

  return (
    <span
      {...rest}
      className={className}
      dangerouslySetInnerHTML={{
        __html: LocalizationService.localize(translation, { ...safeValues, ...trustedHtml }),
      }}
    />
  );
}

Localizer.propTypes = {
  className: PropTypes.string,
  translation: PropTypes.any.isRequired,
  values: PropTypes.objectOf(PropTypes.string),
  trustedHtml: PropTypes.objectOf(PropTypes.string),
};

Localizer.defaultProps = {
  values: {},
  trustedHtml: {},
};
