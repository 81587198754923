import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { DataFetchState } from 'modules/pub-docs/enums/DataFetchState';
import { useLoadPdf, usePdfViewerInitialization } from 'modules/pub-docs/services/';

type TPdfViewerProps = {
  documentUrl: string;
  setFileFetchState?: (fetchState: DataFetchState) => void;
};

const useStyles = makeStyles({
  webviewer: {
    flex: 'auto',
  },
});

const PdfViewer = (props: TPdfViewerProps) => {
  const { documentUrl, setFileFetchState } = props;
  const classes = useStyles(props);
  const viewer = React.useRef<HTMLDivElement>(null);
  const webViewerInstance = React.useRef(null);

  usePdfViewerInitialization(viewer, webViewerInstance, documentUrl, setFileFetchState);
  useLoadPdf(webViewerInstance, documentUrl);

  return <div className={`${classes.webviewer} webviewer`} ref={viewer} role="document" />;
};

export { PdfViewer };
