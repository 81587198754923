import * as PropTypes from 'prop-types';
import * as React from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import {
  IDmsObjectTreeItem,
  IDmsObjectTreeItemBase,
} from 'modules/dms-object/models/IDmsObjectTreeItem';
import { IFlatListItem, isIFlatListItem } from 'modules/presentation/services/dms-tree-service';

export const DRAG_TYPE = 'DMS_OBJECT_TREE_DRAG_DROP';

interface IDragSourceComponentBaseProps {
  canDrag: (draggedItem: IFlatListItem<IDmsObjectTreeItem>) => boolean;
  dragData: IFlatListItem<IDmsObjectTreeItem>;
  onEndDrag: (
    draggedItem: IFlatListItem<IDmsObjectTreeItem>,
    dropDestination: IFlatListItem<IDmsObjectTreeItemBase>,
  ) => void;
  children: JSX.Element;
}

interface IDragSourceComponentProps extends IDragSourceComponentBaseProps {
  onBeginDrag: (draggedItem: IFlatListItem<IDmsObjectTreeItem>, isCopying: boolean) => void;
}

interface IDragSourceCollectorBaseProps extends IDragSourceComponentBaseProps {
  onBeginDrag: (draggedItem: IFlatListItem<IDmsObjectTreeItem>) => void;
}

interface IDragSourceCollectorProps extends IDragSourceCollectorBaseProps {
  connectDragPreview: ConnectDragPreview;
  connectDragSource: ConnectDragSource;
}

const dragSource = {
  beginDrag(props: IDragSourceCollectorProps): IFlatListItem<IDmsObjectTreeItem> {
    props.onBeginDrag(props.dragData);

    return props.dragData;
  },
  canDrag(props: IDragSourceCollectorProps, monitor: DragSourceMonitor): boolean {
    return props.canDrag(props.dragData);
  },
  endDrag(props: IDragSourceCollectorProps, monitor: DragSourceMonitor) {
    if (monitor.didDrop()) {
      const draggedItem = monitor.getItem();
      const dropDestination = monitor.getDropResult();

      props.onEndDrag(
        isIFlatListItem(draggedItem) ? draggedItem : undefined,
        isIFlatListItem(dropDestination) ? dropDestination : undefined,
      );
    } else {
      props.onEndDrag(undefined, undefined);
    }
  },
};

const dragSourceCollector = (connect: DragSourceConnector, monitor: DragSourceMonitor) => {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const DragSourceComponent = DragSource<IDragSourceCollectorBaseProps>(
  DRAG_TYPE,
  dragSource,
  dragSourceCollector,
)(
  class _DragSourceComponent extends React.PureComponent<IDragSourceCollectorProps> {
    constructor(props: IDragSourceCollectorProps) {
      super(props);
    }

    public componentDidMount() {
      const { connectDragPreview } = this.props;

      if (connectDragPreview !== undefined) {
        // Use empty image as a drag preview so browsers don't draw it
        // and we can draw whatever we want on the custom drag layer instead.
        connectDragPreview(getEmptyImage() as any, {
          // IE fallback: specify that we'd rather screenshot the node
          // when it already knows it's being dragged so we can hide it with CSS.
          captureDraggingState: true,
        });
      }
    }

    public render() {
      return this.props.connectDragSource !== undefined
        ? this.props.connectDragSource(this.props.children)
        : this.props.children;
    }
  },
);

export class DmsObjectTreeItemDragSource extends React.Component<IDragSourceComponentProps, {}> {
  static propTypes = {
    connectDragSource: PropTypes.func,
    dragData: PropTypes.object.isRequired,
    canDrag: PropTypes.func.isRequired,
    onBeginDrag: PropTypes.func.isRequired,
    onEndDrag: PropTypes.func.isRequired,
    render: PropTypes.func,
  };

  private isCopyButtonPressed: boolean;

  constructor(props: IDragSourceComponentProps) {
    super(props);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  public render() {
    return (
      <div onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
        <DragSourceComponent
          dragData={this.props.dragData}
          canDrag={this.props.canDrag}
          onBeginDrag={(draggedItem) => {
            this.props.onBeginDrag(draggedItem, this.isCopyButtonPressed);
          }}
          onEndDrag={this.props.onEndDrag}
        >
          {this.props.children}
        </DragSourceComponent>
      </div>
    );
  }

  private onMouseDown(event) {
    this.isCopyButtonPressed =
      event.nativeEvent.ctrlKey || event.nativeEvent.shiftKey || event.nativeEvent.altKey;
  }

  private onMouseUp() {
    this.isCopyButtonPressed = false;
  }
}
