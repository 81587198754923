import { datadogRum } from '@datadog/browser-rum';
import * as Logger from 'js-logger';
import { ILogLevel } from 'js-logger/src/types';

import '../../../datadog';
import { isNil } from './lodash-extended';

// Web configs set the logging level and Webforms makes it available on the window.
/* istanbul ignore next */
function getLoggingLevel(): ILogLevel {
  return Logger[window.loggingLevel] || Logger.ERROR;
}

/* istanbul ignore next */
function sendToDatadog(message: string, level: ILogLevel, attributes?: any): void {
  if (level === Logger.ERROR) {
    try {
      throw new Error(message);
    } catch (err) {
      datadogRum.addError(err, attributes);
    }
  } else {
    datadogRum.addAction(message, attributes);
  }
}

const defaultHandler = Logger.createDefaultHandler();

Logger.useDefaults();

/* istanbul ignore next */
Logger.setHandler((messages, context) => {
  defaultHandler(messages, context);

  /**
   * You're probably wondering why I'm desctructuring with numeric keys.
   * That's because "messages" is actually the arguments that are passed into each logging function.
   * It's not actually an array, despite what the typing suggests.
   */
  const { 0: message, ...attributes } = messages;

  sendToDatadog(message, context.level, attributes);
});

Logger.setLevel(getLoggingLevel());

export { Logger };
