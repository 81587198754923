import * as _ from 'lodash';

import {
  IDefaultDmsObjectTreeItem,
  IDmsObjectTreeItem,
  IDmsObjectTreeItemBase,
} from './IDmsObjectTreeItem';
import { DataStatus } from 'modules/main/enums';

export interface IDmsObjectTreeRoot extends IDmsObjectTreeItemBase {
  content: IDmsObjectTreeItem[];
  hasMore?: boolean;
  onLoadMore?: () => void;
  status?: DataStatus;
}

export type IDmsObjectTreeContainerItem = IDmsObjectTreeRoot | IDefaultDmsObjectTreeItem;

export const isIDmsObjectTreeContainerItem = (
  object: any,
): object is IDmsObjectTreeContainerItem => {
  return _.object(object) && _.isArray(object.content);
};

export function isIDmsObjectTreeRoot(object: any): object is IDmsObjectTreeRoot {
  return _.isObject(object) && _.isArray((object as IDmsObjectTreeRoot).content);
}
