import { put, call, takeLeading, spawn } from 'redux-saga/effects';

import { DmsObjectService } from 'modules/dms-object/services/dms-object-service';
import { ObjectType } from 'modules/dms-object/enums/object-type';
import { fetchDocs } from 'modules/pub-docs/actions/documentsActions';
import { TInitApp, MainActionType } from 'modules/pub-docs/actions/mainActions';
import { fetchSiteInfo } from 'modules/pub-docs/actions/siteInfoActions';
import { setScrollToKey, openFolders } from 'modules/pub-docs/actions/treeActions';
import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';
import { fetchDocsAsync } from 'modules/pub-docs/sagas/documentsSaga';
import { fetchSiteInfoAsync } from 'modules/pub-docs/sagas/siteInfoSaga';

export function* bootstrapSaga() {
  yield takeLeading(MainActionType.InitApp, initAppAsync);
}

export function* initAppAsync(action: TInitApp) {
  const { siteKey, documentId } = action.payload;

  yield spawn(fetchSiteInfoAsync, fetchSiteInfo(siteKey));

  try {
    // TODO: find a better way to infer types. We may have to wait until TS 3.7 is out.
    const docs: TPublicDoc[] = yield call(fetchDocsAsync, fetchDocs(action.payload.siteKey));

    if (docs !== undefined && documentId !== undefined) {
      const selectedDoc = docs.find((doc) => doc.id === documentId);

      if (selectedDoc === undefined) {
        return;
      }

      const docIsNested =
        selectedDoc.breadcrumbs !== undefined && selectedDoc.breadcrumbs[0] !== undefined;

      if (docIsNested) {
        const folderIds = selectedDoc.breadcrumbs[0].trail.map((i) => i.id);
        yield put(openFolders(folderIds));
      }

      const key = DmsObjectService.generateObjectKey({
        id: selectedDoc.id,
        objectType: ObjectType.Document,
      });

      yield put(setScrollToKey(key));
    }
  } catch (error) {
    /**
     * Since fetchSiteInfoAsync and fetchDocsAsync handle their own errors, the error caught here is
     * most likely something to do with the selected doc processing.
     *
     *  TODO: Have a global error handler.
     */
    throw error;
  }
}
