import { datadogRum } from '@datadog/browser-rum';

import { isNil } from 'modules/main/services/lodash-extended';

/* istanbul ignore next */
document.addEventListener('DOMContentLoaded', () => {
  const { datadogRumConfig, isDatadogRumEnabled, SiteID, userID } = window.variablesFromServer;
  // This regex matches query string format based on latest RFC. ?_param234=this_isthe-value-of|the+parameter&_param2=replace{this}
  const queryStringRedactRegEx = /([?&]{1}[a-zA-Z0-9_]+)=([[\]{}%\w:@.?$+!;" =*`()~|,+:/-]+)/g;

  if (!isDatadogRumEnabled) {
    return;
  }

  datadogRum.init({
    ...datadogRumConfig,
    beforeSend: (event) => {
      if (event.view) {
        event.view.url = event.view.url.replace(queryStringRedactRegEx, '$1=[redacted]');
        event.view.referrer = event.view.referrer.replace(queryStringRedactRegEx, '$1=[redacted]');
      }

      // @ts-ignore: resource unknown
      if (event.resource) {
        // @ts-ignore: resource unknown
        event.resource.url = event.resource.url.replace(queryStringRedactRegEx, '$1=[redacted]');
      }

      if (!event.error) {
        return;
      }

      // @ts-ignore: error unknown
      if (event.error.resource) {
        // @ts-ignore: error unknown
        event.error.resource.url = event.error.resource.url.replace(
          queryStringRedactRegEx,
          '$1=[redacted]',
        );
      }

      // @ts-ignore: error unknown
      if (event.error.stack) {
        // @ts-ignore: error unknown
        event.error.stack = event.error.stack.replace(queryStringRedactRegEx, '$1=[redacted]');
      }

      // @ts-ignore: error unknown
      if (event.error.message) {
        // @ts-ignore: error unknown
        event.error.message = event.error.message.replace(queryStringRedactRegEx, '$1=[redacted]');
      }
    },
    sampleRate: 10,
    trackInteractions: false,
  });

  if (isNil(userID) || isNil(SiteID)) {
    return;
  }

  datadogRum.setUser({
    id: userID,
  });

  datadogRum.addRumGlobalContext('SiteID', SiteID);
});
