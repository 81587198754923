import * as React from 'react';

import { IDefaultDmsObjectTreeItem } from 'modules/dms-object/models/IDmsObjectTreeItem';

import { isNil, isNonEmptyString } from 'modules/main/services/lodash-extended';

import { ImportIndicator } from 'modules/content-hub/components/ImportIndicator';
import { DmsObjectName } from 'modules/dms-object/components/DmsObjectName';
import { isFunction } from 'lodash';
import { ObjectType } from 'modules/dms-object/enums';
import { Feature, FeatureService } from 'modules/main/services/feature-service';

type TDmsObjectTreeItemContentProps = {
  anchorRef: HTMLAnchorElement | HTMLButtonElement;
  collapsedIcon: JSX.Element;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLSpanElement>,
    treeItemKey: string,
    treeItemContentRef: HTMLButtonElement | HTMLSpanElement,
    anchorRef: HTMLButtonElement | HTMLAnchorElement,
  ) => void;
  isFocused: boolean;
  objectIcon: JSX.Element;
  onItemClick: () => void;
  setAnchorRef: (ref: HTMLAnchorElement | HTMLButtonElement) => void;
  setTreeItemContentRef: (ref: HTMLButtonElement | HTMLSpanElement) => void;
  useDefaultTabIndex: boolean;
  treeItem: IDefaultDmsObjectTreeItem;
  treeItemContentRef: HTMLButtonElement | HTMLSpanElement;
};

export function DmsObjectTreeItemContent(props: TDmsObjectTreeItemContentProps) {
  const {
    anchorRef,
    collapsedIcon,
    handleKeyDown,
    isFocused,
    objectIcon,
    onItemClick,
    setAnchorRef,
    setTreeItemContentRef,
    useDefaultTabIndex,
    treeItem,
    treeItemContentRef,
  } = props;
  /* istanbul ignore next */
  const hasNoObjectLink = isNil(treeItem.objectLink) || treeItem.objectLink === '#';

  /* istanbul ignore next */
  const [weight, setWeight] = React.useState(400);

  /* istanbul ignore next */
  React.useEffect(() => {
    if (isStorageFolderProvider()) {
      checkFolderDocuments();
    }

    if (isStorageProvider() && !treeItem.isRead) {
      setWeight(900);
    }
  }, []);

  /* istanbul ignore next */
  const checkFolderDocuments = () => {
    const documents = localStorage.getItem('documents');
    if (documents) {
      const documentsArray: any[] = JSON.parse(documents);
      const folderDocuments: any[] = documentsArray.filter(
        (document: any) => document.parentFolderId === treeItem.id,
      );
      const notRead = folderDocuments.some((document: any) => !document.isRead);
      if (notRead) {
        setWeight(900);
      } else {
        setWeight(400);
      }
    }
  };

  /* istanbul ignore next */
  const checkCurrentDocument = () => {
    const documents = localStorage.getItem('documents');
    if (documents) {
      const documentsArray: any[] = JSON.parse(documents);
      const index = documentsArray.findIndex((document: any) => document.id === treeItem.id);
      if (index > -1) {
        documentsArray[index].isRead = true;
        localStorage.setItem('documents', JSON.stringify(documentsArray));
        setWeight(400);
      }
    }
  };

  /* istanbul ignore next */
  const onDocumentClick = () => {
    if (treeItem.objectType === ObjectType.Folder) {
      const isModernizationBulkActionsEnabled = FeatureService.userHas(
        Feature.ModernizationBulkActions,
      );

      if (isModernizationBulkActionsEnabled) {
        window.history.pushState(null, '', `/folders/${treeItem.id}/view`);
      }
    }

    if (isStorageProvider() && !treeItem.isRead) {
      checkCurrentDocument();
    }
  };

  /* istanbul ignore next */
  const onToggleClick = () => {
    if (isStorageFolderProvider()) {
      checkFolderDocuments();
    }
  };

  /* istanbul ignore next */
  function isStorageProvider(): boolean {
    return treeItem.objectType === ObjectType.StorageProviderDocument;
  }

  /* istanbul ignore next */
  function isStorageFolderProvider(): boolean {
    return treeItem.objectType === ObjectType.StorageProviderFolder;
  }

  /* istanbul ignore next */
  const importIndicator = treeItem.isImported && (
    <ImportIndicator
      className="defaultDmsObjectTreeItem_importedIcon"
      objectType={treeItem.objectType}
    />
  );

  /* istanbul ignore next */
  const subtext = isNonEmptyString(treeItem.objectName.subtext) && (
    <span className="defaultDmsObjectTreeItem_subtext">{treeItem.objectName.subtext}</span>
  );

  /* istanbul ignore next */
  function onKeyDown(e: React.KeyboardEvent<HTMLButtonElement | HTMLSpanElement>) {
    if ([' '].includes(e.key)) {
      e.preventDefault();
    }
    /* istanbul ignore next */
    handleKeyDown(e, treeItem.key, treeItemContentRef, anchorRef);
  }

  /* istanbul ignore next */
  if (hasNoObjectLink) {
    return (
      <button
        className="defaultDmsObjectTreeItem_content defaultDmsObjectTreeItem_content--button"
        onClick={(e) => {
          e.preventDefault();
          onToggleClick();
          treeItem.onToggleCollapse();
          onItemClick();
        }}
        onKeyDown={onKeyDown}
        ref={(ref) => {
          setTreeItemContentRef(ref);
          setAnchorRef(ref);
        }}
        style={{ fontWeight: weight }}
        tabIndex={isFocused || useDefaultTabIndex ? 0 : -1}
        data-automation-id="defaultDmsObjectTreeItem_content"
      >
        {collapsedIcon}
        <span className="defaultDmsObjectTreeItem_link">
          {objectIcon}
          &#32;
          <span className="defaultDmsObjectTreeItem_objectTitle">
            <DmsObjectName {...treeItem.objectName} />
            {subtext}
          </span>
        </span>
        {importIndicator}
      </button>
    );
  }

  /* istanbul ignore next */
  return (
    <span
      className="defaultDmsObjectTreeItem_content"
      onClick={() => {
        if (isFunction(treeItem.onToggleCollapse)) {
          treeItem.onToggleCollapse();
        }
        setWeight(400);
        onItemClick();
      }}
      onKeyDown={onKeyDown}
      ref={(ref) => {
        setTreeItemContentRef(ref);
      }}
      style={{ fontWeight: weight }}
      data-automation-id="defaultDmsObjectTreeItem_content"
    >
      {collapsedIcon}
      <a
        className="defaultDmsObjectTreeItem_link"
        href={treeItem.objectLink}
        onClick={() => onDocumentClick()}
        ref={(ref) => {
          setAnchorRef(ref);
        }}
        tabIndex={isFocused || useDefaultTabIndex ? 0 : -1}
      >
        {objectIcon}
        &#32;
        <span className="defaultDmsObjectTreeItem_objectTitle">
          <DmsObjectName {...treeItem.objectName} />
          {subtext}
        </span>
      </a>
      {importIndicator}
    </span>
  );
}
