import { ObjectStatus, Permission } from 'modules/dms-object/enums';
import { DmsObject } from 'modules/dms-object/models/dms-object';
import { IHaveGroupParents } from 'modules/dms-object/models/dms-object';
import { Group, GroupsContainer } from 'modules/group/models';
import { getDataFromResponse } from 'modules/main/utilities/utils';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';

import { AdminStatus } from 'modules/user/enums';
import { IUserCustomFieldDto } from 'modules/user/models/user-custom-field-dto';
import { UserMembershipResource, UsersResource } from 'modules/user/resources';
import { TPatchUserDto } from 'modules/user/models';
import { UserSiteSettingsManager } from 'modules/site/managers';
import { UserService } from 'modules/user/services';

export class User extends DmsObject implements IHaveGroupParents {
  /**
   * NOTE: Private members (prepended with _) have a setter that broadcast an event with payload
   * whenever their values changed. If you need to do the same thing to the other public members,
   * follow the pattern.
   */
  public address: string;
  public adminStatus: AdminStatus;
  public city: string;
  public customFields: IUserCustomFieldDto[] = [];
  public email: string;
  public emailIsValid: boolean;
  public endDate: string;
  private _givenName: string;
  public hasClientPage = true;
  public hasAdminPage = true;
  public isMe: boolean;
  public jobTitle: string;
  public location: string;
  public parents: Array<Group | GroupsContainer> = [];
  public phoneExtension: string;
  private _surname: string;
  public state: string;
  private _username: string;
  public zipCode: string;

  public static $inject = [
    '$injector',
    'UserMembershipResource',
    'UsersResource',
    'UserService',
    'UserSiteSettingsManager',
  ];

  constructor(
    protected $injector: ng.auto.IInjectorService,
    private userMembershipResource: UserMembershipResource,
    private usersResource: UsersResource,
    private userService: UserService,
    private userSiteSettings: UserSiteSettingsManager,
  ) {
    super($injector);
  }

  public canBookmark(): boolean {
    return true;
  }

  public canEditMyProfile(): boolean {
    return this.userSiteSettings.allowUserProfileEditing && this.isMe;
  }

  public canManageUser(): boolean {
    return this.vars.priv_ADMIN && this.hasMinimumRights(Permission.Audit);
  }

  public canMessageUser(): boolean {
    return this.vars.priv_SEND_MESSAGES && this.getObjectStatus() !== ObjectStatus.Archived;
  }

  public getAdminPath(): string {
    return '/users/' + encodeURIComponent(this.id) + '/admin';
  }
  public getClientPath(): string {
    return '/users/' + encodeURIComponent(this.id);
  }

  public getMemberships(): ng.IPromise<Array<Group | GroupsContainer>> {
    return this.userMembershipResource.getMemberships(this.id).then((response) => {
      let groups = getDataFromResponse(response);
      this.parents = <Group[]>this.dmsObjectRepository.syncCollectionDmsObjectModel(groups);
      return this.parents;
    });
  }

  public getParents(): Array<Group | GroupsContainer> {
    if (this.parents.length > 0) {
      return this.parents;
    }

    return [<GroupsContainer>this.dmsObjectRepository.getRootGroupContainer()];
  }

  public hasFullName(): boolean {
    return this.userService.hasFullName(this);
  }

  public refreshModel(rawDmsObject: ISearchResult) {
    super.refreshModel(rawDmsObject);

    this.address = rawDmsObject.address || '';
    this.adminStatus = rawDmsObject.adminStatus;
    this.city = rawDmsObject.city || '';
    this.customFields = rawDmsObject.customFields;
    this.email = rawDmsObject.email || '';
    this.emailIsValid = rawDmsObject.emailIsValid;
    /**
     * endDate and endOn are the same property,
     * but the APIs can't seem to agree on implementation...
     */
    this.endDate = rawDmsObject.endDate || rawDmsObject.endOn;
    this.givenName = rawDmsObject.givenName || '';
    // using '==' for type conversion cause userID can be of type number
    this.isMe = this.vars.userID == this.id;
    this.jobTitle = rawDmsObject.jobTitle || '';
    this.location = rawDmsObject.location || '';
    this.phoneExtension = rawDmsObject.phoneExtension || '';
    this.surname = rawDmsObject.surname || '';
    this.state = rawDmsObject.state || '';
    this.username = rawDmsObject.username || '';
    this.zipCode = rawDmsObject.zipCode || '';

    this.parents = this.getParents();

    if (!this.name) this.name = this.username;
  }

  public save(data: TPatchUserDto): ng.IPromise<User> {
    return this.usersResource.patchUser(this.id, data).then((response) => {
      this.dmsObjectRepository.sync(getDataFromResponse(response));
      return this;
    });
  }

  public getDisplayName(): string {
    return this.userService.getDisplayName(this);
  }

  public get givenName(): string {
    return this._givenName;
  }
  public set givenName(value: string) {
    super.setAndBroadcast('givenName', value);
  }

  public get surname(): string {
    return this._surname;
  }
  public set surname(value: string) {
    super.setAndBroadcast('surname', value);
  }

  public get username(): string {
    return this._username;
  }
  public set username(value: string) {
    super.setAndBroadcast('username', value);
  }
}
