import { put, call, takeLeading } from 'redux-saga/effects';

import {
  fetchSiteInfoStart,
  fetchSiteInfoSuccess,
  fetchSiteInfoFail,
  SiteInfoActionType,
  TFetchSiteInfo,
} from 'modules/pub-docs/actions/siteInfoActions';
import { getSiteInfo } from 'modules/pub-docs/resources';

export function* siteInfoSaga() {
  yield takeLeading(SiteInfoActionType.Fetch, fetchSiteInfoAsync);
}

export function* fetchSiteInfoAsync(action: TFetchSiteInfo) {
  try {
    yield put(fetchSiteInfoStart());
    const siteInfo = yield call(getSiteInfo, action.payload.siteKey);
    yield put(fetchSiteInfoSuccess(siteInfo));
    return siteInfo;
  } catch (error) {
    yield put(fetchSiteInfoFail(error));
  }
}
