import * as H from 'history';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectDocument } from 'modules/pub-docs/actions/treeActions';
import { fetchDocs } from 'modules/pub-docs/actions/documentsActions';
import { initApp } from 'modules/pub-docs/actions/mainActions';
import { fetchSiteInfo } from 'modules/pub-docs/actions/siteInfoActions';
import { ListView } from 'modules/pub-docs/components/ListView/ListView';
import { getPubDocsSorted, listViewIsLoading } from 'modules/pub-docs/selectors';
import { TApplicationState } from 'modules/pub-docs/store';
import { EllipsisLoader } from 'modules/presentation/components/EllipsisLoader';
import { getSelectedDocument, treeViewHeaderIsLoading } from 'modules/pub-docs/selectors';

type TPropsFromRoute = {
  history: H.History;
  siteKey: string;

  documentId?: string;
};

type TPropsFromRedux = ReturnType<typeof mapStateToProps> &
  Partial<ReturnType<typeof mapDispatchToProps>>;

type TListViewContainerProps = TPropsFromRoute & TPropsFromRedux;

const mapStateToProps = (state: TApplicationState, props: TPropsFromRoute) => {
  return {
    siteName: state.siteInfo.data.siteName,
    documents: getPubDocsSorted(state),
    errorCode: state.documents.errorCode,
    hasError: state.documents.status === 'error',
    isLoading: listViewIsLoading(state),
    selectedDocument: getSelectedDocument(state, props),
    headerIsLoading: treeViewHeaderIsLoading(state, props),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchDocs, fetchSiteInfo, initApp, selectDocument }, dispatch);

function noop() {}

export class ListViewContainer extends React.Component<TListViewContainerProps, {}> {
  static propTypes = {
    siteKey: PropTypes.string.isRequired,
    documents: PropTypes.any,
    fetchDocs: PropTypes.func,
    fetchSiteInfo: PropTypes.func,
    initApp: PropTypes.func,
    selectDocument: PropTypes.func,
  };

  static defaultProps = {
    documents: [],
    fetchDocs: noop,
    fetchSiteInfo: noop,
    initApp: noop,
    selectDocument: noop,
  };

  constructor(props) {
    super(props);

    this.reload = this.reload.bind(this);
  }

  public render(): JSX.Element {
    return this.props.isLoading ? (
      <EllipsisLoader />
    ) : (
      <ListView
        errorCode={this.props.errorCode}
        hasError={this.props.hasError}
        onRetry={this.reload}
        documents={this.props.documents}
        selectDocument={(doc) => {
          this.props.selectDocument(
            doc,
            `/${this.props.siteKey}/list/documents/${doc.id}`,
            this.props.history,
          );
        }}
        selectedDocument={this.props.selectedDocument}
        siteName={this.props.siteName}
        siteKey={this.props.siteKey}
        headerIsLoading={this.props.headerIsLoading}
      />
    );
  }

  public componentDidMount() {
    this.init();
  }

  private reload() {
    this.init();
  }

  public init() {
    const alreadyHasPubDocs = this.props.documents.length > 0;
    const hasSiteName = this.props.siteName !== undefined;

    if (!alreadyHasPubDocs && !hasSiteName) {
      this.props.initApp(this.props.siteKey);
    } else if (!alreadyHasPubDocs) {
      this.props.fetchDocs(this.props.siteKey);
    } else if (!hasSiteName) {
      this.props.fetchSiteInfo(this.props.siteKey);
    }
  }
}

export const EnhancedListViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListViewContainer);
