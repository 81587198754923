import * as React from 'react';
import { ReactDirective } from 'ngreact';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';

import { DmsIcon } from 'modules/presentation/components/DmsIcon';
import { IconButton } from 'modules/presentation/components/IconButton';
import { Icon } from 'modules/presentation/enums';
import { NotificationStyle } from 'modules/notification/enums';
import { isNil } from 'modules/main/services/lodash-extended';

export type TMessageBoxProps = {
  children?: React.ReactNode;
  icon?: Icon;
  id?: string;
  innerHtml?: string;
  isDismissable?: boolean;
  messageStyle?: NotificationStyle;
  onDismiss?: () => void;
};

type TMessageBoxState = {
  isDismissed: boolean;
};

class MessageBox extends React.Component<TMessageBoxProps, TMessageBoxState> {
  static propTypes = {
    children: PropTypes.node,
    icon: PropTypes.string,
    innerHtml: PropTypes.string,
    isDismissable: PropTypes.bool,
    messageStyle: PropTypes.string,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    isDismissable: false,
    messageStyle: NotificationStyle.Default,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDismissed: false,
    };
  }

  private getClass = (): string => {
    let className = `messageBox messageBox--${this.props.messageStyle.toLowerCase()}`;

    if (this.state.isDismissed) {
      className += ' messageBox--dismissed';
    }

    return className;
  };

  private onDismissMessage = (): void => {
    this.setState({ isDismissed: true });

    if (_.isFunction(this.props.onDismiss)) {
      this.props.onDismiss();
    }
  };

  public render(): JSX.Element {
    const {
      children,
      icon,
      innerHtml,
      isDismissable,
      messageStyle,
      onDismiss,
      ...rest
    } = this.props;

    return (
      <div className={this.getClass()} {...rest}>
        {!isNil(icon) && <DmsIcon icon={icon} className="messageBox_icon" />}
        {/**
         * to keep this backwards-compatible with Angular using ngreact, which does not support
         * children, we need to have a prop that allows us to insert HTML or JSX elements as
         * "children" to the component
         */}
        <span className="messageBox_content">
          {isNil(innerHtml) ? children : <span dangerouslySetInnerHTML={{ __html: innerHtml }} />}
        </span>
        {isDismissable && (
          <IconButton
            icon={Icon.Exit}
            onClick={this.onDismissMessage}
            title="Dismiss"
            className="messageBox_dismiss"
          />
        )}
      </div>
    );
  }
}

export { MessageBox };

export class messageBoxDirective implements ng.IDirective {
  public static $inject = ['reactDirective'];

  constructor(reactDirective: ReactDirective) {
    return reactDirective(MessageBox as any);
  }
}
