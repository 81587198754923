export module Keys {
  export module ObjectTypes {
    export class ArchivedRevision {
      static readonly key: string = "ObjectTypes.ArchivedRevision";
    }
    export class ArchivedRevisions {
      static readonly key: string = "ObjectTypes.ArchivedRevisions";
    }
    export class ArchivedUser {
      static readonly key: string = "ObjectTypes.ArchivedUser";
    }
    export class Assessment {
      static readonly key: string = "ObjectTypes.Assessment";
    }
    export class AssessmentContainer {
      static readonly key: string = "ObjectTypes.AssessmentContainer";
    }
    export class AssessmentPlural {
      static readonly key: string = "ObjectTypes.AssessmentPlural";
    }
    export class Certificate {
      static readonly key: string = "ObjectTypes.Certificate";
    }
    export class CertificateContainer {
      static readonly key: string = "ObjectTypes.CertificateContainer";
    }
    export class CertificateLabel {
      static readonly key: string = "ObjectTypes.CertificateLabel";
    }
    export class CertificatePlural {
      static readonly key: string = "ObjectTypes.CertificatePlural";
    }
    export class CertificateType {
      static readonly key: string = "ObjectTypes.CertificateType";
    }
    export class Chapter {
      static readonly key: string = "ObjectTypes.Chapter";
    }
    export class ChapterPlural {
      static readonly key: string = "ObjectTypes.ChapterPlural";
    }
    export class Course {
      static readonly key: string = "ObjectTypes.Course";
    }
    export class CourseContainer {
      static readonly key: string = "ObjectTypes.CourseContainer";
    }
    export class CourseLabel {
      static readonly key: string = "ObjectTypes.CourseLabel";
    }
    export class CoursePlural {
      static readonly key: string = "ObjectTypes.CoursePlural";
    }
    export class CourseType {
      static readonly key: string = "ObjectTypes.CourseType";
    }
    export class Document {
      static readonly key: string = "ObjectTypes.Document";
    }
    export class DocumentContainer {
      static readonly key: string = "ObjectTypes.DocumentContainer";
    }
    export class DocumentPlural {
      static readonly key: string = "ObjectTypes.DocumentPlural";
    }
    export class Discussion {
      static readonly key: string = "ObjectTypes.Discussion";
    }
    export class DraftRevision {
      static readonly key: string = "ObjectTypes.DraftRevision";
    }
    export class EventLog {
      static readonly key: string = "ObjectTypes.EventLog";
    }
    export class StorageProviderDocument {
      static readonly key: string = "ObjectTypes.StorageProviderDocument";
    }
    export class StorageProviderFolder {
      static readonly key: string = "ObjectTypes.StorageProviderFolder";
    }
    export class Folder {
      static readonly key: string = "ObjectTypes.Folder";
    }
    export class Group {
      static readonly key: string = "ObjectTypes.Group";
    }
    export class GroupPlural {
      static readonly key: string = "ObjectTypes.GroupPlural";
    }
    export class Information {
      static readonly key: string = "ObjectTypes.Information";
    }
    export class JobTitle {
      static readonly key: string = "ObjectTypes.JobTitle";
    }
    export class LinkedGroup {
      static readonly key: string = "ObjectTypes.LinkedGroup";
    }
    export class Location {
      static readonly key: string = "ObjectTypes.Location";
    }
    export class Manual {
      static readonly key: string = "ObjectTypes.Manual";
    }
    export class ManualContainer {
      static readonly key: string = "ObjectTypes.ManualContainer";
    }
    export class ManualRevision {
      static readonly key: string = "ObjectTypes.ManualRevision";
    }
    export class Message {
      static readonly key: string = "ObjectTypes.Message";
    }
    export class PublishedRevision {
      static readonly key: string = "ObjectTypes.PublishedRevision";
    }
    export class SearchSuggestion {
      static readonly key: string = "ObjectTypes.SearchSuggestion";
    }
    export class Section {
      static readonly key: string = "ObjectTypes.Section";
    }
    export class Standard {
      static readonly key: string = "ObjectTypes.Standard";
    }
    export class StandardPlural {
      static readonly key: string = "ObjectTypes.StandardPlural";
    }
    export class StandardsManual {
      static readonly key: string = "ObjectTypes.StandardsManual";
    }
    export class StandardsNode {
      static readonly key: string = "ObjectTypes.StandardsNode";
    }
    export class StorageProvider {
      static readonly key: string = "ObjectTypes.StorageProvider";
    }
    export class StorageProvidersRoot {
      static readonly key: string = "ObjectTypes.StorageProvidersRoot";
    }
    export class SubType {
      static readonly key: string = "ObjectTypes.SubType";
    }
    export class Survey {
      static readonly key: string = "ObjectTypes.Survey";
    }
    export class SurveyContainer {
      static readonly key: string = "ObjectTypes.SurveyContainer";
    }
    export class SurveyPlural {
      static readonly key: string = "ObjectTypes.SurveyPlural";
    }
    export class Tag {
      static readonly key: string = "ObjectTypes.Tag";
    }
    export class Test {
      static readonly key: string = "ObjectTypes.Test";
    }
    export class TestContainer {
      static readonly key: string = "ObjectTypes.TestContainer";
    }
    export class TestLabel {
      static readonly key: string = "ObjectTypes.TestLabel";
    }
    export class TestPlural {
      static readonly key: string = "ObjectTypes.TestPlural";
    }
    export class TrainingRequest {
      static readonly key: string = "ObjectTypes.TrainingRequest";
    }
    export class TrainingRequestLabel {
      static readonly key: string = "ObjectTypes.TrainingRequestLabel";
    }
    export class TrainingRequestPlural {
      static readonly key: string = "ObjectTypes.TrainingRequestPlural";
    }
    export class User {
      static readonly key: string = "ObjectTypes.User";
    }
    export class UserContainer {
      static readonly key: string = "ObjectTypes.UserContainer";
    }
    export class UserPlural {
      static readonly key: string = "ObjectTypes.UserPlural";
    }
    export class Workflow {
      static readonly key: string = "ObjectTypes.Workflow";
    }
    export class WorkflowPlural {
      static readonly key: string = "ObjectTypes.WorkflowPlural";
    }
    export class WorkflowPluralAbbreviation {
      static readonly key: string = "ObjectTypes.WorkflowPluralAbbreviation";
    }
  }
  export module ObjectRoot {
    export class Assessments {
      static readonly key: string = "ObjectRoot.Assessments";
    }
    export class Certificates {
      static readonly key: string = "ObjectRoot.Certificates";
    }
    export class Courses {
      static readonly key: string = "ObjectRoot.Courses";
    }
    export class Documents {
      static readonly key: string = "ObjectRoot.Documents";
    }
    export class StandardsManuals {
      static readonly key: string = "ObjectRoot.StandardsManuals";
    }
    export class Surveys {
      static readonly key: string = "ObjectRoot.Surveys";
    }
    export class Tests {
      static readonly key: string = "ObjectRoot.Tests";
    }
  }
  export module SearchType {
    export class Assessments {
      static readonly key: string = "SearchType.Assessments";
    }
    export class Certificates {
      static readonly key: string = "SearchType.Certificates";
    }
    export class ContentHub {
      static readonly key: string = "SearchType.ContentHub";
    }
    export class CertificateTypes {
      static readonly key: string = "SearchType.CertificateTypes";
    }
    export class Courses {
      static readonly key: string = "SearchType.Courses";
    }
    export class CourseTypes {
      static readonly key: string = "SearchType.CourseTypes";
    }
    export class Discussions {
      static readonly key: string = "SearchType.Discussions";
    }
    export class Documents {
      static readonly key: string = "SearchType.Documents";
    }
    export class DocumentTypes {
      static readonly key: string = "SearchType.DocumentTypes";
    }
    export class EventLog {
      static readonly key: string = "SearchType.EventLog";
    }
    export class Everything {
      static readonly key: string = "SearchType.Everything";
    }
    export class Folders {
      static readonly key: string = "SearchType.Folders";
    }
    export class FoldersAndDocs {
      static readonly key: string = "SearchType.FoldersAndDocs";
    }
    export class FoldersAndGroups {
      static readonly key: string = "SearchType.FoldersAndGroups";
    }
    export class Groups {
      static readonly key: string = "SearchType.Groups";
    }
    export class Manuals {
      static readonly key: string = "SearchType.Manuals";
    }
    export class Tags {
      static readonly key: string = "SearchType.Tags";
    }
    export class Tests {
      static readonly key: string = "SearchType.Tests";
    }
    export class StandardsNodes {
      static readonly key: string = "SearchType.StandardsNodes";
    }
    export class ManualsAndStandardsNodes {
      static readonly key: string = "SearchType.ManualsAndStandardsNodes";
    }
    export class Surveys {
      static readonly key: string = "SearchType.Surveys";
    }
    export class Users {
      static readonly key: string = "SearchType.Users";
    }
    export class UsersAndGroups {
      static readonly key: string = "SearchType.UsersAndGroups";
    }
    export class Workflows {
      static readonly key: string = "SearchType.Workflows";
    }
  }
  export module CommonTerms {
    export class Acquire {
      static readonly key: string = "CommonTerms.Acquire";
    }
    export class Action {
      static readonly key: string = "CommonTerms.Action";
    }
    export class Actions {
      static readonly key: string = "CommonTerms.Actions";
    }
    export class Advance {
      static readonly key: string = "CommonTerms.Advance";
    }
    export class All {
      static readonly key: string = "CommonTerms.All";
    }
    export class Anyone {
      static readonly key: string = "CommonTerms.Anyone";
    }
    export class Approve {
      static readonly key: string = "CommonTerms.Approve";
    }
    export class Archived {
      static readonly key: string = "CommonTerms.Archived";
    }
    export class ArchivedUser {
      static readonly key: string = "CommonTerms.ArchivedUser";
    }
    export class Archiving {
      static readonly key: string = "CommonTerms.Archiving";
    }
    export class Assess {
      static readonly key: string = "CommonTerms.Assess";
    }
    export class Assignments {
      static readonly key: string = "CommonTerms.Assignments";
    }
    export class Asterisk {
      static readonly key: string = "CommonTerms.Asterisk";
    }
    export class Begin {
      static readonly key: string = "CommonTerms.Begin";
    }
    export class Back {
      static readonly key: string = "CommonTerms.Back";
    }
    export class Browse {
      static readonly key: string = "CommonTerms.Browse";
    }
    export class Cancel {
      static readonly key: string = "CommonTerms.Cancel";
    }
    export class Cancelled {
      static readonly key: string = "CommonTerms.Cancelled";
    }
    export class Change {
      static readonly key: string = "CommonTerms.Change";
    }
    export class CharsRemaining {
      static readonly key: string = "CommonTerms.CharsRemaining";
      charCount: string;
    }
    export class CharactersRemainingSingular {
      static readonly key: string = "CommonTerms.CharactersRemainingSingular";
      number: number;
    }
    export class CharactersRemainingPlural {
      static readonly key: string = "CommonTerms.CharactersRemainingPlural";
      number: number;
    }
    export class Checking {
      static readonly key: string = "CommonTerms.Checking";
    }
    export class Clear {
      static readonly key: string = "CommonTerms.Clear";
    }
    export class Close {
      static readonly key: string = "CommonTerms.Close";
    }
    export class CommaSeparator {
      static readonly key: string = "CommonTerms.CommaSeparator";
    }
    export class Comma {
      static readonly key: string = "CommonTerms.Comma";
    }
    export class Completed {
      static readonly key: string = "CommonTerms.Completed";
    }
    export class Continue {
      static readonly key: string = "CommonTerms.Continue";
    }
    export class Connect {
      static readonly key: string = "CommonTerms.Connect";
    }
    export class CopiedToClipboard {
      static readonly key: string = "CommonTerms.CopiedToClipboard";
    }
    export class CopyToClipboard {
      static readonly key: string = "CommonTerms.CopyToClipboard";
    }
    export class CopyVerb {
      static readonly key: string = "CommonTerms.CopyVerb";
    }
    export class Create {
      static readonly key: string = "CommonTerms.Create";
    }
    export class DateCreated {
      static readonly key: string = "CommonTerms.DateCreated";
    }
    export class Delete {
      static readonly key: string = "CommonTerms.Delete";
    }
    export class Deleting {
      static readonly key: string = "CommonTerms.Deleting";
    }
    export class Description {
      static readonly key: string = "CommonTerms.Description";
    }
    export class DescriptionLabel {
      static readonly key: string = "CommonTerms.DescriptionLabel";
    }
    export class Destination {
      static readonly key: string = "CommonTerms.Destination";
    }
    export class DestinationLabel {
      static readonly key: string = "CommonTerms.DestinationLabel";
    }
    export class Details {
      static readonly key: string = "CommonTerms.Details";
    }
    export class Disable {
      static readonly key: string = "CommonTerms.Disable";
    }
    export class Disabled {
      static readonly key: string = "CommonTerms.Disabled";
    }
    export class Disconnect {
      static readonly key: string = "CommonTerms.Disconnect";
    }
    export class DisconnectParenthesis {
      static readonly key: string = "CommonTerms.DisconnectParenthesis";
    }
    export class Dismiss {
      static readonly key: string = "CommonTerms.Dismiss";
    }
    export class Document {
      static readonly key: string = "CommonTerms.Document";
    }
    export class DocumentsStatus {
      static readonly key: string = "CommonTerms.DocumentsStatus";
    }
    export class DollarSign {
      static readonly key: string = "CommonTerms.DollarSign";
    }
    export class Done {
      static readonly key: string = "CommonTerms.Done";
    }
    export class DownloadVerb {
      static readonly key: string = "CommonTerms.DownloadVerb";
    }
    export class DueDate {
      static readonly key: string = "CommonTerms.DueDate";
    }
    export class DueSoon {
      static readonly key: string = "CommonTerms.DueSoon";
    }
    export class Edit {
      static readonly key: string = "CommonTerms.Edit";
    }
    export class Ellipsis {
      static readonly key: string = "CommonTerms.Ellipsis";
    }
    export class EllipsisFragment {
      static readonly key: string = "CommonTerms.EllipsisFragment";
      fragment: string;
    }
    export class EmailNoun {
      static readonly key: string = "CommonTerms.EmailNoun";
    }
    export class EmailAddress {
      static readonly key: string = "CommonTerms.EmailAddress";
    }
    export class EmailAddresses {
      static readonly key: string = "CommonTerms.EmailAddresses";
    }
    export class Enable {
      static readonly key: string = "CommonTerms.Enable";
    }
    export class Enabled {
      static readonly key: string = "CommonTerms.Enabled";
    }
    export class Enabling {
      static readonly key: string = "CommonTerms.Enabling";
    }
    export class EnableLabel {
      static readonly key: string = "CommonTerms.EnableLabel";
    }
    export class EndDate {
      static readonly key: string = "CommonTerms.EndDate";
    }
    export class ErrorLoading {
      static readonly key: string = "CommonTerms.ErrorLoading";
    }
    export class Excel {
      static readonly key: string = "CommonTerms.Excel";
    }
    export class ExportVerb {
      static readonly key: string = "CommonTerms.ExportVerb";
    }
    export class ExpandRow {
      static readonly key: string = "CommonTerms.ExpandRow";
    }
    export class FillOut {
      static readonly key: string = "CommonTerms.FillOut";
    }
    export class Folder {
      static readonly key: string = "CommonTerms.Folder";
    }
    export class FolderLabel {
      static readonly key: string = "CommonTerms.FolderLabel";
    }
    export class FolderSeparator {
      static readonly key: string = "CommonTerms.FolderSeparator";
    }
    export class ForMoreInfo {
      static readonly key: string = "CommonTerms.ForMoreInfo";
    }
    export class General {
      static readonly key: string = "CommonTerms.General";
    }
    export class GivenNameSurname {
      static readonly key: string = "CommonTerms.GivenNameSurname";
      givenName: string;
      surname: string;
    }
    export class GivenNameSurnameParenthesis {
      static readonly key: string = "CommonTerms.GivenNameSurnameParenthesis";
      givenName: string;
      surname: string;
    }
    export class Go {
      static readonly key: string = "CommonTerms.Go";
    }
    export class GoBack {
      static readonly key: string = "CommonTerms.GoBack";
    }
    export class GotIt {
      static readonly key: string = "CommonTerms.GotIt";
    }
    export class Help {
      static readonly key: string = "CommonTerms.Help";
    }
    export class ImportItems {
      static readonly key: string = "CommonTerms.ImportItems";
      count: string;
    }
    export class ImportSingleItem {
      static readonly key: string = "CommonTerms.ImportSingleItem";
    }
    export class Info {
      static readonly key: string = "CommonTerms.Info";
    }
    export class JobTitleLabel {
      static readonly key: string = "CommonTerms.JobTitleLabel";
    }
    export class Label {
      static readonly key: string = "CommonTerms.Label";
      label: string;
    }
    export class LearnMore {
      static readonly key: string = "CommonTerms.LearnMore";
    }
    export class LoadMore {
      static readonly key: string = "CommonTerms.LoadMore";
    }
    export class LoadingEllipsis {
      static readonly key: string = "CommonTerms.LoadingEllipsis";
    }
    export class Location {
      static readonly key: string = "CommonTerms.Location";
    }
    export class LocationLabel {
      static readonly key: string = "CommonTerms.LocationLabel";
    }
    export class LogoAltText {
      static readonly key: string = "CommonTerms.LogoAltText";
    }
    export class Logout {
      static readonly key: string = "CommonTerms.Logout";
    }
    export class Long {
      static readonly key: string = "CommonTerms.Long";
    }
    export class Manage {
      static readonly key: string = "CommonTerms.Manage";
    }
    export class Medium {
      static readonly key: string = "CommonTerms.Medium";
    }
    export class Name {
      static readonly key: string = "CommonTerms.Name";
    }
    export class NameLabel {
      static readonly key: string = "CommonTerms.NameLabel";
    }
    export class Never {
      static readonly key: string = "CommonTerms.Never";
    }
    export class New {
      static readonly key: string = "CommonTerms.New";
    }
    export class NewMessage {
      static readonly key: string = "CommonTerms.NewMessage";
    }
    export class Next {
      static readonly key: string = "CommonTerms.Next";
    }
    export class No {
      static readonly key: string = "CommonTerms.No";
    }
    export class None {
      static readonly key: string = "CommonTerms.None";
    }
    export class NoneStatus {
      static readonly key: string = "CommonTerms.NoneStatus";
    }
    export class NotPublic {
      static readonly key: string = "CommonTerms.NotPublic";
    }
    export class NotSetStatus {
      static readonly key: string = "CommonTerms.NotSetStatus";
    }
    export class OK {
      static readonly key: string = "CommonTerms.OK";
    }
    export class Okay {
      static readonly key: string = "CommonTerms.Okay";
    }
    export class Open {
      static readonly key: string = "CommonTerms.Open";
    }
    export class OR {
      static readonly key: string = "CommonTerms.OR";
    }
    export class Overdue {
      static readonly key: string = "CommonTerms.Overdue";
    }
    export class DatePickerTooltip {
      static readonly key: string = "CommonTerms.DatePickerTooltip";
    }
    export class Parentheses {
      static readonly key: string = "CommonTerms.Parentheses";
      text: string;
    }
    export class Password {
      static readonly key: string = "CommonTerms.Password";
    }
    export class PasswordLabel {
      static readonly key: string = "CommonTerms.PasswordLabel";
    }
    export class PermanentlyDelete {
      static readonly key: string = "CommonTerms.PermanentlyDelete";
    }
    export class Permissions {
      static readonly key: string = "CommonTerms.Permissions";
    }
    export class PhoneNumberLabel {
      static readonly key: string = "CommonTerms.PhoneNumberLabel";
    }
    export class PowerDMSAuthor {
      static readonly key: string = "CommonTerms.PowerDMSAuthor";
    }
    export class PowerDMSHome {
      static readonly key: string = "CommonTerms.PowerDMSHome";
    }
    export class PowerPoint {
      static readonly key: string = "CommonTerms.PowerPoint";
    }
    export class Print {
      static readonly key: string = "CommonTerms.Print";
    }
    export class Previous {
      static readonly key: string = "CommonTerms.Previous";
    }
    export class PrivacyPolicy {
      static readonly key: string = "CommonTerms.PrivacyPolicy";
    }
    export class Proceed {
      static readonly key: string = "CommonTerms.Proceed";
    }
    export class Public {
      static readonly key: string = "CommonTerms.Public";
    }
    export class Publish {
      static readonly key: string = "CommonTerms.Publish";
    }
    export class Published {
      static readonly key: string = "CommonTerms.Published";
    }
    export class Publishing {
      static readonly key: string = "CommonTerms.Publishing";
    }
    export class Remove {
      static readonly key: string = "CommonTerms.Remove";
    }
    export class Renew {
      static readonly key: string = "CommonTerms.Renew";
    }
    export class Reset {
      static readonly key: string = "CommonTerms.Reset";
    }
    export class ResetPassword {
      static readonly key: string = "CommonTerms.ResetPassword";
    }
    export class Restore {
      static readonly key: string = "CommonTerms.Restore";
    }
    export class Retry {
      static readonly key: string = "CommonTerms.Retry";
    }
    export class ReviewVerb {
      static readonly key: string = "CommonTerms.ReviewVerb";
    }
    export class ReviewExceptionVerb {
      static readonly key: string = "CommonTerms.ReviewExceptionVerb";
    }
    export class Save {
      static readonly key: string = "CommonTerms.Save";
    }
    export class Saved {
      static readonly key: string = "CommonTerms.Saved";
    }
    export class Saving {
      static readonly key: string = "CommonTerms.Saving";
    }
    export class Search {
      static readonly key: string = "CommonTerms.Search";
    }
    export class Searching {
      static readonly key: string = "CommonTerms.Searching";
    }
    export class Section {
      static readonly key: string = "CommonTerms.Section";
    }
    export class Sections {
      static readonly key: string = "CommonTerms.Sections";
    }
    export class Security {
      static readonly key: string = "CommonTerms.Security";
    }
    export class SendWelcomeEmail {
      static readonly key: string = "CommonTerms.SendWelcomeEmail";
    }
    export class ShortAdjective {
      static readonly key: string = "CommonTerms.ShortAdjective";
    }
    export class SignVerb {
      static readonly key: string = "CommonTerms.SignVerb";
    }
    export class SiteKey {
      static readonly key: string = "CommonTerms.SiteKey";
    }
    export class SiteKeyLabel {
      static readonly key: string = "CommonTerms.SiteKeyLabel";
    }
    export class SiteNameLabel {
      static readonly key: string = "CommonTerms.SiteNameLabel";
    }
    export class Start {
      static readonly key: string = "CommonTerms.Start";
    }
    export class SortByLabel {
      static readonly key: string = "CommonTerms.SortByLabel";
    }
    export class SortOrder {
      static readonly key: string = "CommonTerms.SortOrder";
    }
    export class StartDate {
      static readonly key: string = "CommonTerms.StartDate";
    }
    export class Status {
      static readonly key: string = "CommonTerms.Status";
    }
    export class Stop {
      static readonly key: string = "CommonTerms.Stop";
    }
    export class Submit {
      static readonly key: string = "CommonTerms.Submit";
    }
    export class SubmittingEllipsis {
      static readonly key: string = "CommonTerms.SubmittingEllipsis";
    }
    export class Successful {
      static readonly key: string = "CommonTerms.Successful";
    }
    export class SurnameGivenName {
      static readonly key: string = "CommonTerms.SurnameGivenName";
      surname: string;
      givenName: string;
    }
    export class TaggedWithLabel {
      static readonly key: string = "CommonTerms.TaggedWithLabel";
    }
    export class Take {
      static readonly key: string = "CommonTerms.Take";
    }
    export class TakeAdditional {
      static readonly key: string = "CommonTerms.TakeAdditional";
    }
    export class TermsOfService {
      static readonly key: string = "CommonTerms.TermsOfService";
    }
    export class APITermsAndConditions {
      static readonly key: string = "CommonTerms.APITermsAndConditions";
    }
    export class ToggleSecretVisibility {
      static readonly key: string = "CommonTerms.ToggleSecretVisibility";
    }
    export class TrainingNoun {
      static readonly key: string = "CommonTerms.TrainingNoun";
    }
    export class Update {
      static readonly key: string = "CommonTerms.Update";
    }
    export class Updating {
      static readonly key: string = "CommonTerms.Updating";
    }
    export class Upload {
      static readonly key: string = "CommonTerms.Upload";
    }
    export class Username {
      static readonly key: string = "CommonTerms.Username";
    }
    export class ViewLess {
      static readonly key: string = "CommonTerms.ViewLess";
    }
    export class ViewMore {
      static readonly key: string = "CommonTerms.ViewMore";
    }
    export class VoteOn {
      static readonly key: string = "CommonTerms.VoteOn";
    }
    export class Web {
      static readonly key: string = "CommonTerms.Web";
    }
    export class Word {
      static readonly key: string = "CommonTerms.Word";
    }
    export class Yes {
      static readonly key: string = "CommonTerms.Yes";
    }
    export module Archive {
      export class Verb {
        static readonly key: string = "CommonTerms.Archive.Verb";
      }
    }
    export module Draft {
      export class Noun {
        static readonly key: string = "CommonTerms.Draft.Noun";
      }
    }
    export module Error {
      export class Hmmm {
        static readonly key: string = "CommonTerms.Error.Hmmm";
      }
    }
    export module File {
      export class Singular {
        static readonly key: string = "CommonTerms.File.Singular";
      }
      export class Plural {
        static readonly key: string = "CommonTerms.File.Plural";
      }
    }
  }
  export module AdminMenu {
    export module ApiAccess {
      export class ApiKeys {
        static readonly key: string = "AdminMenu.ApiAccess.ApiKeys";
      }
      export class ApiKeyRequest {
        static readonly key: string = "AdminMenu.ApiAccess.ApiKeyRequest";
      }
      export class ApiKeyRequestText {
        static readonly key: string = "AdminMenu.ApiAccess.ApiKeyRequestText";
      }
      export class Title {
        static readonly key: string = "AdminMenu.ApiAccess.Title";
      }
      export class ClientID {
        static readonly key: string = "AdminMenu.ApiAccess.ClientID";
      }
      export class ClientSecret {
        static readonly key: string = "AdminMenu.ApiAccess.ClientSecret";
      }
      export class ForMoreInformation {
        static readonly key: string = "AdminMenu.ApiAccess.ForMoreInformation";
        apiDocsUrl: string;
        apiSupportEmail: string;
      }
      export class RedirectURL {
        static readonly key: string = "AdminMenu.ApiAccess.RedirectURL";
      }
      export class RedirectURLInfoCaption {
        static readonly key: string =
          "AdminMenu.ApiAccess.RedirectURLInfoCaption";
      }
      export class RedirectURLInfo {
        static readonly key: string = "AdminMenu.ApiAccess.RedirectURLInfo";
      }
      export class ApiClientName {
        static readonly key: string = "AdminMenu.ApiAccess.ApiClientName";
      }
      export class ApiClientNameInfoCaption {
        static readonly key: string =
          "AdminMenu.ApiAccess.ApiClientNameInfoCaption";
      }
      export class ApiClientNameInfo {
        static readonly key: string = "AdminMenu.ApiAccess.ApiClientNameInfo";
      }
      export class SendRequest {
        static readonly key: string = "AdminMenu.ApiAccess.SendRequest";
      }
      export class ApiKeyRequestConfirmation {
        static readonly key: string =
          "AdminMenu.ApiAccess.ApiKeyRequestConfirmation";
        termsOfService: string;
        privacyPolicy: string;
      }
      export class ApiKeyRequestApiTermsConfirmation {
        static readonly key: string =
          "AdminMenu.ApiAccess.ApiKeyRequestApiTermsConfirmation";
        apiTermsAndConditions: string;
      }
      export module ApiKeyRequestError {
        export class RequiredField {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.RequiredField";
          fieldName: string;
        }
        export class ServerRequestError {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.ServerRequestError";
        }
        export class ApiClientNameContainsPowerDMS {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.ApiClientNameContainsPowerDMS";
        }
        export class MaxNumberOfClientsExceeded {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.MaxNumberOfClientsExceeded";
        }
        export class RedirectUrlNotWellFormed {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.RedirectUrlNotWellFormed";
        }
        export class MaxLength {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.MaxLength";
          fieldName: string;
          maxLength: string;
        }
        export class TOSConfirm {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.TOSConfirm";
        }
        export class ApiTOSConfirm {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyRequestError.ApiTOSConfirm";
        }
      }
      export module ApiKeyActionError {
        export class DeleteRequest {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyActionError.DeleteRequest";
        }
        export class UpdateRequest {
          static readonly key: string =
            "AdminMenu.ApiAccess.ApiKeyActionError.UpdateRequest";
        }
      }
    }
    export module Backup {
      export class RequestPowerDMSBackup {
        static readonly key: string = "AdminMenu.Backup.RequestPowerDMSBackup";
      }
      export class FeatureDescription {
        static readonly key: string = "AdminMenu.Backup.FeatureDescription";
      }
      export class BackedUpWithin24Hours {
        static readonly key: string = "AdminMenu.Backup.BackedUpWithin24Hours";
      }
      export class CheckingLastBackupStatus {
        static readonly key: string =
          "AdminMenu.Backup.CheckingLastBackupStatus";
      }
      export class ErrorOccurredCheckingStatusOfBackup {
        static readonly key: string =
          "AdminMenu.Backup.ErrorOccurredCheckingStatusOfBackup";
      }
      export class RequestInProgress {
        static readonly key: string = "AdminMenu.Backup.RequestInProgress";
      }
      export class RequestCanceled {
        static readonly key: string = "AdminMenu.Backup.RequestCanceled";
      }
      export class RequestFailed {
        static readonly key: string = "AdminMenu.Backup.RequestFailed";
      }
      export class RequestedOn {
        static readonly key: string = "AdminMenu.Backup.RequestedOn";
        fileName: string;
        requestedOn: string;
      }
      export class StartBackupRequest {
        static readonly key: string = "AdminMenu.Backup.StartBackupRequest";
      }
      export class Warning {
        static readonly key: string = "AdminMenu.Backup.Warning";
      }
    }
    export module GeneralSettings {
      export class TwelveHourOption {
        static readonly key: string =
          "AdminMenu.GeneralSettings.TwelveHourOption";
      }
      export class TwentyFourHourOption {
        static readonly key: string =
          "AdminMenu.GeneralSettings.TwentyFourHourOption";
      }
      export class ChangesToDefaultLanguageAndTimeFormat {
        static readonly key: string =
          "AdminMenu.GeneralSettings.ChangesToDefaultLanguageAndTimeFormat";
      }
      export class ChangesToTimeFormat {
        static readonly key: string =
          "AdminMenu.GeneralSettings.ChangesToTimeFormat";
      }
      export class Header {
        static readonly key: string = "AdminMenu.GeneralSettings.Header";
      }
      export class DefaultLanguageLabel {
        static readonly key: string =
          "AdminMenu.GeneralSettings.DefaultLanguageLabel";
      }
      export class TimeFormatLabel {
        static readonly key: string =
          "AdminMenu.GeneralSettings.TimeFormatLabel";
      }
      export class TimeZoneLabel {
        static readonly key: string = "AdminMenu.GeneralSettings.TimeZoneLabel";
      }
    }
    export module Licensing {
      export class AssessmentsLabel {
        static readonly key: string = "AdminMenu.Licensing.AssessmentsLabel";
      }
      export class AssessmentLicenses {
        static readonly key: string = "AdminMenu.Licensing.AssessmentLicenses";
      }
      export class ErrorLoadingLicensingInfo {
        static readonly key: string =
          "AdminMenu.Licensing.ErrorLoadingLicensingInfo";
      }
      export class ForAdditionalLicenses {
        static readonly key: string =
          "AdminMenu.Licensing.ForAdditionalLicenses";
        email: string;
        phoneNumber: string;
      }
      export class PurchasedLicenses {
        static readonly key: string = "AdminMenu.Licensing.PurchasedLicenses";
        purchased: string;
      }
      export class UsedLicenses {
        static readonly key: string = "AdminMenu.Licensing.UsedLicenses";
        used: string;
      }
      export class UserLicenses {
        static readonly key: string = "AdminMenu.Licensing.UserLicenses";
      }
      export class ViewReport {
        static readonly key: string = "AdminMenu.Licensing.ViewReport";
      }
    }
    export module Users {
      export class AllowUsersToAccessMobile {
        static readonly key: string =
          "AdminMenu.Users.AllowUsersToAccessMobile";
      }
      export class AllowUsersToAuthenticate {
        static readonly key: string =
          "AdminMenu.Users.AllowUsersToAuthenticate";
      }
      export class AllowUsersToAuthenticateAndSign {
        static readonly key: string =
          "AdminMenu.Users.AllowUsersToAuthenticateAndSign";
      }
      export class AllowUsersToChangeSecurity {
        static readonly key: string =
          "AdminMenu.Users.AllowUsersToChangeSecurity";
        emOpening: string;
        emClosing: string;
      }
      export class AllowUsersToEdit {
        static readonly key: string = "AdminMenu.Users.AllowUsersToEdit";
      }
      export class AnErrorHasOccurred {
        static readonly key: string = "AdminMenu.Users.AnErrorHasOccurred";
        breakTag: string;
        errorHttpCode: string;
        error: string;
      }
      export class SendWelcomeEmail {
        static readonly key: string = "AdminMenu.Users.SendWelcomeEmail";
        existingUsersLinkOpening: string;
        usersWhoHaventLoggedIn: string;
        linkClosing: string;
        sendEmailLinkOpening: string;
      }
      export class SendEmailToNewUsers {
        static readonly key: string = "AdminMenu.Users.SendEmailToNewUsers";
      }
      export class SendEmailToNewUsersDisabled {
        static readonly key: string =
          "AdminMenu.Users.SendEmailToNewUsersDisabled";
      }
      export class WelcomeEmailSent {
        static readonly key: string = "AdminMenu.Users.WelcomeEmailSent";
      }
      export class WelcomeEmailSentPlural {
        static readonly key: string = "AdminMenu.Users.WelcomeEmailSentPlural";
        numUsers: number;
      }
    }
    export module WelcomeEmail {
      export class AnErrorHasOccurred {
        static readonly key: string =
          "AdminMenu.WelcomeEmail.AnErrorHasOccurred";
      }
      export class DontSend {
        static readonly key: string = "AdminMenu.WelcomeEmail.DontSend";
      }
      export class NewUsersWillReceiveWelcomeEmail {
        static readonly key: string =
          "AdminMenu.WelcomeEmail.NewUsersWillReceiveWelcomeEmail";
        usersWhoHaventLoggedIn: string;
      }
      export class NoEmailAssociatedWithUser {
        static readonly key: string =
          "AdminMenu.WelcomeEmail.NoEmailAssociatedWithUser";
      }
      export class SelectedUser {
        static readonly key: string = "AdminMenu.WelcomeEmail.SelectedUser";
      }
      export class SelectedUsers {
        static readonly key: string = "AdminMenu.WelcomeEmail.SelectedUsers";
        selectedUsers: string;
      }
      export class WelcomeEmailEnabled {
        static readonly key: string =
          "AdminMenu.WelcomeEmail.WelcomeEmailEnabled";
      }
    }
  }
  export module AdvancedSearch {
    export class DefaultError {
      static readonly key: string = "AdvancedSearch.DefaultError";
    }
    export class DefaultErrorWithLink {
      static readonly key: string = "AdvancedSearch.DefaultErrorWithLink";
      linkStart: string;
      linkEnd: string;
    }
    export class DidYouMean {
      static readonly key: string = "AdvancedSearch.DidYouMean";
    }
    export class ExportExcel {
      static readonly key: string = "AdvancedSearch.ExportExcel";
    }
    export class ExportingStatus {
      static readonly key: string = "AdvancedSearch.ExportingStatus";
    }
    export class ExportPDF {
      static readonly key: string = "AdvancedSearch.ExportPDF";
    }
    export class PageNotFound {
      static readonly key: string = "AdvancedSearch.PageNotFound";
    }
    export class SearchDiscussions {
      static readonly key: string = "AdvancedSearch.SearchDiscussions";
    }
    export class SearchEventLog {
      static readonly key: string = "AdvancedSearch.SearchEventLog";
    }
    export class SearchWorkflows {
      static readonly key: string = "AdvancedSearch.SearchWorkflows";
    }
    export class SearchPhraseLabel {
      static readonly key: string = "AdvancedSearch.SearchPhraseLabel";
    }
    export class SearchTypeLabel {
      static readonly key: string = "AdvancedSearch.SearchTypeLabel";
    }
    export class ServerError {
      static readonly key: string = "AdvancedSearch.ServerError";
    }
    export class StandardsManualSuggestion {
      static readonly key: string = "AdvancedSearch.StandardsManualSuggestion";
      linkStart: string;
      linkEnd: string;
    }
    export class Title {
      static readonly key: string = "AdvancedSearch.Title";
    }
    export module Filters {
      export class AllFoldersPlaceholder {
        static readonly key: string =
          "AdvancedSearch.Filters.AllFoldersPlaceholder";
      }
      export class AnonymousLabel {
        static readonly key: string = "AdvancedSearch.Filters.AnonymousLabel";
      }
      export class AssignedToGroupLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.AssignedToGroupLabel";
      }
      export class CertificateTypeLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.CertificateTypeLabel";
      }
      export class CourseTypeLabel {
        static readonly key: string = "AdvancedSearch.Filters.CourseTypeLabel";
      }
      export class DateRangeSeparator {
        static readonly key: string =
          "AdvancedSearch.Filters.DateRangeSeparator";
      }
      export class FirstNameLabel {
        static readonly key: string = "AdvancedSearch.Filters.FirstNameLabel";
      }
      export class DocTypesAllTypesPlaceholder {
        static readonly key: string =
          "AdvancedSearch.Filters.DocTypesAllTypesPlaceholder";
      }
      export class EnabledLabel {
        static readonly key: string = "AdvancedSearch.Filters.EnabledLabel";
      }
      export class FolderAlreadyAdded {
        static readonly key: string =
          "AdvancedSearch.Filters.FolderAlreadyAdded";
      }
      export class HasAdministratorPrivilegesLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.HasAdministratorPrivilegesLabel";
      }
      export class HasDraftRevisionLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.HasDraftRevisionLabel";
      }
      export class IncludeLabel {
        static readonly key: string = "AdvancedSearch.Filters.IncludeLabel";
      }
      export class IncludePastSectionsLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.IncludePastSectionsLabel";
      }
      export class LastNameLabel {
        static readonly key: string = "AdvancedSearch.Filters.LastNameLabel";
      }
      export class MasterAdministratorLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.MasterAdministratorLabel";
      }
      export class MemberOfLabel {
        static readonly key: string = "AdvancedSearch.Filters.MemberOfLabel";
      }
      export class PrivilegesLabel {
        static readonly key: string = "AdvancedSearch.Filters.PrivilegesLabel";
      }
      export class ShowArchivedLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.ShowArchivedLabel";
      }
      export class ShowArchivedAssessmentsLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.ShowArchivedAssessmentsLabel";
      }
      export class ArchivedLabel {
        static readonly key: string = "AdvancedSearch.Filters.ArchivedLabel";
      }
      export class ShowDisabledArchivedLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.ShowDisabledArchivedLabel";
      }
      export class ShowExpiredRevisionsLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.ShowExpiredRevisionsLabel";
      }
      export class ShowTagsTypesFoldersLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.ShowTagsTypesFoldersLabel";
      }
      export class StartDateRangeLabel {
        static readonly key: string =
          "AdvancedSearch.Filters.StartDateRangeLabel";
      }
      export class TypeLabel {
        static readonly key: string = "AdvancedSearch.Filters.TypeLabel";
      }
      export class UsernameLabel {
        static readonly key: string = "AdvancedSearch.Filters.UsernameLabel";
      }
    }
    export module Pagination {
      export class ItemsOf {
        static readonly key: string = "AdvancedSearch.Pagination.ItemsOf";
        startCount: string;
        endCount: string;
        totalCount: string;
      }
      export class PageSize {
        static readonly key: string = "AdvancedSearch.Pagination.PageSize";
      }
      export class PageXOfY {
        static readonly key: string = "AdvancedSearch.Pagination.PageXOfY";
        currentPage: string;
        totalPages: string;
      }
    }
    export module Results {
      export class AnonymousColumn {
        static readonly key: string = "AdvancedSearch.Results.AnonymousColumn";
      }
      export class ArchivedColumn {
        static readonly key: string = "AdvancedSearch.Results.ArchivedColumn";
      }
      export class AwardNoCertificate {
        static readonly key: string =
          "AdvancedSearch.Results.AwardNoCertificate";
      }
      export class AwardOneCertificate {
        static readonly key: string =
          "AdvancedSearch.Results.AwardOneCertificate";
      }
      export class AwardMultipleCertificates {
        static readonly key: string =
          "AdvancedSearch.Results.AwardMultipleCertificates";
        certificateCount: string;
      }
      export class CollapseRevisionsTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.CollapseRevisionsTooltip";
      }
      export class CollapseSectionsTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.CollapseSectionsTooltip";
      }
      export class CreditHoursColumn {
        static readonly key: string =
          "AdvancedSearch.Results.CreditHoursColumn";
      }
      export class DescriptionColumn {
        static readonly key: string =
          "AdvancedSearch.Results.DescriptionColumn";
      }
      export class DisabledStatus {
        static readonly key: string = "AdvancedSearch.Results.DisabledStatus";
      }
      export class DocumentNameColumn {
        static readonly key: string =
          "AdvancedSearch.Results.DocumentNameColumn";
      }
      export class DocumentRootFolder {
        static readonly key: string =
          "AdvancedSearch.Results.DocumentRootFolder";
      }
      export class DraftRevisionColumn {
        static readonly key: string =
          "AdvancedSearch.Results.DraftRevisionColumn";
      }
      export class DueDateColumn {
        static readonly key: string = "AdvancedSearch.Results.DueDateColumn";
      }
      export class EnabledColumn {
        static readonly key: string = "AdvancedSearch.Results.EnabledColumn";
      }
      export class EndDateColumn {
        static readonly key: string = "AdvancedSearch.Results.EndDateColumn";
      }
      export class EndDateDefault {
        static readonly key: string = "AdvancedSearch.Results.EndDateDefault";
      }
      export class ExpiresColumn {
        static readonly key: string = "AdvancedSearch.Results.ExpiresColumn";
      }
      export class FilledSeats {
        static readonly key: string = "AdvancedSearch.Results.FilledSeats";
        filledSeats: string;
        totalSeats: string;
      }
      export class FirstNameColumn {
        static readonly key: string = "AdvancedSearch.Results.FirstNameColumn";
      }
      export class FolderColumn {
        static readonly key: string = "AdvancedSearch.Results.FolderColumn";
      }
      export class GenericFilterTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.GenericFilterTooltip";
        objectType: string;
      }
      export class GenericManageTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.GenericManageTooltip";
        objectType: string;
      }
      export class GenericViewTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.GenericViewTooltip";
        objectType: string;
      }
      export class JobTitleColumn {
        static readonly key: string = "AdvancedSearch.Results.JobTitleColumn";
      }
      export class LastNameColumn {
        static readonly key: string = "AdvancedSearch.Results.LastNameColumn";
      }
      export class LinkedToColumn {
        static readonly key: string = "AdvancedSearch.Results.LinkedToColumn";
      }
      export class LocationColumn {
        static readonly key: string = "AdvancedSearch.Results.LocationColumn";
      }
      export class ManageAssessmentTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ManageAssessmentTooltip";
      }
      export class ManageCertificateTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ManageCertificateTooltip";
      }
      export class ManageCourseTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ManageCourseTooltip";
      }
      export class ManageDocumentTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ManageDocumentTooltip";
      }
      export class ManageSectionTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ManageSectionTooltip";
      }
      export class ManualNoRevisions {
        static readonly key: string =
          "AdvancedSearch.Results.ManualNoRevisions";
      }
      export class NameColumn {
        static readonly key: string = "AdvancedSearch.Results.NameColumn";
      }
      export class NoAvailableSections {
        static readonly key: string =
          "AdvancedSearch.Results.NoAvailableSections";
      }
      export class NoRecords {
        static readonly key: string = "AdvancedSearch.Results.NoRecords";
      }
      export class PublishedColumn {
        static readonly key: string = "AdvancedSearch.Results.PublishedColumn";
      }
      export class PublishedRevisionColumn {
        static readonly key: string =
          "AdvancedSearch.Results.PublishedRevisionColumn";
      }
      export class RevisionColumn {
        static readonly key: string = "AdvancedSearch.Results.RevisionColumn";
      }
      export class SeatsColumn {
        static readonly key: string = "AdvancedSearch.Results.SeatsColumn";
      }
      export class SeatCountDefault {
        static readonly key: string = "AdvancedSearch.Results.SeatCountDefault";
      }
      export class SectionNumberColumn {
        static readonly key: string =
          "AdvancedSearch.Results.SectionNumberColumn";
      }
      export class ShowRevisionsTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ShowRevisionsTooltip";
      }
      export class ShowSectionsTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ShowSectionsTooltip";
      }
      export class StandardsManualColumn {
        static readonly key: string =
          "AdvancedSearch.Results.StandardsManualColumn";
      }
      export class StartDateColumn {
        static readonly key: string = "AdvancedSearch.Results.StartDateColumn";
      }
      export class StartDateDefault {
        static readonly key: string = "AdvancedSearch.Results.StartDateDefault";
      }
      export class TitleColumn {
        static readonly key: string = "AdvancedSearch.Results.TitleColumn";
      }
      export class TypeColumn {
        static readonly key: string = "AdvancedSearch.Results.TypeColumn";
      }
      export class UsernameColumn {
        static readonly key: string = "AdvancedSearch.Results.UsernameColumn";
      }
      export class ViewAssessmentTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewAssessmentTooltip";
      }
      export class ViewCertificateTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewCertificateTooltip";
      }
      export class ViewCourseTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewCourseTooltip";
      }
      export class ViewDocumentTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewDocumentTooltip";
      }
      export class ViewDraftRevisionTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewDraftRevisionTooltip";
      }
      export class ViewPublishedRevisionTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewPublishedRevisionTooltip";
      }
      export class ViewRevisionTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewRevisionTooltip";
      }
      export class ViewSectionTooltip {
        static readonly key: string =
          "AdvancedSearch.Results.ViewSectionTooltip";
      }
    }
  }
  export module Assessments {
    export class DueDateLabel {
      static readonly key: string = "Assessments.DueDateLabel";
    }
    export class ManualPublicationLabel {
      static readonly key: string = "Assessments.ManualPublicationLabel";
    }
    export class NameLabel {
      static readonly key: string = "Assessments.NameLabel";
    }
    export class ViewAssessment {
      static readonly key: string = "Assessments.ViewAssessment";
    }
    export class YourRoleLabel {
      static readonly key: string = "Assessments.YourRoleLabel";
    }
    export module CopyTasks {
      export class AssignToRole {
        static readonly key: string = "Assessments.CopyTasks.AssignToRole";
      }
      export class CopyTasks {
        static readonly key: string = "Assessments.CopyTasks.CopyTasks";
      }
      export class CopyToAssessment {
        static readonly key: string = "Assessments.CopyTasks.CopyToAssessment";
      }
      export class CopyToRole {
        static readonly key: string = "Assessments.CopyTasks.CopyToRole";
      }
      export class DestinationAssessmentLabel {
        static readonly key: string =
          "Assessments.CopyTasks.DestinationAssessmentLabel";
      }
      export class DoesNotRepeat {
        static readonly key: string = "Assessments.CopyTasks.DoesNotRepeat";
      }
      export class Header {
        static readonly key: string = "Assessments.CopyTasks.Header";
      }
      export class NoStartDate {
        static readonly key: string = "Assessments.CopyTasks.NoStartDate";
      }
      export class NonCopyablePlural {
        static readonly key: string = "Assessments.CopyTasks.NonCopyablePlural";
        numTasks: string;
        roleName: string;
        assessmentName: string;
      }
      export class NonCopyableSingular {
        static readonly key: string =
          "Assessments.CopyTasks.NonCopyableSingular";
        roleName: string;
        assessmentName: string;
      }
      export class NoValidAssessments {
        static readonly key: string =
          "Assessments.CopyTasks.NoValidAssessments";
      }
      export class NoValidRoles {
        static readonly key: string = "Assessments.CopyTasks.NoValidRoles";
      }
      export class NumberTasksPlural {
        static readonly key: string = "Assessments.CopyTasks.NumberTasksPlural";
        numTasks: string;
      }
      export class NumberTasksSingular {
        static readonly key: string =
          "Assessments.CopyTasks.NumberTasksSingular";
      }
      export class Repeats {
        static readonly key: string = "Assessments.CopyTasks.Repeats";
        recurrencePeriod: string;
        recurrencePeriodType: string;
        endDate: string;
      }
      export class SourceAssessmentLabel {
        static readonly key: string =
          "Assessments.CopyTasks.SourceAssessmentLabel";
      }
      export class StartDate {
        static readonly key: string = "Assessments.CopyTasks.StartDate";
        startDate: string;
      }
      export class StepOne {
        static readonly key: string = "Assessments.CopyTasks.StepOne";
      }
      export class StepTwo {
        static readonly key: string = "Assessments.CopyTasks.StepTwo";
      }
      export class StepThree {
        static readonly key: string = "Assessments.CopyTasks.StepThree";
        roleName: string;
        assessmentName: string;
      }
      export class Success {
        static readonly key: string = "Assessments.CopyTasks.Success";
      }
    }
    export module New {
      export class ChooseManualAdminRights {
        static readonly key: string = "Assessments.New.ChooseManualAdminRights";
        linkStart: string;
        linkEnd: string;
      }
      export class Error {
        static readonly key: string = "Assessments.New.Error";
        phoneNumber: string;
        email: string;
      }
      export class ManualSelection {
        static readonly key: string = "Assessments.New.ManualSelection";
      }
      export class NewAssessment {
        static readonly key: string = "Assessments.New.NewAssessment";
      }
      export class ChooseManualNoAdminRights {
        static readonly key: string =
          "Assessments.New.ChooseManualNoAdminRights";
      }
      export class StandardsManualLabel {
        static readonly key: string = "Assessments.New.StandardsManualLabel";
      }
    }
    export module Title {
      export class Assessment {
        static readonly key: string = "Assessments.Title.Assessment";
      }
      export class New {
        static readonly key: string = "Assessments.Title.New";
      }
      export class General {
        static readonly key: string = "Assessments.Title.General";
      }
      export class Security {
        static readonly key: string = "Assessments.Title.Security";
      }
    }
    export module Viewer {
      export module Attachments {
        export class AffectedHighlights {
          static readonly key: string =
            "Assessments.Viewer.Attachments.AffectedHighlights";
        }
        export class AttachmentCopiedSuccessfully {
          static readonly key: string =
            "Assessments.Viewer.Attachments.AttachmentCopiedSuccessfully";
        }
        export class AttachmentCopyFailed {
          static readonly key: string =
            "Assessments.Viewer.Attachments.AttachmentCopyFailed";
        }
        export class AttachmentHasBeenCopiedTo {
          static readonly key: string =
            "Assessments.Viewer.Attachments.AttachmentHasBeenCopiedTo";
          attachmentName: string;
          count: string;
          totalCount: string;
          assessmentName: string;
          roleName: string;
        }
        export class AttachmentFailedToCopyTo {
          static readonly key: string =
            "Assessments.Viewer.Attachments.AttachmentFailedToCopyTo";
        }
        export class DocumentHasNoHighlights {
          static readonly key: string =
            "Assessments.Viewer.Attachments.DocumentHasNoHighlights";
        }
        export class DownloadAttachment {
          static readonly key: string =
            "Assessments.Viewer.Attachments.DownloadAttachment";
        }
        export class ChangeAssessment {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ChangeAssessment";
        }
        export class CollapseAllHighlights {
          static readonly key: string =
            "Assessments.Viewer.Attachments.CollapseAllHighlights";
        }
        export class CopyAttachment {
          static readonly key: string =
            "Assessments.Viewer.Attachments.CopyAttachment";
        }
        export class CopyAttachmentsTitle {
          static readonly key: string =
            "Assessments.Viewer.Attachments.CopyAttachmentsTitle";
        }
        export class CopyToRole {
          static readonly key: string =
            "Assessments.Viewer.Attachments.CopyToRole";
        }
        export class DeleteAttachment {
          static readonly key: string =
            "Assessments.Viewer.Attachments.DeleteAttachment";
        }
        export class DragZoneMessage {
          static readonly key: string =
            "Assessments.Viewer.Attachments.DragZoneMessage";
        }
        export class DeleteHighlight {
          static readonly key: string =
            "Assessments.Viewer.Attachments.DeleteHighlight";
        }
        export class EditAttachment {
          static readonly key: string =
            "Assessments.Viewer.Attachments.EditAttachment";
        }
        export class EditHighlights {
          static readonly key: string =
            "Assessments.Viewer.Attachments.EditHighlights";
        }
        export class EditSelection {
          static readonly key: string =
            "Assessments.Viewer.Attachments.EditSelection";
        }
        export class ErrorAssessmentArchived {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorAssessmentArchived";
        }
        export class ErrorAssessmentNotRetrieved {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorAssessmentNotRetrieved";
        }
        export class ErrorAttachmentDoesNotExist {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorAttachmentDoesNotExist";
        }
        export class ErrorConversionAgree {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorConversionAgree";
        }
        export class ErrorFileNameLength {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorFileNameLength";
          maxChars: number;
        }
        export class ErrorInsufficientPermissions {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorInsufficientPermissions";
        }
        export class ErrorLockedRole {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorLockedRole";
        }
        export class ErrorNoNotes {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorNoNotes";
        }
        export class ErrorNoAssignedRoles {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorNoAssignedRoles";
        }
        export class ErrorNoStandardsFoundForRole {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorNoStandardsFoundForRole";
        }
        export class ErrorNodeDoesNotAllowAttachments {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorNodeDoesNotAllowAttachments";
        }
        export class ErrorNodesNotFoundForAssessment {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorNodesNotFoundForAssessment";
        }
        export class ErrorNoPoints {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorNoPoints";
        }
        export class ErrorUpload {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ErrorUpload";
        }
        export class ExpandAllHighlights {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ExpandAllHighlights";
        }
        export class GoToAffectedStandards {
          static readonly key: string =
            "Assessments.Viewer.Attachments.GoToAffectedStandards";
        }
        export class HighlightDisplayAsPdf {
          static readonly key: string =
            "Assessments.Viewer.Attachments.HighlightDisplayAsPdf";
        }
        export class HighlightPageMissing {
          static readonly key: string =
            "Assessments.Viewer.Attachments.HighlightPageMissing";
        }
        export class HighlightPointError {
          static readonly key: string =
            "Assessments.Viewer.Attachments.HighlightPointError";
        }
        export class Highlights {
          static readonly key: string =
            "Assessments.Viewer.Attachments.Highlights";
        }
        export class HighlightsMayHaveChanged {
          static readonly key: string =
            "Assessments.Viewer.Attachments.HighlightsMayHaveChanged";
        }
        export class HighlightsReviewRemaining {
          static readonly key: string =
            "Assessments.Viewer.Attachments.HighlightsReviewRemaining";
        }
        export class HighlightsWillBeRemoved {
          static readonly key: string =
            "Assessments.Viewer.Attachments.HighlightsWillBeRemoved";
        }
        export class HighlightTitle {
          static readonly key: string =
            "Assessments.Viewer.Attachments.HighlightTitle";
        }
        export class InformationTitle {
          static readonly key: string =
            "Assessments.Viewer.Attachments.InformationTitle";
        }
        export class NoAttachmentsSelectedCopy {
          static readonly key: string =
            "Assessments.Viewer.Attachments.NoAttachmentsSelectedCopy";
        }
        export class NoAttachmentsSelectedUpdate {
          static readonly key: string =
            "Assessments.Viewer.Attachments.NoAttachmentsSelectedUpdate";
        }
        export class NoStandardsFound {
          static readonly key: string =
            "Assessments.Viewer.Attachments.NoStandardsFound";
        }
        export class NotAssociatedHighlight {
          static readonly key: string =
            "Assessments.Viewer.Attachments.NotAssociatedHighlight";
        }
        export class OpenInDocumentViewer {
          static readonly key: string =
            "Assessments.Viewer.Attachments.OpenInDocumentViewer";
        }
        export class PleaseConfirmTitle {
          static readonly key: string =
            "Assessments.Viewer.Attachments.PleaseConfirmTitle";
        }
        export class PointSelected {
          static readonly key: string =
            "Assessments.Viewer.Attachments.PointSelected";
        }
        export class PointSelectedPlural {
          static readonly key: string =
            "Assessments.Viewer.Attachments.PointSelectedPlural";
          numPoints: number;
        }
        export class RoleLabel {
          static readonly key: string =
            "Assessments.Viewer.Attachments.RoleLabel";
        }
        export class SearchStandards {
          static readonly key: string =
            "Assessments.Viewer.Attachments.SearchStandards";
        }
        export class SelectHighlight {
          static readonly key: string =
            "Assessments.Viewer.Attachments.SelectHighlight";
        }
        export class SelectionSummary {
          static readonly key: string =
            "Assessments.Viewer.Attachments.SelectionSummary";
          attachmentName: string;
        }
        export class SelectedStandardsCount {
          static readonly key: string =
            "Assessments.Viewer.Attachments.SelectedStandardsCount";
          count: string;
        }
        export class SelectAttachmentDestination {
          static readonly key: string =
            "Assessments.Viewer.Attachments.SelectAttachmentDestination";
        }
        export class ThisAssessment {
          static readonly key: string =
            "Assessments.Viewer.Attachments.ThisAssessment";
        }
        export class UpdateAttachmentTitle {
          static readonly key: string =
            "Assessments.Viewer.Attachments.UpdateAttachmentTitle";
        }
        export class VerifyHighlight {
          static readonly key: string =
            "Assessments.Viewer.Attachments.VerifyHighlight";
        }
        export class SelectStandardToCopyAttachmentTo {
          static readonly key: string =
            "Assessments.Viewer.Attachments.SelectStandardToCopyAttachmentTo";
          attachmentName: string;
        }
        export class EmptyStateDescription {
          static readonly key: string =
            "Assessments.Viewer.Attachments.EmptyStateDescription";
        }
      }
      export module Export {
        export class Available {
          static readonly key: string = "Assessments.Viewer.Export.Available";
        }
        export class CancelModalTitle {
          static readonly key: string =
            "Assessments.Viewer.Export.CancelModalTitle";
        }
        export class CancelModalHeader {
          static readonly key: string =
            "Assessments.Viewer.Export.CancelModalHeader";
        }
        export class Cancel {
          static readonly key: string = "Assessments.Viewer.Export.Cancel";
        }
        export class DeleteModalTitle {
          static readonly key: string =
            "Assessments.Viewer.Export.DeleteModalTitle";
        }
        export class DeleteModalHeader {
          static readonly key: string =
            "Assessments.Viewer.Export.DeleteModalHeader";
        }
        export class ChapterSelectionCount {
          static readonly key: string =
            "Assessments.Viewer.Export.ChapterSelectionCount";
          count: string;
        }
        export class CreateExport {
          static readonly key: string =
            "Assessments.Viewer.Export.CreateExport";
        }
        export class Download {
          static readonly key: string = "Assessments.Viewer.Export.Download";
        }
        export class Delete {
          static readonly key: string = "Assessments.Viewer.Export.Delete";
        }
        export class ExportHistory {
          static readonly key: string =
            "Assessments.Viewer.Export.ExportHistory";
        }
        export class ExportType {
          static readonly key: string = "Assessments.Viewer.Export.ExportType";
        }
        export class Filters {
          static readonly key: string = "Assessments.Viewer.Export.Filters";
        }
        export class FullAssessment {
          static readonly key: string =
            "Assessments.Viewer.Export.FullAssessment";
        }
        export class FullAssessmentExport {
          static readonly key: string =
            "Assessments.Viewer.Export.FullAssessmentExport";
        }
        export class FullAssessmentDescription {
          static readonly key: string =
            "Assessments.Viewer.Export.FullAssessmentDescription";
          assessmentName: string;
        }
        export class FullAssessmentSummary {
          static readonly key: string =
            "Assessments.Viewer.Export.FullAssessmentSummary";
        }
        export class NewExport {
          static readonly key: string = "Assessments.Viewer.Export.NewExport";
        }
        export class NoRolesAssigned {
          static readonly key: string =
            "Assessments.Viewer.Export.NoRolesAssigned";
        }
        export class InProgress {
          static readonly key: string = "Assessments.Viewer.Export.InProgress";
        }
        export class PartialAssessment {
          static readonly key: string =
            "Assessments.Viewer.Export.PartialAssessment";
        }
        export class LimitedExportMessage {
          static readonly key: string =
            "Assessments.Viewer.Export.LimitedExportMessage";
        }
        export class PartialAssessmentExport {
          static readonly key: string =
            "Assessments.Viewer.Export.PartialAssessmentExport";
        }
        export class PartialAssessmentSummary {
          static readonly key: string =
            "Assessments.Viewer.Export.PartialAssessmentSummary";
        }
        export class Recipients {
          static readonly key: string = "Assessments.Viewer.Export.Recipients";
        }
        export class RecipientSelectionCount {
          static readonly key: string =
            "Assessments.Viewer.Export.RecipientSelectionCount";
          count: string;
        }
        export class SelectAll {
          static readonly key: string = "Assessments.Viewer.Export.SelectAll";
        }
        export class DeselectAll {
          static readonly key: string = "Assessments.Viewer.Export.DeselectAll";
        }
        export class Selected {
          static readonly key: string = "Assessments.Viewer.Export.Selected";
        }
        export class RequestExport {
          static readonly key: string =
            "Assessments.Viewer.Export.RequestExport";
        }
        export class Retry {
          static readonly key: string = "Assessments.Viewer.Export.Retry";
        }
        export class Role {
          static readonly key: string = "Assessments.Viewer.Export.Role";
        }
        export class Roles {
          static readonly key: string = "Assessments.Viewer.Export.Roles";
        }
        export class RoleSelectionCount {
          static readonly key: string =
            "Assessments.Viewer.Export.RoleSelectionCount";
          count: string;
        }
        export class SelectAllChapters {
          static readonly key: string =
            "Assessments.Viewer.Export.SelectAllChapters";
        }
        export class SelectAllRoles {
          static readonly key: string =
            "Assessments.Viewer.Export.SelectAllRoles";
        }
        export class SelectAllChaptersAndRootStandardsTitle {
          static readonly key: string =
            "Assessments.Viewer.Export.SelectAllChaptersAndRootStandardsTitle";
        }
        export class StepNumberOfTotal {
          static readonly key: string =
            "Assessments.Viewer.Export.StepNumberOfTotal";
          stepNumber: string;
          stepTotal: string;
        }
        export class ViewExportStatus {
          static readonly key: string =
            "Assessments.Viewer.Export.ViewExportStatus";
        }
        export class RetryExport {
          static readonly key: string = "Assessments.Viewer.Export.RetryExport";
        }
        export class ExportRequestSent {
          static readonly key: string =
            "Assessments.Viewer.Export.ExportRequestSent";
        }
        export class ExportRequestError {
          static readonly key: string =
            "Assessments.Viewer.Export.ExportRequestError";
        }
        export class Error403 {
          static readonly key: string = "Assessments.Viewer.Export.Error403";
        }
        export class Error422 {
          static readonly key: string = "Assessments.Viewer.Export.Error422";
        }
        export class Error504 {
          static readonly key: string = "Assessments.Viewer.Export.Error504";
        }
        export class ExportRequestSentSummary {
          static readonly key: string =
            "Assessments.Viewer.Export.ExportRequestSentSummary";
        }
        export class StatusWaiting {
          static readonly key: string =
            "Assessments.Viewer.Export.StatusWaiting";
        }
        export class StatusQueued {
          static readonly key: string =
            "Assessments.Viewer.Export.StatusQueued";
        }
        export class StatusRunning {
          static readonly key: string =
            "Assessments.Viewer.Export.StatusRunning";
        }
        export class FullExportTypeStatus {
          static readonly key: string =
            "Assessments.Viewer.Export.FullExportTypeStatus";
        }
        export class PartialExportTypeStatus {
          static readonly key: string =
            "Assessments.Viewer.Export.PartialExportTypeStatus";
        }
        export class StatusSuccess {
          static readonly key: string =
            "Assessments.Viewer.Export.StatusSuccess";
        }
        export class StatusCanceled {
          static readonly key: string =
            "Assessments.Viewer.Export.StatusCanceled";
        }
        export class StatusFailed {
          static readonly key: string =
            "Assessments.Viewer.Export.StatusFailed";
        }
        export class Status {
          static readonly key: string = "Assessments.Viewer.Export.Status";
        }
        export class User {
          static readonly key: string = "Assessments.Viewer.Export.User";
        }
        export class Date {
          static readonly key: string = "Assessments.Viewer.Export.Date";
        }
        export class Revision {
          static readonly key: string = "Assessments.Viewer.Export.Revision";
        }
        export class Expire {
          static readonly key: string = "Assessments.Viewer.Export.Expire";
        }
        export class Days {
          static readonly key: string = "Assessments.Viewer.Export.Days";
        }
      }
      export module Standards {
        export class InvalidExpirationDate {
          static readonly key: string =
            "Assessments.Viewer.Standards.InvalidExpirationDate";
        }
        export class NoStatusSetAlert {
          static readonly key: string =
            "Assessments.Viewer.Standards.NoStatusSetAlert";
        }
        export class RoleChangeConfirmation {
          static readonly key: string =
            "Assessments.Viewer.Standards.RoleChangeConfirmation";
        }
        export class StatusNotSet {
          static readonly key: string =
            "Assessments.Viewer.Standards.StatusNotSet";
        }
        export class ViewAttachmentsTooltip {
          static readonly key: string =
            "Assessments.Viewer.Standards.ViewAttachmentsTooltip";
        }
        export class ViewStandardTooltip {
          static readonly key: string =
            "Assessments.Viewer.Standards.ViewStandardTooltip";
        }
        export class ViewTasksTooltip {
          static readonly key: string =
            "Assessments.Viewer.Standards.ViewTasksTooltip";
        }
      }
      export module TaskStatus {
        export class Any {
          static readonly key: string = "Assessments.Viewer.TaskStatus.Any";
        }
        export class CommaListSeparator {
          static readonly key: string =
            "Assessments.Viewer.TaskStatus.CommaListSeparator";
        }
        export class None {
          static readonly key: string = "Assessments.Viewer.TaskStatus.None";
        }
      }
    }
  }
  export module AttributePicker {
    export class ShowAll {
      static readonly key: string = "AttributePicker.ShowAll";
      searchType: string;
    }
    export class GroupMembersCount {
      static readonly key: string = "AttributePicker.GroupMembersCount";
      numMembers: number;
    }
    export class UnsavedChanges {
      static readonly key: string = "AttributePicker.UnsavedChanges";
    }
    export module Add {
      export class UsersAndGroups {
        static readonly key: string = "AttributePicker.Add.UsersAndGroups";
      }
      export class FoldersAndDocs {
        static readonly key: string = "AttributePicker.Add.FoldersAndDocs";
      }
      export class ObjectType {
        static readonly key: string = "AttributePicker.Add.ObjectType";
        objectType: string;
      }
      export class Button {
        static readonly key: string = "AttributePicker.Add.Button";
      }
    }
    export module AutoAdd {
      export class Users {
        static readonly key: string = "AttributePicker.AutoAdd.Users";
      }
      export class ObjectType {
        static readonly key: string = "AttributePicker.AutoAdd.ObjectType";
        searchType: string;
      }
      export class OnlyMasterAdmin {
        static readonly key: string = "AttributePicker.AutoAdd.OnlyMasterAdmin";
      }
      export class ApplyButton {
        static readonly key: string = "AttributePicker.AutoAdd.ApplyButton";
      }
    }
    export module Checkboxes {
      export class Cascading {
        static readonly key: string = "AttributePicker.Checkboxes.Cascading";
      }
      export class Edit {
        static readonly key: string = "AttributePicker.Checkboxes.Edit";
      }
      export class Audit {
        static readonly key: string = "AttributePicker.Checkboxes.Audit";
      }
      export class View {
        static readonly key: string = "AttributePicker.Checkboxes.View";
      }
      export class Need {
        static readonly key: string = "AttributePicker.Checkboxes.Need";
      }
      export class Sign {
        static readonly key: string = "AttributePicker.Checkboxes.Sign";
      }
      export class Take {
        static readonly key: string = "AttributePicker.Checkboxes.Take";
      }
      export class AutoAddUsers {
        static readonly key: string = "AttributePicker.Checkboxes.AutoAddUsers";
      }
      export class Content {
        static readonly key: string = "AttributePicker.Checkboxes.Content";
      }
      export class Member {
        static readonly key: string = "AttributePicker.Checkboxes.Member";
      }
    }
    export module DocSettings {
      export class Header {
        static readonly key: string = "AttributePicker.DocSettings.Header";
      }
      export class Enable {
        static readonly key: string = "AttributePicker.DocSettings.Enable";
      }
    }
    export module Error {
      export class GroupMembers {
        static readonly key: string = "AttributePicker.Error.GroupMembers";
      }
      export class GroupOverThisGroup {
        static readonly key: string =
          "AttributePicker.Error.GroupOverThisGroup";
      }
      export class GroupUsers {
        static readonly key: string = "AttributePicker.Error.GroupUsers";
      }
      export class GroupGroups {
        static readonly key: string = "AttributePicker.Error.GroupGroups";
      }
      export class UserMembers {
        static readonly key: string = "AttributePicker.Error.UserMembers";
      }
      export class UserOverThisGroup {
        static readonly key: string = "AttributePicker.Error.UserOverThisGroup";
      }
      export class FolderOverThisGroup {
        static readonly key: string =
          "AttributePicker.Error.FolderOverThisGroup";
      }
      export class FolderAssignments {
        static readonly key: string = "AttributePicker.Error.FolderAssignments";
      }
      export class RightsRequired {
        static readonly key: string = "AttributePicker.Error.RightsRequired";
        neededRights: string;
      }
      export class Generic {
        static readonly key: string = "AttributePicker.Error.Generic";
      }
    }
    export module Filters {
      export class AdvancedHeader {
        static readonly key: string = "AttributePicker.Filters.AdvancedHeader";
      }
      export class ApplyFilters {
        static readonly key: string = "AttributePicker.Filters.ApplyFilters";
      }
      export class EnableOnDate {
        static readonly key: string = "AttributePicker.Filters.EnableOnDate";
      }
      export class EnableAtTime {
        static readonly key: string = "AttributePicker.Filters.EnableAtTime";
      }
      export class FilterObjectType {
        static readonly key: string =
          "AttributePicker.Filters.FilterObjectType";
        objectType: string;
      }
      export class FilterHeader {
        static readonly key: string = "AttributePicker.Filters.FilterHeader";
      }
      export class ShowUsers {
        static readonly key: string = "AttributePicker.Filters.ShowUsers";
      }
      export class ShowGroups {
        static readonly key: string = "AttributePicker.Filters.ShowGroups";
      }
      export class ShowUsersAndGroups {
        static readonly key: string =
          "AttributePicker.Filters.ShowUsersAndGroups";
      }
      export class ShowDocuments {
        static readonly key: string = "AttributePicker.Filters.ShowDocuments";
      }
      export class ShowFolders {
        static readonly key: string = "AttributePicker.Filters.ShowFolders";
      }
      export class ShowDocumentsAndGroups {
        static readonly key: string =
          "AttributePicker.Filters.ShowDocumentsAndGroups";
      }
      export class GroupByUser {
        static readonly key: string = "AttributePicker.Filters.GroupByUser";
      }
      export class UsersByGroup {
        static readonly key: string = "AttributePicker.Filters.UsersByGroup";
      }
      export class HaveRights {
        static readonly key: string = "AttributePicker.Filters.HaveRights";
      }
      export class GroupByNonMemberUser {
        static readonly key: string =
          "AttributePicker.Filters.GroupByNonMemberUser";
      }
      export class UsersByNonMemberOfGroup {
        static readonly key: string =
          "AttributePicker.Filters.UsersByNonMemberOfGroup";
      }
      export class WithoutRights {
        static readonly key: string = "AttributePicker.Filters.WithoutRights";
      }
      export class AllGroups {
        static readonly key: string = "AttributePicker.Filters.AllGroups";
      }
      export class ShowActiveUsers {
        static readonly key: string = "AttributePicker.Filters.ShowActiveUsers";
      }
      export class WithRights {
        static readonly key: string = "AttributePicker.Filters.WithRights";
      }
    }
    export module Help {
      export class NeedCertificate {
        static readonly key: string = "AttributePicker.Help.NeedCertificate";
        openStrong: string;
        closeStrong: string;
      }
      export class SignDocument {
        static readonly key: string = "AttributePicker.Help.SignDocument";
        openStrong: string;
        closeStrong: string;
      }
      export class AssignedFolder {
        static readonly key: string = "AttributePicker.Help.AssignedFolder";
        openStrong: string;
        closeStrong: string;
      }
      export class TakeSurvey {
        static readonly key: string = "AttributePicker.Help.TakeSurvey";
        openStrong: string;
        closeStrong: string;
      }
      export class TakeTest {
        static readonly key: string = "AttributePicker.Help.TakeTest";
        openStrong: string;
        closeStrong: string;
      }
      export class ViewAssessment {
        static readonly key: string = "AttributePicker.Help.ViewAssessment";
        openStrong: string;
        closeStrong: string;
      }
      export class ViewCourse {
        static readonly key: string = "AttributePicker.Help.ViewCourse";
        openStrong: string;
        closeStrong: string;
      }
      export class ViewDocument {
        static readonly key: string = "AttributePicker.Help.ViewDocument";
        openStrong: string;
        closeStrong: string;
      }
      export class ViewFolder {
        static readonly key: string = "AttributePicker.Help.ViewFolder";
        openStrong: string;
        closeStrong: string;
      }
      export class ViewGroup {
        static readonly key: string = "AttributePicker.Help.ViewGroup";
        openStrong: string;
        closeStrong: string;
      }
      export class ViewManual {
        static readonly key: string = "AttributePicker.Help.ViewManual";
        openStrong: string;
        closeStrong: string;
      }
      export class ViewUser {
        static readonly key: string = "AttributePicker.Help.ViewUser";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditAssessment {
        static readonly key: string = "AttributePicker.Help.AuditAssessment";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditCertificate {
        static readonly key: string = "AttributePicker.Help.AuditCertificate";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditCourse {
        static readonly key: string = "AttributePicker.Help.AuditCourse";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditDocument {
        static readonly key: string = "AttributePicker.Help.AuditDocument";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditFolder {
        static readonly key: string = "AttributePicker.Help.AuditFolder";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditGroup {
        static readonly key: string = "AttributePicker.Help.AuditGroup";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditManual {
        static readonly key: string = "AttributePicker.Help.AuditManual";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditSurvey {
        static readonly key: string = "AttributePicker.Help.AuditSurvey";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditTest {
        static readonly key: string = "AttributePicker.Help.AuditTest";
        openStrong: string;
        closeStrong: string;
      }
      export class AuditUser {
        static readonly key: string = "AttributePicker.Help.AuditUser";
        openStrong: string;
        closeStrong: string;
      }
      export class EditAssessment {
        static readonly key: string = "AttributePicker.Help.EditAssessment";
        openStrong: string;
        closeStrong: string;
      }
      export class EditCertificate {
        static readonly key: string = "AttributePicker.Help.EditCertificate";
        openStrong: string;
        closeStrong: string;
      }
      export class EditCourse {
        static readonly key: string = "AttributePicker.Help.EditCourse";
        openStrong: string;
        closeStrong: string;
      }
      export class EditDocument {
        static readonly key: string = "AttributePicker.Help.EditDocument";
        openStrong: string;
        closeStrong: string;
      }
      export class EditFolder {
        static readonly key: string = "AttributePicker.Help.EditFolder";
        openStrong: string;
        closeStrong: string;
      }
      export class EditGroup {
        static readonly key: string = "AttributePicker.Help.EditGroup";
        openStrong: string;
        closeStrong: string;
      }
      export class EditManual {
        static readonly key: string = "AttributePicker.Help.EditManual";
        openStrong: string;
        closeStrong: string;
      }
      export class EditSurvey {
        static readonly key: string = "AttributePicker.Help.EditSurvey";
        openStrong: string;
        closeStrong: string;
      }
      export class EditTest {
        static readonly key: string = "AttributePicker.Help.EditTest";
        openStrong: string;
        closeStrong: string;
      }
      export class EditUser {
        static readonly key: string = "AttributePicker.Help.EditUser";
        openStrong: string;
        closeStrong: string;
      }
      export class CascadingSecurity {
        static readonly key: string = "AttributePicker.Help.CascadingSecurity";
        openStrong: string;
        closeStrong: string;
        openLink: string;
        closeLink: string;
      }
    }
    export module ObjectList {
      export class ViewPendingChanges {
        static readonly key: string =
          "AttributePicker.ObjectList.ViewPendingChanges";
      }
      export class SingularChangeShown {
        static readonly key: string =
          "AttributePicker.ObjectList.SingularChangeShown";
        changesNumber: number;
      }
      export class PluralChangesShown {
        static readonly key: string =
          "AttributePicker.ObjectList.PluralChangesShown";
        changesNumber: number;
      }
      export class SingularChange {
        static readonly key: string =
          "AttributePicker.ObjectList.SingularChange";
        changesNumber: number;
      }
      export class PluralChanges {
        static readonly key: string =
          "AttributePicker.ObjectList.PluralChanges";
        changesNumber: number;
      }
      export class SecurityNote {
        static readonly key: string = "AttributePicker.ObjectList.SecurityNote";
      }
      export class ViaNameType {
        static readonly key: string = "AttributePicker.ObjectList.ViaNameType";
        objectName: string;
        objectType: string;
      }
      export class ViaNumberType {
        static readonly key: string =
          "AttributePicker.ObjectList.ViaNumberType";
        objectNumber: string;
        objectType: string;
      }
      export class InheritedFromListLabel {
        static readonly key: string =
          "AttributePicker.ObjectList.InheritedFromListLabel";
      }
      export class NoResults {
        static readonly key: string = "AttributePicker.ObjectList.NoResults";
      }
      export class Pagination {
        static readonly key: string = "AttributePicker.ObjectList.Pagination";
        startCount: string;
        endCount: string;
        totalCount: string;
      }
    }
    export module SortOptions {
      export class Asc {
        static readonly key: string = "AttributePicker.SortOptions.Asc";
      }
      export class Desc {
        static readonly key: string = "AttributePicker.SortOptions.Desc";
      }
      export class AssessmentName {
        static readonly key: string =
          "AttributePicker.SortOptions.AssessmentName";
      }
      export class CertificateName {
        static readonly key: string =
          "AttributePicker.SortOptions.CertificateName";
      }
      export class CourseNumber {
        static readonly key: string =
          "AttributePicker.SortOptions.CourseNumber";
      }
      export class CourseName {
        static readonly key: string = "AttributePicker.SortOptions.CourseName";
      }
      export class Document {
        static readonly key: string = "AttributePicker.SortOptions.Document";
      }
      export class DocumentName {
        static readonly key: string =
          "AttributePicker.SortOptions.DocumentName";
      }
      export class FirstName {
        static readonly key: string = "AttributePicker.SortOptions.FirstName";
      }
      export class Folder {
        static readonly key: string = "AttributePicker.SortOptions.Folder";
      }
      export class Group {
        static readonly key: string = "AttributePicker.SortOptions.Group";
      }
      export class GroupName {
        static readonly key: string = "AttributePicker.SortOptions.GroupName";
      }
      export class LastName {
        static readonly key: string = "AttributePicker.SortOptions.LastName";
      }
      export class ManualName {
        static readonly key: string = "AttributePicker.SortOptions.ManualName";
      }
      export class Name {
        static readonly key: string = "AttributePicker.SortOptions.Name";
      }
      export class SurveyName {
        static readonly key: string = "AttributePicker.SortOptions.SurveyName";
      }
      export class TestName {
        static readonly key: string = "AttributePicker.SortOptions.TestName";
      }
      export class User {
        static readonly key: string = "AttributePicker.SortOptions.User";
      }
      export class Username {
        static readonly key: string = "AttributePicker.SortOptions.Username";
      }
    }
  }
  export module AssessmentChart {
    export class PleaseRefreshError {
      static readonly key: string = "AssessmentChart.PleaseRefreshError";
    }
    export class Warning {
      static readonly key: string = "AssessmentChart.Warning";
    }
    export class NoAssociatedRoles {
      static readonly key: string = "AssessmentChart.NoAssociatedRoles";
    }
    export class NoteLabel {
      static readonly key: string = "AssessmentChart.NoteLabel";
    }
    export class NoStandardsInManual {
      static readonly key: string = "AssessmentChart.NoStandardsInManual";
      assessmentName: string;
    }
    export class NoStatusesAvailable {
      static readonly key: string = "AssessmentChart.NoStatusesAvailable";
    }
    export class SingularStandardsCount {
      static readonly key: string = "AssessmentChart.SingularStandardsCount";
      standardsCount: string;
    }
    export class PluralStandardsCount {
      static readonly key: string = "AssessmentChart.PluralStandardsCount";
      standardsCount: string;
    }
    export class RunReport {
      static readonly key: string = "AssessmentChart.RunReport";
    }
    export class NotSetStatus {
      static readonly key: string = "AssessmentChart.NotSetStatus";
    }
  }
  export module Auth0 {
    export class AdfsMetadataFileLabel {
      static readonly key: string = "Auth0.AdfsMetadataFileLabel";
    }
    export class AdfsMetadataUrlLabel {
      static readonly key: string = "Auth0.AdfsMetadataUrlLabel";
    }
    export class AssertionConsumerServiceUrlLabel {
      static readonly key: string = "Auth0.AssertionConsumerServiceUrlLabel";
    }
    export class AuthenticationUpgrade {
      static readonly key: string = "Auth0.AuthenticationUpgrade";
    }
    export class EnabledConfirmation {
      static readonly key: string = "Auth0.EnabledConfirmation";
    }
    export class EntityIdLabel {
      static readonly key: string = "Auth0.EntityIdLabel";
    }
    export class ImportAuthProviderErrorMessage {
      static readonly key: string = "Auth0.ImportAuthProviderErrorMessage";
    }
    export class RemoveAuthProviderErrorMessage {
      static readonly key: string = "Auth0.RemoveAuthProviderErrorMessage";
    }
    export class RemovedConfirmation {
      static readonly key: string = "Auth0.RemovedConfirmation";
    }
    export class RetrievingAuthProvidersErrorMessage {
      static readonly key: string = "Auth0.RetrievingAuthProvidersErrorMessage";
    }
    export class SamlSigningCertLabel {
      static readonly key: string = "Auth0.SamlSigningCertLabel";
    }
    export class SamlSignInEndpointLabel {
      static readonly key: string = "Auth0.SamlSignInEndpointLabel";
    }
    export class SamlSsoIssuerLabel {
      static readonly key: string = "Auth0.SamlSsoIssuerLabel";
    }
    export class SsoIsConfigured {
      static readonly key: string = "Auth0.SsoIsConfigured";
      auth0Strategy: string;
    }
    export class SsoLoginSettingsErrorLoading {
      static readonly key: string = "Auth0.SsoLoginSettingsErrorLoading";
    }
    export class SsoLoginSettingsErrorSaving {
      static readonly key: string = "Auth0.SsoLoginSettingsErrorSaving";
    }
    export class UpdatingAuthProviderErrorMessage {
      static readonly key: string = "Auth0.UpdatingAuthProviderErrorMessage";
    }
    export class SsoSettingsErrorSaving {
      static readonly key: string = "Auth0.SsoSettingsErrorSaving";
    }
    export module SsoLoginSettings {
      export class Enable {
        static readonly key: string = "Auth0.SsoLoginSettings.Enable";
      }
      export class Info {
        static readonly key: string = "Auth0.SsoLoginSettings.Info";
      }
    }
    export module SSOLoginEmailValidation {
      export class Enable {
        static readonly key: string = "Auth0.SSOLoginEmailValidation.Enable";
      }
      export class Info {
        static readonly key: string = "Auth0.SSOLoginEmailValidation.Info";
      }
    }
  }
  export module Bookmark {
    export class RemoveBookmark {
      static readonly key: string = "Bookmark.RemoveBookmark";
    }
  }
  export module BulkOperationJob {
    export class NotStarted {
      static readonly key: string = "BulkOperationJob.NotStarted";
    }
  }
  export module BulkWorkflowAssignment {
    export class JobInProgress {
      static readonly key: string = "BulkWorkflowAssignment.JobInProgress";
      anchorOpen: string;
      documentName: string;
      anchorClose: string;
    }
    export class JobCompletedSuccessfully {
      static readonly key: string =
        "BulkWorkflowAssignment.JobCompletedSuccessfully";
      anchorOpen: string;
      totalItemsCount: string;
      anchorClose: string;
    }
    export class JobCompletedWithFailures {
      static readonly key: string =
        "BulkWorkflowAssignment.JobCompletedWithFailures";
      anchorOpen: string;
      failedItemsCount: string;
      anchorClose: string;
    }
    export class Failed {
      static readonly key: string = "BulkWorkflowAssignment.Failed";
    }
  }
  export module Modals {
    export module BulkOperationJob {
      export module StopBulkWorkflowAssignment {
        export class BodyHeader {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkWorkflowAssignment.BodyHeader";
        }
        export class ContinueWorkflowAssignment {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkWorkflowAssignment.ContinueWorkflowAssignment";
        }
        export class StopWorkflowAssignment {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkWorkflowAssignment.StopWorkflowAssignment";
        }
        export class StopMessage {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkWorkflowAssignment.StopMessage";
        }
        export class Title {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkWorkflowAssignment.Title";
        }
        export class UnableToStopCompletedWorkflowAssignment {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkWorkflowAssignment.UnableToStopCompletedWorkflowAssignment";
        }
        export class UnableToStopFailedWorkflowAssignment {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkWorkflowAssignment.UnableToStopFailedWorkflowAssignment";
        }
      }
      export module StopBulkPublish {
        export class BodyHeader {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkPublish.BodyHeader";
        }
        export class ContinueBulkPublish {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkPublish.ContinueBulkPublish";
        }
        export class StopBulkPublish {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkPublish.StopBulkPublish";
        }
        export class StopMessage {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkPublish.StopMessage";
        }
        export class Title {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkPublish.Title";
        }
        export class UnableToStopCompletedBulkPublish {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkPublish.UnableToStopCompletedBulkPublish";
        }
        export class UnableToStopFailedBulkPublish {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkPublish.UnableToStopFailedBulkPublish";
        }
      }
      export module StopBulkUploadDocument {
        export class BodyHeader {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkUploadDocument.BodyHeader";
        }
        export class ContinueUploadDocument {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkUploadDocument.ContinueUploadDocument";
        }
        export class StopUploadDocument {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkUploadDocument.StopUploadDocument";
        }
        export class StopMessage {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkUploadDocument.StopMessage";
        }
        export class Title {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkUploadDocument.Title";
        }
        export class UnableToStopCompletedUploadDocument {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkUploadDocument.UnableToStopCompletedUploadDocument";
        }
        export class UnableToStopFailedUploadDocument {
          static readonly key: string =
            "Modals.BulkOperationJob.StopBulkUploadDocument.UnableToStopFailedUploadDocument";
        }
      }
    }
    export module ContentHub {
      export class AuthenicateAnotherAccount {
        static readonly key: string =
          "Modals.ContentHub.AuthenicateAnotherAccount";
      }
      export class ConnectAccountHeader {
        static readonly key: string = "Modals.ContentHub.ConnectAccountHeader";
      }
      export class Connecting {
        static readonly key: string = "Modals.ContentHub.Connecting";
      }
      export class Disconnecting {
        static readonly key: string = "Modals.ContentHub.Disconnecting";
      }
      export class DisconnectAccountHeader {
        static readonly key: string =
          "Modals.ContentHub.DisconnectAccountHeader";
      }
      export class DisconnectAccountText {
        static readonly key: string = "Modals.ContentHub.DisconnectAccountText";
        publisherName: string;
      }
      export class DisconnectContentSource {
        static readonly key: string =
          "Modals.ContentHub.DisconnectContentSource";
      }
      export class DisconnectFromContentHub {
        static readonly key: string =
          "Modals.ContentHub.DisconnectFromContentHub";
      }
      export class DisconnectFromContentHubDescription {
        static readonly key: string =
          "Modals.ContentHub.DisconnectFromContentHubDescription";
        docName: string;
      }
      export class DisconnectingDocument {
        static readonly key: string = "Modals.ContentHub.DisconnectingDocument";
      }
      export class ForgotPassword {
        static readonly key: string = "Modals.ContentHub.ForgotPassword";
      }
      export class Password {
        static readonly key: string = "Modals.ContentHub.Password";
      }
      export class SiteKey {
        static readonly key: string = "Modals.ContentHub.SiteKey";
      }
      export class Username {
        static readonly key: string = "Modals.ContentHub.Username";
      }
      export module Import {
        export class ChooseDifferentDestinationFolder {
          static readonly key: string =
            "Modals.ContentHub.Import.ChooseDifferentDestinationFolder";
        }
        export class FolderLabel {
          static readonly key: string = "Modals.ContentHub.Import.FolderLabel";
        }
        export class ImportDocumentAsDraft {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportDocumentAsDraft";
        }
        export class ImportDocumentAsPublished {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportDocumentAsPublished";
        }
        export class ImportDocumentToFolderAsDraft {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportDocumentToFolderAsDraft";
        }
        export class ImportDocumentToFolderAsPublished {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportDocumentToFolderAsPublished";
        }
        export class ImportDocumentsAsDraft {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportDocumentsAsDraft";
        }
        export class ImportDocumentsAsPublished {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportDocumentsAsPublished";
        }
        export class ImportAsDraftToDoc {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportAsDraftToDoc";
        }
        export class ImportAsNewDocument {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportAsNewDocument";
        }
        export class ImportEverything {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportEverything";
        }
        export class ImportUnimported {
          static readonly key: string =
            "Modals.ContentHub.Import.ImportUnimported";
        }
        export class NotifyContentUpdates {
          static readonly key: string =
            "Modals.ContentHub.Import.NotifyContentUpdates";
        }
        export class OptionsLabel {
          static readonly key: string = "Modals.ContentHub.Import.OptionsLabel";
        }
        export class SelectDestination {
          static readonly key: string =
            "Modals.ContentHub.Import.SelectDestination";
        }
        export class skipPreviouslyImported {
          static readonly key: string =
            "Modals.ContentHub.Import.skipPreviouslyImported";
        }
        export class Title {
          static readonly key: string = "Modals.ContentHub.Import.Title";
        }
        export class HiddenImportedFoldersNote {
          static readonly key: string =
            "Modals.ContentHub.Import.HiddenImportedFoldersNote";
          boldOpen: string;
          boldClose: string;
        }
        export class WarningInsufficientDestinationPermission {
          static readonly key: string =
            "Modals.ContentHub.Import.WarningInsufficientDestinationPermission";
          boldOpen: string;
          boldClose: string;
        }
        export class WarningNewDraftFromPublishedRevision {
          static readonly key: string =
            "Modals.ContentHub.Import.WarningNewDraftFromPublishedRevision";
          boldOpen: string;
          boldClose: string;
        }
        export class WarningDisconnectLinkedDocument {
          static readonly key: string =
            "Modals.ContentHub.Import.WarningDisconnectLinkedDocument";
          boldOpen: string;
          boldClose: string;
          providerName: string;
        }
        export class WhereToImport {
          static readonly key: string =
            "Modals.ContentHub.Import.WhereToImport";
        }
        export class WhereToImportFolder {
          static readonly key: string =
            "Modals.ContentHub.Import.WhereToImportFolder";
        }
        export class DuplicateFileWarning {
          static readonly key: string =
            "Modals.ContentHub.Import.DuplicateFileWarning";
          boldOpen: string;
          total: string;
          boldClose: string;
          folder: string;
        }
        export class DuplicateFilesWarning {
          static readonly key: string =
            "Modals.ContentHub.Import.DuplicateFilesWarning";
          boldOpen: string;
          total: string;
          boldClose: string;
          folder: string;
        }
        export module PreviouslyImported {
          export class Singular {
            static readonly key: string =
              "Modals.ContentHub.Import.PreviouslyImported.Singular";
            boldOpen: string;
            importedCount: string;
            total: string;
            boldClose: string;
          }
          export class SingularPlural {
            static readonly key: string =
              "Modals.ContentHub.Import.PreviouslyImported.SingularPlural";
            boldOpen: string;
            importedCount: string;
            total: string;
            boldClose: string;
          }
          export class Plural {
            static readonly key: string =
              "Modals.ContentHub.Import.PreviouslyImported.Plural";
            boldOpen: string;
            importedCount: string;
            total: string;
            boldClose: string;
          }
        }
        export module PreviouslyImportedIntoDestination {
          export class Singular {
            static readonly key: string =
              "Modals.ContentHub.Import.PreviouslyImportedIntoDestination.Singular";
            boldOpen: string;
            importedCount: string;
            total: string;
            boldClose: string;
            destination: string;
          }
          export class SingularPlural {
            static readonly key: string =
              "Modals.ContentHub.Import.PreviouslyImportedIntoDestination.SingularPlural";
            boldOpen: string;
            importedCount: string;
            total: string;
            boldClose: string;
            destination: string;
          }
          export class Plural {
            static readonly key: string =
              "Modals.ContentHub.Import.PreviouslyImportedIntoDestination.Plural";
            boldOpen: string;
            importedCount: string;
            total: string;
            boldClose: string;
            destination: string;
          }
        }
        export module StopBulkImport {
          export class BodyHeader {
            static readonly key: string =
              "Modals.ContentHub.Import.StopBulkImport.BodyHeader";
          }
          export class ContinueImport {
            static readonly key: string =
              "Modals.ContentHub.Import.StopBulkImport.ContinueImport";
          }
          export class StopImport {
            static readonly key: string =
              "Modals.ContentHub.Import.StopBulkImport.StopImport";
          }
          export class StopMessage {
            static readonly key: string =
              "Modals.ContentHub.Import.StopBulkImport.StopMessage";
            boldOpen: string;
            importingFolderName: string;
            boldClose: string;
          }
          export class Title {
            static readonly key: string =
              "Modals.ContentHub.Import.StopBulkImport.Title";
          }
          export class UnableToStopCompletedImport {
            static readonly key: string =
              "Modals.ContentHub.Import.StopBulkImport.UnableToStopCompletedImport";
          }
          export class UnableToStopFailedImport {
            static readonly key: string =
              "Modals.ContentHub.Import.StopBulkImport.UnableToStopFailedImport";
          }
        }
      }
    }
    export module LanguageSettings {
      export class ChooseLanguage {
        static readonly key: string = "Modals.LanguageSettings.ChooseLanguage";
      }
      export class LanguageLabel {
        static readonly key: string = "Modals.LanguageSettings.LanguageLabel";
      }
      export class LanguageSettingsHeader {
        static readonly key: string =
          "Modals.LanguageSettings.LanguageSettingsHeader";
      }
    }
    export module MoveContent {
      export class CannotMoveToOriginalFolder {
        static readonly key: string =
          "Modals.MoveContent.CannotMoveToOriginalFolder";
      }
      export class DecideNewLocation {
        static readonly key: string = "Modals.MoveContent.DecideNewLocation";
      }
      export class MoveContentButton {
        static readonly key: string = "Modals.MoveContent.MoveContentButton";
      }
      export class SelectDestinationHeader {
        static readonly key: string =
          "Modals.MoveContent.SelectDestinationHeader";
      }
    }
    export module OutdatedBrowser {
      export class ContactWithQuestions {
        static readonly key: string =
          "Modals.OutdatedBrowser.ContactWithQuestions";
        supportEmail: string;
      }
      export class GoogleChromeIcon {
        static readonly key: string = "Modals.OutdatedBrowser.GoogleChromeIcon";
      }
      export class InternetExplorerIcon {
        static readonly key: string =
          "Modals.OutdatedBrowser.InternetExplorerIcon";
      }
      export class MozillaFirefoxIcon {
        static readonly key: string =
          "Modals.OutdatedBrowser.MozillaFirefoxIcon";
      }
      export class NotModernBrowser {
        static readonly key: string = "Modals.OutdatedBrowser.NotModernBrowser";
        boldOpen: string;
        boldClose: string;
      }
      export class OutdatedBrowserDetected {
        static readonly key: string =
          "Modals.OutdatedBrowser.OutdatedBrowserDetected";
      }
      export class PowerDMSTeam {
        static readonly key: string = "Modals.OutdatedBrowser.PowerDMSTeam";
      }
      export class ThankYou {
        static readonly key: string = "Modals.OutdatedBrowser.ThankYou";
      }
      export class UpdateYourBrowser {
        static readonly key: string =
          "Modals.OutdatedBrowser.UpdateYourBrowser";
        boldOpen: string;
        boldClose: string;
      }
      export class ValuedCustomer {
        static readonly key: string = "Modals.OutdatedBrowser.ValuedCustomer";
      }
    }
    export module PartialMove {
      export class CancelMoveButton {
        static readonly key: string = "Modals.PartialMove.CancelMoveButton";
      }
      export class ConfirmMoveTitle {
        static readonly key: string = "Modals.PartialMove.ConfirmMoveTitle";
      }
      export class ContinuePartialHeader {
        static readonly key: string =
          "Modals.PartialMove.ContinuePartialHeader";
      }
      export class MoveContentsBelow {
        static readonly key: string = "Modals.PartialMove.MoveContentsBelow";
      }
      export class MoveRemainingButton {
        static readonly key: string = "Modals.PartialMove.MoveRemainingButton";
      }
      export class MoveRemainingCheckbox {
        static readonly key: string =
          "Modals.PartialMove.MoveRemainingCheckbox";
        targetFolder: string;
      }
    }
    export module PrivilegeLoss {
      export class AttributePickerChange {
        static readonly key: string =
          "Modals.PrivilegeLoss.AttributePickerChange";
      }
      export class CancelMove {
        static readonly key: string = "Modals.PrivilegeLoss.CancelMove";
      }
      export class CancelSave {
        static readonly key: string = "Modals.PrivilegeLoss.CancelSave";
      }
      export class ContinueMove {
        static readonly key: string = "Modals.PrivilegeLoss.ContinueMove";
      }
      export class ContinueSave {
        static readonly key: string = "Modals.PrivilegeLoss.ContinueSave";
      }
      export class DragDropChange {
        static readonly key: string = "Modals.PrivilegeLoss.DragDropChange";
        objectType: string;
      }
      export class FolderChange {
        static readonly key: string = "Modals.PrivilegeLoss.FolderChange";
        objectType: string;
      }
      export class WarningPrivLossHeader {
        static readonly key: string =
          "Modals.PrivilegeLoss.WarningPrivLossHeader";
      }
    }
    export module WarnPubDocs {
      export class InformationLink {
        static readonly key: string = "Modals.WarnPubDocs.InformationLink";
      }
      export class PubDocSettingsTitle {
        static readonly key: string = "Modals.WarnPubDocs.PubDocSettingsTitle";
      }
      export class PubFacingDocsHeader {
        static readonly key: string = "Modals.WarnPubDocs.PubFacingDocsHeader";
      }
      export class ShowNotificationAgainCheckbox {
        static readonly key: string =
          "Modals.WarnPubDocs.ShowNotificationAgainCheckbox";
      }
      export class WarnPublicMeaning {
        static readonly key: string = "Modals.WarnPubDocs.WarnPublicMeaning";
        openBoldHTMLTag: string;
        closeBoldHTMLTag: string;
      }
    }
    export module RenameFolder {
      export class FolderNameLabel {
        static readonly key: string = "Modals.RenameFolder.FolderNameLabel";
      }
      export class NameCollisionError {
        static readonly key: string = "Modals.RenameFolder.NameCollisionError";
        enteredName: string;
        parentFolder: string;
      }
      export class RenamePrompt {
        static readonly key: string = "Modals.RenameFolder.RenamePrompt";
      }
      export class RenameThisFolder {
        static readonly key: string = "Modals.RenameFolder.RenameThisFolder";
      }
      export class UpdatingButton {
        static readonly key: string = "Modals.RenameFolder.UpdatingButton";
      }
    }
  }
  export module BulkPublish {
    export class JobInProgress {
      static readonly key: string = "BulkPublish.JobInProgress";
      anchorOpen: string;
      documentName: string;
      anchorClose: string;
    }
    export class JobCompletedSuccessfully {
      static readonly key: string = "BulkPublish.JobCompletedSuccessfully";
      anchorOpen: string;
      totalItemsCount: string;
      anchorClose: string;
    }
    export class JobCompletedWithFailures {
      static readonly key: string = "BulkPublish.JobCompletedWithFailures";
      anchorOpen: string;
      failedItemsCount: string;
      anchorClose: string;
    }
    export class Failed {
      static readonly key: string = "BulkPublish.Failed";
    }
  }
  export module BulkUploadDocument {
    export class JobInProgressFile {
      static readonly key: string = "BulkUploadDocument.JobInProgressFile";
      documentName: string;
    }
    export class JobInProgressFiles {
      static readonly key: string = "BulkUploadDocument.JobInProgressFiles";
      totalItemsCount: string;
    }
    export class JobCompletedFile {
      static readonly key: string = "BulkUploadDocument.JobCompletedFile";
      anchorOpen: string;
      documentName: string;
      anchorClose: string;
    }
    export class JobCompletedFiles {
      static readonly key: string = "BulkUploadDocument.JobCompletedFiles";
      anchorOpen: string;
      totalItemsCount: string;
      anchorClose: string;
    }
    export class JobFailedCount {
      static readonly key: string = "BulkUploadDocument.JobFailedCount";
      failedItemsCount: string;
      totalItemsCount: string;
    }
    export class Failed {
      static readonly key: string = "BulkUploadDocument.Failed";
    }
  }
  export module Certificates {
    export class InvalidFileType {
      static readonly key: string = "Certificates.InvalidFileType";
    }
    export class LongDateFormatLabel {
      static readonly key: string = "Certificates.LongDateFormatLabel";
      DateFormat: string;
    }
    export class ManageCertificate {
      static readonly key: string = "Certificates.ManageCertificate";
    }
    export class MediumDateFormatLabel {
      static readonly key: string = "Certificates.MediumDateFormatLabel";
      DateFormat: string;
    }
    export class NewCertificate {
      static readonly key: string = "Certificates.NewCertificate";
    }
    export class ShortDateFormatLabel {
      static readonly key: string = "Certificates.ShortDateFormatLabel";
      DateFormat: string;
    }
    export class ViewCertificate {
      static readonly key: string = "Certificates.ViewCertificate";
    }
    export module CourseOfferings {
      export class CourseName {
        static readonly key: string = "Certificates.CourseOfferings.CourseName";
      }
      export class CourseNumber {
        static readonly key: string =
          "Certificates.CourseOfferings.CourseNumber";
      }
    }
    export module DeleteModal {
      export class DeleteCertificate {
        static readonly key: string =
          "Certificates.DeleteModal.DeleteCertificate";
      }
      export class WarningMessage {
        static readonly key: string = "Certificates.DeleteModal.WarningMessage";
        certificateName: string;
      }
    }
    export module EditImageModal {
      export class AddText {
        static readonly key: string = "Certificates.EditImageModal.AddText";
      }
      export class AwardedDate {
        static readonly key: string = "Certificates.EditImageModal.AwardedDate";
      }
      export class AwardedDateShort {
        static readonly key: string =
          "Certificates.EditImageModal.AwardedDateShort";
      }
      export class AwardedDateMedium {
        static readonly key: string =
          "Certificates.EditImageModal.AwardedDateMedium";
      }
      export class AwardedDateLong {
        static readonly key: string =
          "Certificates.EditImageModal.AwardedDateLong";
      }
      export class CertificateName {
        static readonly key: string =
          "Certificates.EditImageModal.CertificateName";
      }
      export class ExpirationDate {
        static readonly key: string =
          "Certificates.EditImageModal.ExpirationDate";
      }
      export class ExpirationDateShort {
        static readonly key: string =
          "Certificates.EditImageModal.ExpirationDateShort";
      }
      export class ExpirationDateMedium {
        static readonly key: string =
          "Certificates.EditImageModal.ExpirationDateMedium";
      }
      export class ExpirationDateLong {
        static readonly key: string =
          "Certificates.EditImageModal.ExpirationDateLong";
      }
      export class Heading {
        static readonly key: string = "Certificates.EditImageModal.Heading";
      }
      export class RemoveLabel {
        static readonly key: string = "Certificates.EditImageModal.RemoveLabel";
      }
      export class ResetToDefaultImage {
        static readonly key: string =
          "Certificates.EditImageModal.ResetToDefaultImage";
      }
      export class SaveCertificate {
        static readonly key: string =
          "Certificates.EditImageModal.SaveCertificate";
      }
      export class Title {
        static readonly key: string = "Certificates.EditImageModal.Title";
      }
    }
    export module Image {
      export class SaveLabelsError {
        static readonly key: string = "Certificates.Image.SaveLabelsError";
      }
      export class SaveLabelsSuccess {
        static readonly key: string = "Certificates.Image.SaveLabelsSuccess";
      }
    }
    export module SiteSettingsPage {
      export class AddNewCertificate {
        static readonly key: string =
          "Certificates.SiteSettingsPage.AddNewCertificate";
      }
      export class CurrentCertificateLabel {
        static readonly key: string =
          "Certificates.SiteSettingsPage.CurrentCertificateLabel";
      }
      export class EditCurrentTemplate {
        static readonly key: string =
          "Certificates.SiteSettingsPage.EditCurrentTemplate";
      }
      export class NewCertificateLabel {
        static readonly key: string =
          "Certificates.SiteSettingsPage.NewCertificateLabel";
      }
      export class RecommendedSize {
        static readonly key: string =
          "Certificates.SiteSettingsPage.RecommendedSize";
      }
      export class ResetToDefault {
        static readonly key: string =
          "Certificates.SiteSettingsPage.ResetToDefault";
      }
      export class Title {
        static readonly key: string = "Certificates.SiteSettingsPage.Title";
      }
      export class DefaultSiteImage {
        static readonly key: string =
          "Certificates.SiteSettingsPage.DefaultSiteImage";
      }
    }
  }
  export module ContentHub {
    export class AboutContentHub {
      static readonly key: string = "ContentHub.AboutContentHub";
    }
    export class AboutPowerDms {
      static readonly key: string = "ContentHub.AboutPowerDms";
    }
    export class AcceptAndContinue {
      static readonly key: string = "ContentHub.AcceptAndContinue";
    }
    export class AccountNameLabel {
      static readonly key: string = "ContentHub.AccountNameLabel";
    }
    export class HiddenRecordsWarning {
      static readonly key: string = "ContentHub.HiddenRecordsWarning";
    }
    export class AllDocumentsImported {
      static readonly key: string = "ContentHub.AllDocumentsImported";
    }
    export class ArchiveDraftWarning {
      static readonly key: string = "ContentHub.ArchiveDraftWarning";
    }
    export class CompareAndUpdate {
      static readonly key: string = "ContentHub.CompareAndUpdate";
    }
    export class CompareWithPublicationAccount {
      static readonly key: string = "ContentHub.CompareWithPublicationAccount";
      accountName: string;
    }
    export class DocViewerPageTitle {
      static readonly key: string = "ContentHub.DocViewerPageTitle";
    }
    export class DocumentImported {
      static readonly key: string = "ContentHub.DocumentImported";
    }
    export class DocumentsLinkedTo {
      static readonly key: string = "ContentHub.DocumentsLinkedTo";
      externalDocument: string;
    }
    export class EmailAlertsLabel {
      static readonly key: string = "ContentHub.EmailAlertsLabel";
    }
    export class EmailAlertsNote {
      static readonly key: string = "ContentHub.EmailAlertsNote";
    }
    export class EnableContentHubDescription {
      static readonly key: string = "ContentHub.EnableContentHubDescription";
    }
    export class EnableContentHubTitle {
      static readonly key: string = "ContentHub.EnableContentHubTitle";
    }
    export class ExploreContentHub {
      static readonly key: string = "ContentHub.ExploreContentHub";
    }
    export class ExplorePowerDms {
      static readonly key: string = "ContentHub.ExplorePowerDms";
    }
    export class IllDoThisLater {
      static readonly key: string = "ContentHub.IllDoThisLater";
    }
    export class Import {
      static readonly key: string = "ContentHub.Import";
    }
    export class ImportAsNewDraft {
      static readonly key: string = "ContentHub.ImportAsNewDraft";
    }
    export class ImportDocument {
      static readonly key: string = "ContentHub.ImportDocument";
    }
    export class ImportedSuccessfully {
      static readonly key: string = "ContentHub.ImportedSuccessfully";
      anchorOpen: string;
      folderName: string;
      anchorClose: string;
    }
    export class ImportFailed {
      static readonly key: string = "ContentHub.ImportFailed";
    }
    export class ImportFolder {
      static readonly key: string = "ContentHub.ImportFolder";
    }
    export class ImportError {
      static readonly key: string = "ContentHub.ImportError";
      importedItem: string;
      origin: string;
      error: string;
    }
    export class ImportFromPublicationAccount {
      static readonly key: string = "ContentHub.ImportFromPublicationAccount";
      accountName: string;
    }
    export class Importing {
      static readonly key: string = "ContentHub.Importing";
    }
    export class ImportInProgress {
      static readonly key: string = "ContentHub.ImportInProgress";
      anchorOpen: string;
      folderName: string;
      anchorClose: string;
    }
    export class ImportNotStarted {
      static readonly key: string = "ContentHub.ImportNotStarted";
      anchorOpen: string;
      folderName: string;
      anchorClose: string;
    }
    export class ImportSuccess {
      static readonly key: string = "ContentHub.ImportSuccess";
      importedItem: string;
      origin: string;
      destination: string;
    }
    export class UpdateSuccess {
      static readonly key: string = "ContentHub.UpdateSuccess";
      importedItem: string;
      origin: string;
    }
    export class ListOfImportedDocuments {
      static readonly key: string = "ContentHub.ListOfImportedDocuments";
    }
    export class ManageProvider {
      static readonly key: string = "ContentHub.ManageProvider";
    }
    export class NewUpdateAvailable {
      static readonly key: string = "ContentHub.NewUpdateAvailable";
      objectType: string;
    }
    export class NewUpdatesAndDocumentsAvailable {
      static readonly key: string =
        "ContentHub.NewUpdatesAndDocumentsAvailable";
      objectType: string;
      newUpdatesCount: string;
      notImportedCount: string;
      importedCount: string;
      totalDocsCount: string;
    }
    export class NewUpdatesAndOneDocumentAvailable {
      static readonly key: string =
        "ContentHub.NewUpdatesAndOneDocumentAvailable";
      objectType: string;
      newUpdatesCount: string;
      importedCount: string;
      totalDocsCount: string;
    }
    export class NewUpdatesAvailable {
      static readonly key: string = "ContentHub.NewUpdatesAvailable";
      objectType: string;
      newUpdatesCount: string;
      importedCount: string;
      totalDocsCount: string;
    }
    export class OneNewUpdateAndDocumentsAvailable {
      static readonly key: string =
        "ContentHub.OneNewUpdateAndDocumentsAvailable";
      objectType: string;
      notImportedCount: string;
      importedCount: string;
      totalDocsCount: string;
    }
    export class OneNewUpdateAndOneDocumentAvailable {
      static readonly key: string =
        "ContentHub.OneNewUpdateAndOneDocumentAvailable";
      objectType: string;
      importedCount: string;
      totalDocsCount: string;
    }
    export class OneNewUpdateAvailable {
      static readonly key: string = "ContentHub.OneNewUpdateAvailable";
      objectType: string;
      importedCount: string;
      totalDocsCount: string;
    }
    export class OpenInContentHub {
      static readonly key: string = "ContentHub.OpenInContentHub";
    }
    export class NoPermissionsToImport {
      static readonly key: string = "ContentHub.NoPermissionsToImport";
      accountName: string;
    }
    export class NoPermissionsToView {
      static readonly key: string = "ContentHub.NoPermissionsToView";
      accountName: string;
    }
    export class NotProvidedRequiredPermissions {
      static readonly key: string = "ContentHub.NotProvidedRequiredPermissions";
      accountName: string;
    }
    export class NoUpdateAvailableFromProvider {
      static readonly key: string = "ContentHub.NoUpdateAvailableFromProvider";
      accountName: string;
    }
    export class RetryButton {
      static readonly key: string = "ContentHub.RetryButton";
    }
    export class ReviewChanges {
      static readonly key: string = "ContentHub.ReviewChanges";
    }
    export class UpdateFromProviderAccount {
      static readonly key: string = "ContentHub.UpdateFromProviderAccount";
      accountName: string;
    }
    export class ViewAllDocuments {
      static readonly key: string = "ContentHub.ViewAllDocuments";
    }
    export class ViewDocumentButton {
      static readonly key: string = "ContentHub.ViewDocumentButton";
    }
    export class ViewImported {
      static readonly key: string = "ContentHub.ViewImported";
    }
    export class VisitPowerDms {
      static readonly key: string = "ContentHub.VisitPowerDms";
    }
    export class WatchThisVideo {
      static readonly key: string = "ContentHub.WatchThisVideo";
    }
    export module Invitations {
      export class AcceptedInvitations {
        static readonly key: string =
          "ContentHub.Invitations.AcceptedInvitations";
      }
      export class AddAnother {
        static readonly key: string = "ContentHub.Invitations.AddAnother";
      }
      export class AddInvitees {
        static readonly key: string = "ContentHub.Invitations.AddInvitees";
      }
      export class AddManyAtOnce {
        static readonly key: string = "ContentHub.Invitations.AddManyAtOnce";
      }
      export class AttributePickerHeader {
        static readonly key: string =
          "ContentHub.Invitations.AttributePickerHeader";
      }
      export class BulkInputHelper {
        static readonly key: string = "ContentHub.Invitations.BulkInputHelper";
      }
      export class BulkInputError {
        static readonly key: string = "ContentHub.Invitations.BulkInputError";
      }
      export class Connecting {
        static readonly key: string = "ContentHub.Invitations.Connecting";
      }
      export class ConnectToPublisherSite {
        static readonly key: string =
          "ContentHub.Invitations.ConnectToPublisherSite";
        publisherSite: string;
      }
      export class ContinueToPowerDms {
        static readonly key: string =
          "ContentHub.Invitations.ContinueToPowerDms";
      }
      export class DateInvited {
        static readonly key: string = "ContentHub.Invitations.DateInvited";
      }
      export class ExportExcel {
        static readonly key: string = "ContentHub.Invitations.ExportExcel";
      }
      export class EmailLengthInvalid {
        static readonly key: string =
          "ContentHub.Invitations.EmailLengthInvalid";
      }
      export class GroupRightsAndSecurityAssignmentsWarning {
        static readonly key: string =
          "ContentHub.Invitations.GroupRightsAndSecurityAssignmentsWarning";
        boldOpen: string;
        boldClose: string;
      }
      export class InvitationMessageLabel {
        static readonly key: string =
          "ContentHub.Invitations.InvitationMessageLabel";
      }
      export class InvitationMessagePlaceholder {
        static readonly key: string =
          "ContentHub.Invitations.InvitationMessagePlaceholder";
        publisher: string;
      }
      export class InvalidEmail {
        static readonly key: string = "ContentHub.Invitations.InvalidEmail";
      }
      export class InvitationPending {
        static readonly key: string =
          "ContentHub.Invitations.InvitationPending";
      }
      export class InviteNewSubscribers {
        static readonly key: string =
          "ContentHub.Invitations.InviteNewSubscribers";
      }
      export class InviteNewUsers {
        static readonly key: string = "ContentHub.Invitations.InviteNewUsers";
      }
      export class InviteNotActiveBody {
        static readonly key: string =
          "ContentHub.Invitations.InviteNotActiveBody";
      }
      export class InviteNotActiveTitle {
        static readonly key: string =
          "ContentHub.Invitations.InviteNotActiveTitle";
      }
      export class InviteSent {
        static readonly key: string = "ContentHub.Invitations.InviteSent";
      }
      export class InvitesSent {
        static readonly key: string = "ContentHub.Invitations.InvitesSent";
      }
      export class InvitedBy {
        static readonly key: string = "ContentHub.Invitations.InvitedBy";
      }
      export class JoinedOn {
        static readonly key: string = "ContentHub.Invitations.JoinedOn";
      }
      export class MailAnimationAltText {
        static readonly key: string =
          "ContentHub.Invitations.MailAnimationAltText";
      }
      export class MailIllustrationAltText {
        static readonly key: string =
          "ContentHub.Invitations.MailIllustrationAltText";
      }
      export class MixedSuccessWithFailurePlural {
        static readonly key: string =
          "ContentHub.Invitations.MixedSuccessWithFailurePlural";
        boldOpen: string;
        boldClose: string;
        failures: string;
      }
      export class MixedSuccessWithSuccessPlural {
        static readonly key: string =
          "ContentHub.Invitations.MixedSuccessWithSuccessPlural";
        successes: string;
        boldOpen: string;
        boldClose: string;
      }
      export class MixedSuccessWithOnlyFailureSingular {
        static readonly key: string =
          "ContentHub.Invitations.MixedSuccessWithOnlyFailureSingular";
        boldOpen: string;
        boldClose: string;
      }
      export class MixedSuccessWithOnlyFailurePlural {
        static readonly key: string =
          "ContentHub.Invitations.MixedSuccessWithOnlyFailurePlural";
        failures: string;
        boldOpen: string;
        boldClose: string;
      }
      export class MixedSuccessWithSuccessAndFailurePlural {
        static readonly key: string =
          "ContentHub.Invitations.MixedSuccessWithSuccessAndFailurePlural";
        successes: string;
        boldOpen: string;
        boldClose: string;
        failures: string;
      }
      export class MixedSuccessWithSuccessAndFailureSingular {
        static readonly key: string =
          "ContentHub.Invitations.MixedSuccessWithSuccessAndFailureSingular";
        boldOpen: string;
        boldClose: string;
      }
      export class OrganizationName {
        static readonly key: string = "ContentHub.Invitations.OrganizationName";
      }
      export class OrganizationHeader {
        static readonly key: string =
          "ContentHub.Invitations.OrganizationHeader";
      }
      export class ProcessingInvitations {
        static readonly key: string =
          "ContentHub.Invitations.ProcessingInvitations";
      }
      export class ReachedSiteLimitUnacceptedInvitations {
        static readonly key: string =
          "ContentHub.Invitations.ReachedSiteLimitUnacceptedInvitations";
        email: string;
      }
      export class RequiredField {
        static readonly key: string = "ContentHub.Invitations.RequiredField";
      }
      export class RightsAndSecurity {
        static readonly key: string =
          "ContentHub.Invitations.RightsAndSecurity";
      }
      export class RightsAndSecurityContent {
        static readonly key: string =
          "ContentHub.Invitations.RightsAndSecurityContent";
      }
      export class CancelRowButton {
        static readonly key: string = "ContentHub.Invitations.CancelRowButton";
      }
      export class SendInvite {
        static readonly key: string = "ContentHub.Invitations.SendInvite";
      }
      export class SendingInvitations {
        static readonly key: string =
          "ContentHub.Invitations.SendingInvitations";
      }
      export class SentInvitations {
        static readonly key: string = "ContentHub.Invitations.SentInvitations";
      }
      export class Status {
        static readonly key: string = "ContentHub.Invitations.Status";
      }
      export class TryAgain {
        static readonly key: string = "ContentHub.Invitations.TryAgain";
      }
      export class UserAlreadyExists {
        static readonly key: string =
          "ContentHub.Invitations.UserAlreadyExists";
      }
      export class ViewSentInvitations {
        static readonly key: string =
          "ContentHub.Invitations.ViewSentInvitations";
      }
      export class YouHaveBeenInvited {
        static readonly key: string =
          "ContentHub.Invitations.YouHaveBeenInvited";
        senderFullName: string;
      }
      export class YouHaveBeenInvitedNoSenderUser {
        static readonly key: string =
          "ContentHub.Invitations.YouHaveBeenInvitedNoSenderUser";
      }
      export class YouHaveInvitedOneUser {
        static readonly key: string =
          "ContentHub.Invitations.YouHaveInvitedOneUser";
        groupName: string;
      }
      export class YouHaveInvitedNumUsers {
        static readonly key: string =
          "ContentHub.Invitations.YouHaveInvitedNumUsers";
        numInvited: string;
        groupName: string;
      }
      export class YouHaveInvitedUsers {
        static readonly key: string =
          "ContentHub.Invitations.YouHaveInvitedUsers";
        groupName: string;
      }
      export module Statuses {
        export class Accepted {
          static readonly key: string =
            "ContentHub.Invitations.Statuses.Accepted";
        }
        export class Expired {
          static readonly key: string =
            "ContentHub.Invitations.Statuses.Expired";
        }
        export class Failed {
          static readonly key: string =
            "ContentHub.Invitations.Statuses.Failed";
        }
        export class Invalid {
          static readonly key: string =
            "ContentHub.Invitations.Statuses.Invalid";
        }
        export class Sent {
          static readonly key: string = "ContentHub.Invitations.Statuses.Sent";
        }
        export class Viewed {
          static readonly key: string =
            "ContentHub.Invitations.Statuses.Viewed";
        }
      }
    }
  }
  export module Courses {
    export class Begin {
      static readonly key: string = "Courses.Begin";
    }
    export class Continue {
      static readonly key: string = "Courses.Continue";
    }
    export class CourseDetails {
      static readonly key: string = "Courses.CourseDetails";
    }
    export class CreditHours {
      static readonly key: string = "Courses.CreditHours";
    }
    export class DesignCourse {
      static readonly key: string = "Courses.DesignCourse";
    }
    export class DisabledEnrollButtonTitle {
      static readonly key: string = "Courses.DisabledEnrollButtonTitle";
    }
    export class Enroll {
      static readonly key: string = "Courses.Enroll";
    }
    export class Enrolled {
      static readonly key: string = "Courses.Enrolled";
    }
    export class ManageCourse {
      static readonly key: string = "Courses.ManageCourse";
    }
    export class NewCourse {
      static readonly key: string = "Courses.NewCourse";
    }
    export class NotOpen {
      static readonly key: string = "Courses.NotOpen";
    }
    export class Seats {
      static readonly key: string = "Courses.Seats";
    }
    export class SectionNumber {
      static readonly key: string = "Courses.SectionNumber";
    }
    export class ViewEnrollmentPage {
      static readonly key: string = "Courses.ViewEnrollmentPage";
    }
    export module DeleteCourseModal {
      export class DeleteCourse {
        static readonly key: string = "Courses.DeleteCourseModal.DeleteCourse";
      }
      export class WarningMessage {
        static readonly key: string =
          "Courses.DeleteCourseModal.WarningMessage";
        courseName: string;
      }
    }
    export module DeleteSectionModal {
      export class DeleteSection {
        static readonly key: string =
          "Courses.DeleteSectionModal.DeleteSection";
      }
      export class WarningMessage {
        static readonly key: string =
          "Courses.DeleteSectionModal.WarningMessage";
        sectionNumber: string;
        courseName: string;
      }
    }
    export module PublishSectionModal {
      export class PublishNow {
        static readonly key: string = "Courses.PublishSectionModal.PublishNow";
      }
      export class PublishOn {
        static readonly key: string = "Courses.PublishSectionModal.PublishOn";
      }
      export class PublishSection {
        static readonly key: string =
          "Courses.PublishSectionModal.PublishSection";
      }
      export class WarningMessage {
        static readonly key: string =
          "Courses.PublishSectionModal.WarningMessage";
      }
    }
    export module Status {
      export class Enrolled {
        static readonly key: string = "Courses.Status.Enrolled";
      }
      export class PendingApproval {
        static readonly key: string = "Courses.Status.PendingApproval";
      }
    }
  }
  export module DatePicker {
    export class ChangeDate {
      static readonly key: string = "DatePicker.ChangeDate";
    }
    export module Error {
      export class InvalidDate {
        static readonly key: string = "DatePicker.Error.InvalidDate";
      }
      export class MaxDate {
        static readonly key: string = "DatePicker.Error.MaxDate";
        date: string;
      }
      export class MinDate {
        static readonly key: string = "DatePicker.Error.MinDate";
        date: string;
      }
    }
  }
  export module DeleteModal {
    export class PermanentlyDelete {
      static readonly key: string = "DeleteModal.PermanentlyDelete";
    }
    export class TypeDeleteIntoTheTextBox {
      static readonly key: string = "DeleteModal.TypeDeleteIntoTheTextBox";
      deletePhrase: string;
    }
  }
  export module Documents {
    export class AddAndPublishFileAsNewRevision {
      static readonly key: string = "Documents.AddAndPublishFileAsNewRevision";
    }
    export class AddIntegration {
      static readonly key: string = "Documents.AddIntegration";
    }
    export class AddManageIntegrations {
      static readonly key: string = "Documents.AddManageIntegrations";
    }
    export class AddNewFile {
      static readonly key: string = "Documents.AddNewFile";
    }
    export class AddFile {
      static readonly key: string = "Documents.AddFile";
    }
    export class AddGoogleDrive {
      static readonly key: string = "Documents.AddGoogleDrive";
      trademark: string;
    }
    export class AddNewIntegration {
      static readonly key: string = "Documents.AddNewIntegration";
    }
    export class AddNewActiveFileToPublishedRevision {
      static readonly key: string =
        "Documents.AddNewActiveFileToPublishedRevision";
    }
    export class AddOneDrive {
      static readonly key: string = "Documents.AddOneDrive";
    }
    export class AreYouSureUnlockRevision {
      static readonly key: string = "Documents.AreYouSureUnlockRevision";
    }
    export class ActiveFileWarningShort {
      static readonly key: string = "Documents.ActiveFileWarningShort";
    }
    export class ActiveFileWarningLong {
      static readonly key: string = "Documents.ActiveFileWarningLong";
    }
    export class BreadcrumbEllipsisButton {
      static readonly key: string = "Documents.BreadcrumbEllipsisButton";
    }
    export class CheckAgainForUpdates {
      static readonly key: string = "Documents.CheckAgainForUpdates";
    }
    export class CheckProviderForUpdates {
      static readonly key: string = "Documents.CheckProviderForUpdates";
      provider: string;
    }
    export class ConnectGoogleDriveAccount {
      static readonly key: string = "Documents.ConnectGoogleDriveAccount";
      trademark: string;
    }
    export class ConnectOneDriveAccount {
      static readonly key: string = "Documents.ConnectOneDriveAccount";
    }
    export class ContinueEditInBrowser {
      static readonly key: string = "Documents.ContinueEditInBrowser";
    }
    export class ContinueEditOnDesktop {
      static readonly key: string = "Documents.ContinueEditOnDesktop";
    }
    export class ContinueAddNewFile {
      static readonly key: string = "Documents.ContinueAddNewFile";
    }
    export class CurrentIntegrations {
      static readonly key: string = "Documents.CurrentIntegrations";
    }
    export class DisconnectFromProvider {
      static readonly key: string = "Documents.DisconnectFromProvider";
      provider: string;
    }
    export class DiscussPublicationLabel {
      static readonly key: string = "Documents.DiscussPublicationLabel";
    }
    export class DocConnectedToImportedFileFromProvider {
      static readonly key: string =
        "Documents.DocConnectedToImportedFileFromProvider";
      provider: string;
    }
    export class DocName {
      static readonly key: string = "Documents.DocName";
    }
    export class DocStatus {
      static readonly key: string = "Documents.DocStatus";
    }
    export class DocTiedToImportedFileFromProvider {
      static readonly key: string =
        "Documents.DocTiedToImportedFileFromProvider";
      provider: string;
    }
    export class DocumentHasBeenArchived {
      static readonly key: string = "Documents.DocumentHasBeenArchived";
      strongOpening: string;
      strongClosing: string;
    }
    export class DocumentLabel {
      static readonly key: string = "Documents.DocumentLabel";
    }
    export class DocumentNameLabel {
      static readonly key: string = "Documents.DocumentNameLabel";
    }
    export class DocumentType {
      static readonly key: string = "Documents.DocumentType";
    }
    export class DocumentViewer {
      static readonly key: string = "Documents.DocumentViewer";
    }
    export class DuplicateMessageBox {
      static readonly key: string = "Documents.DuplicateMessageBox";
    }
    export class EditInBrowser {
      static readonly key: string = "Documents.EditInBrowser";
    }
    export class EditOnDesktop {
      static readonly key: string = "Documents.EditOnDesktop";
    }
    export class EditRevision {
      static readonly key: string = "Documents.EditRevision";
    }
    export class EffectiveDateLabel {
      static readonly key: string = "Documents.EffectiveDateLabel";
    }
    export class ErrorProviderNotSupported {
      static readonly key: string = "Documents.ErrorProviderNotSupported";
      provider: string;
    }
    export class ImportFromProvider {
      static readonly key: string = "Documents.ImportFromProvider";
      provider: string;
    }
    export class Importing {
      static readonly key: string = "Documents.Importing";
    }
    export class ImportLatestChanges {
      static readonly key: string = "Documents.ImportLatestChanges";
    }
    export class Integrations {
      static readonly key: string = "Documents.Integrations";
    }
    export class InternalLinkLabel {
      static readonly key: string = "Documents.InternalLinkLabel";
    }
    export class LastSigned {
      static readonly key: string = "Documents.LastSigned";
    }
    export class LinkedProviderEmail {
      static readonly key: string = "Documents.LinkedProviderEmail";
      provider: string;
      email: string;
    }
    export class LinkLabel {
      static readonly key: string = "Documents.LinkLabel";
    }
    export class ManageDocument {
      static readonly key: string = "Documents.ManageDocument";
    }
    export class ManageIntegrations {
      static readonly key: string = "Documents.ManageIntegrations";
    }
    export class ModifyingDocMetadataWarning {
      static readonly key: string = "Documents.ModifyingDocMetadataWarning";
    }
    export class NameTruncatedInfo {
      static readonly key: string = "Documents.NameTruncatedInfo";
    }
    export class NewChangesToImport {
      static readonly key: string = "Documents.NewChangesToImport";
    }
    export class NewDocDateStatus {
      static readonly key: string = "Documents.NewDocDateStatus";
      docDate: string;
    }
    export class NewUpdatesAreAvailable {
      static readonly key: string = "Documents.NewUpdatesAreAvailable";
    }
    export class NextReviewDateLabel {
      static readonly key: string = "Documents.NextReviewDateLabel";
    }
    export class NoAccessibleChanges {
      static readonly key: string = "Documents.NoAccessibleChanges";
    }
    export class NoAccessToProviderFile {
      static readonly key: string = "Documents.NoAccessToProviderFile";
      provider: string;
    }
    export class NoAccountsConnected {
      static readonly key: string = "Documents.NoAccountsConnected";
    }
    export class NoChangesMade {
      static readonly key: string = "Documents.NoChangesMade";
    }
    export class NoNewChanges {
      static readonly key: string = "Documents.NoNewChanges";
    }
    export class NoNewChangesFromProvider {
      static readonly key: string = "Documents.NoNewChangesFromProvider";
      provider: string;
    }
    export class NotTaggedStatus {
      static readonly key: string = "Documents.NotTaggedStatus";
    }
    export class OnlyOneAccountPerProvider {
      static readonly key: string = "Documents.OnlyOneAccountPerProvider";
    }
    export class OpenInProvider {
      static readonly key: string = "Documents.OpenInProvider";
      provider: string;
    }
    export class ArchivalScheduled {
      static readonly key: string = "Documents.ArchivalScheduled";
      date: string;
    }
    export class ProviderSourceLabel {
      static readonly key: string = "Documents.ProviderSourceLabel";
    }
    export class Public {
      static readonly key: string = "Documents.Public";
    }
    export class PublicDocumentsPage {
      static readonly key: string = "Documents.PublicDocumentsPage";
    }
    export class PublicLinkLabel {
      static readonly key: string = "Documents.PublicLinkLabel";
    }
    export class PublicLinkWarning {
      static readonly key: string = "Documents.PublicLinkWarning";
    }
    export class PublicLinkDetail {
      static readonly key: string = "Documents.PublicLinkDetail";
    }
    export class PublicSettingLabel {
      static readonly key: string = "Documents.PublicSettingLabel";
    }
    export class PublishedDocDateStatus {
      static readonly key: string = "Documents.PublishedDocDateStatus";
      docDate: string;
    }
    export class PublishingRevisionRequiresDocReSign {
      static readonly key: string =
        "Documents.PublishingRevisionRequiresDocReSign";
    }
    export class RelatedStandards {
      static readonly key: string = "Documents.RelatedStandards";
    }
    export class RemoveLinkedAccount {
      static readonly key: string = "Documents.RemoveLinkedAccount";
    }
    export class RenameDocument {
      static readonly key: string = "Documents.RenameDocument";
    }
    export class RequestUpdates {
      static readonly key: string = "Documents.RequestUpdates";
    }
    export class RevisedDocDateStatus {
      static readonly key: string = "Documents.RevisedDocDateStatus";
      docDate: string;
    }
    export class SignaturesEnableAssignments {
      static readonly key: string = "Documents.SignaturesEnableAssignments";
    }
    export class SignaturesFurtherAction {
      static readonly key: string = "Documents.SignaturesFurtherAction";
    }
    export class SignaturesPublishRevision {
      static readonly key: string = "Documents.SignaturesPublishRevision";
    }
    export class SignaturesViewRevisions {
      static readonly key: string = "Documents.SignaturesViewRevisions";
    }
    export class SignInToDifferentGoogleDriveAccount {
      static readonly key: string =
        "Documents.SignInToDifferentGoogleDriveAccount";
      trademark: string;
    }
    export class SignInToDifferentOneDriveAccount {
      static readonly key: string =
        "Documents.SignInToDifferentOneDriveAccount";
    }
    export class TaggedWith {
      static readonly key: string = "Documents.TaggedWith";
    }
    export class UpdateRevision {
      static readonly key: string = "Documents.UpdateRevision";
    }
    export class UnableToImportChanges {
      static readonly key: string = "Documents.UnableToImportChanges";
    }
    export class UnlockDocument {
      static readonly key: string = "Documents.UnlockDocument";
    }
    export class UnableToAccessProvider {
      static readonly key: string = "Documents.UnableToAccessProvider";
      provider: string;
    }
    export class UnableToAccessProviderTitle {
      static readonly key: string = "Documents.UnableToAccessProviderTitle";
      provider: string;
    }
    export class UploadFrom {
      static readonly key: string = "Documents.UploadFrom";
    }
    export class UsersCanMakeAndViewComments {
      static readonly key: string = "Documents.UsersCanMakeAndViewComments";
    }
    export class UsersCanMakeComments {
      static readonly key: string = "Documents.UsersCanMakeComments";
    }
    export class ViewDocument {
      static readonly key: string = "Documents.ViewDocument";
    }
    export class ViewList {
      static readonly key: string = "Documents.ViewList";
    }
    export class YouHaveLatestVersion {
      static readonly key: string = "Documents.YouHaveLatestVersion";
    }
    export module DiscussionPanel {
      export class PrintButton {
        static readonly key: string = "Documents.DiscussionPanel.PrintButton";
      }
    }
    export module Workflow {
      export class CompareStartWorkflow {
        static readonly key: string = "Documents.Workflow.CompareStartWorkflow";
      }
      export class WorkflowStart {
        static readonly key: string = "Documents.Workflow.WorkflowStart";
      }
      export class StartNewWorkflow {
        static readonly key: string = "Documents.Workflow.StartNewWorkflow";
      }
      export class ViewActiveWorkflow {
        static readonly key: string = "Documents.Workflow.ViewActiveWorkflow";
      }
    }
    export module ArchiveModal {
      export class ArchiveDocument {
        static readonly key: string = "Documents.ArchiveModal.ArchiveDocument";
      }
      export class ArchiveNow {
        static readonly key: string = "Documents.ArchiveModal.ArchiveNow";
      }
      export class RemoveScheduledArchive {
        static readonly key: string =
          "Documents.ArchiveModal.RemoveScheduledArchive";
      }
      export class ArchiveOn {
        static readonly key: string = "Documents.ArchiveModal.ArchiveOn";
      }
      export class ArchiveThisDocument {
        static readonly key: string =
          "Documents.ArchiveModal.ArchiveThisDocument";
      }
      export class ByArchivingDocument {
        static readonly key: string =
          "Documents.ArchiveModal.ByArchivingDocument";
        documentName: string;
      }
      export class DocumentCanBeRestored {
        static readonly key: string =
          "Documents.ArchiveModal.DocumentCanBeRestored";
      }
      export class DocumentHasBeenArchived {
        static readonly key: string =
          "Documents.ArchiveModal.DocumentHasBeenArchived";
        documentName: string;
      }
      export class RevisionsWillBeArchived {
        static readonly key: string =
          "Documents.ArchiveModal.RevisionsWillBeArchived";
      }
      export class SaveAndArchive {
        static readonly key: string = "Documents.ArchiveModal.SaveAndArchive";
      }
      export class UsersWontViewOrSign {
        static readonly key: string =
          "Documents.ArchiveModal.UsersWontViewOrSign";
      }
      export class WorkflowsWillBeCancelled {
        static readonly key: string =
          "Documents.ArchiveModal.WorkflowsWillBeCancelled";
      }
    }
    export module FromTemplateDialog {
      export class HeaderText {
        static readonly key: string = "Documents.FromTemplateDialog.HeaderText";
      }
    }
    export module DeleteModal {
      export class CoursesWarningPlural {
        static readonly key: string =
          "Documents.DeleteModal.CoursesWarningPlural";
        courseElementsCount: string;
      }
      export class CoursesWarningSingular {
        static readonly key: string =
          "Documents.DeleteModal.CoursesWarningSingular";
      }
      export class DeleteDocument {
        static readonly key: string = "Documents.DeleteModal.DeleteDocument";
      }
      export class PermanentlyDeleteDocument {
        static readonly key: string =
          "Documents.DeleteModal.PermanentlyDeleteDocument";
      }
      export class RelatedStandardsWarningPlural {
        static readonly key: string =
          "Documents.DeleteModal.RelatedStandardsWarningPlural";
        relatedStandardsCount: string;
      }
      export class RelatedStandardsWarningSingular {
        static readonly key: string =
          "Documents.DeleteModal.RelatedStandardsWarningSingular";
      }
      export class RevisionsWarningPlural {
        static readonly key: string =
          "Documents.DeleteModal.RevisionsWarningPlural";
        revisionsCount: string;
      }
      export class RevisionsWarningSingular {
        static readonly key: string =
          "Documents.DeleteModal.RevisionsWarningSingular";
      }
      export class SignaturesWarningPlural {
        static readonly key: string =
          "Documents.DeleteModal.SignaturesWarningPlural";
        signaturesCount: string;
      }
      export class SignaturesWarningSingular {
        static readonly key: string =
          "Documents.DeleteModal.SignaturesWarningSingular";
      }
      export class SuccessfullyDeleted {
        static readonly key: string =
          "Documents.DeleteModal.SuccessfullyDeleted";
        documentName: string;
      }
      export class TypeDeleteIntoTheTextBox {
        static readonly key: string =
          "Documents.DeleteModal.TypeDeleteIntoTheTextBox";
        deletePhrase: string;
      }
      export class WarningMessage {
        static readonly key: string = "Documents.DeleteModal.WarningMessage";
        documentName: string;
      }
      export class WorkflowsWarningPlural {
        static readonly key: string =
          "Documents.DeleteModal.WorkflowsWarningPlural";
        workflowsCount: string;
      }
      export class WorkflowsWarningSingular {
        static readonly key: string =
          "Documents.DeleteModal.WorkflowsWarningSingular";
      }
    }
    export module Aux {
      export class AssociatedImageFiles {
        static readonly key: string = "Documents.Aux.AssociatedImageFiles";
      }
      export class HtmlSuccessfullyUploaded {
        static readonly key: string = "Documents.Aux.HtmlSuccessfullyUploaded";
      }
      export class NonReferencedFiles {
        static readonly key: string = "Documents.Aux.NonReferencedFiles";
      }
      export class FilesToLinkHeader {
        static readonly key: string = "Documents.Aux.FilesToLinkHeader";
      }
      export class AuxFileReadyForLinking {
        static readonly key: string = "Documents.Aux.AuxFileReadyForLinking";
      }
      export class SelectedFilesHeader {
        static readonly key: string = "Documents.Aux.SelectedFilesHeader";
      }
      export class FilesToDiscard {
        static readonly key: string = "Documents.Aux.FilesToDiscard";
      }
      export class FinishWithoutLinking {
        static readonly key: string = "Documents.Aux.FinishWithoutLinking";
      }
      export class FilesRestritedToMaximumFileSize {
        static readonly key: string =
          "Documents.Aux.FilesRestritedToMaximumFileSize";
        maxFileSizeMb: string;
      }
    }
    export module DisconnectExternalFileDialog {
      export class DisconnectThisDocument {
        static readonly key: string =
          "Documents.DisconnectExternalFileDialog.DisconnectThisDocument";
      }
      export class Heading {
        static readonly key: string =
          "Documents.DisconnectExternalFileDialog.Heading";
      }
      export class Description {
        static readonly key: string =
          "Documents.DisconnectExternalFileDialog.Description";
        documentName: string;
        providerName: string;
      }
    }
    export module DocViewer {
      export class AutoSavetime {
        static readonly key: string = "Documents.DocViewer.AutoSavetime";
        time: string;
      }
      export class ConfirmModify {
        static readonly key: string = "Documents.DocViewer.ConfirmModify";
      }
      export class DiscardChanges {
        static readonly key: string = "Documents.DocViewer.DiscardChanges";
        lineBreak: string;
      }
      export class EditedByText {
        static readonly key: string = "Documents.DocViewer.EditedByText";
        firstName: string;
        lastName: string;
        userName: string;
      }
      export class PageTitle {
        static readonly key: string = "Documents.DocViewer.PageTitle";
      }
      export class UnsavedChanges {
        static readonly key: string = "Documents.DocViewer.UnsavedChanges";
      }
      export module Collaboration {
        export class AddComment {
          static readonly key: string =
            "Documents.DocViewer.Collaboration.AddComment";
        }
        export class ReplyTo {
          static readonly key: string =
            "Documents.DocViewer.Collaboration.ReplyTo";
          userName: string;
        }
      }
      export module EditInBrowser {
        export class FinishEditing {
          static readonly key: string =
            "Documents.DocViewer.EditInBrowser.FinishEditing";
        }
      }
      export module CreateNewDraft {
        export class Heading {
          static readonly key: string =
            "Documents.DocViewer.CreateNewDraft.Heading";
        }
        export class Title {
          static readonly key: string =
            "Documents.DocViewer.CreateNewDraft.Title";
        }
        export class Copy {
          static readonly key: string =
            "Documents.DocViewer.CreateNewDraft.Copy";
        }
        export class SaveCreateNewDraft {
          static readonly key: string =
            "Documents.DocViewer.CreateNewDraft.SaveCreateNewDraft";
        }
        export class CreatingYourDraft {
          static readonly key: string =
            "Documents.DocViewer.CreateNewDraft.CreatingYourDraft";
        }
      }
      export module EditOnDesktop {
        export class LooksGoodTitle {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.LooksGoodTitle";
        }
        export class LooksGoodText {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.LooksGoodText";
        }
        export class SavedDocIsStaleTitle {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.SavedDocIsStaleTitle";
        }
        export class SavedDocIsStaleText {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.SavedDocIsStaleText";
        }
        export class NoSavedChangesTitle {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.NoSavedChangesTitle";
        }
        export class NoSavedChangesText {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.NoSavedChangesText";
        }
        export class ConfirmFinishEditingTitle {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.ConfirmFinishEditingTitle";
        }
        export class ConfirmFinishEditingText {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.ConfirmFinishEditingText";
        }
        export class FinishEditing {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.FinishEditing";
        }
        export class FinishEditingNewAuthor {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.FinishEditingNewAuthor";
        }
        export class ConfirmFinishEditing {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.ConfirmFinishEditing";
        }
        export class ContinueEditing {
          static readonly key: string =
            "Documents.DocViewer.EditOnDesktop.ContinueEditing";
        }
      }
      export module LastSaved {
        export class Hour {
          static readonly key: string = "Documents.DocViewer.LastSaved.Hour";
        }
        export class Hours {
          static readonly key: string = "Documents.DocViewer.LastSaved.Hours";
          hours: string;
        }
        export class Minute {
          static readonly key: string = "Documents.DocViewer.LastSaved.Minute";
        }
        export class Minutes {
          static readonly key: string = "Documents.DocViewer.LastSaved.Minutes";
          minutes: string;
        }
        export class Now {
          static readonly key: string = "Documents.DocViewer.LastSaved.Now";
        }
      }
      export module RequestUpdates {
        export class Heading {
          static readonly key: string =
            "Documents.DocViewer.RequestUpdates.Heading";
        }
        export class Copy {
          static readonly key: string =
            "Documents.DocViewer.RequestUpdates.Copy";
          strongOpen: string;
          originalUploaderName: string;
          strongClose: string;
        }
        export class DisconnectAndAddNewFile {
          static readonly key: string =
            "Documents.DocViewer.RequestUpdates.DisconnectAndAddNewFile";
        }
      }
      export module Search {
        export class CountStatus {
          static readonly key: string =
            "Documents.DocViewer.Search.CountStatus";
          currentNumber: string;
          totalNumber: string;
        }
        export class NoResults {
          static readonly key: string = "Documents.DocViewer.Search.NoResults";
        }
      }
      export module Status {
        export class Failed {
          static readonly key: string = "Documents.DocViewer.Status.Failed";
        }
        export class InstructionWhenOpened {
          static readonly key: string =
            "Documents.DocViewer.Status.InstructionWhenOpened";
        }
        export class Opened {
          static readonly key: string = "Documents.DocViewer.Status.Opened";
        }
        export class Saving {
          static readonly key: string = "Documents.DocViewer.Status.Saving";
        }
      }
      export module Workflow {
        export class AdvanceToNext {
          static readonly key: string =
            "Documents.DocViewer.Workflow.AdvanceToNext";
        }
        export class CommentForAction {
          static readonly key: string =
            "Documents.DocViewer.Workflow.CommentForAction";
        }
        export class RequestReevaluation {
          static readonly key: string =
            "Documents.DocViewer.Workflow.RequestReevaluation";
        }
        export class TryAgainOrRefresh {
          static readonly key: string =
            "Documents.DocViewer.Workflow.TryAgainOrRefresh";
        }
        export module ReevaluateInstructions {
          export class AllResponseMultipleDays {
            static readonly key: string =
              "Documents.DocViewer.Workflow.ReevaluateInstructions.AllResponseMultipleDays";
            fullName: string;
            dayCount: number;
          }
          export class AllResponseOneDay {
            static readonly key: string =
              "Documents.DocViewer.Workflow.ReevaluateInstructions.AllResponseOneDay";
            fullName: string;
          }
          export class FirstOrDynamicFirstResponseMultipleDays {
            static readonly key: string =
              "Documents.DocViewer.Workflow.ReevaluateInstructions.FirstOrDynamicFirstResponseMultipleDays";
            fullName: string;
            dayCount: number;
          }
          export class FirstOrDynamicFirstResponseOneDay {
            static readonly key: string =
              "Documents.DocViewer.Workflow.ReevaluateInstructions.FirstOrDynamicFirstResponseOneDay";
            fullName: string;
          }
        }
        export module SkipInstructions {
          export class MultipleDaysMultipleUsers {
            static readonly key: string =
              "Documents.DocViewer.Workflow.SkipInstructions.MultipleDaysMultipleUsers";
            waitingDayCount: number;
            currentResponseCount: number;
            expectedResponseCount: number;
          }
          export class MultipleDaysOneUser {
            static readonly key: string =
              "Documents.DocViewer.Workflow.SkipInstructions.MultipleDaysOneUser";
            waitingDayCount: number;
          }
          export class OneDayMultipleUsers {
            static readonly key: string =
              "Documents.DocViewer.Workflow.SkipInstructions.OneDayMultipleUsers";
            currentResponseCount: number;
            expectedResponseCount: number;
          }
          export class OneDayOneUser {
            static readonly key: string =
              "Documents.DocViewer.Workflow.SkipInstructions.OneDayOneUser";
          }
        }
      }
    }
    export module Duplicate {
      export class ModalTitle {
        static readonly key: string = "Documents.Duplicate.ModalTitle";
      }
      export class ModalCopy {
        static readonly key: string = "Documents.Duplicate.ModalCopy";
        duplicateDocumentName: string;
      }
      export class ModalAdvance {
        static readonly key: string = "Documents.Duplicate.ModalAdvance";
      }
      export class ModalTryAgainOrRefresh {
        static readonly key: string =
          "Documents.Duplicate.ModalTryAgainOrRefresh";
      }
    }
    export module DuplicateList {
      export class ModalTitle {
        static readonly key: string = "Documents.DuplicateList.ModalTitle";
      }
      export class ModalCopy {
        static readonly key: string = "Documents.DuplicateList.ModalCopy";
        duplicateDocumentName: string;
      }
      export class SecurityNote {
        static readonly key: string = "Documents.DuplicateList.SecurityNote";
      }
    }
    export module Revisions {
      export class UnlockRevisionConfirm {
        static readonly key: string =
          "Documents.Revisions.UnlockRevisionConfirm";
      }
      export class UnlockRevisionPrompt {
        static readonly key: string =
          "Documents.Revisions.UnlockRevisionPrompt";
      }
    }
    export module New {
      export class CreateUsingDocAsTemplate {
        static readonly key: string = "Documents.New.CreateUsingDocAsTemplate";
      }
      export class CreatingYourDocument {
        static readonly key: string = "Documents.New.CreatingYourDocument";
      }
      export class DragDropFile {
        static readonly key: string = "Documents.New.DragDropFile";
        lineBreak: string;
      }
      export class DropFileToUpload {
        static readonly key: string = "Documents.New.DropFileToUpload";
      }
      export class FilterByFolderLabel {
        static readonly key: string = "Documents.New.FilterByFolderLabel";
      }
      export class FromDocumentTemplate {
        static readonly key: string = "Documents.New.FromDocumentTemplate";
      }
      export class FromPublishedRevision {
        static readonly key: string = "Documents.New.FromPublishedRevision";
      }
      export class LooksLikeBigOne {
        static readonly key: string = "Documents.New.LooksLikeBigOne";
      }
      export class NewDraftFromDocTemplate {
        static readonly key: string = "Documents.New.NewDraftFromDocTemplate";
      }
      export class NewActiveDraftRevision {
        static readonly key: string = "Documents.New.NewActiveDraftRevision";
      }
      export class NewActivePublishedRevision {
        static readonly key: string =
          "Documents.New.NewActivePublishedRevision";
      }
      export class NewDoc {
        static readonly key: string = "Documents.New.NewDoc";
      }
      export class NewDraftRevision {
        static readonly key: string = "Documents.New.NewDraftRevision";
      }
      export class NewPublishedRevision {
        static readonly key: string = "Documents.New.NewPublishedRevision";
      }
      export class NewWorkflow {
        static readonly key: string = "Documents.New.NewWorkflow";
      }
      export class OnlyPublishedRevsAsTemplates {
        static readonly key: string =
          "Documents.New.OnlyPublishedRevsAsTemplates";
      }
      export class CreateNew {
        static readonly key: string = "Documents.New.CreateNew";
      }
      export class SelectDestination {
        static readonly key: string = "Documents.New.SelectDestination";
      }
      export class UploadContent {
        static readonly key: string = "Documents.New.UploadContent";
      }
      export class UploadNewActiveFile {
        static readonly key: string = "Documents.New.UploadNewActiveFile";
      }
      export class UploadNewDraft {
        static readonly key: string = "Documents.New.UploadNewDraft";
      }
      export class UploadNewPublishedRevision {
        static readonly key: string =
          "Documents.New.UploadNewPublishedRevision";
      }
      export class UploadingYourLinkedFiles {
        static readonly key: string = "Documents.New.UploadingYourLinkedFiles";
      }
      export class WhereToCreateNewDoc {
        static readonly key: string = "Documents.New.WhereToCreateNewDoc";
      }
    }
    export module OneTimePasswordModal {
      export class AVerificationCodeHasBeenSent {
        static readonly key: string =
          "Documents.OneTimePasswordModal.AVerificationCodeHasBeenSent";
        email: string;
      }
      export class NewCodeSent {
        static readonly key: string =
          "Documents.OneTimePasswordModal.NewCodeSent";
      }
      export class ResendCode {
        static readonly key: string =
          "Documents.OneTimePasswordModal.ResendCode";
      }
      export class VerificationCode {
        static readonly key: string =
          "Documents.OneTimePasswordModal.VerificationCode";
      }
      export class VerificationRequired {
        static readonly key: string =
          "Documents.OneTimePasswordModal.VerificationRequired";
      }
      export class InvalidVerificationCode {
        static readonly key: string =
          "Documents.OneTimePasswordModal.InvalidVerificationCode";
      }
      export class EmailMissing {
        static readonly key: string =
          "Documents.OneTimePasswordModal.EmailMissing";
      }
      export class EmailMissingLineTwo {
        static readonly key: string =
          "Documents.OneTimePasswordModal.EmailMissingLineTwo";
      }
      export class UnverifiedEmailWarning {
        static readonly key: string =
          "Documents.OneTimePasswordModal.UnverifiedEmailWarning";
      }
      export class UpdateEmailAddress {
        static readonly key: string =
          "Documents.OneTimePasswordModal.UpdateEmailAddress";
      }
    }
    export module OverflowMenu {
      export class CompareOptionsAvailable {
        static readonly key: string =
          "Documents.OverflowMenu.CompareOptionsAvailable";
      }
      export class CompareToCurrentPublication {
        static readonly key: string =
          "Documents.OverflowMenu.CompareToCurrentPublication";
      }
      export class CompareToLastRevisionISigned {
        static readonly key: string =
          "Documents.OverflowMenu.CompareToLastRevisionISigned";
      }
      export class CopyLink {
        static readonly key: string = "Documents.OverflowMenu.CopyLink";
      }
      export class CreateDraftRevision {
        static readonly key: string =
          "Documents.OverflowMenu.CreateDraftRevision";
      }
      export class OpenInNewWindow {
        static readonly key: string = "Documents.OverflowMenu.OpenInNewWindow";
      }
      export class PublishDocument {
        static readonly key: string = "Documents.OverflowMenu.PublishDocument";
      }
      export class SearchDocument {
        static readonly key: string = "Documents.OverflowMenu.SearchDocument";
      }
      export class ViewOriginalFormat {
        static readonly key: string =
          "Documents.OverflowMenu.ViewOriginalFormat";
      }
      export class ViewAsPdf {
        static readonly key: string = "Documents.OverflowMenu.ViewAsPdf";
      }
    }
    export module Tabs {
      export class Activity {
        static readonly key: string = "Documents.Tabs.Activity";
      }
      export class Assignments {
        static readonly key: string = "Documents.Tabs.Assignments";
      }
      export class General {
        static readonly key: string = "Documents.Tabs.General";
      }
      export class Review {
        static readonly key: string = "Documents.Tabs.Review";
      }
      export class Revisions {
        static readonly key: string = "Documents.Tabs.Revisions";
      }
      export class Security {
        static readonly key: string = "Documents.Tabs.Security";
      }
      export class Signatures {
        static readonly key: string = "Documents.Tabs.Signatures";
      }
      export class Standards {
        static readonly key: string = "Documents.Tabs.Standards";
      }
    }
  }
  export module PowError {
    export class CommandError {
      static readonly key: string = "PowError.CommandError";
    }
    export class InsufficientPrivileges {
      static readonly key: string = "PowError.InsufficientPrivileges";
    }
    export class InsufficientPermissions {
      static readonly key: string = "PowError.InsufficientPermissions";
    }
    export class FolderDoesNotExist {
      static readonly key: string = "PowError.FolderDoesNotExist";
    }
    export class FolderNameInUse {
      static readonly key: string = "PowError.FolderNameInUse";
    }
    export class FolderNameInvalid {
      static readonly key: string = "PowError.FolderNameInvalid";
    }
    export class FoundationGroupSyncError {
      static readonly key: string = "PowError.FoundationGroupSyncError";
    }
    export class UserNotAssignedSubmission {
      static readonly key: string = "PowError.UserNotAssignedSubmission";
    }
    export class Error500 {
      static readonly key: string = "PowError.Error500";
    }
    export class Error404 {
      static readonly key: string = "PowError.Error404";
    }
    export class NoInternet {
      static readonly key: string = "PowError.NoInternet";
    }
    export class DefaultError {
      static readonly key: string = "PowError.DefaultError";
    }
  }
  export module DownloadApp {
    export class Connected {
      static readonly key: string = "DownloadApp.Connected";
      siteName: string;
    }
    export class GetTheApp {
      static readonly key: string = "DownloadApp.GetTheApp";
    }
    export class PendingInvite {
      static readonly key: string = "DownloadApp.PendingInvite";
      siteName: string;
    }
    export class YouCanAcceptInvite {
      static readonly key: string = "DownloadApp.YouCanAcceptInvite";
    }
  }
  export module ErrorPage {
    export class ContactAdmin {
      static readonly key: string = "ErrorPage.ContactAdmin";
      anAdministrator: string;
    }
    export class CreateCertificateTypes {
      static readonly key: string = "ErrorPage.CreateCertificateTypes";
      anAdministrator: string;
    }
    export class CreateCourseTypes {
      static readonly key: string = "ErrorPage.CreateCourseTypes";
      anAdministrator: string;
    }
    export class RequestPrivileges {
      static readonly key: string = "ErrorPage.RequestPrivileges";
      anAdministrator: string;
    }
    export class TryAgain {
      static readonly key: string = "ErrorPage.TryAgain";
      anAdministrator: string;
    }
    export module SecurityException {
      export class SecurityTitle {
        static readonly key: string =
          "ErrorPage.SecurityException.SecurityTitle";
      }
      export class StandardsManualView {
        static readonly key: string =
          "ErrorPage.SecurityException.StandardsManualView";
      }
      export class InsufficientPrivileges {
        static readonly key: string =
          "ErrorPage.SecurityException.InsufficientPrivileges";
      }
      export class InsufficientPrivilegesOverObject {
        static readonly key: string =
          "ErrorPage.SecurityException.InsufficientPrivilegesOverObject";
      }
      export class AdminPrivileges {
        static readonly key: string =
          "ErrorPage.SecurityException.AdminPrivileges";
      }
      export class AssessmentAuditStandardsView {
        static readonly key: string =
          "ErrorPage.SecurityException.AssessmentAuditStandardsView";
      }
      export class AssessmentAudit {
        static readonly key: string =
          "ErrorPage.SecurityException.AssessmentAudit";
      }
    }
    export module GeneralException {
      export class AssessmentRecurringTaskSaveErrorTitle {
        static readonly key: string =
          "ErrorPage.GeneralException.AssessmentRecurringTaskSaveErrorTitle";
      }
      export class AssessmentRecurringTaskSaveMessage {
        static readonly key: string =
          "ErrorPage.GeneralException.AssessmentRecurringTaskSaveMessage";
      }
      export class NoConnectionTitle {
        static readonly key: string =
          "ErrorPage.GeneralException.NoConnectionTitle";
      }
      export class NoConnectionMessage {
        static readonly key: string =
          "ErrorPage.GeneralException.NoConnectionMessage";
      }
      export class ProductNotPurchasedTitle {
        static readonly key: string =
          "ErrorPage.GeneralException.ProductNotPurchasedTitle";
      }
      export class ProductNotPurchasedMessage {
        static readonly key: string =
          "ErrorPage.GeneralException.ProductNotPurchasedMessage";
        emailAddressLink: string;
      }
      export class RequiredParametersNotFoundTitle {
        static readonly key: string =
          "ErrorPage.GeneralException.RequiredParametersNotFoundTitle";
      }
      export class RequiredParametersNotFoundMessage {
        static readonly key: string =
          "ErrorPage.GeneralException.RequiredParametersNotFoundMessage";
      }
      export class ObjectDoesNotExistTitle {
        static readonly key: string =
          "ErrorPage.GeneralException.ObjectDoesNotExistTitle";
      }
      export class ObjectDoesNotExistMessage {
        static readonly key: string =
          "ErrorPage.GeneralException.ObjectDoesNotExistMessage";
      }
      export class FileNotFoundTitle {
        static readonly key: string =
          "ErrorPage.GeneralException.FileNotFoundTitle";
      }
      export class FileNotFoundMessage {
        static readonly key: string =
          "ErrorPage.GeneralException.FileNotFoundMessage";
      }
      export class TimezoneNotFoundTitle {
        static readonly key: string =
          "ErrorPage.GeneralException.TimezoneNotFoundTitle";
      }
      export class TimezoneNotFoundMessage {
        static readonly key: string =
          "ErrorPage.GeneralException.TimezoneNotFoundMessage";
      }
    }
    export module TrainingException {
      export class NoCertificateTypesTitle {
        static readonly key: string =
          "ErrorPage.TrainingException.NoCertificateTypesTitle";
      }
      export class NoCertificateTypesMessage {
        static readonly key: string =
          "ErrorPage.TrainingException.NoCertificateTypesMessage";
      }
      export class NoCourseTypesTitle {
        static readonly key: string =
          "ErrorPage.TrainingException.NoCourseTypesTitle";
      }
      export class NoCourseTypesMessage {
        static readonly key: string =
          "ErrorPage.TrainingException.NoCourseTypesMessage";
      }
    }
    export module WorkflowException {
      export class CannotBindUserToDefaultTitle {
        static readonly key: string =
          "ErrorPage.WorkflowException.CannotBindUserToDefaultTitle";
      }
      export class CannotBindUserToDefaultMessage {
        static readonly key: string =
          "ErrorPage.WorkflowException.CannotBindUserToDefaultMessage";
      }
    }
    export module QAException {
      export class BankResultTimeExpiredTitle {
        static readonly key: string =
          "ErrorPage.QAException.BankResultTimeExpiredTitle";
      }
      export class BankResultTimeExpiredMessage {
        static readonly key: string =
          "ErrorPage.QAException.BankResultTimeExpiredMessage";
      }
      export class BankResultNotStartedTitle {
        static readonly key: string =
          "ErrorPage.QAException.BankResultNotStartedTitle";
      }
      export class BankResultNotStartedMessage {
        static readonly key: string =
          "ErrorPage.QAException.BankResultNotStartedMessage";
      }
      export class BankResultNotFinishedTitle {
        static readonly key: string =
          "ErrorPage.QAException.BankResultNotFinishedTitle";
      }
      export class BankResultNotFinishedMessage {
        static readonly key: string =
          "ErrorPage.QAException.BankResultNotFinishedMessage";
      }
    }
    export module HttpException {
      export class PageNotFoundTitle {
        static readonly key: string =
          "ErrorPage.HttpException.PageNotFoundTitle";
      }
      export class PageNotFoundMessage {
        static readonly key: string =
          "ErrorPage.HttpException.PageNotFoundMessage";
      }
      export class FileSizeExceededTitle {
        static readonly key: string =
          "ErrorPage.HttpException.FileSizeExceededTitle";
      }
      export class FileSizeExceededMessage {
        static readonly key: string =
          "ErrorPage.HttpException.FileSizeExceededMessage";
      }
    }
    export module ServerException {
      export class ServerTimeoutTitle {
        static readonly key: string =
          "ErrorPage.ServerException.ServerTimeoutTitle";
      }
      export class ServerTimeoutMessage {
        static readonly key: string =
          "ErrorPage.ServerException.ServerTimeoutMessage";
      }
    }
    export module DefaultException {
      export class Title {
        static readonly key: string = "ErrorPage.DefaultException.Title";
      }
      export class ContactSupport {
        static readonly key: string =
          "ErrorPage.DefaultException.ContactSupport";
        phoneNumber: string;
        emailAddress: string;
      }
    }
    export module Common {
      export class ContactSupport {
        static readonly key: string = "ErrorPage.Common.ContactSupport";
        phoneNumber: string;
        emailAddress: string;
      }
      export class AnAdministrator {
        static readonly key: string = "ErrorPage.Common.AnAdministrator";
      }
      export class Title {
        static readonly key: string = "ErrorPage.Common.Title";
      }
      export class YourAdministrator {
        static readonly key: string = "ErrorPage.Common.YourAdministrator";
        adminGivenName: string;
        adminFamilyName: string;
        adminEmailAddress: string;
      }
      export class AdminTooltip {
        static readonly key: string = "ErrorPage.Common.AdminTooltip";
        adminGivenName: string;
        adminFamilyName: string;
        adminEmailAddress: string;
      }
    }
  }
  export module Folders {
    export class AbleToMoveSingular {
      static readonly key: string = "Folders.AbleToMoveSingular";
    }
    export class AbleToMovePlural {
      static readonly key: string = "Folders.AbleToMovePlural";
      numItems: string;
    }
    export class AndXMore {
      static readonly key: string = "Folders.AndXMore";
      linkStart: string;
      numItems: string;
      linkEnd: string;
    }
    export class ConfirmDelete {
      static readonly key: string = "Folders.ConfirmDelete";
    }
    export class ConfirmDeleteHasChildren {
      static readonly key: string = "Folders.ConfirmDeleteHasChildren";
    }
    export class ConfirmDeleteMessage {
      static readonly key: string = "Folders.ConfirmDeleteMessage";
      folderName: string;
    }
    export class Content {
      static readonly key: string = "Folders.Content";
    }
    export class DeleteAndMoveContents {
      static readonly key: string = "Folders.DeleteAndMoveContents";
    }
    export class DeleteThisFolder {
      static readonly key: string = "Folders.DeleteThisFolder";
    }
    export class FolderNameLabel {
      static readonly key: string = "Folders.FolderNameLabel";
    }
    export class FolderDoesntExistError {
      static readonly key: string = "Folders.FolderDoesntExistError";
    }
    export class ManageFolder {
      static readonly key: string = "Folders.ManageFolder";
    }
    export class MoveRenameWarning {
      static readonly key: string = "Folders.MoveRenameWarning";
      boldStart: string;
      boldEnd: string;
    }
    export class NoCancelDelete {
      static readonly key: string = "Folders.NoCancelDelete";
    }
    export class NoDontDelete {
      static readonly key: string = "Folders.NoDontDelete";
    }
    export class PrivilegeLossWarning {
      static readonly key: string = "Folders.PrivilegeLossWarning";
    }
    export class SameNameError {
      static readonly key: string = "Folders.SameNameError";
      folderName: string;
    }
    export class SameNameMoveError {
      static readonly key: string = "Folders.SameNameMoveError";
      boldStart: string;
      boldEnd: string;
      folderName: string;
    }
    export class UnableToMoveSingular {
      static readonly key: string = "Folders.UnableToMoveSingular";
    }
    export class UnableToMovePlural {
      static readonly key: string = "Folders.UnableToMovePlural";
      numItems: string;
    }
    export class YesContinueDelete {
      static readonly key: string = "Folders.YesContinueDelete";
    }
    export class YesDeleteLabel {
      static readonly key: string = "Folders.YesDeleteLabel";
    }
  }
  export module ErrorPhrases {
    export class Hmmm {
      static readonly key: string = "ErrorPhrases.Hmmm";
    }
    export class PleaseContactAdmin {
      static readonly key: string = "ErrorPhrases.PleaseContactAdmin";
    }
    export class TryRefreshing {
      static readonly key: string = "ErrorPhrases.TryRefreshing";
      refreshLinkStart: string;
      refreshLinkEnd: string;
    }
  }
  export module Errors {
    export class CertificateLabelsTooLong {
      static readonly key: string = "Errors.CertificateLabelsTooLong";
    }
    export class ContentHubUserAlreadyLinked {
      static readonly key: string = "Errors.ContentHubUserAlreadyLinked";
    }
    export class CreateDocumentPrivileges {
      static readonly key: string = "Errors.CreateDocumentPrivileges";
    }
    export class CreateDocumentTypePrivileges {
      static readonly key: string = "Errors.CreateDocumentTypePrivileges";
    }
    export class DefaultError {
      static readonly key: string = "Errors.DefaultError";
      status: string;
      statusText: string;
    }
    export class DocumentDescriptionTooLong {
      static readonly key: string = "Errors.DocumentDescriptionTooLong";
    }
    export class DocumentNameExists {
      static readonly key: string = "Errors.DocumentNameExists";
      documentName: string;
    }
    export class DocumentNameExistsInFolder {
      static readonly key: string = "Errors.DocumentNameExistsInFolder";
      documentName: string;
    }
    export class DocumentNameTooLong {
      static readonly key: string = "Errors.DocumentNameTooLong";
    }
    export class DocumentNotFound {
      static readonly key: string = "Errors.DocumentNotFound";
    }
    export class DocumentRevisionIsLocked {
      static readonly key: string = "Errors.DocumentRevisionIsLocked";
    }
    export class DraftAlreadyExists {
      static readonly key: string = "Errors.DraftAlreadyExists";
    }
    export class FileIsRequired {
      static readonly key: string = "Errors.FileIsRequired";
    }
    export class FileNoExtension {
      static readonly key: string = "Errors.FileNoExtension";
    }
    export class FileSizeUnsupported {
      static readonly key: string = "Errors.FileSizeUnsupported";
      maxFileSizeMb: string;
    }
    export class IdentityProviderAccountAlreadyExists {
      static readonly key: string =
        "Errors.IdentityProviderAccountAlreadyExists";
      siteKey: string;
    }
    export class IntegrityMismatch {
      static readonly key: string = "Errors.IntegrityMismatch";
    }
    export class InsufficientEditPermissionsOverDocument {
      static readonly key: string =
        "Errors.InsufficientEditPermissionsOverDocument";
    }
    export class InsufficientPermissionsOverSiteFileUpload {
      static readonly key: string =
        "Errors.InsufficientPermissionsOverSiteFileUpload";
    }
    export class InvalidDocumentCreationSource {
      static readonly key: string = "Errors.InvalidDocumentCreationSource";
    }
    export class MultipleFilesUnsupported {
      static readonly key: string = "Errors.MultipleFilesUnsupported";
    }
    export class MultipleHtmlFilesFound {
      static readonly key: string = "Errors.MultipleHtmlFilesFound";
    }
    export class NoAccessTokenFound {
      static readonly key: string = "Errors.NoAccessTokenFound";
      provider: string;
    }
    export class NoContentHubPublisherLicense {
      static readonly key: string = "Errors.NoContentHubPublisherLicense";
      siteKey: string;
    }
    export class NoInternet {
      static readonly key: string = "Errors.NoInternet";
    }
    export class NoInternetConnection {
      static readonly key: string = "Errors.NoInternetConnection";
    }
    export class NoInternetTryAgain {
      static readonly key: string = "Errors.NoInternetTryAgain";
    }
    export class NoNameSpecified {
      static readonly key: string = "Errors.NoNameSpecified";
    }
    export class NoResponse {
      static readonly key: string = "Errors.NoResponse";
    }
    export class PublicationDoesNotExist {
      static readonly key: string = "Errors.PublicationDoesNotExist";
    }
    export class RefreshPage {
      static readonly key: string = "Errors.RefreshPage";
    }
    export class RevisionNotFound {
      static readonly key: string = "Errors.RevisionNotFound";
    }
    export class SiteAuthenticationFailed {
      static readonly key: string = "Errors.SiteAuthenticationFailed";
    }
    export class SourceDocumentIdUnspecified {
      static readonly key: string = "Errors.SourceDocumentIdUnspecified";
    }
    export class TagAlreadyExists {
      static readonly key: string = "Errors.TagAlreadyExists";
      duplicateTags: string;
    }
    export class UnableToCalculateFileHash {
      static readonly key: string = "Errors.UnableToCalculateFileHash";
    }
    export class UnifiedPasswordResetFailed {
      static readonly key: string = "Errors.UnifiedPasswordResetFailed";
    }
    export class UnknownUnsupportedFileType {
      static readonly key: string = "Errors.UnknownUnsupportedFileType";
    }
    export class UnsupportedFileType {
      static readonly key: string = "Errors.UnsupportedFileType";
      fileExtension: string;
    }
    export class SubscriberUserIsAlreadyConnectedToPublisherSite {
      static readonly key: string =
        "Errors.SubscriberUserIsAlreadyConnectedToPublisherSite";
    }
    export class UnknownError {
      static readonly key: string = "Errors.UnknownError";
    }
  }
  export module Groups {
    export class ErrorLoadingGroupsParents {
      static readonly key: string = "Groups.ErrorLoadingGroupsParents";
    }
    export class GroupIsMemberOf {
      static readonly key: string = "Groups.GroupIsMemberOf";
      groupName: string;
    }
    export class MemberOf {
      static readonly key: string = "Groups.MemberOf";
    }
    export class NumberOfGroups {
      static readonly key: string = "Groups.NumberOfGroups";
      numberOfGroups: string;
    }
    export class NewGroup {
      static readonly key: string = "Groups.NewGroup";
    }
  }
  export module Home {
    export class EditHomepageMessage {
      static readonly key: string = "Home.EditHomepageMessage";
    }
    export class ImportantMessage {
      static readonly key: string = "Home.ImportantMessage";
    }
    export class NewFeaturesAvailable {
      static readonly key: string = "Home.NewFeaturesAvailable";
    }
    export class SiteLogoAltText {
      static readonly key: string = "Home.SiteLogoAltText";
    }
    export class Welcome {
      static readonly key: string = "Home.Welcome";
    }
    export module OldSiteKeyMessage {
      export class Singular {
        static readonly key: string = "Home.OldSiteKeyMessage.Singular";
        newSiteKey: string;
      }
      export class Plural {
        static readonly key: string = "Home.OldSiteKeyMessage.Plural";
        newSiteKey: string;
        daysLeft: string;
      }
    }
    export module ContactSalesRep {
      export class HaveQuestions {
        static readonly key: string = "Home.ContactSalesRep.HaveQuestions";
      }
      export class IfYouWouldLikeToContinue {
        static readonly key: string =
          "Home.ContactSalesRep.IfYouWouldLikeToContinue";
      }
      export class TrialHasEnded {
        static readonly key: string = "Home.ContactSalesRep.TrialHasEnded";
      }
      export class TrialHasExpired {
        static readonly key: string = "Home.ContactSalesRep.TrialHasExpired";
      }
      export class WeAreHappyToHelp {
        static readonly key: string = "Home.ContactSalesRep.WeAreHappyToHelp";
      }
    }
    export module Dashboard {
      export class Dashboard {
        static readonly key: string = "Home.Dashboard.Dashboard";
      }
      export class HelpText {
        static readonly key: string = "Home.Dashboard.HelpText";
      }
      export class MaximumOfResultsMayBeViewed {
        static readonly key: string =
          "Home.Dashboard.MaximumOfResultsMayBeViewed";
        maxNumberOfItemsShown: string;
      }
      export class RecordsMayBeHidden {
        static readonly key: string = "Home.Dashboard.RecordsMayBeHidden";
      }
      export module CommonTerms {
        export class DueOn {
          static readonly key: string = "Home.Dashboard.CommonTerms.DueOn";
          dueOnDate: string;
        }
        export class FilterBy {
          static readonly key: string = "Home.Dashboard.CommonTerms.FilterBy";
        }
        export class InboxSummaryReports {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.InboxSummaryReports";
        }
        export class UsersAndGroupsReports {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.UsersAndGroupsReports";
        }
        export class NumberOfToDosPlural {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.NumberOfToDosPlural";
          totalTasks: string;
        }
        export class NumberOfToDosSingular {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.NumberOfToDosSingular";
        }
        export class NumberOverdue {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.NumberOverdue";
          overdue: string;
        }
        export class NumberDueSoon {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.NumberDueSoon";
          dueSoon: string;
        }
        export class NumberOverdueAndDueSoon {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.NumberOverdueAndDueSoon";
          overdue: string;
          dueSoon: string;
        }
        export class OnTopOfToDoList {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.OnTopOfToDoList";
        }
        export class OrderBy {
          static readonly key: string = "Home.Dashboard.CommonTerms.OrderBy";
        }
        export class RunReport {
          static readonly key: string = "Home.Dashboard.CommonTerms.RunReport";
        }
        export class TryBeta {
          static readonly key: string = "Home.Dashboard.CommonTerms.TryBeta";
        }
        export class SortByDueDate {
          static readonly key: string =
            "Home.Dashboard.CommonTerms.SortByDueDate";
        }
      }
      export module Assessments {
        export class AssessmentReports {
          static readonly key: string =
            "Home.Dashboard.Assessments.AssessmentReports";
        }
        export class CurrentTasksPlural {
          static readonly key: string =
            "Home.Dashboard.Assessments.CurrentTasksPlural";
          totalTasks: string;
        }
        export class CurrentTasksSingular {
          static readonly key: string =
            "Home.Dashboard.Assessments.CurrentTasksSingular";
        }
        export class LastWorkingOn {
          static readonly key: string =
            "Home.Dashboard.Assessments.LastWorkingOn";
          spanOpening: string;
          spanClosing: string;
          subjectNameLink: string;
        }
        export class NoAssessments {
          static readonly key: string =
            "Home.Dashboard.Assessments.NoAssessments";
        }
        export class PercentEvaluated {
          static readonly key: string =
            "Home.Dashboard.Assessments.PercentEvaluated";
          percentageEvaluated: string;
          percentSign: string;
        }
        export class TasksNotSetPlural {
          static readonly key: string =
            "Home.Dashboard.Assessments.TasksNotSetPlural";
          tasksNotSet: string;
        }
        export class TasksNotSetSingular {
          static readonly key: string =
            "Home.Dashboard.Assessments.TasksNotSetSingular";
        }
        export class UnassignedTasksPlural {
          static readonly key: string =
            "Home.Dashboard.Assessments.UnassignedTasksPlural";
          unassignedTasks: string;
        }
        export class UnassignedTasksSingular {
          static readonly key: string =
            "Home.Dashboard.Assessments.UnassignedTasksSingular";
        }
      }
      export module Documents {
        export class DocumentReports {
          static readonly key: string =
            "Home.Dashboard.Documents.DocumentReports";
        }
        export class MostRecentlyPublished {
          static readonly key: string =
            "Home.Dashboard.Documents.MostRecentlyPublished";
        }
        export class MostOutstandingSignatures {
          static readonly key: string =
            "Home.Dashboard.Documents.MostOutstandingSignatures";
        }
        export class NoOutstandingDocuments {
          static readonly key: string =
            "Home.Dashboard.Documents.NoOutstandingDocuments";
        }
        export class NumberOfSignatures {
          static readonly key: string =
            "Home.Dashboard.Documents.NumberOfSignatures";
          signedDocuments: string;
          totalDocuments: string;
        }
        export class PublishedOn {
          static readonly key: string = "Home.Dashboard.Documents.PublishedOn";
          publishedOn: string;
        }
        export class SignaturesCollectedPlural {
          static readonly key: string =
            "Home.Dashboard.Documents.SignaturesCollectedPlural";
          signedCount: string;
        }
        export class SignaturesCollectedSingular {
          static readonly key: string =
            "Home.Dashboard.Documents.SignaturesCollectedSingular";
        }
        export class SignaturesNeededPlural {
          static readonly key: string =
            "Home.Dashboard.Documents.SignaturesNeededPlural";
          unsignedCount: string;
        }
        export class SignaturesNeededSingular {
          static readonly key: string =
            "Home.Dashboard.Documents.SignaturesNeededSingular";
        }
      }
      export module Groups {
        export class NoOutstandingItems {
          static readonly key: string =
            "Home.Dashboard.Groups.NoOutstandingItems";
          parentGroupName: string;
        }
        export class NumberOfUsersPlural {
          static readonly key: string =
            "Home.Dashboard.Groups.NumberOfUsersPlural";
          membersCount: string;
        }
        export class NumberOfUsersSingular {
          static readonly key: string =
            "Home.Dashboard.Groups.NumberOfUsersSingular";
        }
        export class NumberOfUsersZero {
          static readonly key: string =
            "Home.Dashboard.Groups.NumberOfUsersZero";
        }
        export class ThisGroupHas {
          static readonly key: string = "Home.Dashboard.Groups.ThisGroupHas";
        }
        export class ThisUserHas {
          static readonly key: string = "Home.Dashboard.Groups.ThisUserHas";
        }
      }
      export module Training {
        export class AwardsLabel {
          static readonly key: string = "Home.Dashboard.Training.AwardsLabel";
        }
        export class CertificateReports {
          static readonly key: string =
            "Home.Dashboard.Training.CertificateReports";
        }
        export class CourseReports {
          static readonly key: string = "Home.Dashboard.Training.CourseReports";
        }
        export class CreditHours {
          static readonly key: string = "Home.Dashboard.Training.CreditHours";
          creditHours: string;
        }
        export class DueSoon {
          static readonly key: string = "Home.Dashboard.Training.DueSoon";
          dueSoon: string;
        }
        export class NoOutstandingTrainingItems {
          static readonly key: string =
            "Home.Dashboard.Training.NoOutstandingTrainingItems";
        }
        export class SortByMostIncomplete {
          static readonly key: string =
            "Home.Dashboard.Training.SortByMostIncomplete";
        }
        export class SortByMostRecentlyCompleted {
          static readonly key: string =
            "Home.Dashboard.Training.SortByMostRecentlyCompleted";
        }
        export class TasksAwarded {
          static readonly key: string = "Home.Dashboard.Training.TasksAwarded";
          totalCompleted: string;
          totalTasks: string;
        }
        export class TasksCompleted {
          static readonly key: string =
            "Home.Dashboard.Training.TasksCompleted";
          totalCompleted: string;
          totalTasks: string;
        }
        export class TestReports {
          static readonly key: string = "Home.Dashboard.Training.TestReports";
        }
      }
      export module Workflows {
        export class ApprovalLabel {
          static readonly key: string =
            "Home.Dashboard.Workflows.ApprovalLabel";
        }
        export class CourseUserNameFormat {
          static readonly key: string =
            "Home.Dashboard.Workflows.CourseUserNameFormat";
          courseName: string;
          userName: string;
        }
        export class GoToWorkflow {
          static readonly key: string = "Home.Dashboard.Workflows.GoToWorkflow";
        }
        export class NoWorkflowsInProgress {
          static readonly key: string =
            "Home.Dashboard.Workflows.NoWorkflowsInProgress";
        }
        export class NumberOfComments {
          static readonly key: string =
            "Home.Dashboard.Workflows.NumberOfComments";
          commentCount: string;
        }
        export class ReviewLabel {
          static readonly key: string = "Home.Dashboard.Workflows.ReviewLabel";
        }
        export class ReviewExceptionLabel {
          static readonly key: string =
            "Home.Dashboard.Workflows.ReviewExceptionLabel";
        }
        export class SortByDurationOfCurrentStep {
          static readonly key: string =
            "Home.Dashboard.Workflows.SortByDurationOfCurrentStep";
        }
        export class SortByMostRecentlyStarted {
          static readonly key: string =
            "Home.Dashboard.Workflows.SortByMostRecentlyStarted";
        }
        export class StepNumberOfTotal {
          static readonly key: string =
            "Home.Dashboard.Workflows.StepNumberOfTotal";
          stepNumber: string;
          stepTotal: string;
        }
        export class StepCompletedByUserOnDate {
          static readonly key: string =
            "Home.Dashboard.Workflows.StepCompletedByUserOnDate";
          user: string;
          date: string;
        }
        export class StepCompletedByUserAndOneOtherOnDate {
          static readonly key: string =
            "Home.Dashboard.Workflows.StepCompletedByUserAndOneOtherOnDate";
          linkOpening: string;
          user: string;
          linkClosing: string;
          date: string;
        }
        export class StepCompletedByUserAndManyOthersOnDate {
          static readonly key: string =
            "Home.Dashboard.Workflows.StepCompletedByUserAndManyOthersOnDate";
          linkOpening: string;
          user: string;
          participatingUsers: string;
          linkClosing: string;
          date: string;
        }
        export class ViewAllWorkflows {
          static readonly key: string =
            "Home.Dashboard.Workflows.ViewAllWorkflows";
        }
        export class WaitingOnStep {
          static readonly key: string =
            "Home.Dashboard.Workflows.WaitingOnStep";
          stepNumber: string;
          numberOfDays: string;
        }
        export class WaitingOnUserForTimePassed {
          static readonly key: string =
            "Home.Dashboard.Workflows.WaitingOnUserForTimePassed";
          user: string;
          timePassed: string;
        }
        export class WaitingOnUserAndOneOtherForTimePassed {
          static readonly key: string =
            "Home.Dashboard.Workflows.WaitingOnUserAndOneOtherForTimePassed";
          user: string;
          timePassed: string;
        }
        export class WaitingOnUserAndManyOthersForTimePassed {
          static readonly key: string =
            "Home.Dashboard.Workflows.WaitingOnUserAndManyOthersForTimePassed";
          user: string;
          nonParticipatingUsers: string;
          timePassed: string;
        }
        export class WorkflowInitiatedBy {
          static readonly key: string =
            "Home.Dashboard.Workflows.WorkflowInitiatedBy";
          user: string;
          date: string;
        }
      }
    }
    export module Error {
      export class HmmmEllipsis {
        static readonly key: string = "Home.Error.HmmmEllipsis";
      }
      export class SomethingIsNotQuiteRight {
        static readonly key: string = "Home.Error.SomethingIsNotQuiteRight";
      }
      export class TryRefreshing {
        static readonly key: string = "Home.Error.TryRefreshing";
        linkOpening: string;
        linkClosing: string;
      }
    }
    export module QuickStart {
      export class Header {
        static readonly key: string = "Home.QuickStart.Header";
      }
      export class HaveQuestions {
        static readonly key: string = "Home.QuickStart.HaveQuestions";
      }
    }
    export module ToDo {
      export class AndNumberOtherTasks {
        static readonly key: string = "Home.ToDo.AndNumberOtherTasks";
        tasksNumber: string;
        tasksType: string;
      }
      export class AndNumberOtherTasksLink {
        static readonly key: string = "Home.ToDo.AndNumberOtherTasksLink";
        linkOpening: string;
        tasksNumber: string;
        tasksType: string;
        linkClosing: string;
      }
      export class ClickHereToSeeDetails {
        static readonly key: string = "Home.ToDo.ClickHereToSeeDetails";
      }
      export class CloseToMaxPurchasedLicenses {
        static readonly key: string = "Home.ToDo.CloseToMaxPurchasedLicenses";
      }
      export class CompletedToday {
        static readonly key: string = "Home.ToDo.CompletedToday";
      }
      export class GetStarted {
        static readonly key: string = "Home.ToDo.GetStarted";
      }
      export class NoThanks {
        static readonly key: string = "Home.ToDo.NoThanks";
      }
      export class NumberTasksAsRoleInAssessmentSingular {
        static readonly key: string =
          "Home.ToDo.NumberTasksAsRoleInAssessmentSingular";
        role: string;
        assessment: string;
      }
      export class NumberTasksAsRoleInAssessmentPlural {
        static readonly key: string =
          "Home.ToDo.NumberTasksAsRoleInAssessmentPlural";
        tasksNumber: string;
        role: string;
        assessment: string;
      }
      export class NumberTasksLinkAsRoleInAssessmentSingular {
        static readonly key: string =
          "Home.ToDo.NumberTasksLinkAsRoleInAssessmentSingular";
        linkOpening: string;
        linkClosing: string;
        role: string;
        assessment: string;
      }
      export class NumberTasksLinkAsRoleInAssessmentPlural {
        static readonly key: string =
          "Home.ToDo.NumberTasksLinkAsRoleInAssessmentPlural";
        linkOpening: string;
        tasksNumber: string;
        linkClosing: string;
        role: string;
        assessment: string;
      }
      export class ToDo {
        static readonly key: string = "Home.ToDo.ToDo";
      }
      export class YourToDoListIsPrioritized {
        static readonly key: string = "Home.ToDo.YourToDoListIsPrioritized";
      }
      export class YouveCompletedEverything {
        static readonly key: string = "Home.ToDo.YouveCompletedEverything";
      }
      export class RecallCards {
        static readonly key: string = "Home.ToDo.RecallCards";
      }
      export class AvailableCardsInfo {
        static readonly key: string = "Home.ToDo.AvailableCardsInfo";
      }
      export class CardsTakenToday {
        static readonly key: string = "Home.ToDo.CardsTakenToday";
      }
      export class CardsCompletedThisWeek {
        static readonly key: string = "Home.ToDo.CardsCompletedThisWeek";
      }
      export class RecallDeckIconAltText {
        static readonly key: string = "Home.ToDo.RecallDeckIconAltText";
      }
      export class ReviewRecallCards {
        static readonly key: string = "Home.ToDo.ReviewRecallCards";
      }
    }
    export module TreesSaved {
      export class AverageSiteNumber {
        static readonly key: string = "Home.TreesSaved.AverageSiteNumber";
        treesSaved: string;
      }
      export class CostPerReamPaper {
        static readonly key: string = "Home.TreesSaved.CostPerReamPaper";
      }
      export class CostToPrintEachPage {
        static readonly key: string = "Home.TreesSaved.CostToPrintEachPage";
      }
      export class JustGettingStarted {
        static readonly key: string = "Home.TreesSaved.JustGettingStarted";
      }
      export class LearnMore {
        static readonly key: string = "Home.TreesSaved.LearnMore";
      }
      export class PagesSaved {
        static readonly key: string = "Home.TreesSaved.PagesSaved";
      }
      export class PagesSavedIsEstimatedBy {
        static readonly key: string = "Home.TreesSaved.PagesSavedIsEstimatedBy";
      }
      export class PowerDMSSavingsCalculator {
        static readonly key: string =
          "Home.TreesSaved.PowerDMSSavingsCalculator";
      }
      export class TheDefaultCostPerPage {
        static readonly key: string = "Home.TreesSaved.TheDefaultCostPerPage";
      }
      export class TheDefaultCostPerReamOfPaper {
        static readonly key: string =
          "Home.TreesSaved.TheDefaultCostPerReamOfPaper";
      }
      export class TogetherPowerDMSUsersHaveSaved {
        static readonly key: string =
          "Home.TreesSaved.TogetherPowerDMSUsersHaveSaved";
        strongOpening: string;
        treesSaved: string;
        strongClosing: string;
      }
      export class TotalDollarsSaved {
        static readonly key: string = "Home.TreesSaved.TotalDollarsSaved";
      }
      export class TreeImgAlt {
        static readonly key: string = "Home.TreesSaved.TreeImgAlt";
      }
      export class TreesSaved {
        static readonly key: string = "Home.TreesSaved.TreesSaved";
      }
      export class TreeSproutImgAlt {
        static readonly key: string = "Home.TreesSaved.TreeSproutImgAlt";
      }
      export class TryRefreshing {
        static readonly key: string = "Home.TreesSaved.TryRefreshing";
      }
      export class UnableToSaveChanges {
        static readonly key: string = "Home.TreesSaved.UnableToSaveChanges";
      }
      export class YouHaveSavedNumberTrees {
        static readonly key: string = "Home.TreesSaved.YouHaveSavedNumberTrees";
        spanOpening: string;
        treesSaved: string;
        spanClosing: string;
      }
      export class YourSiteNumber {
        static readonly key: string = "Home.TreesSaved.YourSiteNumber";
        treesSaved: string;
      }
    }
  }
  export module IdsValidatableButton {
    export class SendValidationEmailTooltip {
      static readonly key: string =
        "IdsValidatableButton.SendValidationEmailTooltip";
    }
  }
  export module Inbox {
    export class CertificateHistory {
      static readonly key: string = "Inbox.CertificateHistory";
    }
    export class Certificates {
      static readonly key: string = "Inbox.Certificates";
    }
    export class CourseHistory {
      static readonly key: string = "Inbox.CourseHistory";
    }
    export class Courses {
      static readonly key: string = "Inbox.Courses";
    }
    export class DiscussionHistory {
      static readonly key: string = "Inbox.DiscussionHistory";
    }
    export class DocumentHistory {
      static readonly key: string = "Inbox.DocumentHistory";
    }
    export class Documents {
      static readonly key: string = "Inbox.Documents";
    }
    export class Manuals {
      static readonly key: string = "Inbox.Manuals";
    }
    export class ManualsHistory {
      static readonly key: string = "Inbox.ManualsHistory";
    }
    export class MessageDrafts {
      static readonly key: string = "Inbox.MessageDrafts";
    }
    export class MessagesReceived {
      static readonly key: string = "Inbox.MessagesReceived";
    }
    export class MessagesSent {
      static readonly key: string = "Inbox.MessagesSent";
    }
    export class SurveyHistory {
      static readonly key: string = "Inbox.SurveyHistory";
    }
    export class Surveys {
      static readonly key: string = "Inbox.Surveys";
    }
    export class TestHistory {
      static readonly key: string = "Inbox.TestHistory";
    }
    export class Tests {
      static readonly key: string = "Inbox.Tests";
    }
    export class Recall {
      static readonly key: string = "Inbox.Recall";
    }
  }
  export module Languages {
    export class SiteDefault {
      static readonly key: string = "Languages.SiteDefault";
      language: string;
    }
  }
  export module LegacyContentToolbar {
    export class GeneratedBy {
      static readonly key: string = "LegacyContentToolbar.GeneratedBy";
      platform: string;
    }
    export class InstructionsFor {
      static readonly key: string = "LegacyContentToolbar.InstructionsFor";
      instructionType: string;
      instruction: string;
    }
    export class NoRecords {
      static readonly key: string = "LegacyContentToolbar.NoRecords";
    }
    export class Origin {
      static readonly key: string = "LegacyContentToolbar.Origin";
      platform: string;
    }
    export class PrintedBy {
      static readonly key: string = "LegacyContentToolbar.PrintedBy";
      name: string;
    }
  }
  export module LegacyWorkflow {
    export class AddPosition {
      static readonly key: string = "LegacyWorkflow.AddPosition";
    }
    export class ClickToCancel {
      static readonly key: string = "LegacyWorkflow.ClickToCancel";
    }
    export class ModifyPositionConfirmation {
      static readonly key: string = "LegacyWorkflow.ModifyPositionConfirmation";
    }
    export class NewStep {
      static readonly key: string = "LegacyWorkflow.NewStep";
    }
    export class PleaseAssignGeneric {
      static readonly key: string = "LegacyWorkflow.PleaseAssignGeneric";
    }
    export class PleaseAssignMultiple {
      static readonly key: string = "LegacyWorkflow.PleaseAssignMultiple";
    }
    export class PleaseAssignUser {
      static readonly key: string = "LegacyWorkflow.PleaseAssignUser";
    }
    export class PositionExists {
      static readonly key: string = "LegacyWorkflow.PositionExists";
    }
    export class RemoveNodeConfirm {
      static readonly key: string = "LegacyWorkflow.RemoveNodeConfirm";
    }
    export class SaveSuccess {
      static readonly key: string = "LegacyWorkflow.SaveSuccess";
    }
    export class SaveFailure {
      static readonly key: string = "LegacyWorkflow.SaveFailure";
      error: string;
    }
    export class StartPositionExists {
      static readonly key: string = "LegacyWorkflow.StartPositionExists";
    }
    export module Abbreviations {
      export class Group {
        static readonly key: string = "LegacyWorkflow.Abbreviations.Group";
      }
      export class JobTitle {
        static readonly key: string = "LegacyWorkflow.Abbreviations.JobTitle";
      }
      export class Location {
        static readonly key: string = "LegacyWorkflow.Abbreviations.Location";
      }
      export class User {
        static readonly key: string = "LegacyWorkflow.Abbreviations.User";
      }
    }
    export module Options {
      export class AllResponse {
        static readonly key: string = "LegacyWorkflow.Options.AllResponse";
      }
      export class FirstResponse {
        static readonly key: string = "LegacyWorkflow.Options.FirstResponse";
      }
      export class Flex {
        static readonly key: string = "LegacyWorkflow.Options.Flex";
      }
      export class SimpleVote {
        static readonly key: string = "LegacyWorkflow.Options.SimpleVote";
      }
    }
  }
  export module Licensing {
    export module Message {
      export class CloseToMax {
        static readonly key: string = "Licensing.Message.CloseToMax";
      }
      export class CloseToOrAtMax {
        static readonly key: string = "Licensing.Message.CloseToOrAtMax";
      }
      export class CloseToRecommendationUsers {
        static readonly key: string =
          "Licensing.Message.CloseToRecommendationUsers";
      }
      export class CloseToRecommendationAssessments {
        static readonly key: string =
          "Licensing.Message.CloseToRecommendationAssessments";
      }
      export class ExceedingMax {
        static readonly key: string = "Licensing.Message.ExceedingMax";
      }
      export class ExceedingMaxContinue {
        static readonly key: string = "Licensing.Message.ExceedingMaxContinue";
      }
      export class ExceedingRecommendationUsers {
        static readonly key: string =
          "Licensing.Message.ExceedingRecommendationUsers";
      }
      export class ExceedingRecommendationAssessments {
        static readonly key: string =
          "Licensing.Message.ExceedingRecommendationAssessments";
      }
      export class LicensingLink {
        static readonly key: string = "Licensing.Message.LicensingLink";
        linkOpening: string;
        linkClosing: string;
      }
    }
  }
  export module LiveSearch {
    export class Clear {
      static readonly key: string = "LiveSearch.Clear";
    }
    export class Close {
      static readonly key: string = "LiveSearch.Close";
    }
    export class Create {
      static readonly key: string = "LiveSearch.Create";
      label: string;
    }
    export class Edit {
      static readonly key: string = "LiveSearch.Edit";
      label: string;
    }
    export class Open {
      static readonly key: string = "LiveSearch.Open";
    }
    export class Remove {
      static readonly key: string = "LiveSearch.Remove";
      label: string;
    }
  }
  export module LoadingPage {
    export class NewSection {
      static readonly key: string = "LoadingPage.NewSection";
    }
    export class ObjectType {
      static readonly key: string = "LoadingPage.ObjectType";
      boldedObjectName: string;
    }
    export class PageTitle {
      static readonly key: string = "LoadingPage.PageTitle";
      boldedPageTitle: string;
    }
    export class User {
      static readonly key: string = "LoadingPage.User";
      boldHTMLTag: string;
      surname: string;
      givenName: string;
      endBoldHTMLTag: string;
    }
  }
  export module Login {
    export class EmailValidation {
      static readonly key: string = "Login.EmailValidation";
    }
    export class SiteKeyInfo {
      static readonly key: string = "Login.SiteKeyInfo";
    }
    export class SiteKeyInfoTitle {
      static readonly key: string = "Login.SiteKeyInfoTitle";
    }
  }
  export module Manuals {
    export class NewManualTitle {
      static readonly key: string = "Manuals.NewManualTitle";
    }
    export class ManualTitle {
      static readonly key: string = "Manuals.ManualTitle";
    }
    export class ViewManual {
      static readonly key: string = "Manuals.ViewManual";
    }
    export module Tabs {
      export class Assessments {
        static readonly key: string = "Manuals.Tabs.Assessments";
      }
      export class AssessmentsRoles {
        static readonly key: string = "Manuals.Tabs.AssessmentsRoles";
      }
      export class AssessmentsRoleKeys {
        static readonly key: string = "Manuals.Tabs.AssessmentsRoleKeys";
      }
      export class AssessmentsSearch {
        static readonly key: string = "Manuals.Tabs.AssessmentsSearch";
      }
      export class AssessmentsStatuses {
        static readonly key: string = "Manuals.Tabs.AssessmentsStatuses";
      }
      export class General {
        static readonly key: string = "Manuals.Tabs.General";
      }
      export class Revisions {
        static readonly key: string = "Manuals.Tabs.Revisions";
      }
      export class Security {
        static readonly key: string = "Manuals.Tabs.Security";
      }
      export class Style {
        static readonly key: string = "Manuals.Tabs.Style";
      }
      export class StyleStandard {
        static readonly key: string = "Manuals.Tabs.StyleStandard";
      }
      export class StyleInformation {
        static readonly key: string = "Manuals.Tabs.StyleInformation";
      }
    }
  }
  export module PowMultiselect {
    export class NumberSelected {
      static readonly key: string = "PowMultiselect.NumberSelected";
      number: string;
    }
    export class SelectOptions {
      static readonly key: string = "PowMultiselect.SelectOptions";
    }
  }
  export module PowNotification {
    export class Refresh {
      static readonly key: string = "PowNotification.Refresh";
    }
    export class Retry {
      static readonly key: string = "PowNotification.Retry";
    }
    export class ConnectedToPowerDMS {
      static readonly key: string = "PowNotification.ConnectedToPowerDMS";
    }
    export class DocumentBookmarked {
      static readonly key: string = "PowNotification.DocumentBookmarked";
    }
    export class BookmarkRemoved {
      static readonly key: string = "PowNotification.BookmarkRemoved";
    }
    export class DocumentLinkCopySuccess {
      static readonly key: string = "PowNotification.DocumentLinkCopySuccess";
    }
    export class DocumentLinkCopyFail {
      static readonly key: string = "PowNotification.DocumentLinkCopyFail";
    }
    export class FeatureActivationError {
      static readonly key: string = "PowNotification.FeatureActivationError";
    }
    export class FeatureActived {
      static readonly key: string = "PowNotification.FeatureActived";
      feature: string;
    }
    export class FeatureAlreadyEnabled {
      static readonly key: string = "PowNotification.FeatureAlreadyEnabled";
      feature: string;
    }
    export class NetworkConfigMayBeBlockingPowerDMSFeatures {
      static readonly key: string =
        "PowNotification.NetworkConfigMayBeBlockingPowerDMSFeatures";
    }
    export class PleaseContactSupport {
      static readonly key: string = "PowNotification.PleaseContactSupport";
      phoneNumber: string;
      email: string;
    }
    export class PowerDMSFailedToLoadProperly {
      static readonly key: string =
        "PowNotification.PowerDMSFailedToLoadProperly";
    }
    export class PowerDMSUpdateIsAvailable {
      static readonly key: string = "PowNotification.PowerDMSUpdateIsAvailable";
    }
    export class PowerDMSUpdateIsAvailableForced {
      static readonly key: string =
        "PowNotification.PowerDMSUpdateIsAvailableForced";
    }
    export class SiteConnected {
      static readonly key: string = "PowNotification.SiteConnected";
      siteName: string;
    }
    export class TryingToReconnect {
      static readonly key: string = "PowNotification.TryingToReconnect";
    }
    export class YouAppearToBeOffline {
      static readonly key: string = "PowNotification.YouAppearToBeOffline";
    }
    export class SiteModified {
      static readonly key: string = "PowNotification.SiteModified";
    }
    export class LogOut {
      static readonly key: string = "PowNotification.LogOut";
    }
  }
  export module PowValidation {
    export class InvalidDate {
      static readonly key: string = "PowValidation.InvalidDate";
    }
    export class InvalidEmail {
      static readonly key: string = "PowValidation.InvalidEmail";
    }
    export class NetworkError {
      static readonly key: string = "PowValidation.NetworkError";
      strong: string;
      endStrong: string;
    }
    export class RequiredField {
      static readonly key: string = "PowValidation.RequiredField";
    }
    export class TooLong {
      static readonly key: string = "PowValidation.TooLong";
    }
    export class TooShort {
      static readonly key: string = "PowValidation.TooShort";
    }
    export module PasswordRequirements {
      export class CriteriaHeader {
        static readonly key: string =
          "PowValidation.PasswordRequirements.CriteriaHeader";
      }
      export class Length {
        static readonly key: string =
          "PowValidation.PasswordRequirements.Length";
        minimumLength: string;
      }
      export class Lowercase {
        static readonly key: string =
          "PowValidation.PasswordRequirements.Lowercase";
      }
      export class OneNumber {
        static readonly key: string =
          "PowValidation.PasswordRequirements.OneNumber";
      }
      export class PersonalInfo {
        static readonly key: string =
          "PowValidation.PasswordRequirements.PersonalInfo";
      }
      export class Symbol {
        static readonly key: string =
          "PowValidation.PasswordRequirements.Symbol";
      }
      export class Uppercase {
        static readonly key: string =
          "PowValidation.PasswordRequirements.Uppercase";
      }
      export class UppercaseLowercase {
        static readonly key: string =
          "PowValidation.PasswordRequirements.UppercaseLowercase";
      }
    }
    export module SiteKey {
      export class CheckingForAvailability {
        static readonly key: string =
          "PowValidation.SiteKey.CheckingForAvailability";
        strong: string;
        endStrong: string;
      }
      export class NonUniqueError {
        static readonly key: string = "PowValidation.SiteKey.NonUniqueError";
        strong: string;
        endStrong: string;
      }
      export class UniqueSuccess {
        static readonly key: string = "PowValidation.SiteKey.UniqueSuccess";
        strong: string;
        endStrong: string;
      }
    }
  }
  export module Navigation {
    export module SideMenu {
      export class Admin {
        static readonly key: string = "Navigation.SideMenu.Admin";
      }
      export class AddFilterSuggestion {
        static readonly key: string = "Navigation.SideMenu.AddFilterSuggestion";
      }
      export class AlsoFoundIn {
        static readonly key: string = "Navigation.SideMenu.AlsoFoundIn";
      }
      export class AndOneMoreGroup {
        static readonly key: string = "Navigation.SideMenu.AndOneMoreGroup";
      }
      export class AndXMoreGroups {
        static readonly key: string = "Navigation.SideMenu.AndXMoreGroups";
        count: string;
      }
      export class Bookmark {
        static readonly key: string = "Navigation.SideMenu.Bookmark";
      }
      export class BookmarkHelpText {
        static readonly key: string = "Navigation.SideMenu.BookmarkHelpText";
        iconMarkup: string;
      }
      export class BookmarkItemTooltip {
        static readonly key: string = "Navigation.SideMenu.BookmarkItemTooltip";
      }
      export class BookmarksPanel {
        static readonly key: string = "Navigation.SideMenu.BookmarksPanel";
      }
      export class BookmarksSaveError {
        static readonly key: string = "Navigation.SideMenu.BookmarksSaveError";
      }
      export class BookmarksTitle {
        static readonly key: string = "Navigation.SideMenu.BookmarksTitle";
      }
      export class ChangesMayHaveBeenMade {
        static readonly key: string =
          "Navigation.SideMenu.ChangesMayHaveBeenMade";
      }
      export class ClosedItem {
        static readonly key: string = "Navigation.SideMenu.ClosedItem";
        itemName: string;
      }
      export class ConnectContentProvider {
        static readonly key: string =
          "Navigation.SideMenu.ConnectContentProvider";
      }
      export class ContentHubSummarizedResultsPanel {
        static readonly key: string =
          "Navigation.SideMenu.ContentHubSummarizedResultsPanel";
      }
      export class ContentHubProviderResults {
        static readonly key: string =
          "Navigation.SideMenu.ContentHubProviderResults";
        numResults: string;
      }
      export class CopyNotification {
        static readonly key: string = "Navigation.SideMenu.CopyNotification";
        item: string;
        oldLocation: string;
        newLocation: string;
      }
      export class CreateFolder {
        static readonly key: string = "Navigation.SideMenu.CreateFolder";
      }
      export class CreatingFolder {
        static readonly key: string = "Navigation.SideMenu.CreatingFolder";
      }
      export class DidYouMean {
        static readonly key: string = "Navigation.SideMenu.DidYouMean";
        startWrapper: string;
        endWrapper: string;
        suggestion: string;
      }
      export class DidYouMeanTooltip {
        static readonly key: string = "Navigation.SideMenu.DidYouMeanTooltip";
        suggestion: string;
      }
      export class Drafted {
        static readonly key: string = "Navigation.SideMenu.Drafted";
        date: string;
      }
      export class FilesPanel {
        static readonly key: string = "Navigation.SideMenu.FilesPanel";
      }
      export class FindAnything {
        static readonly key: string = "Navigation.SideMenu.FindAnything";
      }
      export class FolderName {
        static readonly key: string = "Navigation.SideMenu.FolderName";
      }
      export class HomePage {
        static readonly key: string = "Navigation.SideMenu.HomePage";
      }
      export class JobTitleLocation {
        static readonly key: string = "Navigation.SideMenu.JobTitleLocation";
        jobTitle: string;
        location: string;
      }
      export class LoadFailed {
        static readonly key: string = "Navigation.SideMenu.LoadFailed";
      }
      export class LoadingAriaLabel {
        static readonly key: string = "Navigation.SideMenu.LoadingAriaLabel";
      }
      export class ManualAndRevision {
        static readonly key: string = "Navigation.SideMenu.ManualAndRevision";
        manual: string;
        revision: string;
      }
      export class ManualCurrentDraft {
        static readonly key: string = "Navigation.SideMenu.ManualCurrentDraft";
      }
      export class ManualRevisions {
        static readonly key: string = "Navigation.SideMenu.ManualRevisions";
      }
      export class AddChapter {
        static readonly key: string = "Navigation.SideMenu.AddChapter";
      }
      export class AddInformation {
        static readonly key: string = "Navigation.SideMenu.AddInformation";
      }
      export class AddStandard {
        static readonly key: string = "Navigation.SideMenu.AddStandard";
      }
      export class DeleteList {
        static readonly key: string = "Navigation.SideMenu.DeleteList";
      }
      export class MoveItemUp {
        static readonly key: string = "Navigation.SideMenu.MoveItemUp";
      }
      export class MoveItemDown {
        static readonly key: string = "Navigation.SideMenu.MoveItemDown";
      }
      export class MasterAdmin {
        static readonly key: string = "Navigation.SideMenu.MasterAdmin";
      }
      export class MemberOfXGroups {
        static readonly key: string = "Navigation.SideMenu.MemberOfXGroups";
        count: string;
      }
      export class MembersOfGroup {
        static readonly key: string = "Navigation.SideMenu.MembersOfGroup";
        groupName: string;
      }
      export class MoveNotification {
        static readonly key: string = "Navigation.SideMenu.MoveNotification";
        item: string;
        oldLocation: string;
        newLocation: string;
      }
      export class NavigationAccessibilityName {
        static readonly key: string =
          "Navigation.SideMenu.NavigationAccessibilityName";
      }
      export class NavigatedToFirstItem {
        static readonly key: string =
          "Navigation.SideMenu.NavigatedToFirstItem";
      }
      export class NavigatedToLastItem {
        static readonly key: string = "Navigation.SideMenu.NavigatedToLastItem";
      }
      export class OpenedItem {
        static readonly key: string = "Navigation.SideMenu.OpenedItem";
        itemName: string;
      }
      export class OpenedSiblingFolders {
        static readonly key: string =
          "Navigation.SideMenu.OpenedSiblingFolders";
      }
      export class OneOpenAssessment {
        static readonly key: string = "Navigation.SideMenu.OneOpenAssessment";
      }
      export class OneOpenSections {
        static readonly key: string = "Navigation.SideMenu.OneOpenSections";
      }
      export class NewDocument {
        static readonly key: string = "Navigation.SideMenu.NewDocument";
      }
      export class NewDocumentPlaceholder {
        static readonly key: string =
          "Navigation.SideMenu.NewDocumentPlaceholder";
      }
      export class NewFolder {
        static readonly key: string = "Navigation.SideMenu.NewFolder";
      }
      export class NoBookmarks {
        static readonly key: string = "Navigation.SideMenu.NoBookmarks";
      }
      export class NoItemsToDisplay {
        static readonly key: string = "Navigation.SideMenu.NoItemsToDisplay";
        itemTypes: string;
      }
      export class NoOpenSections {
        static readonly key: string = "Navigation.SideMenu.NoOpenSections";
      }
      export class NoResults {
        static readonly key: string = "Navigation.SideMenu.NoResults";
      }
      export class NumberOfResults {
        static readonly key: string = "Navigation.SideMenu.NumberOfResults";
        boldOpen: string;
        rangeStart: string;
        rangeEnd: string;
        boldClose: string;
        totalCount: string;
      }
      export class Pin {
        static readonly key: string = "Navigation.SideMenu.Pin";
      }
      export class PowerRecall {
        static readonly key: string = "Navigation.SideMenu.PowerRecall";
      }
      export class Published {
        static readonly key: string = "Navigation.SideMenu.Published";
        date: string;
      }
      export class ProblemConnecting {
        static readonly key: string = "Navigation.SideMenu.ProblemConnecting";
        lineBreak: string;
        secondsToWait: string;
      }
      export class ProblemConnecting1 {
        static readonly key: string = "Navigation.SideMenu.ProblemConnecting1";
      }
      export class ProblemConnecting2 {
        static readonly key: string = "Navigation.SideMenu.ProblemConnecting2";
        secondsToWait: string;
      }
      export class ReloadButton {
        static readonly key: string = "Navigation.SideMenu.ReloadButton";
      }
      export class RemoveFilter {
        static readonly key: string = "Navigation.SideMenu.RemoveFilter";
      }
      export class RemoveBookmarkTooltip {
        static readonly key: string =
          "Navigation.SideMenu.RemoveBookmarkTooltip";
      }
      export class SearchPanel {
        static readonly key: string = "Navigation.SideMenu.SearchPanel";
      }
      export class SearchBoxAriaLabel {
        static readonly key: string = "Navigation.SideMenu.SearchBoxAriaLabel";
      }
      export class TryAgain {
        static readonly key: string = "Navigation.SideMenu.TryAgain";
      }
      export class Unbookmark {
        static readonly key: string = "Navigation.SideMenu.Unbookmark";
      }
      export class UndoButton {
        static readonly key: string = "Navigation.SideMenu.UndoButton";
      }
      export class Unpin {
        static readonly key: string = "Navigation.SideMenu.Unpin";
      }
      export class UsersAndGroupsPanel {
        static readonly key: string = "Navigation.SideMenu.UsersAndGroupsPanel";
      }
      export class ReportsPanel {
        static readonly key: string = "Navigation.SideMenu.ReportsPanel";
      }
      export class XOpenAssessments {
        static readonly key: string = "Navigation.SideMenu.XOpenAssessments";
        count: string;
      }
      export class XOpenSections {
        static readonly key: string = "Navigation.SideMenu.XOpenSections";
        count: string;
      }
      export class CollapseAll {
        static readonly key: string = "Navigation.SideMenu.CollapseAll";
      }
      export class ExpandAll {
        static readonly key: string = "Navigation.SideMenu.ExpandAll";
      }
      export module Errors {
        export class CantUndoPermissions {
          static readonly key: string =
            "Navigation.SideMenu.Errors.CantUndoPermissions";
        }
        export class Cycle {
          static readonly key: string = "Navigation.SideMenu.Errors.Cycle";
        }
        export class DeepLink {
          static readonly key: string = "Navigation.SideMenu.Errors.DeepLink";
        }
        export class FolderSameName {
          static readonly key: string =
            "Navigation.SideMenu.Errors.FolderSameName";
        }
        export class NoInternet {
          static readonly key: string = "Navigation.SideMenu.Errors.NoInternet";
        }
      }
    }
    export module TopMenu {
      export class AdministrationMenu {
        static readonly key: string = "Navigation.TopMenu.AdministrationMenu";
      }
      export class AgentsUnavailable {
        static readonly key: string = "Navigation.TopMenu.AgentsUnavailable";
      }
      export class CertificatesCount {
        static readonly key: string = "Navigation.TopMenu.CertificatesCount";
        count: string;
      }
      export class Champions {
        static readonly key: string = "Navigation.TopMenu.Champions";
      }
      export class AccountSettings {
        static readonly key: string = "Navigation.TopMenu.AccountSettings";
      }
      export class Chat {
        static readonly key: string = "Navigation.TopMenu.Chat";
      }
      export class CoursesCount {
        static readonly key: string = "Navigation.TopMenu.CoursesCount";
        count: string;
      }
      export class DocumentsCount {
        static readonly key: string = "Navigation.TopMenu.DocumentsCount";
        count: string;
      }
      export class InboxCount {
        static readonly key: string = "Navigation.TopMenu.InboxCount";
        count: string;
      }
      export class LanguageLabel {
        static readonly key: string = "Navigation.TopMenu.LanguageLabel";
      }
      export class MessagesCount {
        static readonly key: string = "Navigation.TopMenu.MessagesCount";
        count: string;
      }
      export class MultipleNewCertificates {
        static readonly key: string =
          "Navigation.TopMenu.MultipleNewCertificates";
        numItems: number;
      }
      export class MultipleNewCourses {
        static readonly key: string = "Navigation.TopMenu.MultipleNewCourses";
        numItems: number;
      }
      export class MultipleNewDocuments {
        static readonly key: string = "Navigation.TopMenu.MultipleNewDocuments";
        numItems: number;
      }
      export class MultipleNewDocumentActions {
        static readonly key: string =
          "Navigation.TopMenu.MultipleNewDocumentActions";
        numItems: number;
      }
      export class MultipleNewMessages {
        static readonly key: string = "Navigation.TopMenu.MultipleNewMessages";
        numItems: number;
      }
      export class MultipleNewStandards {
        static readonly key: string = "Navigation.TopMenu.MultipleNewStandards";
        numItems: number;
      }
      export class MultipleNewSurveys {
        static readonly key: string = "Navigation.TopMenu.MultipleNewSurveys";
        numItems: number;
      }
      export class MultipleNewTests {
        static readonly key: string = "Navigation.TopMenu.MultipleNewTests";
        numItems: number;
      }
      export class MyProfile {
        static readonly key: string = "Navigation.TopMenu.MyProfile";
      }
      export class NavigationAccessibilityName {
        static readonly key: string =
          "Navigation.TopMenu.NavigationAccessibilityName";
      }
      export class NewMessageTitle {
        static readonly key: string = "Navigation.TopMenu.NewMessageTitle";
      }
      export class OneNewCertificate {
        static readonly key: string = "Navigation.TopMenu.OneNewCertificate";
      }
      export class OneNewCourse {
        static readonly key: string = "Navigation.TopMenu.OneNewCourse";
      }
      export class OneNewDocument {
        static readonly key: string = "Navigation.TopMenu.OneNewDocument";
      }
      export class OneNewDocumentAction {
        static readonly key: string = "Navigation.TopMenu.OneNewDocumentAction";
      }
      export class OneNewMessage {
        static readonly key: string = "Navigation.TopMenu.OneNewMessage";
      }
      export class OneNewStandard {
        static readonly key: string = "Navigation.TopMenu.OneNewStandard";
      }
      export class OneNewSurvey {
        static readonly key: string = "Navigation.TopMenu.OneNewSurvey";
      }
      export class OneNewTest {
        static readonly key: string = "Navigation.TopMenu.OneNewTest";
      }
      export class Reports {
        static readonly key: string = "Navigation.TopMenu.Reports";
      }
      export class Analytics {
        static readonly key: string = "Navigation.TopMenu.Analytics";
      }
      export class ReportsBetaLabel {
        static readonly key: string = "Navigation.TopMenu.ReportsBetaLabel";
      }
      export class ShowMeHow {
        static readonly key: string = "Navigation.TopMenu.ShowMeHow";
      }
      export class SiteKeyLabel {
        static readonly key: string = "Navigation.TopMenu.SiteKeyLabel";
      }
      export class StandardsCount {
        static readonly key: string = "Navigation.TopMenu.StandardsCount";
        count: string;
      }
      export class SuccessCommunity {
        static readonly key: string = "Navigation.TopMenu.SuccessCommunity";
      }
      export class University {
        static readonly key: string = "Navigation.TopMenu.University";
      }
      export class SurveysCount {
        static readonly key: string = "Navigation.TopMenu.SurveysCount";
        count: string;
      }
      export class TestsCount {
        static readonly key: string = "Navigation.TopMenu.TestsCount";
        count: string;
      }
      export class ViewInbox {
        static readonly key: string = "Navigation.TopMenu.ViewInbox";
      }
      export class WelcomeUser {
        static readonly key: string = "Navigation.TopMenu.WelcomeUser";
        userName: string;
      }
      export class RecallCount {
        static readonly key: string = "Navigation.TopMenu.RecallCount";
        count: string;
      }
    }
  }
  export module Notifications {
    export class Title {
      static readonly key: string = "Notifications.Title";
    }
    export class NoNotifications {
      static readonly key: string = "Notifications.NoNotifications";
    }
    export class DismissAll {
      static readonly key: string = "Notifications.DismissAll";
    }
  }
  export module ProductDetails {
    export class Title {
      static readonly key: string = "ProductDetails.Title";
    }
  }
  export module Providers {
    export class ContentHub {
      static readonly key: string = "Providers.ContentHub";
    }
    export class ContentHubAbbreviation {
      static readonly key: string = "Providers.ContentHubAbbreviation";
    }
    export class GoogleDrive {
      static readonly key: string = "Providers.GoogleDrive";
    }
    export class OneDrive {
      static readonly key: string = "Providers.OneDrive";
    }
  }
  export module PublicDocs {
    export class ContactSales {
      static readonly key: string = "PublicDocs.ContactSales";
      email: string;
    }
    export class PageHeader {
      static readonly key: string = "PublicDocs.PageHeader";
    }
    export class LoadErrorMessage {
      static readonly key: string = "PublicDocs.LoadErrorMessage";
      linkStart: string;
      linkEnd: string;
    }
    export class DocumentMissingMessage {
      static readonly key: string = "PublicDocs.DocumentMissingMessage";
    }
    export class EmptyMessage {
      static readonly key: string = "PublicDocs.EmptyMessage";
    }
    export class Menu {
      static readonly key: string = "PublicDocs.Menu";
    }
    export class NoSiteFound {
      static readonly key: string = "PublicDocs.NoSiteFound";
    }
    export class OrgDoesntHavePubDocs {
      static readonly key: string = "PublicDocs.OrgDoesntHavePubDocs";
    }
    export class PageDoesNotExist {
      static readonly key: string = "PublicDocs.PageDoesNotExist";
    }
    export class PoweredBy {
      static readonly key: string = "PublicDocs.PoweredBy";
    }
    export class PublicDocsDirectory {
      static readonly key: string = "PublicDocs.PublicDocsDirectory";
    }
  }
  export module RadTools {
    export module LinkManager {
      export class TypeCustomTarget {
        static readonly key: string = "RadTools.LinkManager.TypeCustomTarget";
      }
    }
    export module MergeFields {
      export class ChangesNeeded {
        static readonly key: string = "RadTools.MergeFields.ChangesNeeded";
        count: string;
      }
      export class UpdateConfirmation {
        static readonly key: string = "RadTools.MergeFields.UpdateConfirmation";
      }
    }
  }
  export module Reports {
    export class ReportBuilder {
      static readonly key: string = "Reports.ReportBuilder";
    }
  }
  export module Search {
    export class TaggedWith {
      static readonly key: string = "Search.TaggedWith";
      tag: string;
    }
    export class ErrorSearching {
      static readonly key: string = "Search.ErrorSearching";
    }
    export class EnterCreation {
      static readonly key: string = "Search.EnterCreation";
    }
    export class NoResults {
      static readonly key: string = "Search.NoResults";
    }
    export class CourseNumberName {
      static readonly key: string = "Search.CourseNumberName";
      courseNumber: string;
      courseName: string;
    }
    export class StartTyping {
      static readonly key: string = "Search.StartTyping";
    }
    export class ShowingResultsRange {
      static readonly key: string = "Search.ShowingResultsRange";
      numberShown: string;
      totalResults: string;
    }
    export module DisabledReason {
      export class LinkedGroup {
        static readonly key: string = "Search.DisabledReason.LinkedGroup";
      }
      export class CycleError {
        static readonly key: string = "Search.DisabledReason.CycleError";
      }
      export class RequireEditCascading {
        static readonly key: string =
          "Search.DisabledReason.RequireEditCascading";
      }
    }
  }
  export module Ssa {
    export class AppleBadgeAlt {
      static readonly key: string = "Ssa.AppleBadgeAlt";
    }
    export class ContinueInBrowser {
      static readonly key: string = "Ssa.ContinueInBrowser";
    }
    export class Copyright {
      static readonly key: string = "Ssa.Copyright";
      currentYear: string;
    }
    export class CreateAccount {
      static readonly key: string = "Ssa.CreateAccount";
    }
    export class CreateYourAccount {
      static readonly key: string = "Ssa.CreateYourAccount";
    }
    export class GenericHeader {
      static readonly key: string = "Ssa.GenericHeader";
    }
    export class GenericMessage {
      static readonly key: string = "Ssa.GenericMessage";
    }
    export class GoogleBadgeAlt {
      static readonly key: string = "Ssa.GoogleBadgeAlt";
    }
    export class HidePassword {
      static readonly key: string = "Ssa.HidePassword";
    }
    export class NeedHelpHeader {
      static readonly key: string = "Ssa.NeedHelpHeader";
    }
    export class NeedHelpMessage {
      static readonly key: string = "Ssa.NeedHelpMessage";
      phoneNumber: string;
      email: string;
    }
    export class NotYouLink {
      static readonly key: string = "Ssa.NotYouLink";
    }
    export class NotYouHeader {
      static readonly key: string = "Ssa.NotYouHeader";
    }
    export class NotYouBody {
      static readonly key: string = "Ssa.NotYouBody";
      email: string;
      phoneNumber: string;
    }
    export class InvalidTokenHeader {
      static readonly key: string = "Ssa.InvalidTokenHeader";
    }
    export class InvalidTokenMessage {
      static readonly key: string = "Ssa.InvalidTokenMessage";
    }
    export class ReturnToLogin {
      static readonly key: string = "Ssa.ReturnToLogin";
    }
    export class ShowPassword {
      static readonly key: string = "Ssa.ShowPassword";
    }
    export class TokenExpiredHeader {
      static readonly key: string = "Ssa.TokenExpiredHeader";
    }
    export class TokenExpiredMessage {
      static readonly key: string = "Ssa.TokenExpiredMessage";
    }
    export class VisitPowerDms {
      static readonly key: string = "Ssa.VisitPowerDms";
    }
    export class YouHaveJoinedOthers {
      static readonly key: string = "Ssa.YouHaveJoinedOthers";
    }
    export class WelcomeToPowerDms {
      static readonly key: string = "Ssa.WelcomeToPowerDms";
    }
    export class WorkEmailAddressLabel {
      static readonly key: string = "Ssa.WorkEmailAddressLabel";
    }
    export class EightCharsOrMore {
      static readonly key: string = "Ssa.EightCharsOrMore";
    }
    export class UpperAndLowercase {
      static readonly key: string = "Ssa.UpperAndLowercase";
    }
    export class OneNumberOrMore {
      static readonly key: string = "Ssa.OneNumberOrMore";
    }
    export class NoPersonalInfo {
      static readonly key: string = "Ssa.NoPersonalInfo";
    }
    export class UsernameConstraints {
      static readonly key: string = "Ssa.UsernameConstraints";
    }
  }
  export module Signup {
    export class AdhereToStandards {
      static readonly key: string = "Signup.AdhereToStandards";
      optionalStart: string;
      optionalEnd: string;
    }
    export class Copyright {
      static readonly key: string = "Signup.Copyright";
      copyrightSymbol: string;
      currentYear: string;
    }
    export class DropdownPlaceholder {
      static readonly key: string = "Signup.DropdownPlaceholder";
    }
    export class FirstNamePlaceholder {
      static readonly key: string = "Signup.FirstNamePlaceholder";
    }
    export class IndustryLabel {
      static readonly key: string = "Signup.IndustryLabel";
    }
    export class IndustryError {
      static readonly key: string = "Signup.IndustryError";
    }
    export class InvalidLocation {
      static readonly key: string = "Signup.InvalidLocation";
      phoneNumber: string;
    }
    export class LastNamePlaceholder {
      static readonly key: string = "Signup.LastNamePlaceholder";
    }
    export class LetsGetStarted {
      static readonly key: string = "Signup.LetsGetStarted";
    }
    export class LoggingIn {
      static readonly key: string = "Signup.LoggingIn";
    }
    export class LogInToYourTrial {
      static readonly key: string = "Signup.LogInToYourTrial";
    }
    export class NameLabel {
      static readonly key: string = "Signup.NameLabel";
    }
    export class OneMoreToGo {
      static readonly key: string = "Signup.OneMoreToGo";
    }
    export class OrganizationNameLabel {
      static readonly key: string = "Signup.OrganizationNameLabel";
    }
    export class OrganizationNamePlaceholder {
      static readonly key: string = "Signup.OrganizationNamePlaceholder";
    }
    export class OrganizationSizeLabel {
      static readonly key: string = "Signup.OrganizationSizeLabel";
    }
    export class PasswordExplanation {
      static readonly key: string = "Signup.PasswordExplanation";
    }
    export class PasswordLabel {
      static readonly key: string = "Signup.PasswordLabel";
    }
    export class SitekeyError {
      static readonly key: string = "Signup.SitekeyError";
    }
    export class SitekeyExplanation {
      static readonly key: string = "Signup.SitekeyExplanation";
    }
    export class SitekeyLabel {
      static readonly key: string = "Signup.SitekeyLabel";
    }
    export class StartYourTrial {
      static readonly key: string = "Signup.StartYourTrial";
    }
    export class Step1Title {
      static readonly key: string = "Signup.Step1Title";
    }
    export class Step2Title {
      static readonly key: string = "Signup.Step2Title";
    }
    export class Step3Title {
      static readonly key: string = "Signup.Step3Title";
    }
    export class TimezoneExplanation {
      static readonly key: string = "Signup.TimezoneExplanation";
    }
    export class TermsOfService {
      static readonly key: string = "Signup.TermsOfService";
      linkStart: string;
      linkEnd: string;
    }
    export class TimezoneLabel {
      static readonly key: string = "Signup.TimezoneLabel";
    }
    export class UsernameExplanation {
      static readonly key: string = "Signup.UsernameExplanation";
    }
    export class UsernameLabel {
      static readonly key: string = "Signup.UsernameLabel";
    }
    export class ViewErrorDetails {
      static readonly key: string = "Signup.ViewErrorDetails";
      linkStart: string;
      linkEnd: string;
    }
    export class ViewPrivacyPolicy {
      static readonly key: string = "Signup.ViewPrivacyPolicy";
    }
    export class WhatCanIDo {
      static readonly key: string = "Signup.WhatCanIDo";
    }
    export class WhatCanIDoBullet1 {
      static readonly key: string = "Signup.WhatCanIDoBullet1";
    }
    export class WhatCanIDoBullet2 {
      static readonly key: string = "Signup.WhatCanIDoBullet2";
    }
    export class WhatCanIDoBullet3 {
      static readonly key: string = "Signup.WhatCanIDoBullet3";
    }
    export class WhatCanIDoBullet4 {
      static readonly key: string = "Signup.WhatCanIDoBullet4";
    }
    export class WhatCanIDoBullet5 {
      static readonly key: string = "Signup.WhatCanIDoBullet5";
    }
    export class WhatProblem {
      static readonly key: string = "Signup.WhatProblem";
      optionalStart: string;
      optionalEnd: string;
    }
    export class WorkEmailLabel {
      static readonly key: string = "Signup.WorkEmailLabel";
    }
    export class WorkEmailPlaceholder {
      static readonly key: string = "Signup.WorkEmailPlaceholder";
    }
    export module Industries {
      export class Banking {
        static readonly key: string = "Signup.Industries.Banking";
      }
      export class Corrections {
        static readonly key: string = "Signup.Industries.Corrections";
      }
      export class Education {
        static readonly key: string = "Signup.Industries.Education";
      }
      export class Energy {
        static readonly key: string = "Signup.Industries.Energy";
      }
      export class Entertainment {
        static readonly key: string = "Signup.Industries.Entertainment";
      }
      export class Fire {
        static readonly key: string = "Signup.Industries.Fire";
      }
      export class Government {
        static readonly key: string = "Signup.Industries.Government";
      }
      export class Healthcare {
        static readonly key: string = "Signup.Industries.Healthcare";
      }
      export class Hospitality {
        static readonly key: string = "Signup.Industries.Hospitality";
      }
      export class Insurance {
        static readonly key: string = "Signup.Industries.Insurance";
      }
      export class LawEnforcement {
        static readonly key: string = "Signup.Industries.LawEnforcement";
      }
      export class Legal {
        static readonly key: string = "Signup.Industries.Legal";
      }
      export class Manufacturing {
        static readonly key: string = "Signup.Industries.Manufacturing";
      }
      export class Marketing {
        static readonly key: string = "Signup.Industries.Marketing";
      }
      export class Municipalities {
        static readonly key: string = "Signup.Industries.Municipalities";
      }
      export class NotForProfit {
        static readonly key: string = "Signup.Industries.NotForProfit";
      }
      export class Retail {
        static readonly key: string = "Signup.Industries.Retail";
      }
      export class Technology {
        static readonly key: string = "Signup.Industries.Technology";
      }
      export class Transportation {
        static readonly key: string = "Signup.Industries.Transportation";
      }
    }
    export module MarketoProblems {
      export class Compliance {
        static readonly key: string = "Signup.MarketoProblems.Compliance";
      }
      export class EmployeeTraining {
        static readonly key: string = "Signup.MarketoProblems.EmployeeTraining";
      }
      export class PolicyManagement {
        static readonly key: string = "Signup.MarketoProblems.PolicyManagement";
      }
    }
    export module OrgSizes {
      export class FiftyOneToOneHundred {
        static readonly key: string = "Signup.OrgSizes.FiftyOneToOneHundred";
      }
      export class FiveHundredOneToOneThousand {
        static readonly key: string =
          "Signup.OrgSizes.FiveHundredOneToOneThousand";
      }
      export class OneHundredOneToFiveHundred {
        static readonly key: string =
          "Signup.OrgSizes.OneHundredOneToFiveHundred";
      }
      export class OneThousandPlus {
        static readonly key: string = "Signup.OrgSizes.OneThousandPlus";
      }
      export class OneToTwentyFive {
        static readonly key: string = "Signup.OrgSizes.OneToTwentyFive";
      }
      export class TwentySixToFifty {
        static readonly key: string = "Signup.OrgSizes.TwentySixToFifty";
      }
    }
    export module Problems {
      export class DocumentManagement {
        static readonly key: string = "Signup.Problems.DocumentManagement";
      }
      export class OnlineAccreditation {
        static readonly key: string = "Signup.Problems.OnlineAccreditation";
      }
      export class OnlineTraining {
        static readonly key: string = "Signup.Problems.OnlineTraining";
      }
      export class Other {
        static readonly key: string = "Signup.Problems.Other";
      }
    }
  }
  export module Standards {
    export module FeatureModal {
      export class HeaderTitle {
        static readonly key: string = "Standards.FeatureModal.HeaderTitle";
      }
      export class Title {
        static readonly key: string = "Standards.FeatureModal.Title";
      }
      export class ParagraphOne {
        static readonly key: string = "Standards.FeatureModal.ParagraphOne";
        pinIcon: string;
      }
      export class ParagraphTwo {
        static readonly key: string = "Standards.FeatureModal.ParagraphTwo";
        linkStart: string;
        linkEnd: string;
      }
      export class ConfirmButton {
        static readonly key: string = "Standards.FeatureModal.ConfirmButton";
      }
    }
  }
  export module StatusText {
    export class Fail {
      static readonly key: string = "StatusText.Fail";
    }
    export class InProgress {
      static readonly key: string = "StatusText.InProgress";
    }
    export class NotStarted {
      static readonly key: string = "StatusText.NotStarted";
    }
    export class Pass {
      static readonly key: string = "StatusText.Pass";
    }
    export class PendingApproval {
      static readonly key: string = "StatusText.PendingApproval";
    }
    export class PendingGrade {
      static readonly key: string = "StatusText.PendingGrade";
    }
  }
  export module Surveys {
    export class NewSurvey {
      static readonly key: string = "Surveys.NewSurvey";
    }
    export class TakeSurvey {
      static readonly key: string = "Surveys.TakeSurvey";
    }
  }
  export module Table {
    export class ChangeButton {
      static readonly key: string = "Table.ChangeButton";
    }
    export class GoToFirstPage {
      static readonly key: string = "Table.GoToFirstPage";
    }
    export class GoToLastPage {
      static readonly key: string = "Table.GoToLastPage";
    }
    export class ItemsPerPage {
      static readonly key: string = "Table.ItemsPerPage";
    }
    export class ItemsOnPage {
      static readonly key: string = "Table.ItemsOnPage";
      openRange: string;
      closeRange: string;
      totalItems: string;
    }
    export class NextPage {
      static readonly key: string = "Table.NextPage";
    }
    export class NoRecordsToDisplay {
      static readonly key: string = "Table.NoRecordsToDisplay";
    }
    export class Page {
      static readonly key: string = "Table.Page";
    }
    export class PageOfPages {
      static readonly key: string = "Table.PageOfPages";
      totalPages: string;
    }
    export class Pagination {
      static readonly key: string = "Table.Pagination";
    }
    export class PreviousPage {
      static readonly key: string = "Table.PreviousPage";
    }
    export class Selected {
      static readonly key: string = "Table.Selected";
    }
    export class TableIsSortedAscending {
      static readonly key: string = "Table.TableIsSortedAscending";
      columnName: string;
    }
    export class TableIsSortedDescending {
      static readonly key: string = "Table.TableIsSortedDescending";
      columnName: string;
    }
    export class TableIsUnsorted {
      static readonly key: string = "Table.TableIsUnsorted";
    }
    export class ToggleSorting {
      static readonly key: string = "Table.ToggleSorting";
    }
  }
  export module Tags {
    export class NameAlreadyExists {
      static readonly key: string = "Tags.NameAlreadyExists";
    }
    export class NameLabel {
      static readonly key: string = "Tags.NameLabel";
    }
    export class SeeTagged {
      static readonly key: string = "Tags.SeeTagged";
    }
    export class URLLabel {
      static readonly key: string = "Tags.URLLabel";
    }
  }
  export module TaskStatuses {
    export class Acquired {
      static readonly key: string = "TaskStatuses.Acquired";
    }
    export class Approved {
      static readonly key: string = "TaskStatuses.Approved";
    }
    export class Awarded {
      static readonly key: string = "TaskStatuses.Awarded";
    }
    export class Completed {
      static readonly key: string = "TaskStatuses.Completed";
    }
    export class Denied {
      static readonly key: string = "TaskStatuses.Denied";
    }
    export class Fail {
      static readonly key: string = "TaskStatuses.Fail";
    }
    export class Failed {
      static readonly key: string = "TaskStatuses.Failed";
    }
    export class InProgress {
      static readonly key: string = "TaskStatuses.InProgress";
    }
    export class Pass {
      static readonly key: string = "TaskStatuses.Pass";
    }
    export class Passed {
      static readonly key: string = "TaskStatuses.Passed";
    }
    export class Reviewed {
      static readonly key: string = "TaskStatuses.Reviewed";
    }
    export class Renewed {
      static readonly key: string = "TaskStatuses.Renewed";
    }
    export class NeedToAcquire {
      static readonly key: string = "TaskStatuses.NeedToAcquire";
    }
    export class NeedToRenew {
      static readonly key: string = "TaskStatuses.NeedToRenew";
    }
    export class NotStarted {
      static readonly key: string = "TaskStatuses.NotStarted";
    }
    export class PendingGrade {
      static readonly key: string = "TaskStatuses.PendingGrade";
    }
    export class PendingApproval {
      static readonly key: string = "TaskStatuses.PendingApproval";
    }
    export class Signed {
      static readonly key: string = "TaskStatuses.Signed";
    }
    export class VotedOn {
      static readonly key: string = "TaskStatuses.VotedOn";
    }
  }
  export module Tests {
    export class NewTest {
      static readonly key: string = "Tests.NewTest";
    }
    export class TestReview {
      static readonly key: string = "Tests.TestReview";
    }
    export class TakeTest {
      static readonly key: string = "Tests.TakeTest";
    }
  }
  export module Text {
    export class NameFormat1 {
      static readonly key: string = "Text.NameFormat1";
    }
    export class NameFormat2 {
      static readonly key: string = "Text.NameFormat2";
    }
    export class NameFormat3 {
      static readonly key: string = "Text.NameFormat3";
    }
    export class NameFormat4 {
      static readonly key: string = "Text.NameFormat4";
    }
    export class NameFormat5 {
      static readonly key: string = "Text.NameFormat5";
    }
    export class NameFormat6 {
      static readonly key: string = "Text.NameFormat6";
    }
    export module Align {
      export class Center {
        static readonly key: string = "Text.Align.Center";
      }
      export class Left {
        static readonly key: string = "Text.Align.Left";
      }
      export class Right {
        static readonly key: string = "Text.Align.Right";
      }
    }
    export module FontSize {
      export class Large {
        static readonly key: string = "Text.FontSize.Large";
      }
      export class Medium {
        static readonly key: string = "Text.FontSize.Medium";
      }
      export class Small {
        static readonly key: string = "Text.FontSize.Small";
      }
      export class xlarge {
        static readonly key: string = "Text.FontSize.xlarge";
      }
      export class xsmall {
        static readonly key: string = "Text.FontSize.xsmall";
      }
      export class xxlarge {
        static readonly key: string = "Text.FontSize.xxlarge";
      }
      export class xxsmall {
        static readonly key: string = "Text.FontSize.xxsmall";
      }
    }
    export module FontWeight {
      export class Bold {
        static readonly key: string = "Text.FontWeight.Bold";
      }
      export class Bolder {
        static readonly key: string = "Text.FontWeight.Bolder";
      }
      export class Lighter {
        static readonly key: string = "Text.FontWeight.Lighter";
      }
      export class Normal {
        static readonly key: string = "Text.FontWeight.Normal";
      }
    }
    export module Label {
      export class Align {
        static readonly key: string = "Text.Label.Align";
      }
      export class Color {
        static readonly key: string = "Text.Label.Color";
      }
      export class Font {
        static readonly key: string = "Text.Label.Font";
      }
      export class Format {
        static readonly key: string = "Text.Label.Format";
      }
      export class Size {
        static readonly key: string = "Text.Label.Size";
      }
      export class Transform {
        static readonly key: string = "Text.Label.Transform";
      }
      export class Type {
        static readonly key: string = "Text.Label.Type";
      }
      export class Weight {
        static readonly key: string = "Text.Label.Weight";
      }
    }
    export module Transform {
      export class Capitalize {
        static readonly key: string = "Text.Transform.Capitalize";
      }
      export class Lowercase {
        static readonly key: string = "Text.Transform.Lowercase";
      }
      export class Uppercase {
        static readonly key: string = "Text.Transform.Uppercase";
      }
    }
  }
  export module TrainingRequests {
    export class NewTrainingRequest {
      static readonly key: string = "TrainingRequests.NewTrainingRequest";
    }
  }
  export module Thickbox {
    export class CloseWindow {
      static readonly key: string = "Thickbox.CloseWindow";
    }
  }
  export module UserAdmin {
    export class ArchivedWarning {
      static readonly key: string = "UserAdmin.ArchivedWarning";
      openStrong: string;
      closeStrong: string;
    }
    export class ChoosePasswordOnLogin {
      static readonly key: string = "UserAdmin.ChoosePasswordOnLogin";
    }
    export class DeactivateAndArchiveButton {
      static readonly key: string = "UserAdmin.DeactivateAndArchiveButton";
      ampersand: string;
    }
    export class Header {
      static readonly key: string = "UserAdmin.Header";
      username: string;
      fullName: string;
    }
    export class PasswordSetViaEmail {
      static readonly key: string = "UserAdmin.PasswordSetViaEmail";
    }
    export class SaveAndNew {
      static readonly key: string = "UserAdmin.SaveAndNew";
      ampersand: string;
    }
    export module ArchiveButton {
      export class Default {
        static readonly key: string = "UserAdmin.ArchiveButton.Default";
      }
      export class InProgress {
        static readonly key: string = "UserAdmin.ArchiveButton.InProgress";
      }
    }
    export module Archived {
      export class EmailWarning {
        static readonly key: string = "UserAdmin.Archived.EmailWarning";
        openStrong: string;
        closeStrong: string;
      }
    }
    export module InboxSignAll {
      export class Label {
        static readonly key: string = "UserAdmin.InboxSignAll.Label";
      }
      export class HelpText {
        static readonly key: string = "UserAdmin.InboxSignAll.HelpText";
        username: string;
      }
      export class HelpTextNoUser {
        static readonly key: string = "UserAdmin.InboxSignAll.HelpTextNoUser";
      }
    }
    export module PasswordExpirationDate {
      export class Label {
        static readonly key: string = "UserAdmin.PasswordExpirationDate.Label";
      }
    }
    export module PasswordExpirationDay {
      export class Label {
        static readonly key: string = "UserAdmin.PasswordExpirationDay.Label";
      }
      export class ErrorMessage {
        static readonly key: string =
          "UserAdmin.PasswordExpirationDay.ErrorMessage";
        minValue: number;
        maxValue: number;
      }
    }
    export module RestoreButton {
      export class Default {
        static readonly key: string = "UserAdmin.RestoreButton.Default";
      }
      export class InProgress {
        static readonly key: string = "UserAdmin.RestoreButton.InProgress";
      }
    }
    export module SendWelcomeEmail {
      export class HelpText {
        static readonly key: string = "UserAdmin.SendWelcomeEmail.HelpText";
      }
    }
    export module Username {
      export class ErrorMessage {
        static readonly key: string = "UserAdmin.Username.ErrorMessage";
        quote: string;
        pound: string;
        ampersand: string;
        questionMark: string;
      }
      export module Foundation {
        export class ErrorMessage {
          static readonly key: string =
            "UserAdmin.Username.Foundation.ErrorMessage";
          greaterthan: string;
          lessthan: string;
          ampersand: string;
          percent: string;
          quote: string;
          semicolan: string;
        }
      }
    }
    export module FirstName {
      export module Foundation {
        export class ErrorMessage {
          static readonly key: string =
            "UserAdmin.FirstName.Foundation.ErrorMessage";
          greaterthan: string;
          lessthan: string;
          ampersand: string;
          percent: string;
          quote: string;
          semicolan: string;
        }
      }
    }
    export module LastName {
      export module Foundation {
        export class ErrorMessage {
          static readonly key: string =
            "UserAdmin.LastName.Foundation.ErrorMessage";
          greaterthan: string;
          lessthan: string;
          ampersand: string;
          percent: string;
          quote: string;
          semicolan: string;
        }
      }
    }
    export module MiddleName {
      export module Foundation {
        export class ErrorMessage {
          static readonly key: string =
            "UserAdmin.MiddleName.Foundation.ErrorMessage";
          greaterthan: string;
          lessthan: string;
          ampersand: string;
          percent: string;
          quote: string;
          semicolan: string;
        }
      }
    }
    export module Email {
      export module Foundation {
        export class ErrorMessage {
          static readonly key: string =
            "UserAdmin.Email.Foundation.ErrorMessage";
        }
      }
    }
    export module CreateDocPrivModal {
      export class ConfirmButton {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.ConfirmButton";
      }
      export class EditAllowCreation {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.EditAllowCreation";
      }
      export class GrantEdit {
        static readonly key: string = "UserAdmin.CreateDocPrivModal.GrantEdit";
      }
      export class GrantPrivilege {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.GrantPrivilege";
      }
      export class NavigateToFolderOrGroup {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.NavigateToFolderOrGroup";
      }
      export class RemoveEdit {
        static readonly key: string = "UserAdmin.CreateDocPrivModal.RemoveEdit";
      }
      export class RevokePrivilege {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.RevokePrivilege";
      }
      export class RevokeToRemove {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.RevokeToRemove";
      }
      export class SelectSecurityFromFolder {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.SelectSecurityFromFolder";
      }
      export class SelectFolderFromGroup {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.SelectFolderFromGroup";
      }
      export class SpecifyTheFolders {
        static readonly key: string =
          "UserAdmin.CreateDocPrivModal.SpecifyTheFolders";
      }
      export class Title {
        static readonly key: string = "UserAdmin.CreateDocPrivModal.Title";
      }
    }
    export module ArchiveUserModal {
      export class ArchiveNow {
        static readonly key: string = "UserAdmin.ArchiveUserModal.ArchiveNow";
      }
      export class ArchiveOn {
        static readonly key: string = "UserAdmin.ArchiveUserModal.ArchiveOn";
      }
      export class Header {
        static readonly key: string = "UserAdmin.ArchiveUserModal.Header";
      }
      export class Message {
        static readonly key: string = "UserAdmin.ArchiveUserModal.Message";
      }
    }
    export module DeleteUserModal {
      export class AgreeToDeletePermanently {
        static readonly key: string =
          "UserAdmin.DeleteUserModal.AgreeToDeletePermanently";
        username: string;
      }
      export class AlreadyArchived {
        static readonly key: string =
          "UserAdmin.DeleteUserModal.AlreadyArchived";
        username: string;
      }
      export class CancelDeletion {
        static readonly key: string =
          "UserAdmin.DeleteUserModal.CancelDeletion";
      }
      export class FollowTheSteps {
        static readonly key: string =
          "UserAdmin.DeleteUserModal.FollowTheSteps";
      }
      export class Header {
        static readonly key: string = "UserAdmin.DeleteUserModal.Header";
      }
      export class PermanentlyDeleteUser {
        static readonly key: string =
          "UserAdmin.DeleteUserModal.PermanentlyDeleteUser";
        username: string;
      }
      export class TypeToDelete {
        static readonly key: string = "UserAdmin.DeleteUserModal.TypeToDelete";
        keyword: string;
      }
      export module ConfirmButton {
        export class Idle {
          static readonly key: string =
            "UserAdmin.DeleteUserModal.ConfirmButton.Idle";
        }
      }
    }
    export module RestoreUserModal {
      export class Header {
        static readonly key: string = "UserAdmin.RestoreUserModal.Header";
      }
      export class Message {
        static readonly key: string = "UserAdmin.RestoreUserModal.Message";
      }
    }
  }
  export module Users {
    export class AddressLabel {
      static readonly key: string = "Users.AddressLabel";
    }
    export class AddPhoneLabel {
      static readonly key: string = "Users.AddPhoneLabel";
    }
    export class CityLabel {
      static readonly key: string = "Users.CityLabel";
    }
    export class ConfirmationEmailSent {
      static readonly key: string = "Users.ConfirmationEmailSent";
    }
    export class EditMyProfile {
      static readonly key: string = "Users.EditMyProfile";
    }
    export class EmailAddressVerified {
      static readonly key: string = "Users.EmailAddressVerified";
    }
    export class EmailLabel {
      static readonly key: string = "Users.EmailLabel";
    }
    export class ExtensionLabel {
      static readonly key: string = "Users.ExtensionLabel";
    }
    export class FirstNameLabel {
      static readonly key: string = "Users.FirstNameLabel";
    }
    export class FullName {
      static readonly key: string = "Users.FullName";
      givenName: string;
      surname: string;
    }
    export class JobTitleLabel {
      static readonly key: string = "Users.JobTitleLabel";
    }
    export class LastNameLabel {
      static readonly key: string = "Users.LastNameLabel";
    }
    export class ManageUser {
      static readonly key: string = "Users.ManageUser";
    }
    export class MiddleName {
      static readonly key: string = "Users.MiddleName";
    }
    export class NewUser {
      static readonly key: string = "Users.NewUser";
    }
    export class Notes {
      static readonly key: string = "Users.Notes";
    }
    export class RemoveField {
      static readonly key: string = "Users.RemoveField";
    }
    export class SendMessage {
      static readonly key: string = "Users.SendMessage";
    }
    export class StateLabel {
      static readonly key: string = "Users.StateLabel";
    }
    export class SurnameCommaGivenName {
      static readonly key: string = "Users.SurnameCommaGivenName";
      surname: string;
      givenName: string;
    }
    export class SurnameGivenNameAndUsername {
      static readonly key: string = "Users.SurnameGivenNameAndUsername";
      username: string;
      surname: string;
      givenName: string;
    }
    export class UserProfile {
      static readonly key: string = "Users.UserProfile";
    }
    export class ZipCodeLabel {
      static readonly key: string = "Users.ZipCodeLabel";
    }
  }
  export module UsersAdmin {
    export class UsernameValidationError {
      static readonly key: string = "UsersAdmin.UsernameValidationError";
    }
  }
  export module UserSync {
    export class Title {
      static readonly key: string = "UserSync.Title";
    }
  }
  export module AccountSettings {
    export class Header {
      static readonly key: string = "AccountSettings.Header";
    }
    export module Tabs {
      export class password {
        static readonly key: string = "AccountSettings.Tabs.password";
      }
      export class email {
        static readonly key: string = "AccountSettings.Tabs.email";
      }
      export class securityQuestions {
        static readonly key: string = "AccountSettings.Tabs.securityQuestions";
      }
      export class contentHub {
        static readonly key: string = "AccountSettings.Tabs.contentHub";
      }
      export class thirdPartyAccess {
        static readonly key: string = "AccountSettings.Tabs.thirdPartyAccess";
      }
    }
  }
  export module ThirdPartyAccessTab {
    export class ConnectionAccessInfo {
      static readonly key: string = "ThirdPartyAccessTab.ConnectionAccessInfo";
    }
    export class ThirdParties {
      static readonly key: string = "ThirdPartyAccessTab.ThirdParties";
    }
    export module Revoke {
      export class RevokeAccess {
        static readonly key: string = "ThirdPartyAccessTab.Revoke.RevokeAccess";
      }
      export class RevokeThirdPartyAccess {
        static readonly key: string =
          "ThirdPartyAccessTab.Revoke.RevokeThirdPartyAccess";
        thirdParty: string;
      }
      export class AccessWarning {
        static readonly key: string =
          "ThirdPartyAccessTab.Revoke.AccessWarning";
        thirdParty: string;
      }
      export class ShareWarning {
        static readonly key: string = "ThirdPartyAccessTab.Revoke.ShareWarning";
      }
    }
  }
  export module UserEmailTab {
    export class CurrentPassword {
      static readonly key: string = "UserEmailTab.CurrentPassword";
    }
    export class VerifyIdentity {
      static readonly key: string = "UserEmailTab.VerifyIdentity";
    }
    export module UpdateError {
      export class InvalidEmail {
        static readonly key: string = "UserEmailTab.UpdateError.InvalidEmail";
      }
      export class InvalidPassword {
        static readonly key: string =
          "UserEmailTab.UpdateError.InvalidPassword";
      }
    }
    export module CurrentEmail {
      export class EmailHasBeenVerified {
        static readonly key: string =
          "UserEmailTab.CurrentEmail.EmailHasBeenVerified";
      }
    }
    export module ChangeEmail {
      export class NoEmailMessage {
        static readonly key: string = "UserEmailTab.ChangeEmail.NoEmailMessage";
      }
      export class HasEmailMessage {
        static readonly key: string =
          "UserEmailTab.ChangeEmail.HasEmailMessage";
      }
      export class ConfirmationMessageInfo {
        static readonly key: string =
          "UserEmailTab.ChangeEmail.ConfirmationMessageInfo";
      }
      export class EmailLabel {
        static readonly key: string = "UserEmailTab.ChangeEmail.EmailLabel";
      }
    }
  }
  export module Foundation {
    export class SingleSignOnContactSupport {
      static readonly key: string = "Foundation.SingleSignOnContactSupport";
    }
  }
  export module IntegratedContent {
    export class YourHomeForPolicyManagement {
      static readonly key: string =
        "IntegratedContent.YourHomeForPolicyManagement";
    }
    export class WelcomeToPowerDms {
      static readonly key: string = "IntegratedContent.WelcomeToPowerDms";
    }
    export class SecurePlatform {
      static readonly key: string = "IntegratedContent.SecurePlatform";
    }
    export class SeeHowItWorks {
      static readonly key: string = "IntegratedContent.SeeHowItWorks";
    }
    export class WhatsIncluded {
      static readonly key: string = "IntegratedContent.WhatsIncluded";
    }
    export class CaleaCoreStandardsResourceLibrary {
      static readonly key: string =
        "IntegratedContent.CaleaCoreStandardsResourceLibrary";
    }
    export class StandardsThatHelpYou {
      static readonly key: string = "IntegratedContent.StandardsThatHelpYou";
    }
    export class AddressDuties {
      static readonly key: string = "IntegratedContent.AddressDuties";
    }
    export class SelfAssess {
      static readonly key: string = "IntegratedContent.SelfAssess";
    }
    export class EvaluateAccreditationReadiness {
      static readonly key: string =
        "IntegratedContent.EvaluateAccreditationReadiness";
    }
    export class StandardsImageAltText {
      static readonly key: string = "IntegratedContent.StandardsImageAltText";
    }
    export class SamplePolicies {
      static readonly key: string = "IntegratedContent.SamplePolicies";
    }
    export class ContentProvided {
      static readonly key: string = "IntegratedContent.ContentProvided";
    }
    export class WrittenAgainst {
      static readonly key: string = "IntegratedContent.WrittenAgainst";
    }
    export class AccessToUpdates {
      static readonly key: string = "IntegratedContent.AccessToUpdates";
    }
    export class AutomaticEmailNotifications {
      static readonly key: string =
        "IntegratedContent.AutomaticEmailNotifications";
    }
    export class UseOfForcePolicyImageAltText {
      static readonly key: string =
        "IntegratedContent.UseOfForcePolicyImageAltText";
    }
    export class YouAreDoingYourBest {
      static readonly key: string = "IntegratedContent.YouAreDoingYourBest";
    }
    export class WhyPowerDms {
      static readonly key: string = "IntegratedContent.WhyPowerDms";
    }
    export class ExcellenceMadeEasy {
      static readonly key: string = "IntegratedContent.ExcellenceMadeEasy";
    }
    export class PowerDmsIsAlreadyUtilized {
      static readonly key: string =
        "IntegratedContent.PowerDmsIsAlreadyUtilized";
    }
    export class LearnMore {
      static readonly key: string = "IntegratedContent.LearnMore";
    }
    export class FirstRespondersImageAltText {
      static readonly key: string =
        "IntegratedContent.FirstRespondersImageAltText";
    }
    export class ShieldIconAltText {
      static readonly key: string = "IntegratedContent.ShieldIconAltText";
    }
    export class KeepOfficersSafe {
      static readonly key: string = "IntegratedContent.KeepOfficersSafe";
    }
    export class CollaborationTools {
      static readonly key: string = "IntegratedContent.CollaborationTools";
    }
    export class SignatureIconAltText {
      static readonly key: string = "IntegratedContent.SignatureIconAltText";
    }
    export class HoldOfficersAccountable {
      static readonly key: string = "IntegratedContent.HoldOfficersAccountable";
    }
    export class PowerDmsEnsures {
      static readonly key: string = "IntegratedContent.PowerDmsEnsures";
    }
    export class WebIconAltText {
      static readonly key: string = "IntegratedContent.WebIconAltText";
    }
    export class BePubliclyTransparent {
      static readonly key: string = "IntegratedContent.BePubliclyTransparent";
    }
    export class SpecifySelectPolicies {
      static readonly key: string = "IntegratedContent.SpecifySelectPolicies";
    }
    export class ExplorePowerDms {
      static readonly key: string = "IntegratedContent.ExplorePowerDms";
    }
    export class WatchVideoTutorial {
      static readonly key: string = "IntegratedContent.WatchVideoTutorial";
    }
    export class ContactPowerDmsSales {
      static readonly key: string = "IntegratedContent.ContactPowerDmsSales";
    }
    export class GettingStartedVideo {
      static readonly key: string = "IntegratedContent.GettingStartedVideo";
    }
    export class WelcomeVideo {
      static readonly key: string = "IntegratedContent.WelcomeVideo";
    }
  }
  export module ProviderFeaturePage {
    export class ByPowerDMS {
      static readonly key: string = "ProviderFeaturePage.ByPowerDMS";
    }
    export class Info {
      static readonly key: string = "ProviderFeaturePage.Info";
      providerName: string;
    }
    export class GetFeature {
      static readonly key: string = "ProviderFeaturePage.GetFeature";
    }
    export class FreeWithPowerDMS {
      static readonly key: string = "ProviderFeaturePage.FreeWithPowerDMS";
    }
    export class Overview {
      static readonly key: string = "ProviderFeaturePage.Overview";
    }
    export class Support {
      static readonly key: string = "ProviderFeaturePage.Support";
    }
    export class SupportDocumentation {
      static readonly key: string = "ProviderFeaturePage.SupportDocumentation";
    }
    export class PrivacyPolicy {
      static readonly key: string = "ProviderFeaturePage.PrivacyPolicy";
    }
    export class Enabling {
      static readonly key: string = "ProviderFeaturePage.Enabling";
    }
  }
  export module GoogleDriveFeaturePage {
    export class Description {
      static readonly key: string = "GoogleDriveFeaturePage.Description";
    }
    export class InstructionOne {
      static readonly key: string = "GoogleDriveFeaturePage.InstructionOne";
    }
    export class InstructionTwo {
      static readonly key: string = "GoogleDriveFeaturePage.InstructionTwo";
    }
    export class InstructionThree {
      static readonly key: string = "GoogleDriveFeaturePage.InstructionThree";
    }
    export class AltTextForImageNewDocument {
      static readonly key: string =
        "GoogleDriveFeaturePage.AltTextForImageNewDocument";
    }
    export class AltTextForImageAccountSelection {
      static readonly key: string =
        "GoogleDriveFeaturePage.AltTextForImageAccountSelection";
    }
  }
  export module OneDriveFeaturePage {
    export class Description {
      static readonly key: string = "OneDriveFeaturePage.Description";
    }
    export class InstructionOne {
      static readonly key: string = "OneDriveFeaturePage.InstructionOne";
    }
    export class InstructionTwo {
      static readonly key: string = "OneDriveFeaturePage.InstructionTwo";
    }
    export class InstructionThree {
      static readonly key: string = "OneDriveFeaturePage.InstructionThree";
    }
    export class AltTextForImageNewDocument {
      static readonly key: string =
        "OneDriveFeaturePage.AltTextForImageNewDocument";
    }
    export class AltTextForImageAccountSelection {
      static readonly key: string =
        "OneDriveFeaturePage.AltTextForImageAccountSelection";
    }
  }
}
