'use strict';

import { ReactDirective } from 'ngreact';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { Icon } from 'modules/presentation/enums';
import { Keys } from 'locales/keys';
import { MessageBox } from 'modules/presentation/components/MessageBox';
import { NotificationStyle } from 'modules/notification/enums';
import { Localizer } from 'modules/main/components/Localizer';

const SECONDS_UNTIL_RELOAD = 5;

export type TAutoReloadErrorMessageProps = {
  attemptLoadMore: () => void;
  showCountdown: boolean;
};

type TAutoReloadErrorMessageState = {
  secondsUntilAutoReload: number;
};

class AutoReloadErrorMessage extends React.Component<
  TAutoReloadErrorMessageProps,
  TAutoReloadErrorMessageState
> {
  private timer: NodeJS.Timer;

  static propTypes = {
    attemptLoadMore: PropTypes.func.isRequired,
    showCountdown: PropTypes.bool.isRequired,
  };

  constructor(props: TAutoReloadErrorMessageProps) {
    super(props);
    this.state = { secondsUntilAutoReload: SECONDS_UNTIL_RELOAD };
  }

  componentDidMount() {
    if (this.props.showCountdown) {
      this.timer = setInterval(() => this.countdownUntilReload(), 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  private countdownUntilReload() {
    if (this.state.secondsUntilAutoReload > 1) {
      this.setState((prevState) => ({
        secondsUntilAutoReload: prevState.secondsUntilAutoReload - 1,
      }));
    } else {
      clearInterval(this.timer);
      this.props.attemptLoadMore();
    }
  }

  private getTryAgain(): JSX.Element {
    return (
      <span className="autoReloadErrorMessage_loadFailed">
        <span className="autoReloadErrorMessage_loadFailedText">
          <Localizer translation={Keys.Navigation.SideMenu.LoadFailed} />
        </span>
        <span
          onClick={this.props.attemptLoadMore}
          className="autoReloadErrorMessage_tryAgainLink fakeAnchor"
        >
          <Localizer translation={Keys.Navigation.SideMenu.TryAgain} />
        </span>
      </span>
    );
  }

  private getSecondsUntilReload(): JSX.Element {
    return (
      <span className="autoReloadErrorMessage_loadFailed">
        <span className="autoReloadErrorMessage_loadFailedText">
          <Localizer translation={Keys.Navigation.SideMenu.ProblemConnecting1} />
        </span>
        <span className="autoReloadErrorMessage_secondsUntilReloadText">
          <Localizer
            translation={Keys.Navigation.SideMenu.ProblemConnecting2}
            values={{ secondsToWait: this.state.secondsUntilAutoReload.toString() }}
          />
        </span>
      </span>
    );
  }

  public render() {
    return (
      <div className="autoReloadErrorMessage">
        <MessageBox messageStyle={NotificationStyle.Error} icon={Icon.Error}>
          {this.props.showCountdown ? this.getSecondsUntilReload() : this.getTryAgain()}
        </MessageBox>
      </div>
    );
  }
}

export { AutoReloadErrorMessage };

export class autoReloadErrorMessageDirective implements ng.IDirective {
  public static $inject = ['reactDirective'];

  constructor(reactDirective: ReactDirective) {
    return reactDirective(AutoReloadErrorMessage as any);
  }
}
