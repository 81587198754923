'use strict';

import * as React from 'react';

export enum SpinnerSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Wrapped = 'wrapped',
}

export type TSpinnerProps = {
  children?: any;
  className?: string;
  isSpinning?: boolean;
  /** Adds a class to the `spinner_animation` element */
  spinnerClassName?: string;
  /** Determines sizing of the spinner */
  spinnerSize?: SpinnerSizes;
};

function getSpinnerSize(spinnerSize: SpinnerSizes) {
  switch (spinnerSize) {
    case SpinnerSizes.Small:
      return 'spinner--small';
    case SpinnerSizes.Large:
      return 'spinner--large';
    case SpinnerSizes.Wrapped:
      return 'spinner--wrapped';
    case SpinnerSizes.Medium:
    default:
      return 'spinner--medium';
  }
}
/** Default loading spinner */
export function Spinner(props: TSpinnerProps): JSX.Element {
  const { children, className, isSpinning, spinnerClassName, spinnerSize, ...rest } = props;

  if (isSpinning) {
    return (
      <div className={`spinner ${getSpinnerSize(spinnerSize)} ${className || ''}`} {...rest}>
        <div className={`spinner_animation ${spinnerClassName || ''}`} />
        <div className="spinner_children">{children}</div>
      </div>
    );
  }

  return children;
}
