import { createSelector } from 'reselect';

import { TPublicDoc } from './models/TPublicDoc';
import { TApplicationState } from './store';
import { TPropsFromRoute } from './containers/TreeViewContainer/TreeViewContainer';

export const getPubDocsStore = (state: TApplicationState) => state.documents;
// TODO: Do the find logic in getSelectedDocument instead of in this input selector.
export const findSelectedDocument = (state: TApplicationState, props: TPropsFromRoute) =>
  state.documents.list.find((x) => x.id === props.documentId);
const getDocStatus = (state: TApplicationState) => state.documents.status;
const getSiteInfoStatus = (state: TApplicationState) => state.siteInfo.isLoading;

export const getAllDocuments = createSelector(getPubDocsStore, (state) => state.list);

export const getSelectedDocument = createSelector(
  findSelectedDocument,
  (selectedDocument: TPublicDoc) => selectedDocument,
);

export const getPubDocsSorted = createSelector(getAllDocuments, (pubDocs: TPublicDoc[]) =>
  pubDocs.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
);

export const listViewIsLoading = createSelector(
  getDocStatus,
  getSiteInfoStatus,
  (docLoadingStatus, siteInfoIsLoading) => docLoadingStatus === 'loading' || siteInfoIsLoading,
);

export const treeViewHeaderIsLoading = createSelector(
  getDocStatus,
  getSiteInfoStatus,
  (_: TApplicationState, props: TPropsFromRoute) => props.documentId,
  (docLoadingStatus, siteInfoIsLoading, selectDocumentId) =>
    selectDocumentId === undefined
      ? siteInfoIsLoading || docLoadingStatus === 'loading'
      : docLoadingStatus === 'init' || docLoadingStatus === 'loading',
);

// They both have the same loading group.
export const treeViewSideBarIsLoading = listViewIsLoading;
