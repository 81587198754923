'use strict';

export enum ObjectType {
  Document = 'Document',
  DocumentRevision = 'DocumentRevision',
  DocumentFile = 'File',
  //The following three are used as view logic. Never does the backend send us an objectType
  //that has one of these three. It's always 'DocumentRevision'
  PublishedRevision = 'PublishedRevision',
  DraftRevision = 'DraftRevision',
  ArchivedRevision = 'ArchivedRevision',
  ArchivedRevisions = 'ArchivedRevisions',
  Manual = 'Manual',
  ManualRevision = 'ManualRevision',
  StandardsNode = 'StandardsNode',
  /**
   * This 'Node' type is obsolete in the backend, but it's still used in some APIs, such as the
   * assessmentCharts api and the search api used by the files panel.
   */
  Node = 'Node',
  Assessment = 'Assessment',
  AssessmentAttachment = 'AssessmentAttachment',
  Certificate = 'Certificate',
  Course = 'Course',
  Seat = 'Seat',
  Section = 'Section',
  Test = 'Test',
  TrainingRequest = 'TrainingRequest',
  Survey = 'Survey',
  User = 'User',
  UserContainer = 'UserContainer',
  Group = 'Group',
  LinkedGroup = 'LinkedGroup',
  Tag = 'Tag',
  SubType = 'SubType',
  DocumentType = 'DocumentType',
  CertificateType = 'CertificateType',
  CourseType = 'CourseType',
  Folder = 'Folder',
  Workflow = 'Workflow',
  Discussion = 'Discussion',
  EventLog = 'EventLog',
  Location = 'Location',
  JobTitle = 'JobTitle',
  Message = 'Message',
  SearchSuggestion = 'SearchSuggestion',
  Role = 'Role',
  StorageProviderFolder = 'ExternalStorageProviderFolder',
  StorageProviderDocument = 'ExternalStorageProviderDocument',

  // Roots
  StorageProvidersRoot = 'StorageProvidersRoot',
  FilesRoot = 'FilesRoot',
  GroupsContainer = 'GroupsContainer',
  StorageProvider = 'StorageProvider',

  DocumentContainer = 'DocumentContainer',
  ManualContainer = 'ManualContainer',
  AssessmentContainer = 'AssessmentContainer',
  CertificateContainer = 'CertificateContainer',
  CourseContainer = 'CourseContainer',
  TestContainer = 'TestContainer',
  SurveyContainer = 'SurveyContainer',

  // This is so we can show the deactivated user icon. It's not really a type, but it allows us to use enums and pow-object-icon,
  //    so that if we ever update the icons, we only have to do it in one place.
  ArchivedUser = 'ArchivedUser',

  None = '',
}
