import * as PropTypes from 'prop-types';
import * as React from 'react';

import { DmsObjectService } from 'modules/dms-object/services/dms-object-service';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';

type TBreadcrumbsProps = {
  trail: ISearchResult[];
};

const Breadcrumbs: React.SFC<TBreadcrumbsProps> = ({ trail }) => {
  const crumbs = trail.map((breadcrumb, index) => {
    const key = `${DmsObjectService.generateObjectKey(breadcrumb)}|Crumb`;
    const name = <span className="fileViewerBreadcrumb_name ellipse">{breadcrumb.name}</span>;

    if (index < trail.length - 1) {
      return (
        <div key={key} className="fileViewerBreadcrumb_trailItem ellipse">
          {name}
          <span className="fileViewerBreadcrumb_divider">/</span>
        </div>
      );
    }

    return (
      <div key={key} className="fileViewerBreadcrumb_trailItem ellipse">
        {name}
      </div>
    );
  });

  return <div className="fileViewerBreadcrumb_trail">{crumbs}</div>;
};

Breadcrumbs.propTypes = {
  trail: PropTypes.array,
};

export { Breadcrumbs };
