import * as React from 'react';
import ReactGa from 'react-ga4';

import { TrackerName } from 'modules/analytics/models/TrackerName';

type TGaPageViewProps = {
  trackerNames?: TrackerName[];
};

const GaPageView: React.FC<TGaPageViewProps> = ({ trackerNames }) => {
  const page = window.location.pathname + window.location.search;

  ReactGa.send({ hitType: 'pageview', page: page });

  return null;
};

export { GaPageView };
