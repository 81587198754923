/**
 * This should stay in sync with the Link.tsx in modules/self-service-activation/components/Link.
 * That file is a copy of this one with the css dependency stripped out so that it works in SSA M1.
 */
import * as React from 'react';
import { default as MaterialLink, LinkProps } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import defaultTheme from 'modules/presentation/themes/defaultTheme';
import { DefaultPalette } from 'modules/presentation/enums/DefaultPalette';

export type TLinkProps = Omit<
  LinkProps,
  'color' | 'underline' | 'TypographyClasses' | 'variant'
> & {
  disabled?: boolean;
  // Prop is for accessibility on top of background colors
  monochrome?: 'white' | 'black';
  ref?: React.MutableRefObject<HTMLAnchorElement>;
  type?: 'inline' | 'block';
};

const useStyles = makeStyles<typeof defaultTheme, TLinkProps>((theme) => ({
  link: {
    color: (props) => {
      if (props.disabled) {
        return DefaultPalette.darkTextLighter;
      }

      if (props.monochrome === 'black') {
        return DefaultPalette.darkText;
      }

      if (props.monochrome === 'white') {
        return DefaultPalette.lightTextDark;
      }

      return DefaultPalette.primaryDark;
    },
  },
}));

export const Link = React.forwardRef<HTMLAnchorElement, TLinkProps>((props, ref) => {
  const { monochrome, disabled, className, type = 'inline', ...rest } = props;

  /**
   * So that we can still use the correct uniform link styling in webforms, we are using css classes
   * instead of css in js (with the exception of the font color, which needs to be in css in js to
   * override MUI's font color)
   */
  const lessClass = constructLessClass();
  const styles = useStyles(props);

  /**
   * Storybook's addon-info has a bug that causes it to display forwardRef component names
   * incorrectly. This is fixed in a later version of Storybook.
   * @TODO remove this when upgrading storybook.
   *
   * https://github.com/storybookjs/storybook/issues/4787
   * https://github.com/storybookjs/storybook/pull/4961
   */
  Link.displayName = 'Link';

  return (
    <MaterialLink
      ref={ref}
      className={`${lessClass} ${styles.link} ${className}`}
      underline={!props.disabled && props.monochrome ? 'always' : 'hover'}
      tabIndex={props.disabled ? -1 : 0}
      {...rest}
    />
  );

  function constructLessClass(): string {
    let className = 'linkComponent';

    if (props.disabled) {
      className = `${className} linkComponent--disabled`;
    } else if (props.monochrome === 'black') {
      className = `${className} linkComponent--monochromeBlack`;
    } else if (props.monochrome === 'white') {
      className = `${className} linkComponent--monochromeWhite`;
    }

    if (props.type === 'block') {
      className = `${className} linkComponent--block`;
    }

    return className;
  }
});
