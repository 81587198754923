export enum MainActionType {
  InitApp = 'PubDocs.MainActions.InitApp',
}

export function initApp(siteKey: string, documentId?: string) {
  return {
    type: MainActionType.InitApp as const,
    payload: { siteKey, documentId },
  };
}

export type TInitApp = ReturnType<typeof initApp>;

export type TMainAction = TInitApp;
