import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { getPubDocContentType } from 'modules/pub-docs/resources';

function useIsPdfDocument(documentUrl) {
  const [isPdfDocument, setIsPdfDocument] = useState<boolean>(false);

  useEffect(
    () => {
      if (isEmpty(documentUrl)) {
        return;
      }

      getPubDocContentType(documentUrl).then((contentType) => {
        setIsPdfDocument(contentType.toLowerCase().indexOf('pdf') >= 0);
      });
    },
    [documentUrl],
  );

  return isPdfDocument;
}

export { useIsPdfDocument };
