import * as _ from 'lodash';

import { IDefaultDmsObjectTreeItem } from 'modules/dms-object/models/IDmsObjectTreeItem';
import { DmsObjectService } from 'modules/dms-object/services/dms-object-service';
import { isNil } from 'modules/main/services/lodash-extended';

export interface IFlatListItem<T> {
  data: T & { key?: string };
  treeItemIndices: number[];
}

export function isIFlatListItem(object: any): object is IFlatListItem<any> {
  const flatItem = object as IFlatListItem<any>;
  return _.isObject(flatItem) && !isNil(flatItem.data) && _.isArray(flatItem.treeItemIndices);
}

export function getTreeItemKey(treeItem: IDefaultDmsObjectTreeItem): string {
  const {
    dmsObject: { rawDmsObject },
  } = treeItem.getOriginalItem();

  return DmsObjectService.generateObjectKey(rawDmsObject);
}

function getTreePositioningAriaProperties<T>(
  newTreeItemIndices: number[],
  children: T[],
  index: number,
) {
  return {
    'aria-level': newTreeItemIndices.length,
    'aria-setsize': children.length,
    'aria-posinset': index + 1,
  };
}

export class DmsTreeService {
  static itemIndicesRegex = /^(\d\-*)*\|/;

  static convertTreeToFlatList<T>(
    tree: T[],
    getChildren: (treeItem: T) => T[],
  ): IFlatListItem<T>[] {
    const flatList = [];

    if (!_.isArray(tree)) return flatList;

    const generateFlatList = (children: T[], treeItemIndices: number[]) => {
      children.forEach((child: T, index: number) => {
        const newTreeItemIndices = [...treeItemIndices, index];
        const innerChildren = getChildren(child);

        flatList.push({
          data: child,
          treeItemIndices: newTreeItemIndices,
          // Calc and add new aria attributes for tree items
          ariaAttributes: getTreePositioningAriaProperties<T>(newTreeItemIndices, children, index),
        });

        if (_.isArray(innerChildren)) {
          generateFlatList(innerChildren, newTreeItemIndices);
        }
      });
    };

    generateFlatList(tree, []);

    return flatList;
  }

  static getDepth<T>(item: IFlatListItem<T>) {
    if (isNil(item)) return undefined;

    return Math.max(0, item.treeItemIndices.length - 1);
  }

  static getTotalHeight<T>(
    treeItem: T,
    getChildren: (treeItem: T) => T[],
    getHeight: (treeItem: T) => number,
  ): number {
    const sumChildrenHeight = (totalHeight, child) => {
      return totalHeight + getChildren(child).reduce(sumChildrenHeight, getHeight(child));
    };

    return getChildren(treeItem).reduce(sumChildrenHeight, getHeight(treeItem));
  }

  static getHeightBetweenTwoItems<T>(
    flatList: IFlatListItem<T>[],
    fromItem: IFlatListItem<T>,
    toItem: IFlatListItem<T>,
    getHeight: (treeItem: T) => number,
  ) {
    if (isNil(flatList)) return 0;

    var fromIndex = _.findIndex(flatList, (item) =>
      _.isEqual(item.treeItemIndices, fromItem.treeItemIndices),
    );

    if (fromIndex < 0) {
      throw '"fromItem" does not exist in passed in "flatList"';
    }

    var toIndex = _.findIndex(
      flatList,
      (item) => _.isEqual(item.treeItemIndices, toItem.treeItemIndices),
      fromIndex,
    );

    if (toIndex < 0) {
      throw '"toItem" does not exist after "fromItem" in passed in "flatList"';
    }

    if (toIndex === fromIndex) return 0;

    return flatList
      .slice(fromIndex, toIndex)
      .map((flatItem) => getHeight(flatItem.data))
      .reduce((totalHeight, itemHeight) => totalHeight + itemHeight, 0);
  }

  static getHeightFromTop<T>(
    flatList: IFlatListItem<T>[],
    item: IFlatListItem<T>,
    getHeight: (treeItem: T) => number = (i) => i['height'],
  ) {
    return DmsTreeService.getHeightBetweenTwoItems(flatList, flatList[0], item, getHeight);
  }

  static getParentIndices(indices: number[]) {
    const parentIndices = [...indices];

    parentIndices.pop();

    return parentIndices;
  }

  static getTreeItem<T>(
    tree: T[],
    getChildren: (treeItem: T) => T[],
    treeItemIndices: number[],
  ): T {
    let currentItem: T;
    let currentList = tree;

    for (const index of treeItemIndices) {
      if (_.isUndefined(currentList) || index >= currentList.length || index < 0) {
        return;
      }

      currentItem = currentList[index];
      currentList = getChildren(currentItem);
    }

    return currentItem;
  }

  static isChildOfContainerByIndices(childIndices: number[], containerIndices: number[]): boolean {
    if (
      !_.isArray(childIndices) ||
      !_.isArray(containerIndices) ||
      childIndices.length <= containerIndices.length
    ) {
      return false;
    }

    for (let i = 0; i < containerIndices.length; i++) {
      if (childIndices[i] !== containerIndices[i]) {
        return false;
      }
    }

    return true;
  }

  static scrollToTreeItem(treeId: string, treeItemKey: string) {
    const detail = {
      treeId,
      treeItemKey,
    };

    window.dispatchEvent(new CustomEvent('scrollToTreeItem', { detail }));
  }
}
