import { useEffect } from 'react';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';

import { getDecodedBase64String } from 'modules/main/utilities/encoding';
import { DataFetchState } from 'modules/pub-docs/enums';

const contentBasePath = (window as any).contentBasePath;

function initializePdfTron(instance: WebViewerInstance, webViewerInstance, setFileFetchState) {
  const elementsToDisable = ['thumbnailControl', 'notesPanel', 'documentControl'];
  const { UI, Core } = instance;
  const { Feature } = UI;
  const featuresToDisable = [
    Feature.Annotations,
    Feature.NotesPanel,
    Feature.Redaction,
    Feature.ThumbnailMerging,
    Feature.ThumbnailReordering,
  ];

  UI.disableElements(elementsToDisable);
  UI.disableFeatures(featuresToDisable);
  UI.disableTools();

  const { documentViewer } = Core;
  documentViewer.addEventListener(
    'documentLoaded',
    () => setFileFetchState && setFileFetchState(DataFetchState.Data),
  );

  webViewerInstance.current = instance;
}

function usePdfViewerInitialization(viewer, webViewerInstance, documentUrl, setFileFetchState) {
  useEffect(() => {
    WebViewer(
      {
        path: `${contentBasePath}/lib/pdftron`,
        initialDoc: documentUrl,
        useDownloader: false,
        accessibleMode: true,
        isReadOnly: true,
        extension: 'pdf',
        licenseKey: getDecodedBase64String((window as any).pdfTronLicense),
        backendType: 'ems',
      },
      viewer.current,
    ).then((instance) => {
      initializePdfTron(instance, webViewerInstance, setFileFetchState);
      instance.UI.setPrintQuality(5);
    });
  }, []);
}

export { usePdfViewerInitialization };
