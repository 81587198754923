import { isNil } from 'modules/main/services/lodash-extended';

import { Keys } from 'locales/keys';
import { ObjectType } from 'modules/dms-object/enums';
import { ErrorCode } from 'modules/error/enums/ErrorCode';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';

import IHttpPromiseCallbackArg = ng.IHttpPromiseCallbackArg;

/* istanbul ignore next */
export function isEmptyObject(obj: any): boolean {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}

/* istanbul ignore next */
export function createUniqueGuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/* istanbul ignore next */
export function scrollToElement(
  $element: JQuery,
  $scrollContainer,
  bottomOffset = 0,
  topOffset = 0,
  animationDuration = 200,
) {
  if (!$element) return;
  !bottomOffset && (bottomOffset = 0);
  !topOffset && (topOffset = 0);

  var _scrollToItem = (item) => {
    // Offset is the distance to the top of the document. A position:fixed element still has a greater offset when the page scrolls down.
    // scrollTop is how far the container is scrolled down, in pixels
    var itemTop = item.offset().top - $scrollContainer.offset().top;
    var itemBottom = itemTop + item.outerHeight();

    if (itemTop < 0) {
      $scrollContainer.animate(
        { scrollTop: $scrollContainer.scrollTop() + itemTop + topOffset },
        { duration: animationDuration, queue: false },
      );
    } else if (itemBottom > $scrollContainer.height()) {
      $scrollContainer.animate(
        {
          scrollTop:
            $scrollContainer.scrollTop() + (itemBottom - $scrollContainer.height()) + bottomOffset,
        },
        { duration: animationDuration, queue: false },
      );
    }
  };

  // The pow-performance-list hides chunks of items at a time. If an item's chunk is hidden, we can't scroll to it.
  // So, first we'll scroll to it's chunk, which will cause the chunk to show, then we'll scroll to the item
  if (!$element.is(':visible')) {
    var visibleParent = $element.closest(':visible');

    var goingDown =
      visibleParent.offset().top >= $scrollContainer.offset().top + $scrollContainer.height();

    var targetDistanceFromTop;
    // If going down, line up the top of the chunk with the bottom of the scrollContainer, then offset it by 10px so it's visible
    if (goingDown) targetDistanceFromTop = $scrollContainer.height() - 10;
    // If we're going up, we want to line up the bottom of the chunk with the top of the scrollContainer and scroll down a bit.
    else targetDistanceFromTop = -visibleParent.height() + 10;

    var chunkDistanceFromTop = visibleParent.offset().top - $scrollContainer.offset().top;
    $scrollContainer.scrollTop(
      $scrollContainer.scrollTop() + chunkDistanceFromTop - targetDistanceFromTop,
    );

    requestAnimationFrame(() => {
      _scrollToItem($element);
    });
  } else {
    _scrollToItem($element);
  }
}

/* istanbul ignore next */
export function hasScrollBar($element) {
  return $element.get(0).scrollHeight > $element.height();
}

export function findParentElement(tagName: string, element) {
  while (!isNil(element)) {
    if ((element.nodeName || element.tagName).toLowerCase() === tagName.toLowerCase()) {
      return element;
    }

    element = element.parentNode;
  }
  return null;
}

/* istanbul ignore next */
export function getParentElementWithScrollbar($element) {
  if (!$element.parent) {
    return undefined;
  }

  var $parent = $element.parent();

  if (hasScrollBar($parent)) {
    return $parent;
  }

  return getParentElementWithScrollbar($parent);
}

/* istanbul ignore next */
export function getScrollElement($element): JQuery {
  var scrollElement = $element.closest('.scroll-container-vertical');

  if (scrollElement.length === 0) {
    scrollElement = $element.closest('.scroll-container-horizontal');

    if (scrollElement.length === 0) {
      scrollElement = $element.closest('.scroll-container');
    }
  }

  if (
    scrollElement.length === 0 &&
    (scrollElement.hasClass('.scroll-container-vertical') ||
      scrollElement.hasClass('.scroll-container-horizontal') ||
      scrollElement.hasClass('.scroll-container'))
  ) {
    scrollElement = $element;
  }

  if (scrollElement.length !== 0) {
    return scrollElement;
  } else {
    return undefined;
  }
}

/* istanbul ignore next */
export function isElementWithinVerticalBounds(
  element: ng.IAugmentedJQuery,
  top: number,
  bottom: number,
): boolean {
  var elementTop = element.offset().top;
  var elementBottom = elementTop + element.outerHeight();

  //is the top of element inside
  if (elementTop > top && elementTop < bottom) {
    return true;
  }
  //is the bottom of element inside
  if (elementBottom > top && elementBottom < bottom) {
    return true;
  }
  //is the internals of the element inside
  if (elementTop <= top && elementBottom >= bottom) {
    return true;
  }

  return false;
}

/* istanbul ignore next */
export function getNextIndex(array: Array<any>, item): number {
  var nextIndex = array.indexOf(item) + 1;

  if (array.length <= nextIndex) {
    nextIndex = 0;
  }

  return nextIndex;
}

/* istanbul ignore next */
export function getPreviousIndex(array: Array<any>, item): number {
  var previousIndex = array.indexOf(item) - 1;

  if (previousIndex < 0) {
    previousIndex = array.length - 1;
  }

  return previousIndex;
}

/* istanbul ignore next */
export function clearInputValue(inputElement) {
  /**
   * Reset the file input
   * Note: A file input's value cannot be set via JavaScript, but it can be reset, technically
   * Reference: http://stackoverflow.com/a/17183001
   */
  inputElement.wrap('<form>').closest('form').get(0).reset();
  inputElement.unwrap();
}

/* istanbul ignore next */
export function toLowerCaseObjectKeys(obj) {
  //http://stackoverflow.com/questions/12539574/whats-the-best-way-most-efficient-to-turn-all-the-keys-of-an-object-to-lower
  var key,
    keys = Object.keys(obj);
  var n = keys.length;
  var newobj = {};
  while (n--) {
    key = keys[n];
    newobj[key.toLowerCase()] = obj[key];
  }

  return newobj;
}

/* istanbul ignore next */
export function stringStartsWith(source, prefix): boolean {
  return source && source.slice(0, prefix.length) === prefix;
}

/* istanbul ignore next */
export function getABCSortStringForItem(item: ISearchResult): string {
  var sortString = '';

  if (!item.objectType) console.log("Warning: item doesn't have type. item: ", item);
  switch (item.objectType) {
    case ObjectType.User:
      sortString = item.surname + ', ' + item.givenName + ' - ' + item.name;
      break;
    case ObjectType.Course:
      sortString = item.number + ' - ' + item.name;
      break;
    default:
      sortString = item.name;
      break;
  }

  if (sortString) sortString = sortString.trim().toLowerCase();
  return sortString;
}

/* istanbul ignore next */
export function areDmsObjectsEqual(
  a: { id: string; objectType: ObjectType },
  b: { id: string; objectType: ObjectType },
): boolean {
  return a && b && a.id == b.id && a.objectType == b.objectType;
}

/**
 * DEPRECATION NOTICE
 *
 * Do not use this function if all you want is to retrieve data from AxiosPromise and ICcmpResponse.
 * You can achieve that by simply accessing response.data.data (assuming
 * AxiosPromise<ICcmpResponse<ActualData>> type). If you ever find a use case where this function is
 * needed, please note that use case.
 */
/* istanbul ignore next */
export function getDataFromResponse(response) {
  if (!response) return response;

  while (response.data) response = response.data;

  return response;
}

/* istanbul ignore next */
export function getSingleErrorCodeFromResponse(
  response: ng.IHttpPromiseCallbackArg<ICcmpResponse<any>> = {},
): ErrorCode {
  return getErrorCodesFromResponse(response)[0];
}

/* istanbul ignore next */
export function mockServerSideErrorResponse(errorCode: string) {
  return { data: { error: { code: errorCode } } };
}

/* istanbul ignore next */
export function mockServerSideGetResponse(data: any) {
  return { data: { data: data } };
}

/* istanbul ignore next */
export function getErrorCodesFromResponse(
  response: ng.IHttpPromiseCallbackArg<ICcmpResponse<any>> = {},
): ErrorCode[] {
  let errors: ErrorCode[] = [];

  // Not using 'getDataFromResponse' here because the error response can look like {data: {data: { }, error: stuff} },
  // which means it will always return null for the error if the second data property is not null
  if (
    response &&
    (response.status === 207 || response.status === 400) &&
    response.data &&
    response.data.data
  ) {
    errors = response.data.data.map((item) => item.error.code);
  } else if (response && response.data && response.data.error && response.data.error.code) {
    // 422 entity validation error has multiple sub errors that we want to show instead
    if (
      response.data.error.code == ErrorCode.EntityValidationError &&
      response.data.error.errors &&
      response.data.error.errors.length > 0
    ) {
      errors = response.data.error.errors.map((error) => error.code || ErrorCode.DefaultError);
    } else errors = [response.data.error.code];
  } else {
    // These are namespaced with 'frontend' so that we don't collide with future backend errors
    switch (response.status) {
      case 500:
        errors = [ErrorCode.FiveHundredError];
        break;
      case 404:
        errors = [ErrorCode.FourOhFourError];
        break;
      case 0:
        errors = [ErrorCode.NoInternet];
        break;
      default:
        errors = [ErrorCode.UnknownError];
        break;
    }
  }

  // Make super sure we're actually returning a code and not undefined by removing invalid errors.
  for (let i = errors.length - 1; i >= 0; i--) {
    if (!errors[i]) {
      console.log(
        'API returned invalid error code? response, errorsList, code: ',
        response,
        errors,
        errors[i],
      );
      errors.splice(i, 1);
    }
  }
  if (errors.length == 0) errors.push(ErrorCode.DefaultError);

  return errors;
}

/* istanbul ignore next */
export function getErrorMessagesForResponse(response: any): string[] {
  const localStrings = window.top.localStrings;
  var errors = [];

  if (response) {
    var error = response.error || (response.data && response.data.error);

    if (error) {
      // It's possible that the message could be blank if the backend has a problem
      if (error.messages && error.messages.length > 0 && error.messages[0]) {
        errors = error.messages;
      } else errors.push(JSON.stringify(error));
    } else {
      const sprintf = (<any>window).top.sprintf; // For readability
      //use the localization service when this gets put into a class
      const defaultError = sprintf(localStrings[Keys.Errors.DefaultError.key], {
        status: response.status,
        statusText: response.statusText,
      });

      switch (response.status) {
        case 0:
          errors = [localStrings[Keys.Errors.NoInternetConnection.key]];
          break;
        default:
          errors = [defaultError];
          break;
      }
    }
  } else {
    errors.push(localStrings[Keys.Errors.NoResponse.key]);
  }

  return errors;
}

/**
 * This function prevents any further resource loading.
 * It's mainly used when we manually set the location of the browser.
 */
/* istanbul ignore next */
export function stopLoading(): void {
  if (!isNil(window.stop)) {
    window.stop();
  } else if (!isNil(document.execCommand)) {
    document.execCommand('Stop', false);
  }
}

// has coverage
export function isValidEmail(email: string) {
  // Should match: stuff@stuff.alphabetical
  // From http://www.regular-expressions.info/email.html, a combination of the basic one + the accepted characters from the more advanced one
  return /^[A-Za-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[A-Za-z0-9.!#$%&'*+\/=?^_`{|}~-]+\.[A-Za-z]+$/.test(
    email,
  );
}

export function getSearchTermFromParams() {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('q')) {
    return urlParams.get('q');
  }
}

/* istanbul ignore next */
export class Utilities {
  public static $inject = ['userLanguage'];

  constructor(private userLanguage: string) {}

  public getEmailDomain(email: string) {
    if (!email) return '';

    var emailParts = email.split('@');
    if (emailParts.length < 2) return '';

    return emailParts[1];
  }

  public getEmailUsername(email: string) {
    if (!email) return '';

    var emailParts = email.split('@');
    if (emailParts.length < 2) return '';

    return emailParts[0];
  }

  public toCamelCase(input: string) {
    if (!input) return input;

    // split into words, capitalize each word, then rejoin
    input = $.trim(input);
    var words = input.split(/[\s-_.]/g);
    var capitalizedWords = [];
    for (var i = 0; i < words.length; i++) {
      capitalizedWords.push(
        i == 0
          ? words[i].toLocaleLowerCase(this.userLanguage)
          : this.capitalizeFirstLetter(words[i]),
      );
    }

    return capitalizedWords.join('');
  }

  public capitalizeFirstLetter(input: string) {
    if (!input) return input;
    return input.charAt(0).toLocaleUpperCase(this.userLanguage) + input.slice(1);
  }

  public uncapitalizeFirstLetter(input: string) {
    if (!input) return input;
    return input.charAt(0).toLocaleLowerCase(this.userLanguage) + input.slice(1);
  }

  // Expects to be passed a key-value object like {param1: foo, param2: 'bar with spaces'}
  // Outputs '?param1=foo&param2=bar%20with%20spaces'
  public createSearchStringFromParams(params: any) {
    var paramsArray = [];
    for (var param in params) {
      if (params.hasOwnProperty(param)) {
        paramsArray.push(encodeURIComponent(param) + '=' + encodeURIComponent(params[param]));
      }
    }
    return '?' + paramsArray.join('&');
  }

  // Expects to be passed the cookie string from document.cookie. We're passing it in to make unit testing easier
  public getCookiesFromString(cookieString) {
    var out = {};

    if (cookieString) {
      var cookies = cookieString.split(';');
      for (var i = 0; i < cookies.length; i++) {
        // We can't just use string.split because the value can contain '=' also
        var splitLocation = cookies[i].indexOf('=');

        if (splitLocation != -1)
          out[$.trim(cookies[i].substring(0, splitLocation))] = cookies[i].substring(
            splitLocation + 1,
          );
        else console.log('Error: invalid cookie. cookie, cookieString', cookies[i], cookieString);
      }
    }

    return out;
  }

  public setCookie(key: string, value?: any, expires?: Date, path?: string) {
    var cookieString = key + '=';
    if (value) cookieString += value;
    if (expires) cookieString += '; expires=' + expires.toUTCString();
    if (path) cookieString += '; path=' + path;
    window.document.cookie = cookieString;
  }

  public deleteCookie(key: string, path?: string) {
    var cookieString = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
    if (path) cookieString += '; path=' + path;
    window.document.cookie = cookieString;
  }

  // From http://stackoverflow.com/questions/2897155/get-cursor-position-in-characters-within-a-text-input-field
  // Note from Daniel: I've modified this a little to use selectionEnd, so that if a range is selected,
  //     we return the end of the range, not the beginning.
  public getCaretPositionInInput(input) {
    return input.selectionEnd;
  }

  /**
   * There's a bug in MicrosoftAjax that causes __doPostBack()
   * to be unable to be called from strict mode.
   *
   * We're employing a stripped down version of this hack:
   * https://mnaoumov.wordpress.com/2016/02/12/wtf-microsoftajax-js-vs-use-strict-vs-firefox-vs-ie/
   *
   * The idea is that we modify the Window Event prototype temporarily,
   * and the PostBack will revert it for us.
   *
   * This code is called to trigger a postback event with the access token as an argument
   * to the WebForms Auth0Authenticator controller's prerender event.
   */
  public doPostBack(control: string, argument: string) {
    const originalEventDescriptor = Object.getOwnPropertyDescriptor(Window.prototype, 'event');

    Object.defineProperty(window, 'event', {
      configurable: true,
      get: function get() {
        const result =
          originalEventDescriptor && originalEventDescriptor.get.apply(this, arguments);

        return result || {};
      },
    });

    const hackedDoPostBack = function hackedDoPostBack(...args) {
      __doPostBack.apply(this, arguments);
    };

    hackedDoPostBack(control, argument);
  }
} // end Utils
