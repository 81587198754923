import * as React from 'react';
import * as PropTypes from 'prop-types';

import { Icon } from 'modules/presentation/enums';
import { IconMetaLayout } from 'modules/presentation/components/IconMetaLayout';
import { DmsIcon } from 'modules/presentation/components/DmsIcon';
import { Link } from 'modules/presentation/components/Link';

import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';

type TColumnProps = {
  documents: TPublicDoc[];
  selectDocument: (document: TPublicDoc) => void;
};

const Column: React.FC<TColumnProps> = ({ documents, selectDocument }) => {
  return (
    <div
      className={`pubDocsListView_columns ${
        documents.length < 15 ? '' : 'pubDocsListView_columns--split'
      }`}
    >
      <ul className="pubDocsListView_documentList">
        {documents.map((doc) => {
          return (
            <li key={doc.id} className="pubDocsListView_documentItem">
              {/**
               * This wrapper div is specifically needed for IE11, which has an issue with
               * display: flex (used in the DmsIconMetaLayout)
               */}
              <div className="pubDocsListView_dmsIconMetaLayout">
                <IconMetaLayout
                  icon={<DmsIcon icon={Icon.Document} />}
                  primaryContent={
                    <Link
                      className="ellipse"
                      onClick={(e) => {
                        e.preventDefault();

                        selectDocument(doc);
                      }}
                      href={doc.publicUrl}
                    >
                      {doc.name}
                    </Link>
                  }
                  ellipseContent
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Column.propTypes = {
  documents: PropTypes.any.isRequired,
};

export { Column };
