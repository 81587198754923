import * as _ from 'lodash';

import { ErrorCode } from 'modules/error/enums';
import { ObjectStatus, ObjectType } from 'modules/dms-object/enums';
import { ContainerNode, ContentNode, ContentNodeCreationState } from 'modules/dms-object/models';
import { DataStatus } from 'modules/main/enums';
import { Icon } from 'modules/presentation/enums';
import { TMenuItem } from 'modules/presentation/models/TMenuItem';
import { IManualRevision, ISearchResult } from 'modules/search/managers/search-manager-factory';

export interface IDmsObjectTreeItemBase {
  canCopy?: boolean;
  canDrag?: boolean;
  canDrop?: (draggedItem: IDmsObjectTreeItemBase, isCopying: boolean) => boolean;
  getOriginalItem?: () => ContentNode;
  onDraggedItemDropped?: (
    draggedItem: IDmsObjectTreeItem,
    destination: IDmsObjectTreeItemBase,
    isCopying: boolean,
  ) => void;
}

export interface IDmsObjectTreeItem extends IDmsObjectTreeItemBase {
  height: number;
  key: string;
}

export interface IDefaultDmsObjectTreeItem extends IDmsObjectTreeItem {
  bookmark?: {
    canShow: boolean;
    isBookmarked: boolean;
    onClick: () => void;
  };
  content?: IDmsObjectTreeItem[];
  cancelCreation: () => void;
  createNewFolder: (
    name: string,
  ) => ng.IPromise<ng.IHttpPromiseCallbackArg<ICcmpResponse<ISearchResult>>>;
  creationState: ContentNodeCreationState;
  dragDropLoadingStatus: DataStatus;
  errorText: string;
  hasMore?: boolean;
  id: string;
  isImported: boolean;
  isImporting: boolean;
  isCollapsed?: boolean;
  isItemSelected: boolean;
  isTemp: boolean;
  isRead?: boolean;
  manageDropDownList: TMenuItem[];
  objectIcon: {
    hasUpdate?: boolean;
    icon: Icon;
    objectStatus?: ObjectStatus;
  };
  objectLink?: string;
  objectName: {
    name: string;
    subtext?: string;
    username?: string;
  };
  objectType: ObjectType;
  onClick?: (event: Event) => void;
  onLoadMore?: () => void;
  onToggleCollapse?: () => void;
  resetCreationError: () => void;
  status?: DataStatus;
  isBeingDragged: boolean;
}

export interface IEmptyDmsObjectTreeItem extends IDmsObjectTreeItem {
  text: string;
}

export interface IErrorDmsObjectTreeItem extends IDmsObjectTreeItem {
  attemptLoadMore: () => void;
  showCountdown: boolean;
}

export interface ILoadingDmsObjectTreeItem extends IDmsObjectTreeItem {
  loadingIcon: Icon;
}

export interface IInputDmsObjectTreeItem extends IDmsObjectTreeItem {
  objectIcon?: Icon;
  onSubmit: (input: string) => void;
  placeHolderText: string;
  submitErrorCode?: ErrorCode;
  submitStatus?: DataStatus;
}

export function isIDmsObjectTreeItem(object: any): object is IDmsObjectTreeItem {
  return _.isObject(object) && _.isNumber((object as IDmsObjectTreeItem).height);
}

export function isIDefaultDmsObjectTreeItem(object: any): object is IDefaultDmsObjectTreeItem {
  return (
    isIDmsObjectTreeItem(object) && _.isObject((object as IDefaultDmsObjectTreeItem).objectName)
  );
}

export function isIEmptyDmsObjectTreeItem(object: any): object is IEmptyDmsObjectTreeItem {
  return isIDmsObjectTreeItem(object) && _.isString((object as IEmptyDmsObjectTreeItem).text);
}

export function isIErrorDmsObjectTreeItem(object: any): object is IErrorDmsObjectTreeItem {
  return (
    isIDmsObjectTreeItem(object) &&
    _.isFunction((object as IErrorDmsObjectTreeItem).attemptLoadMore) &&
    _.isBoolean((object as IErrorDmsObjectTreeItem).showCountdown)
  );
}

export function isILoadingDmsObjectTreeItem(object: any): object is ILoadingDmsObjectTreeItem {
  return (
    isIDmsObjectTreeItem(object) && _.isString((object as ILoadingDmsObjectTreeItem).loadingIcon)
  );
}

export function isIInputDmsObjectTreeItem(object: any): object is IInputDmsObjectTreeItem {
  return (
    isIDmsObjectTreeItem(object) &&
    _.isString((object as IInputDmsObjectTreeItem).placeHolderText) &&
    _.isFunction((object as IInputDmsObjectTreeItem).onSubmit)
  );
}
