import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { TrackerName } from 'modules/analytics/models/TrackerName';
import { GaPageView } from 'modules/analytics/components/GoogleAnalytics';

import { MainView } from './components/MainView';
import { useRedirectIfLegacyUrl } from './services/useRedirectIfLegacyUrl';

const Routes: React.FC<{}> = () => {

  useRedirectIfLegacyUrl();

  return (
    <>
      <Route
        render={() => (
          <GaPageView trackerNames={[TrackerName.jointTracker, TrackerName.marketingSite]} />
        )}
      />
      <Route
        exact
        path="/:siteKey"
        render={({ match }) => <Redirect to={`/${match.params.siteKey}/tree`} />}
      />
      <Route
        exact
        path={['/:siteKey/:view(list|tree)', '/:siteKey/:view(list|tree)/documents/:documentId']}
        component={MainView}
      />
    </>
  );
};

export { Routes };
