import * as React from 'react';

export type TEllipsisLoaderProps = {
  /** Places the component in one of three desired horizontal positions within the parent container */
  horizontalPosition?: HorizontalPositions;
  /** Places the component in one of three desired vertical positions within the parent container */
  verticalPosition?: VerticalPositions;
};

export enum HorizontalPositions {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum VerticalPositions {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'middle',
}

/** Use when the rest of the page's content is still loading or while server operations finish processing. */
export function EllipsisLoader(props: TEllipsisLoaderProps): JSX.Element {
  const {
    horizontalPosition = HorizontalPositions.Center,
    verticalPosition = VerticalPositions.Center,
  } = props;
  return (
    <div
      className={`
        ellipsisLoader 
        ellipsisLoader--${horizontalPosition}
        ellipsisLoader--${verticalPosition}
      `}
    >
      <div className="ellipsisLoader_squareRow">
        <div className="ellipsisLoader_square" />
        <div className="ellipsisLoader_square" />
        <div className="ellipsisLoader_square" />
      </div>
    </div>
  );
}
