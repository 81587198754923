'use strict';

export class Permission {
  static View = 'View';
  static Audit = 'Audit';
  static Edit = 'Edit';
  static EditCascading = 'EditCascading';
  static None = 'None';

  static convertToNumber(permission: Permission): number {
    var num = -1;

    switch (permission) {
      case Permission.None:
        num = 0;
        break;
      case Permission.View:
        num = 1;
        break;
      case Permission.Audit:
        num = 2;
        break;
      case Permission.Edit:
        num = 3;
        break;
      case Permission.EditCascading:
        num = 4;
        break;
    }
    return num;
  }
}
