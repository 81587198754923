import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { TDefaultTheme } from 'modules/presentation/themes/defaultTheme';
import { DefaultPalette } from 'modules/presentation/enums/DefaultPalette';

export const useStyles = makeStyles<TDefaultTheme, TTreeHeaderProps>((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '2px solid transparent',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    fontSize: '14px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    textAlign: 'left',
  },
  visible: {
    /**
     * There isn't a suitable color in our DefaultPalette that matches the tree,
     * so we're going with the same color as a hovered tree item.
     *
     * TODO: update tree items to follow color palette
     */
    backgroundColor: '#5079A2',
    '&:focus': {
      borderColor: DefaultPalette.primaryLight,
    },
  },
  empty: {
    minHeight: '19px',
  },
  name: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type TTreeHeaderProps = {
  status: 'empty' | 'visible';
  icon?: JSX.Element;
  name?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function TreeHeader(props: TTreeHeaderProps) {
  const { status, icon, name, onClick } = props;
  const styles = useStyles(props);

  switch (status) {
    case 'visible':
      return (
        <button
          data-automation-id="treeHeader"
          className={`${styles.root} ${styles.visible}`}
          onClick={onClick}
        >
          {icon}
          <span className={styles.name}>{name}</span>
        </button>
      );
    case 'empty':
    default:
      return <div data-automation-id="treeHeader" className={`${styles.root} ${styles.empty}`} />;
  }
}
