import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { EnhancedListViewContainer } from 'modules/pub-docs/containers/ListViewContainer';
import { EnhancedTreeViewContainer } from 'modules/pub-docs/containers/TreeViewContainer';

type TMainViewProps = {
  siteKey: string;
  documentId?: string;
  view?: 'list' | 'tree';
};

// TODO: Remove MainView since the branching logic can be performed in Router instead.
const MainView: React.SFC<RouteComponentProps<TMainViewProps>> = ({ match, history }) => {
  const { view, siteKey, documentId } = match.params;

  return (
    <div className="pubDocsMainView">
      {view === 'list' && (
        <EnhancedListViewContainer siteKey={siteKey} documentId={documentId} history={history} />
      )}
      {view === 'tree' && (
        <EnhancedTreeViewContainer siteKey={siteKey} documentId={documentId} history={history} />
      )}
    </div>
  );
};

export { MainView };
