'use strict';

export enum ModuleType {
  Alerts = 'Alerts',
  Assessments = 'Assessments',
  Policy = 'Policy',
  Standards = 'Standards',
  Survey = 'Survey',
  Test = 'Test',
  Training = 'Training',
}
