'use strict';

export class UploadEntry {
  public name: string;
}

export function extractFullFileName(fullName: string) {
  const nameSplit = fullName.split('.');

  return {
    fullName: fullName,
    name: nameSplit[0],
    extension: nameSplit.length > 1 ? nameSplit[nameSplit.length - 1].toLowerCase() : undefined,
  };
}

export class FileEntry extends UploadEntry {
  public extension: string;
  public browserEntry: HTMLFormElement;
  public fullName: string;

  constructor(browserEntry) {
    super();
    this.browserEntry = browserEntry;

    const nameExtraction = extractFullFileName(this.browserEntry.name);
    this.name = nameExtraction.name;
    this.extension = nameExtraction.extension;
    this.fullName = nameExtraction.fullName;
  }
}

export class InputFileEntry extends FileEntry {
  public input: any;

  constructor(browseInput, browserEntry) {
    super(browserEntry);
    this.input = browseInput;
  }
}
