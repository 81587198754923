'use strict';

export function requestInterval(fn, delay) {
  const handle: any = {};
  let startTime = new Date().getTime();

  function loop() {
    handle.value = requestAnimationFrame(loop);

    const timeDelta = new Date().getTime() - startTime;

    if (timeDelta >= delay) {
      fn.call();

      startTime = new Date().getTime();
    }
  }

  handle.value = requestAnimationFrame(loop);

  return handle;
}

export function clearRequestInterval(handle) {
  window.cancelAnimationFrame(handle.value);
}
