'use strict';

export class NotificationTemplate {
  static DmsUpdate = 'dms_update';
  static ForcedDmsUpdate = 'forced_dms_update';
  static Generic = 'generic';
  static ImportDocError = 'import_doc_error';
  static ImportDocSuccess = 'import_doc_success';
  static InternetConnected = 'internet_connected';
  static InternetDisconnected = 'internet_disconnected';
  static InternetReconnecting = 'internet_reconnecting';
  static SiteConnected = 'site_connected';
  static UnableToLoadApp = 'unable_to_load_app';
  static UpdateDocSuccess = 'update_doc_success';
  static WebsocketsFirewall = 'websockets_firewall';
  static Logout = 'logout';
}
