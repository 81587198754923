import * as bowser from 'bowser';
import { Logger } from 'modules/main/services/js-logger-extended';

export function browserCheck(onBrowserCheckComplete: () => void) {
  if (bowser.msie && parseInt(<string>bowser.version, 10) < 11) {
    if (document.readyState !== 'loading') {
      onBrowserCheckComplete();
    } else {
      document.addEventListener('DOMContentLoaded', onBrowserCheckComplete);
    }

    Logger.error('ERROR: Browser version not supported');
  }
}
