/**
 * IMPORTANT: Extension functions should be pure and contains NO business logic whatsoever. If it
 * doesn't sound like a lodash function or if it needs any other import, there's a good chance that
 * it doesn't belong here.
 *
 * NOTE: LoDashStatic interface is extended in 'powerdms.d.ts' so be sure to keep these two in-sync
 * as we add/remove/modify functionalities.
 */
import * as _ from 'lodash';
export default _;
module.exports = _;

// In the latest version of lodash
export const isNil = function(value: any): boolean {
  return _.isNull(value) || _.isUndefined(value);
};

export const isNonEmptyString =
  _.isNonEmptyString ||
  function(value: string): boolean {
    return _.isString(value) && value.length > 0;
  };

_.mixin({
  isNil,
  isNonEmptyString,
});
