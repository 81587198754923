import { createStore, combineReducers, applyMiddleware } from 'redux';
import { default as createSagaMiddleware } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { documentsReducer } from './reducers/documentsReducer';
import { siteInfoReducer } from './reducers/siteInfoReducer';
import { treeReducer } from './reducers/treeReducer';
import { bootstrapSaga } from './sagas/mainSaga';
import { documentsSaga } from './sagas/documentsSaga';
import { siteInfoSaga } from './sagas/siteInfoSaga';
import { treeSaga } from './sagas/treeSaga';

export type TApplicationState = {
  documents: ReturnType<typeof documentsReducer>;
  siteInfo: ReturnType<typeof siteInfoReducer>;
  tree: ReturnType<typeof treeReducer>;
};

const sagaMiddleware = createSagaMiddleware();

export default createStore(
  combineReducers({ documents: documentsReducer, siteInfo: siteInfoReducer, tree: treeReducer }),
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(bootstrapSaga);
sagaMiddleware.run(documentsSaga);
sagaMiddleware.run(siteInfoSaga);
sagaMiddleware.run(treeSaga);
