/**
 *  TODO: Drop DMS prefix from this component
 *  - this was not updated because there is an enum called Icon
 *    that is extensively used throughout the codebase
 */

import * as _ from 'lodash';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ReactDirective } from 'ngreact';

import { DmsIconMappings } from 'modules/presentation/services/DmsIconMappings';
import { Icon } from 'modules/presentation/enums';

export type TDmsIconProps = {
  className?: string;
  icon: Icon;
  iconTitle?: string;
  useFontColor?: boolean;
};

const getIconClass = ({ icon, useFontColor }: TDmsIconProps): string => {
  const iconClass = DmsIconMappings.iconClasses[icon];
  // Some icons require a "pow" or "pow-fc" prefix.
  const prefix = useFontColor ? 'pow' : 'pow-fc';
  const completeClass = needsPowPrefix(icon) ? `${prefix} ${iconClass}` : iconClass;

  return completeClass;
};

/**
 * Some icons do not require the "<i></i>" inside the object icon.
 * TODO: Perhaps we could change the CSS instead of hiding the DOM?
 */
const hasInnerITag = (icon: Icon): boolean => {
  return icon !== Icon.EventLog && icon !== Icon.Message;
};

/**
 * The rule of thumbs is that if an icon has a "fa" prefix,
 * it doesn't need a "pow" or "pow-fc" prefix.
 */
const needsPowPrefix = (icon: Icon): boolean => {
  return (
    icon !== Icon.Delete &&
    icon !== Icon.Error &&
    icon !== Icon.Message &&
    icon !== Icon.OneDrive &&
    icon !== Icon.Refresh &&
    icon !== Icon.SearchSuggestion &&
    icon !== Icon.Success &&
    icon !== Icon.StorageProvidersRoot &&
    icon !== Icon.Tag
  );
};

const DmsIcon: React.SFC<TDmsIconProps> = (props: TDmsIconProps): JSX.Element => {
  const iconInner = hasInnerITag(props.icon) ? <i /> : null;

  const customClass = _.isNonEmptyString(props.className) ? props.className + ' ' : '';

  return (
    <span
      data-automation-id={`dmsIcon-${props.icon}`}
      className={`${customClass}${getIconClass(props)}`}
      title={props.iconTitle}
    >
      {iconInner}
    </span>
  );
};

DmsIcon.defaultProps = {
  useFontColor: false,
};

DmsIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(Icon)).isRequired,
  iconTitle: PropTypes.string,
  useFontColor: PropTypes.bool,
};

export { DmsIcon };

export class dmsIconDirective implements ng.IDirective {
  public static $inject = ['reactDirective'];

  constructor(reactDirective: ReactDirective) {
    /**
     * TODO: Find a way to type this properly.
     * It should be "React.ComponentClass<TDmsIconProps>"
     */
    return reactDirective(DmsIcon as any);
  }
}
