import { useEffect } from 'react';

function useLoadPdf(webViewerInstance, pdfUrl) {
  useEffect(
    () => {
      if (webViewerInstance && webViewerInstance.current) {
        webViewerInstance.current.loadDocument(pdfUrl, { extension: 'pdf' });
      }
    },
    [pdfUrl],
  );
}

export { useLoadPdf };
