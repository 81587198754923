import { createMuiTheme } from '@material-ui/core/styles';
import * as Roboto from 'assets/fonts/roboto-regular.woff';
import * as RobotoBold from 'assets/fonts/roboto-bold.woff';
import * as RobotoLight from 'assets/fonts/roboto-light.woff';
import * as RobotoLightItalic from 'assets/fonts/roboto-lightitalic.woff';

import { DefaultPalette } from '../enums/DefaultPalette';

const roboto = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${Roboto}) format('woff')`,
};

const robotoBold = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `url(${RobotoBold}) format('woff')`,
};

const robotoLight = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `url(${RobotoLight}) format('woff')`,
};

const robotoLightItalic = {
  fontFamily: 'Roboto',
  fontStyle: 'italic',
  fontWeight: 300,
  src: `url(${RobotoLightItalic}) format('woff')`,
};

export const defaultTheme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [roboto, robotoBold, robotoLight, robotoLightItalic],
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 605,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    allVariants: {
      color: DefaultPalette.darkText,
    },
    h1: {
      fontSize: '2rem',
      lineHeight: 1,
      fontWeight: 300,
      letterSpacing: '-0.01em',
    },
    h2: {
      fontSize: '1.75rem',
      lineHeight: 1.143,
      fontWeight: 400,
      letterSpacing: '-0.01em',
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: 1.167,
      fontWeight: 400,
      letterSpacing: '-0.01em',
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: '-0.008em',
    },
    h5: {
      fontSize: '1rem',
      lineHeight: 1.25,
      fontWeight: 400,
      letterSpacing: '-0.006em',
    },
    h6: {
      fontSize: '0.875rem',
      lineHeight: 1.143,
      fontWeight: 400,
      letterSpacing: '-0.003em',
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.143,
      fontWeight: 400,
      letterSpacing: '0em',
    },
    body2: {
      fontSize: '1rem',
      lineHeight: 1.25,
      fontWeight: 400,
      letterSpacing: '-0.003em',
    },
    button: {
      fontSize: '0.875rem',
      lineHeight: 1.429,
      fontWeight: 700,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.333,
      fontWeight: 400,
      letterSpacing: '0em',
    },
    overline: {
      fontSize: '0.6875rem',
      lineHeight: 1.273,
      fontWeight: 400,
      letterSpacing: '0em',
      textTransform: 'none',
    },
  },
});

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    buttonCompact: Typography['button'];
    // Our design system makes no use of these
    subtitle1: never;
    subtitle2: never;
  }
}

defaultTheme.typography.buttonCompact = {
  ...defaultTheme.typography.button,
  fontSize: '0.8125rem',
  lineHeight: 1.231,
};

export default defaultTheme;

export type TDefaultTheme = typeof defaultTheme;
