import * as _ from 'lodash';

import { DmsObject } from 'modules/dms-object/models/dms-object';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';

import { ICourseSection } from './i-section';
import { CoursesService } from 'modules/course/services';

export class Course extends DmsObject {
  /**
   * NOTE: Private members (prepended with _) have a setter that broadcast an event with payload
   * whenever their values changed. If you need to do the same thing to the other public members,
   * follow the pattern.
   */
  public certificateCount: number;
  public description: string;
  public dueOn: string;
  public enabled: boolean;
  public endOn: string;
  public hasAdminPage = true;
  public hasClientPage = true;
  private _number: string;
  public numOpenSections: number;
  public sections: ICourseSection[];
  private _type: string;

  public static $inject = ['$injector', 'CoursesService'];

  constructor(
    protected $injector: ng.auto.IInjectorService,
    protected coursesService: CoursesService,
  ) {
    super($injector);
  }

  public canBookmark(): boolean {
    return true;
  }

  public getAdminPath(): string {
    return `/courses/${encodeURIComponent(this.id)}/admin`;
  }

  public getClientPath(): string {
    return `/courses/${encodeURIComponent(this.id)}`;
  }

  public getDesignPath(sectionId: string = null): string {
    const sectionIdParam =
      _.isString(sectionId) && sectionId.length > 0 ? `&sectionid=${sectionId}` : '';
    const url = `/client/OnlineTrainingWorkspace.aspx?CourseID=${this.id}`;

    return `${url}${sectionIdParam}`;
  }

  public getDisplayName(): string {
    return this.coursesService.getDisplayName(this);
  }

  public getSectionAdminPath(sectionId: string): string {
    return `${this.getAdminPath()}?sectionId=${encodeURIComponent(sectionId)}`;
  }

  public getSectionClientPath(sectionId: string): string {
    return `/courses/sections/${encodeURIComponent(sectionId)}`;
  }

  public refreshModel(rawDmsObject: ISearchResult) {
    super.refreshModel(rawDmsObject);

    this.certificateCount = rawDmsObject.certificateCount;
    this.description = rawDmsObject.description;
    this.dueOn = rawDmsObject.dueOn;
    this.enabled = rawDmsObject.enabled;
    this.endOn = rawDmsObject.endOn;
    this.number = rawDmsObject.number;
    this.numOpenSections = rawDmsObject.numOpenSections;
    this.sections = rawDmsObject.sections;
    this.type = rawDmsObject.type;
  }

  public get number(): string {
    return this._number;
  }
  public set number(value: string) {
    this.setAndBroadcast('number', value);
  }

  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this.setAndBroadcast('type', value);
  }
}
