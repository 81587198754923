import * as H from 'history';
import * as React from 'react';
import { isString } from 'lodash';
import { isNil } from 'modules/main/services/lodash-extended';

import { Keys } from 'locales/keys';
import { getDefaultTreeItemHeight } from 'modules/dms-object/components/DmsObjectTree';
import { ObjectType } from 'modules/dms-object/enums/object-type';
import { ContentNodeCreationState, ContentNode } from 'modules/dms-object/models/content-node';
import {
  IDefaultDmsObjectTreeItem,
  IEmptyDmsObjectTreeItem,
} from 'modules/dms-object/models/IDmsObjectTreeItem';
import { IDmsObjectTreeRoot } from 'modules/dms-object/models/IDmsObjectTreeRoot';
import { DmsObjectService } from 'modules/dms-object/services/dms-object-service';
import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';
import { DataStatus } from 'modules/main/enums/data-status';
import { LocalizationService } from 'modules/main/services/localization-service';
import { Icon } from 'modules/presentation/enums/icon';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';

type TTreeConverterProps = {
  children: (tree: IDmsObjectTreeRoot) => JSX.Element;
  openedFolders: { [id: string]: boolean };
  isCompact: boolean;
  selectedDocumentId: string;
  onDocumentClick: (document) => void;
  documents: TPublicDoc[];
  toggleFolder: (id: string) => void;
  siteName?: string;
};

/**
 * There's a lot of properties on "IDefaultDmsObjectTreeItem" that should be optional, but aren't,
 * mostly related to drag 'n' drop. For these, we're going to use this "mockFunction",
 * and ensure that drag 'n' drop is disabled.
 */
const mockFunction = () => ({} as any);
const optionalProperties = {
  cancelCreation: mockFunction,
  creationState: ContentNodeCreationState.Idle,
  createNewFolder: mockFunction,
  dragDropLoadingStatus: DataStatus.initial,
  errorText: 'MAKE THIS OPTIONAL',
  isBeingDragged: false,
  isImported: false,
  isImporting: false,
  isTemp: false,
  manageDropDownList: [],
  resetCreationError: mockFunction,
};

function isISearchResult(object: any): object is ISearchResult {
  return isString((object as ISearchResult).objectType);
}

function sortContents(treeItem: IDefaultDmsObjectTreeItem): IDefaultDmsObjectTreeItem {
  const isDocument = isNil(treeItem.content);
  const isEmptyFolder = !isDocument && treeItem.content.length === 0;

  if (isDocument || isEmptyFolder) {
    return treeItem;
  }

  return {
    ...treeItem,
    content: treeItem.content.sort(compareTypeThenName).map(sortContents),
  };
}

function compareTypeThenName(a: IDefaultDmsObjectTreeItem, b: IDefaultDmsObjectTreeItem) {
  if (a.objectType === ObjectType.Folder && b.objectType === ObjectType.Document) {
    return -1;
  }

  if (a.objectType === ObjectType.Document && b.objectType === ObjectType.Folder) {
    return 1;
  }

  if (a.objectName.name === b.objectName.name) {
    return 0;
  }

  return a.objectName.name.localeCompare(b.objectName.name, undefined, { numeric: true });
}

export class TreeConverter extends React.Component<TTreeConverterProps, {}> {
  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    const tree = this.generateSortedDocumentsTree(this.props.documents, this.props.siteName);

    return this.props.children(tree);
  }

  private generateDocumentsTreeItem(
    list: TPublicDoc[] = [],
    siteName?: string,
  ): IDefaultDmsObjectTreeItem {
    const content = [];
    const document: ISearchResult = {
      id: null,
      name: siteName || LocalizationService.localize(Keys.SearchType.Documents, {}),
      objectType: ObjectType.Folder,
    };

    if (list.length === 0) {
      content.push(this.generateEmptyTreeItem());
    }

    return {
      ...this.generateTreeItem(document),
      content,
      objectIcon: {
        icon: Icon.Folder,
      },
    };
  }

  private generateEmptyTreeItem(): IEmptyDmsObjectTreeItem {
    const text = LocalizationService.localize(Keys.Navigation.SideMenu.NoItemsToDisplay, {
      itemTypes: LocalizationService.localize(Keys.SearchType.Documents, {}).toLowerCase(),
    });

    return {
      text,
      height: getDefaultTreeItemHeight(this.props.isCompact),
      key: 'Empty',
    };
  }

  private generateSortedDocumentsTree(
    list: TPublicDoc[] = [],
    siteName: string,
  ): IDmsObjectTreeRoot {
    const folderMap: { [id: string]: IDefaultDmsObjectTreeItem } = {};
    const rootTreeItem = this.generateDocumentsTreeItem(list, siteName);
    const documentsRootTreeItem = list.reduce((tree, pubDoc) => {
      const { breadcrumbs } = pubDoc;
      const pubDocTreeItem = this.generateTreeItem(pubDoc);
      const trail = !isNil(breadcrumbs[0]) ? breadcrumbs[0].trail : [];
      let currentFolder = tree;

      trail.forEach((folder) => {
        const existingFolder = folderMap[folder.id];

        if (!isNil(existingFolder)) {
          currentFolder = existingFolder;
        } else {
          const folderTreeItem = this.generateTreeItem(folder);

          folderMap[folder.id] = folderTreeItem;
          currentFolder.content.push(folderTreeItem);
          currentFolder = folderTreeItem;
        }
      });

      currentFolder.content.push(pubDocTreeItem);

      return tree;
    }, rootTreeItem);
    let content = [documentsRootTreeItem];

    if (list.length > 0) {
      content = [sortContents(documentsRootTreeItem)];
    }

    return { content };
  }

  private generateTreeItem(item: TPublicDoc | ISearchResult): IDefaultDmsObjectTreeItem {
    const { id, name } = item;

    /**
     * All TPublicDocs are all Documents and all ISearchResults are Folders.
     * TPublicDocs don't have "objectType", so need to supplement it.
     */
    const isFolder = isISearchResult(item);
    const icon: Icon = isFolder ? Icon.Folder : Icon.Document;
    const objectType: ObjectType = isFolder ? ObjectType.Folder : ObjectType.Document;
    const isSelected = !isFolder && this.isSelected(id);
    const treeItem: IDefaultDmsObjectTreeItem = {
      id,
      objectType,
      // We're mocking a ContentNode object to only the things we need for auto-scrolling.
      getOriginalItem: () =>
        ({
          dmsObject: {
            rawDmsObject: {
              id,
              objectType,
            },
          },
        } as ContentNode),
      height: getDefaultTreeItemHeight(this.props.isCompact),
      isItemSelected: isSelected,
      key: DmsObjectService.generateObjectKey({ ...item, objectType }),
      objectIcon: { icon },
      objectName: { name },
      ...optionalProperties,
    };

    /**
     * We're not using the isFolder boolean here because typescript isn't smart enough to realize
     * it's a type guard, so instead we're manually calling the type guard again
     */
    if (isISearchResult(item)) {
      treeItem.content = [];
      treeItem.isCollapsed = this.folderIsCollapsed(id);
      treeItem.onToggleCollapse = () => {
        this.props.toggleFolder(id);
      };
    } else {
      treeItem.objectLink = item.publicUrl;

      treeItem.onClick = (event: Event) => {
        event.preventDefault();

        this.props.onDocumentClick && this.props.onDocumentClick(item);
      };
    }

    return treeItem;
  }

  private folderIsCollapsed(id: string): boolean {
    return !Boolean(this.props.openedFolders && this.props.openedFolders[id]);
  }

  private isSelected(id: string): boolean {
    return !isNil(this.props.selectedDocumentId) && this.props.selectedDocumentId === id;
  }
}
