import * as React from 'react';
import * as clsx from 'clsx/dist/clsx';
import { isNil } from 'modules/main/services/lodash-extended';

export type TIconMetaLayout = {
  /** Typically used with a `<DMSIcon />` or `<DMSObjectIcon />` wrapped in an HTML element. */
  icon: JSX.Element;
  /** The larger text. Names and describes the context. */
  primaryContent: React.ReactNode;
  /** Smaller text. Can be used to describe where the context comes from.  */
  secondaryContent?: React.ReactNode;
  /** Determines whether `secondaryContent` appears on the top line or the bottom line */
  secondaryContentLocation?: 'above' | 'below';
  className?: string;
  /** Enables the content to cut off when it reaches the edge of the parent container */
  ellipseContent?: boolean;
};

const shouldShowSecondaryAbovePrimary = (
  secondaryContent: React.ReactNode,
  secondaryContentLocation = 'above',
) => {
  return !isNil(secondaryContent) && secondaryContentLocation === 'above';
};

const shouldShowSecondaryBelowPrimary = (
  secondaryContent: React.ReactNode,
  secondaryContentLocation = 'above',
) => {
  return !isNil(secondaryContent) && secondaryContentLocation === 'below';
};
/** This component is used as a layout; a building block for other components such as
 * `<JobProgressNotification />` and `<DocumentHeader />`.
 *
 * Typically, we would want to stay away from using this component on its own
 * in favor of using a reusable component that already uses this layout.
 * */
const IconMetaLayout: React.FC<TIconMetaLayout> = (props: TIconMetaLayout) => {
  const {
    secondaryContent,
    primaryContent,
    className,
    ellipseContent,
    secondaryContentLocation = 'above',
  } = props;
  const ellipseClass = ellipseContent && 'ellipse';

  return (
    <div className={clsx('iconMetaLayout', className, ellipseClass)}>
      <div>{props.icon}</div>
      <div className="iconMetaLayout_content">
        {shouldShowSecondaryAbovePrimary(secondaryContent, secondaryContentLocation) && (
          <div className={ellipseClass}>{secondaryContent}</div>
        )}
        <div className={ellipseClass}>{primaryContent}</div>
        {shouldShowSecondaryBelowPrimary(secondaryContent, secondaryContentLocation) && (
          <div className={ellipseClass}>{secondaryContent}</div>
        )}
      </div>
    </div>
  );
};

export { IconMetaLayout };
