import * as React from 'react';

import { Keys } from 'locales/keys';
import { Localizer } from 'modules/main/components/Localizer';

import * as logoUrl from 'assets/images/PowerDMS-Footer-Logo-color.png';

const PoweredByDms: React.SFC<{}> = () => {
  return (
    <div className="pubDocsPoweredByDms">
      <Localizer translation={Keys.PublicDocs.PoweredBy} />
      &nbsp;
      <img
        src={logoUrl}
        className="pubDocsPoweredByDms_logo"
        alt="PowerDMS Footer Logo"
        title="PowerDMS Footer Logo"
      />
    </div>
  );
};

export { PoweredByDms };
