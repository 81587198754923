import { TSiteInfoAction, SiteInfoActionType } from 'modules/pub-docs/actions/siteInfoActions';
import { TSiteInfo } from 'modules/pub-docs/models/TSiteInfo';

export type TSiteInfoState = {
  data: TSiteInfo;
  isLoading: boolean;
  errorMessage: string;
};

export const initialState: TSiteInfoState = {
  data: {},
  isLoading: false,
  errorMessage: undefined,
};

export function siteInfoReducer(state = initialState, action: TSiteInfoAction): TSiteInfoState {
  switch (action.type) {
    case SiteInfoActionType.FetchStart: {
      return { ...state, isLoading: true, errorMessage: undefined };
    }
    case SiteInfoActionType.FetchFail: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.error.message || 'Failed to get site info.',
      };
    }
    case SiteInfoActionType.FetchSuccess: {
      return { ...state, isLoading: false, data: action.payload.data };
    }
    default:
      return state;
  }
}
