import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { DefaultPalette } from 'modules/presentation/enums/DefaultPalette';

// Todo: Once we build out the Page component, lets move this over there
export type TPageSize = 'full' | 'narrow';

type TPageHeaderProps = {
  children: React.ReactNode;
  size?: TPageSize;
};

const useStyles = makeStyles<unknown, TPageHeaderProps>({
  content: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  header: {
    alignItems: 'center',
    background: DefaultPalette.grayLight,
    boxSizing: 'border-box',
    display: 'flex',
    /**
     * IE11 hack: In order for a Flex element to center vertically,
     * it needs a set "height" property.
     * In this case, the "minHeight" property will override the "height".
     */
    height: 1,
    minHeight: 70,
    padding: '0px 20px',
  },
  narrowContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1100,
  },
});

function PageHeader(props: TPageHeaderProps): JSX.Element {
  const { children, size } = props;
  const styles = useStyles(props);
  const narrowStyle = size == 'narrow' ? styles.narrowContent : '';

  return (
    <header className={styles.header} data-automation-id="pageHeader">
      <div className={`${styles.content} ${narrowStyle}`}>{children}</div>
    </header>
  );
}

PageHeader.defaultProps = {
  size: 'full',
};

export { PageHeader };
