import * as React from 'react';

import { DataFetchState } from 'modules/pub-docs/enums/DataFetchState';

type TIframeProps = {
  documentUrl: string;
  disablePointerEvents?: boolean;
  setFileFetchState?: (fetchState: DataFetchState) => void;
};

const IFrame: React.FC<TIframeProps> = ({
  disablePointerEvents = false,
  documentUrl,
  setFileFetchState,
}) => {
  return (
    <iframe
      /**
       * An iframe's src change triggers a history push. That side effect pollutes the global
       * history in that the "back" button works unexpectedly (it rewinds the iframe's src, not the
       * app's URL). To avoid the src change, we should NOT re-use the iframe element when the src
       * change, but create a new instance of the iframe by giving it a different key when the
       * documentUrl changes.
       */
      key={documentUrl}
      className="fileViewerIframe"
      src={documentUrl}
      style={{ pointerEvents: disablePointerEvents ? 'none' : 'auto' }}
      role="presentation"
      onLoad={() => setFileFetchState && setFileFetchState(DataFetchState.Data)}
    />
  );
};

export { IFrame };
