import * as React from 'react';
import { DragLayer, DragLayerMonitor } from 'react-dnd';

import { IDmsObjectTreeItem } from 'modules/dms-object/models/IDmsObjectTreeItem';
import { IFlatListItem, isIFlatListItem } from 'modules/presentation/services/dms-tree-service';
import { isNil } from 'modules/main/services/lodash-extended';

export const DEFAULT_TRANSITION_DURATION = 333;

enum DragLayerState {
  IDLE = 'IDLE',
  DRAGGING = 'DRAGGING',
  RETURNING = 'RETURNING',
}

interface IDragLayerComponentProps {
  depthBlockWidth: number;
  renderDraggedItem: (item: IFlatListItem<IDmsObjectTreeItem>, widthOffset: number) => JSX.Element;
  width: number;
  returnTransitionDuration?: number;
  getReturnPosition: (draggedItem: IFlatListItem<IDmsObjectTreeItem>) => __ReactDnd.ClientOffset;
  /**
   * Needed to reconcile the absolutely-positioned (as opposed to fixed) drag layer and the coords
   * returned by DragLayerMonitor.getSourceClientOffset(), whose origin is (0,0).
   */
  topOffset: number;
}

interface IDragLayerCollectorProps extends IDragLayerComponentProps {
  item: IFlatListItem<IDmsObjectTreeItem>;
  isDragging: boolean;
}

class CustomDragLayer extends React.Component<IDragLayerCollectorProps, {}> {
  private cachedDragPreview: JSX.Element;
  private cachedDraggedItem: IFlatListItem<IDmsObjectTreeItem>;

  constructor(props) {
    super(props);
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  public componentDidMount(): void {
    dragPreviewWrapper.addEventListener('transitionend', this.handleTransitionEnd);
  }

  public componentDidUpdate(prevProps: IDragLayerCollectorProps): void {
    if (this.deriveDragStateFrom(prevProps) === DragLayerState.RETURNING) {
      this.animateReturn();
    }
  }

  public componentWillUnmount(): void {
    dragPreviewWrapper.removeEventListener('transitionend', this.handleTransitionEnd);
  }

  public render(): JSX.Element {
    const dragState = this.deriveDragStateFrom(this.props);
    let style: React.CSSProperties = {
      width: this.props.width,
      top: -this.props.topOffset,
    };

    if (dragState === DragLayerState.IDLE) {
      style = { ...style, display: 'none' };
    }

    if (dragState === DragLayerState.DRAGGING) {
      this.cachedDraggedItem = this.props.item;
      this.cachedDragPreview = this.props.renderDraggedItem(
        this.cachedDraggedItem,
        this.getWidthOffset(),
      );
    }

    return (
      <div className="dmsObjectTreeDragLayer" style={style}>
        <div className="dmsObjectTreeDragLayer_previewWrapper" ref={this.setDragPreviewWrapperRef}>
          {this.cachedDragPreview}
        </div>
      </div>
    );
  }

  private deriveDragStateFrom(props: IDragLayerCollectorProps) {
    if (
      !props.isDragging &&
      props.item === undefined &&
      this.cachedDraggedItem === undefined &&
      this.cachedDragPreview === undefined
    ) {
      return DragLayerState.IDLE;
    }

    if (props.isDragging) {
      return DragLayerState.DRAGGING;
    }

    if (
      !props.isDragging &&
      props.item === undefined &&
      this.cachedDraggedItem !== undefined &&
      this.cachedDragPreview !== undefined
    ) {
      return DragLayerState.RETURNING;
    }

    return DragLayerState.IDLE;
  }

  private setDragPreviewWrapperRef(ref: HTMLDivElement): void {
    // Prevent setting it to null so that we can call removeEventListener on cWU
    if (!isNil(ref)) {
      dragPreviewWrapper = ref;
    }
  }

  private getWidthOffset(): number {
    const depth = Math.max(this.cachedDraggedItem.treeItemIndices.length - 1, 0);
    return depth * this.props.depthBlockWidth;
  }

  private handleTransitionEnd(event: TransitionEvent): void {
    if (
      event.target instanceof HTMLElement &&
      event.target.className === 'dmsObjectTreeDragLayer_previewWrapper' &&
      event.propertyName === 'transform'
    ) {
      this.cachedDraggedItem = undefined;
      this.cachedDragPreview = undefined;
      /**
       * Since resetting the cached variables above won't trigger a render, force one more render so
       * that the dragState on the next cycle is set to IDLE, which would hide the whole drag layer.
       */
      this.setState({});
    }
  }

  private animateReturn(): void {
    if (!isNil(this.props.getReturnPosition)) {
      const { returnTransitionDuration = DEFAULT_TRANSITION_DURATION } = this.props;
      const offset = this.props.getReturnPosition(this.cachedDraggedItem);

      if (!offset) return;

      dragPreviewWrapper.style.transition = `transform ${returnTransitionDuration}ms ease-in-out`;
      dragPreviewWrapper.style.transform = `translate(${offset.x}px, ${offset.y}px)`;
    }
  }
}

let dragPreviewWrapper: HTMLElement;

const dragLayerCollector = (monitor: DragLayerMonitor) => {
  const draggedItem = monitor.getItem();
  const isDragging = isIFlatListItem(draggedItem) && monitor.isDragging();

  if (!isNil(dragPreviewWrapper)) {
    const offset = monitor.getSourceClientOffset() || monitor.getInitialClientOffset();

    // Doing the translation here for performance reasons:
    // https://github.com/react-dnd/react-dnd/issues/592
    if (isDragging && !isNil(offset)) {
      Object.assign(dragPreviewWrapper.style, {
        transform: `translate(${offset.x}px, ${offset.y}px)`,
        transition: 'transform 0ms ease-in-out',
      });
    }
  }

  return {
    isDragging,
    item: isDragging && isIFlatListItem(draggedItem) ? draggedItem : undefined,
  };
};

export const DmsObjectTreeItemDragLayer = DragLayer<IDragLayerComponentProps>(dragLayerCollector)(
  CustomDragLayer,
);
