import { DocumentsActionType, TDocumentsAction } from 'modules/pub-docs/actions/documentsActions';

import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';
import { ErrorCode } from 'modules/error/enums/ErrorCode';
import { AxiosError } from 'axios';

export type TDocumentState = {
  list: TPublicDoc[];
  status: 'init' | 'loading' | 'success' | 'error';
  errorCode: ErrorCode;
  errorMessage: string;
};

export const initialState: TDocumentState = {
  list: [],
  status: 'init',
  errorCode: undefined,
  errorMessage: undefined,
};

export function documentsReducer(state = initialState, action: TDocumentsAction): TDocumentState {
  switch (action.type) {
    case DocumentsActionType.FetchStart: {
      return { ...state, status: 'loading', errorCode: undefined, errorMessage: undefined };
    }
    case DocumentsActionType.FetchSuccess: {
      return { ...state, status: 'success', list: action.payload.list };
    }
    case DocumentsActionType.FetchFail: {
      const getErrorCode = (): ErrorCode => {
        /**
         * The CDN for pubdocs returns an error page for 403s, so we will not be able to check
         * against the normal properties in a ccmp response. To make sure we're handling the
         * possible error conditions, we'll check specifically for a regular Axios 403 before
         * checking for ccmp errors, and if none of that matches, then we'll return an unknown
         * error code so that the page doesn't appear to be infinitely loading.
         */
        if (
          !isErrorWithCcmpResponse(action.payload.error) &&
          action.payload.error.response?.status === 403
        ) {
          return ErrorCode.KeyNotFound;
        }

        return (
          // @ts-ignore
          (action.payload.error?.response?.data?.error?.code as ErrorCode) ?? ErrorCode.Unknown
        );
      };

      return {
        ...state,
        status: 'error',
        errorCode: getErrorCode(),
        errorMessage: action.payload.error.message || 'Failed to get public documents.',
      };
    }
    default:
      return state;
  }
}

function isErrorWithCcmpResponse(
  error: AxiosError<ICcmpResponse<TPublicDoc[]>> | AxiosError,
): error is AxiosError<ICcmpResponse<TPublicDoc[]>> {
  return (error as AxiosError<ICcmpResponse<TPublicDoc[]>>).response?.data?.error !== undefined;
}
