import * as React from 'react';
import { Provider } from 'react-redux';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { GoogleAnalytics } from 'modules/analytics/components/GoogleAnalytics';

import defaultTheme from 'modules/presentation/themes/defaultTheme';
import { PubDocsRouter } from './components/PubDocsRouter';
import { Routes as PubDocsRoutes } from './routes';
import store from './store';

export const App: React.SFC = () => (
  <>
    <GoogleAnalytics />
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <PubDocsRouter>
          <PubDocsRoutes />
        </PubDocsRouter>
      </ThemeProvider>
    </Provider>
  </>
);
