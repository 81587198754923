'use strict';

export interface IEventCallback<T> {
  (data?: T): void;
}

export interface IEventUnregisterFunction {
  (): void;
}

export class EventService<T> {
  private registeredCallbacks: Array<any>;

  constructor() {
    this.registeredCallbacks = [];
  }

  public clearRegistrations() {
    this.registeredCallbacks = [];
  }

  public notify(data?: T) {
    for (var callback of this.registeredCallbacks) {
      callback(data);
    }
  }

  public register(callback: IEventCallback<T>): IEventUnregisterFunction {
    this.registeredCallbacks.push(callback);

    //return unregister function
    return () => {
      var index = this.registeredCallbacks.indexOf(callback);

      if (index > -1) {
        this.registeredCallbacks.splice(index, 1);
      }
    };
  }
}
