import * as React from 'react';
import * as H from 'history';
import * as clsx from 'clsx/dist/clsx';
import { makeStyles } from '@material-ui/core/styles';
import { default as MenuIcon } from '@material-ui/icons/Menu';

import { Keys } from 'locales/keys';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';
import { Icon } from 'modules/presentation/enums/icon';
import { DmsIcon } from 'modules/presentation/components/DmsIcon';
import { IconMetaLayout } from 'modules/presentation/components/IconMetaLayout';
import { EllipsisLoader } from 'modules/presentation/components/EllipsisLoader';
import { PageHeader } from 'modules/presentation/components/PageHeader/PageHeader';
import { Localizer } from 'modules/main/components/Localizer';

import { Breadcrumbs } from './Breadcrumbs';
import { LocalizationService } from 'modules/main/services';
import { DefaultPalette } from 'modules/presentation/enums/DefaultPalette';

type THeaderProps = {
  breadcrumbTrail?: ISearchResult[];
  documentName?: string;
  hasDocumentsError?: boolean;
  headerIsLoading?: boolean;
  history?: H.History;
  siteKey?: string;
  siteName?: string;
};

const useStyles = makeStyles({
  header: {
    alignItems: 'center',
    flex: 1,
    // 24px is the width/height of the menu icon component
    maxWidth: 'calc(100% - 24px)',
  },
  headerIcon: {
    fontSize: '30px',
  },
  headerMainText: {
    fontSize: '30px',
    fontWeight: 300,
  },
  menuLink: {
    display: 'flex',
    alignItems: 'center',
    color: DefaultPalette.black,
    '&:hover': {
      color: DefaultPalette.primaryDark,
    },
  },
  docName: { margin: 0 },
  siteName: { margin: 0 },
});

export function Header(props: THeaderProps): JSX.Element | null {
  const {
    breadcrumbTrail = [],
    documentName,
    hasDocumentsError = false,
    headerIsLoading = false,
    history,
    siteKey,
    siteName,
  } = props;
  const styles = useStyles({});

  if (headerIsLoading) {
    return (
      <PageHeader>
        <EllipsisLoader />
      </PageHeader>
    );
  }

  if (documentName) {
    const hasBreadcrumb = breadcrumbTrail.length > 0;

    return (
      <PageHeader>
        <IconMetaLayout
          className={styles.header}
          icon={<DmsIcon className={styles.headerIcon} icon={Icon.Document} />}
          primaryContent={
            <>
              {hasBreadcrumb && <Breadcrumbs trail={breadcrumbTrail} />}
              <h1
                className={clsx(styles.headerMainText, styles.docName, 'ellipse')}
                data-automation-id="docName"
              >
                {documentName}
              </h1>
            </>
          }
        />

        {siteKey && history && (
          <a
            className={styles.menuLink}
            data-automation-id="menuLink"
            href={`/public/${siteKey}/tree`}
            onClick={(e) => {
              e.preventDefault();

              history.push(`/${siteKey}/tree`);
            }}
            title={LocalizationService.localize(Keys.PublicDocs.Menu, {})}
          >
            <MenuIcon />
          </a>
        )}
      </PageHeader>
    );
  }

  if (!hasDocumentsError && siteName) {
    return (
      <PageHeader>
        <h1
          className={clsx(styles.headerMainText, styles.siteName, 'ellipse')}
          data-automation-id="siteName"
        >
          {siteName}
        </h1>
      </PageHeader>
    );
  }

  return null;
}
