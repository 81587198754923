import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

import { Keys } from 'locales/keys';

import { ErrorCode } from 'modules/error/enums/ErrorCode';
import { Localizer } from 'modules/main/components/Localizer';
import { NotificationStyle } from 'modules/notification/enums';
import { Icon } from 'modules/presentation/enums/icon';
import { MessageBox } from 'modules/presentation/components/MessageBox';
import { Typography } from 'modules/presentation/components/Typography';
import { Link } from 'modules/presentation/components/Link/Link';

type TLoadFailureMessageBoxProps = {
  errorCode: ErrorCode;
  onRetry: () => void;
};

const useStyles = makeStyles({
  loadFailureMessageBox: {
    '& .messageBox_icon': {
      alignSelf: 'start',
      position: 'relative',
      top: '2px',
    },
  },
});

const LoadFailureMessageBox: React.SFC<TLoadFailureMessageBoxProps> = (props) => {
  const { errorCode, onRetry } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.loadFailureMessageBox}>
      <MessageBox icon={Icon.Warning} messageStyle={NotificationStyle.Error}>
        {(errorCode === ErrorCode.KeyNotFound || errorCode === ErrorCode.FeatureNotEnabled) && (
          <Typography variant="h4" component="h1">
            <Localizer translation={Keys.PublicDocs.NoSiteFound} />
          </Typography>
        )}

        {errorCode !== ErrorCode.FeatureNotEnabled && errorCode !== ErrorCode.KeyNotFound && (
          <>
            <Localizer translation={Keys.Navigation.SideMenu.LoadFailed} />

            <div className="loadFailureMessageBox_tryAgain" onClick={onRetry}>
              <Link monochrome="black">
                <Localizer translation={Keys.Navigation.SideMenu.TryAgain} />
              </Link>
            </div>
          </>
        )}
      </MessageBox>
    </div>
  );
};

export { LoadFailureMessageBox };
