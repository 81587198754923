import { useEffect } from 'react';

/**
 * If a user tries to visit pubdocs with the legacy url (powerdms.com/public/sitekey) without the
 * `www`, we want to redirect them to the proper url (the `www` is covered by proper
 * redirects). This ensures that we don't break existing links to pubdocs for customers that
 * mistyped the legacy URL and forgot the `www` but cannot update the links.
 */
export function useRedirectIfLegacyUrl() {
  useEffect(() => {
    /**
     * Doing an `includes` here instead of an equality check because the dev instance pageUrl is
     * `dev.powerdms.net/public` and the origin will be `dev.powerdms.net`
     */
    const pageOriginDoesNotMatch = !(window as any).pubDocsPageUrl.includes(window.location.origin);

    if (pageOriginDoesNotMatch) {
      const correctedPathname = window.location.pathname.replace('/public/', '');
      const redirectUrl = `${(window as any).pubDocsPageUrl}${correctedPathname}`;
  
      window.location.replace(redirectUrl);
    } 
  }, []);
};
