import { takeLeading } from 'redux-saga/effects';

import { TreeActionType, TSelectDocument } from 'modules/pub-docs/actions/treeActions';

export function* treeSaga() {
  yield takeLeading(TreeActionType.SelectDocument, selectDocumentSideEffects);
}

/**
 * TODO: Consider moving this out of sagas back into the component. In addition, consider
 * redux-connected router library like https://github.com/supasate/connected-react-router
 */
export function* selectDocumentSideEffects(action: TSelectDocument) {
  yield action.payload.history.push(action.payload.spaUrl);
}
