import * as _ from 'lodash';

import { StorageProviderManager } from 'modules/content-hub/managers';
import { Course } from 'modules/course/models/course';
import { CoursesService } from 'modules/course/services';
import { LocalizationService } from 'modules/main/services';
import { isNil } from 'modules/main/services/lodash-extended';
import { User } from 'modules/user/models/user';
import { UserService } from 'modules/user/services';

import { ITreeNode } from 'modules/dms-object/components/pow-object-tree/pow-object-tree';
import { ObjectType, Permission } from 'modules/dms-object/enums';
import { DmsObjectRepository } from 'modules/dms-object/managers';
import { DmsObject } from 'modules/dms-object/models';
import { DmsObjectResource } from 'modules/dms-object/resources';
import { ISearchResult } from 'modules/search/managers/search-manager-factory';
import { areDmsObjectsEqual } from 'modules/main/utilities/utils';
import { TNotification, TJobProgressNotification } from 'modules/notification/models/notification';
import { NotificationType } from 'modules/notification/enums/notification-type';

export class DmsObjectService {
  public static $inject = [
    '$q',
    'CoursesService',
    'DmsObjectRepository',
    'DmsObjectResource',
    'LocalizationService',
    'StorageProviderManager',
    'UserService',
  ];

  constructor(
    private $q: ng.IQService,
    private coursesService: CoursesService,
    private dmsObjectRepository: DmsObjectRepository,
    private dmsObjectResource: DmsObjectResource,
    private localizationService: LocalizationService,
    private storageProviderManager: StorageProviderManager,
    private userService: UserService,
  ) {}

  public static generateObjectKey(rawDmsObject: Partial<ISearchResult>) {
    const { id, objectType } = rawDmsObject;
    const objectKey = `${objectType}|${id}`;

    /**
     * We need a more specific key for Standards Nodes,
     * since they can live in multiple places, at once.
     */
    if (objectType === ObjectType.StandardsNode) {
      const parent = rawDmsObject.parentAccreditationObject;
      const parentObjectKey = `${parent.objectType}|${parent.id}`;

      return `${parentObjectKey}|${objectKey}`;
    }

    return objectKey;
  }

  public getDmsObject<T extends DmsObject>(
    objectType: ObjectType,
    id: any,
    ensureCallingContext: boolean = false,
  ): ng.IPromise<T> {
    /**
     * Have to cast to any due to typescript incorrectly complaining about the object below
     * not being an instance of DmsObject, which it is. This should be fixed in future
     * versions of typescript. Once we upgrade, let's remove the casting below.
     */
    switch (objectType) {
      case ObjectType.StorageProvider:
        return <ng.IPromise<T>>(<any>this.storageProviderManager.getProvider(id));
      case ObjectType.StorageProviderDocument:
      case ObjectType.StorageProviderFolder:
        const errorMessage =
          'Getting StorageProvider documents and folders is not supported for this function because the providerId is also required.';
        console.error(errorMessage);
        return this.$q.reject(errorMessage);
      default:
        /**
         * Todo: Once all the services for each object type have been built, we'll want to
         * use them instead of the dmsObjectResource.
         */
        return this.dmsObjectResource
          .getDmsObject(objectType, id, ensureCallingContext)
          .then((dmsObject) => {
            if (dmsObject?.parentAccreditationObject) {
              return this.dmsObjectRepository.getDmsObjectModel<T>({
                id: id,
                objectType: objectType,
                parentAccreditationObject: {
                  id: dmsObject.parentAccreditationObject.id,
                  objectType: dmsObject.parentAccreditationObject.objectType,
                  currentPublicationId: dmsObject.parentAccreditationObject.currentPublicationId,
                },
              });
            }

            return this.dmsObjectRepository.getDmsObjectModel<T>({
              id: id,
              objectType: objectType,
            });
          });
    }
  }

  public getDisplayName(dmsObject: DmsObject): string {
    // TODO: Once everything is coming through the models, we can get rid of this branching logic
    if (_.isUndefined(dmsObject)) {
      return '';
    }

    if (dmsObject instanceof Course) {
      return this.coursesService.getDisplayName(dmsObject);
    }

    if (dmsObject instanceof User) {
      return this.userService.getDisplayName(dmsObject);
    }

    return dmsObject.name;
  }

  /**
   * Get a trail of objects in outer-to-inner order.
   * We will shift each outermost object on iteration.
   */
  public getDmsObjectTrail(dmsObject: ISearchResult, rootContainer: ITreeNode): ISearchResult[] {
    if (!_.isObject(rootContainer)) {
      return;
    }

    const root = rootContainer.dmsObject.rawDmsObject;

    let objects = [dmsObject];

    if (dmsObject.objectType === ObjectType.StandardsNode) {
      const parentAccreditationObject = this.getParentAccreditationObject(dmsObject);

      if (_.isObject(parentAccreditationObject)) {
        /**
         * If isRootNode, the dmsObject and parentAccreditationObject are the same,
         * in which case we only use the parentAccreditationObject.
         */
        if (dmsObject.isRootNode) {
          objects = [parentAccreditationObject];
        } else {
          objects.unshift(parentAccreditationObject);
        }
      }
    } else {
      console.log(
        `Warning: Only Manuals and Assessments are currently supported`,
        `Attempted to get the object trail of ${dmsObject}`,
      );
    }

    objects.unshift(root);

    return objects;
  }

  public getParentAccreditationObject(dmsObject: ISearchResult): ISearchResult {
    const parentAccreditationObject = dmsObject.parentAccreditationObject;

    if (_.isObject(parentAccreditationObject)) {
      return this.dmsObjectRepository.sync(parentAccreditationObject);
    }
  }
}

export function getCascadingRights(dmsObject: ISearchResult): Permission {
  /**
   * Unfortunately the api is inconsistent with how it gives us permissions.
   * - isEditableWithCascade: returned via our search api.
   * - callingContext: returned via our GET metadata endpoints for all our dms objects.
   */
  if (!isNil(dmsObject)) {
    if (dmsObject.isEditableWithCascade) {
      return Permission.Edit;
    } else if (!isNil(dmsObject.callingContext)) {
      return dmsObject.callingContext.userCascadingPermissions;
    }
  }

  return Permission.None;
}

type TDmsObjectGetRightsProps = {
  isEditableWithCascade?: boolean;
  callingContext?: { userPermissions: Permission };
  permission?: Permission;
  rights?: Permission;
};
export function getRights(dmsObject: TDmsObjectGetRightsProps): Permission {
  /**
   * Unfortunately the api is inconsistent with how it gives us permissions.
   * - isEditableWithCascade: returned via our search api.
   * - callingContext: returned via our GET metadata endpoints for all our dms objects.
   * - permission: returned via our search api.
   */
  if (!isNil(dmsObject)) {
    if (dmsObject.isEditableWithCascade) {
      return Permission.Edit;
    } else if (!isNil(dmsObject.callingContext)) {
      return dmsObject.callingContext.userPermissions;
    } else if (!isNil(dmsObject.permission)) {
      return dmsObject.permission;
    } else if (!isNil(dmsObject.rights)) {
      return dmsObject.rights;
    }
  }

  return Permission.None;
}

export function hasDmsObjectWithEditRights(dmsObjects: DmsObject[]): boolean {
  return !isNil(dmsObjects) && dmsObjects.some((x) => hasMinimumRights(x.rights, Permission.Edit));
}

export function hasMinimumRights(
  rightsOrDmsObject: Permission | ISearchResult,
  minimumRights: Permission,
): boolean {
  if (isNil(rightsOrDmsObject) || isNil(minimumRights)) {
    return false;
  }

  const rights = _.isString(rightsOrDmsObject)
    ? rightsOrDmsObject
    : getRights(<ISearchResult>rightsOrDmsObject);

  return Permission.convertToNumber(rights) >= Permission.convertToNumber(minimumRights);
}

export function hasSomeMinimumRights(
  rightsOrDmsObjects: Permission[] | ISearchResult[],
  minimumRights: Permission,
): boolean {
  if (isNil(rightsOrDmsObjects) || isNil(minimumRights)) {
    return false;
  }

  return rightsOrDmsObjects.some((x) => hasMinimumRights(x, Permission.Edit));
}

export function isDmsObjectImporting(
  inProgressNotifications: TNotification[],
  dmsObject: { id: string; objectType: ObjectType },
): boolean {
  // filter inProgressNotifications to contain only TJobProgressNotification
  const jobProgressNotifications = inProgressNotifications.filter(
    (notification) => notification.type === NotificationType.JobProgressNotification,
  ) as TJobProgressNotification[];

  if (dmsObject.objectType === ObjectType.StorageProviderFolder) {
    return (
      jobProgressNotifications.findIndex((notification) =>
        areDmsObjectsEqual(
          { id: notification.meta.sourceFolderId, objectType: ObjectType.StorageProviderFolder },
          dmsObject,
        ),
      ) > -1
    );
  } else if (dmsObject.objectType === ObjectType.Folder) {
    return (
      jobProgressNotifications.findIndex((notification) =>
        areDmsObjectsEqual(notification.meta.destinationFolder, dmsObject),
      ) > -1
    );
  }

  return false;
}
