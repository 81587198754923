import { isNil } from 'modules/main/services/lodash-extended';
import { TTreeAction, TreeActionType } from 'modules/pub-docs/actions/treeActions';

export type TTreeState = {
  scrollToKey: string;
  openedFolders: Record<string, boolean>;
};

export const initialState: TTreeState = {
  /**
   * ID of the root item ("Documents"/site name) is null.
   *
   * TODO: Consider not making the ID null.
   */
  openedFolders: { null: true },
  /**
   * TODO: Rename this to be "selectedDocumentKey" because redux should not be cognizant of UI
   * functions.
   */
  scrollToKey: undefined,
};

export function treeReducer(state = initialState, action: TTreeAction): TTreeState {
  switch (action.type) {
    case TreeActionType.ToggleFolder: {
      const {
        payload: { folderId },
      } = action;

      const openedFolders = {
        ...state.openedFolders,
        [folderId]: isNil(state.openedFolders[folderId]) ? true : !state.openedFolders[folderId],
      };

      return { ...state, openedFolders };
    }
    case TreeActionType.OpenFolders: {
      const openedFolders = action.payload.folderIds.reduce(
        (openedFolders, id) => ({ ...openedFolders, [id]: true }),
        state.openedFolders,
      );

      return { ...state, openedFolders };
    }
    case TreeActionType.SetScrollToKey: {
      return { ...state, scrollToKey: action.payload.scrollToKey };
    }
    default:
      return state;
  }
}
