export enum WebsocketEvents {
  Action = 'action',
  Close = 'close',
  Connect = 'connect',
  ConnectError = 'connect_error',
  ConnectTimeout = 'connect_timeout',
  Connecting = 'connecting',
  DisruptiveFeatureDowngrade = 'disruptive_feature_downgrade',
  AuthorStatusNotification = 'author_status_notification',
  UserEvent = 'user_event',
  Error = 'error',
  ReconnectAttempt = 'reconnect_attempt',
  ReconnectError = 'reconnect_error',
  ReconnectFail = 'reconnect_fail',
  Reconnecting = 'reconnecting',
  Refresh = 'refresh',
  Update = 'update',
}
