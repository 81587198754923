export enum NotificationDtoType {
  BulkHubJobCompleted = 'BulkHubJobCompleted',
  BulkHubJobCancelled = 'BulkHubJobCancelled',
  BulkHubJobFailed = 'BulkHubJobFailed',
  BulkWorkflowAssignmentJobUpdated = 'BulkWorkflowAssignmentJobUpdated',
  BulkWorkflowAssignmentJobCompleted = 'BulkWorkflowAssignmentJobCompleted',
  BulkWorkflowAssignmentJobCanceled = 'BulkWorkflowAssignmentJobCanceled',
  BulkPublishDocumentJobUpdated = 'BulkPublishDocumentJobUpdated',
  BulkPublishDocumentJobCompleted = 'BulkPublishDocumentJobCompleted',
  BulkPublishDocumentJobCanceled = 'BulkPublishDocumentJobCanceled',
  BulkUploadDocumentJobUpdated = 'BulkUploadDocumentJobUpdated',
  BulkUploadDocumentJobCompleted = 'BulkUploadDocumentJobCompleted',
  BulkUploadDocumentJobCanceled = 'BulkUploadDocumentJobCanceled',
}
