/**
 * Hide text for sighted users, but remain accessible for screenreaders. Intended for use with
 * `makeStyles`. See https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html for more
 * info on the css.
 */
export function getScreenReaderOnlyStyles() {
  // Avoid type widening by using `as const`
  return {
    clip: 'rect(0 0 0 0)' as const,
    clipPath: 'inset(100%)' as const,
    height: 1 as const,
    overflow: 'hidden' as const,
    position: 'absolute' as const,
    whiteSpace: 'nowrap' as const,
    width: 1 as const,
  };
}
