// TODO: rename this file to default-dms-object-tree-item.tsx at the end of this rewrite project

import { ReactDirective } from 'ngreact';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import * as bowser from 'bowser';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';

import { Keys } from 'locales/keys';

import { TDefaultTheme } from 'modules/presentation/themes/defaultTheme';
import { getScreenReaderOnlyStyles } from 'modules/presentation/styles/base/screenReaderOnly';
import { ContentNodeCreationState } from 'modules/dms-object/models/content-node';
import { LocalizationService } from 'modules/main/services/localization-service';
import { DmsIcon } from 'modules/presentation/components/DmsIcon/DmsIcon';
import { Menu } from 'modules/presentation/components/deprecated-Menu/deprecated-Menu';
import { Popover, IPopoverProps } from 'modules/presentation/components/Popover/Popover';

import { DmsObjectIcon } from 'modules/dms-object/components/DmsObjectIcon';
import {
  PopoverArrowLocation,
  PopoverLocation,
  PopoverShowOn,
  Icon,
} from 'modules/presentation/enums';
import { IDefaultDmsObjectTreeItem } from 'modules/dms-object/models/IDmsObjectTreeItem';
import { DataStatus, KeyboardKey } from 'modules/main/enums';
import { Spinner, SpinnerSizes } from 'modules/presentation/components/Spinner';
import { isNonEmptyString } from 'modules/main/services/lodash-extended';
import { DmsObjectTreeItemContent } from './DmsObjectTreeItemContent';
import { getLocalizedText } from 'modules/dms-object/services/object-type-service';
import { ObjectType } from 'modules/dms-object/enums';

export interface IDmsObjectTreeItemProps {
  /** This property enables a user to view the expanded nodes of the tree upon
   *  reload of the page. For example, if a user is currently viewing a
   *  Standard on a Manual, the tree will auto-open up the Standard Manual
   *  nodes until the user is able to see the selected Standard in the tree. */
  autoOpenContainer?: boolean;
  /**
   * The HTML 5 Drag and Drop API doesn't play nicely with some pointer events including hover. This
   * results in the hover state getting "stuck" or incorrectly applied  during a drag operation. Our
   * low-impact work-around is to apply a class that ignores hover events when a drag is in effect.
   */
  disableCssHoverStyle?: boolean;
  handleKeyDown?: (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLSpanElement>,
    treeItemKey: string,
    treeItemContentRef: HTMLButtonElement | HTMLSpanElement,
    anchorRef: HTMLButtonElement | HTMLAnchorElement,
  ) => void;
  /** When the tree is compact, (shrink the tree by dragging the handle to the
   *  left). Everything eventually enters compact mode, including the tree items. */
  isCompact?: boolean;
  /** When an item is selected with space or enter, the item is already focused (via keyboard nav),
   * vs when you click on an item, something else is currently focused prior to the click. */
  isFocused: boolean;
  item: IDefaultDmsObjectTreeItem;
  setAriaLiveNotice: (treeItemKey: string) => void;
  setFocused: (key: string) => void;
  shouldRefocus: boolean;
  resetAfterRefocus: () => void;
  /** Enables the default tab index rather than the one created artificially by the component. */
  useDefaultTabIndex: boolean;
}

enum CreationFormState {
  Blurred = 'blurred',
  Error = 'error',
  Idle = 'idle',
  Loading = 'loading',
  MousedOut = 'mousedOut',
}
export interface IDmsObjectTreeItemState {
  isHovered: boolean;
  newFolderName: string;
  creationFormState: CreationFormState;
  msFocusWithin: boolean;
}

const useStyles = makeStyles<TDefaultTheme, {}>(() => ({
  screenReaderOnly: getScreenReaderOnlyStyles(),
}));

class DmsObjectTreeItem extends React.Component<
  IDmsObjectTreeItemProps,
  IDmsObjectTreeItemState,
  {}
> {
  static propTypes = {
    autoOpenContainer: PropTypes.bool,
    disableCssHoverStyle: PropTypes.bool,
    handleKeyDown: PropTypes.func,
    isCompact: PropTypes.bool,
    isFocused: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    useDefaultTabIndex: PropTypes.bool.isRequired,
  };

  static getDerivedStateFromProps(
    nextProps: IDmsObjectTreeItemProps,
    prevState: IDmsObjectTreeItemState,
  ) {
    const state: Partial<IDmsObjectTreeItemState> = {};

    if (
      nextProps.item.creationState === ContentNodeCreationState.Idle &&
      prevState.creationFormState !== CreationFormState.Idle
    ) {
      state.creationFormState = CreationFormState.Idle;
    } else if (
      nextProps.item.creationState === ContentNodeCreationState.Error &&
      prevState.creationFormState !== CreationFormState.Error
    ) {
      state.creationFormState = CreationFormState.Error;
    }

    if (nextProps.item.creationState !== ContentNodeCreationState.Idle) {
      state.isHovered = true;
    }

    if (!_.isEmpty(state)) {
      return state;
    }

    return null;
  }

  public readonly state: IDmsObjectTreeItemState = {
    isHovered: false,
    newFolderName: '',
    creationFormState: CreationFormState.Idle,
    // IE11 and Edge don't support the focus-within css selector, so we need to simulate it
    msFocusWithin: false,
  };

  public localize = LocalizationService.localize;

  public anchor: HTMLAnchorElement | HTMLButtonElement;
  private treeItemContent: HTMLButtonElement | HTMLSpanElement;

  private autoOpenElement: HTMLDivElement;
  private newFolderInput = React.createRef<HTMLInputElement>();
  private focusBorderOffset = 4;

  constructor(props: IDmsObjectTreeItemProps) {
    super(props);

    this.autoOpenAnimationAction = this.autoOpenAnimationAction.bind(this);
  }

  public componentDidMount(): void {
    /**
     * TODO: We don't like this. We want to have an onClick on the anchor tag,
     * but React doesn't seem to play nicely with event.preventDefault(),
     * so we're doing this, for now.
     */
    if (_.isFunction(this.props.item.onClick)) {
      this.anchor.addEventListener('click', (e: Event) => {
        this.props.item.onClick(e);
      });
    }

    // IE11 and Edge don't support the focus-within css selector, so we need to simulate it
    if (bowser.msie || bowser.msedge) {
      this.anchor.addEventListener('focus', () => this.setState({ msFocusWithin: true }));

      this.anchor.addEventListener('blur', () => this.setState({ msFocusWithin: false }));
    }

    if (this.props.autoOpenContainer) {
      this.autoOpenElement.addEventListener('transitionend', this.autoOpenAnimationAction);
    }

    /**
     * if we run this code for all mounts and not just ones that need to be refocused after a
     * remount (i.e. we had to scroll to it, or folders above it were opened), then any item that
     * was focused and then scrolled away from will automatically grab focus as soon as it mounts to
     * the DOM again with mouse scrolling, which basically breaks the whole tree scrolling and is
     * really jarring
     */
    if (this.props.isFocused && this.props.shouldRefocus) {
      this.anchor?.focus();
      this.props.resetAfterRefocus();
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    if (
      _.isObject(this.newFolderInput.current) &&
      prevState.creationFormState === CreationFormState.Idle
    ) {
      this.newFolderInput.current.focus();
    }

    if (prevProps.autoOpenContainer && !this.props.autoOpenContainer) {
      this.autoOpenElement.removeEventListener('transitionend', this.autoOpenAnimationAction);
    } else if (!prevProps.autoOpenContainer && this.props.autoOpenContainer) {
      this.autoOpenElement.addEventListener('transitionend', this.autoOpenAnimationAction);
    }

    /**
     * when you select an item with space or enter, the item is already focused (via keyboard nav),
     * vs when you click on an item, something else is currently focused prior to the click.
     * in pubdocs, the item retains its focus after space or enter is hit, but for some reason, the
     * main app seems to be stealing the focus for a millisecond after the doc is selected (via
     * click/enter/space), which removes the existing visual focus and the item never gets refocused
     * because prevProps.isFocused will be the same as this.props.isFocused. so we need to check for
     * the shouldRefocus value, which gets set when space or enter are hit
     */
    if (
      (!prevProps.isFocused || this.props.shouldRefocus) &&
      this.props.isFocused &&
      this.props.item.objectType !== ObjectType.StandardsNode
    ) {
      setTimeout(() => {
        this.anchor?.focus();
        if (this) this.props.resetAfterRefocus();
      }, 1);
    }
  }

  public componentWillUnmount(): void {
    if (_.isFunction(this.props.item.onClick)) {
      this.anchor.removeEventListener('click', this.props.item.onClick);
    }

    this.autoOpenElement.removeEventListener('transitionend', this.autoOpenAnimationAction);

    // IE11 and Edge don't support the focus-within css selector, so we need to simulate it
    if (bowser.msie || bowser.msedge) {
      this.anchor.removeEventListener('focus', () => this.setState({ msFocusWithin: true }));

      this.anchor.removeEventListener('blur', () => this.setState({ msFocusWithin: false }));
    }
  }

  public render() {
    const treeItem = this.props.item;

    return (
      <div
        className={this.getClassName()}
        onMouseOver={() => this.setHoveredState(true)}
        onMouseLeave={() => this.setHoveredState(false)}
        style={{ height: treeItem.height - this.focusBorderOffset }}
      >
        <DmsObjectTreeItemContent
          handleKeyDown={this.props.handleKeyDown}
          collapsedIcon={this.renderCollapsedIcon(treeItem)}
          isFocused={this.props.isFocused}
          objectIcon={<ObjectTreeIcon item={this.props.item} isCompact={this.props.isCompact} />}
          setAnchorRef={(ref) => {
            this.anchor = ref;
          }}
          setTreeItemContentRef={(ref) => {
            this.treeItemContent = ref;
          }}
          anchorRef={this.anchor}
          treeItemContentRef={this.treeItemContent}
          useDefaultTabIndex={this.props.useDefaultTabIndex}
          treeItem={treeItem}
          onItemClick={() => {
            this.props.setFocused(this.props.item.key);
            this.props.setAriaLiveNotice(this.props.item.key);
          }}
        />
        <div className="defaultDmsObjectTreeItem_actionIcon">
          {this.renderManageMenuOrBookmark(this.props)}
        </div>
        {this.renderAutoOpenContainerAnimation()}
      </div>
    );
  }

  private setHoveredState(isHovered: boolean) {
    if (this.state.isHovered !== isHovered) {
      this.setState({ isHovered });
    }
  }

  private createFolder(treeItem: IDefaultDmsObjectTreeItem) {
    this.setState({ creationFormState: CreationFormState.Loading });
    treeItem.createNewFolder(this.state.newFolderName).then(() => {
      this.setState({
        creationFormState: CreationFormState.Idle,
        newFolderName: '',
      });
    });
  }

  private getClassName(): string {
    const baseClass = 'defaultDmsObjectTreeItem';
    const selectedClass =
      this.props.item.isItemSelected || this.props.item.isTemp ? `${baseClass}--selected` : '';
    const tempClass = this.props.item.isTemp ? `${baseClass}--temp` : '';
    const draggedClass = this.props.item.isBeingDragged ? `${baseClass}--invisible` : '';
    const noHoverEventsClass = this.props.disableCssHoverStyle ? `${baseClass}--nohover ` : '';
    const msFocusClass = this.state.msFocusWithin ? `${baseClass}--focusWithin` : '';

    // tslint:disable-next-line:max-line-length
    return `${baseClass} ${selectedClass} ${tempClass} ${draggedClass} ${noHoverEventsClass} ${msFocusClass}`;
  }

  private renderAutoOpenContainerAnimation() {
    let animateClass = '';

    if (this.props.item.isCollapsed && this.props.autoOpenContainer) {
      animateClass = `defaultDmsObjectTreeItem_autoOpenAnimation--visible`;
    }

    return (
      <div
        className={`defaultDmsObjectTreeItem_autoOpenAnimation ${animateClass}`}
        ref={(element) => (this.autoOpenElement = element)}
      />
    );
  }

  private renderBookmarkIcon(props: IDmsObjectTreeItemProps): JSX.Element {
    const { bookmark } = props.item;

    return (
      <span
        className="defaultDmsObjectTreeItem_manageIcon defaultDmsObjectTreeItem_manageIcon--bookmark"
        onClick={bookmark.onClick}
      >
        <DmsIcon icon={bookmark.isBookmarked ? Icon.Bookmark : Icon.Unbookmark} />
      </span>
    );
  }

  private getNewFolderInputClass() {
    const baseClassName = 'defaultDmsObjectTreeItem_folderNameInput';

    return `${baseClassName} ${baseClassName}--${this.state.creationFormState}`;
  }

  private renderCollapsedIcon(treeItem: IDefaultDmsObjectTreeItem): JSX.Element {
    const { content, isCollapsed } = treeItem;
    const collapsedIcon = isCollapsed ? Icon.TreeItemCollapsed : Icon.TreeItemExpanded;

    return (
      <span className="defaultDmsObjectTreeItem_collapseIcon">
        {!_.isUndefined(content) && <DmsIcon icon={collapsedIcon} />}
      </span>
    );
  }

  private renderManageDropDown(treeItem: IDefaultDmsObjectTreeItem): JSX.Element {
    if (!this.state.isHovered && treeItem.creationState === ContentNodeCreationState.Idle) {
      return;
    }

    /**
     * We are not getting into the nitty gritty of getting the actual popoverHeight since we plan
     * to replace this popover component with Material UI's in the future. So we are doing a quick
     * fix of just padding the height of the popover up to 200px to fix the issue with the popover
     * expanding below the screen: https://dev.azure.com/powerdmsbuild/Build/_workitems/edit/41021
     */
    const arbitaryPopoverHeight = 200;
    const popoverBottom = this.anchor?.getBoundingClientRect()?.bottom + arbitaryPopoverHeight;
    const isPopoverBelowScreen = document.body.scrollHeight < popoverBottom;

    const popoverProps: IPopoverProps = {
      arrowLocation: PopoverArrowLocation.End,
      className: 'defaultDmsObjectTreeItem_popover',
      content: <Menu menuItems={treeItem.manageDropDownList} />,
      popoverLocation: isPopoverBelowScreen ? PopoverLocation.Above : PopoverLocation.Below,
      showOn: PopoverShowOn.Hover,
    };

    const hasError = this.state.creationFormState === CreationFormState.Error && treeItem.errorText;

    if (
      isNonEmptyString(treeItem.creationState) &&
      treeItem.creationState !== ContentNodeCreationState.Idle
    ) {
      const submitButtonText =
        this.state.creationFormState !== CreationFormState.Loading
          ? this.localize(Keys.Navigation.SideMenu.CreateFolder, {})
          : this.localize(Keys.Navigation.SideMenu.CreatingFolder, {});
      const isSubmitDisabled =
        this.state.newFolderName.trim().length === 0 ||
        this.state.creationFormState === CreationFormState.Loading ||
        this.state.creationFormState === CreationFormState.Error;

      popoverProps.content = (
        <div>
          <div>
            <button
              className="popover_backBtn"
              onClick={() => {
                this.props.item.cancelCreation();
              }}
            >
              <DmsIcon icon={Icon.TraverseOut} />
              &nbsp;
              {this.localize(Keys.Navigation.SideMenu.NewFolder, {})}
            </button>
          </div>
          {hasError ? (
            <div className="defaultDmsObjectTreeItem_folderCollisionError">
              {treeItem.errorText}
            </div>
          ) : null}
          <div>
            <input
              className={this.getNewFolderInputClass()}
              ref={this.newFolderInput}
              type="text"
              value={this.state.newFolderName}
              onChange={(e) => {
                treeItem.resetCreationError();
                const formState =
                  this.state.creationFormState === CreationFormState.MousedOut
                    ? CreationFormState.MousedOut
                    : CreationFormState.Idle;

                this.setState({
                  newFolderName: e.target.value,
                  creationFormState: formState,
                });
              }}
              onBlur={() => {
                this.updateCreationFormState(CreationFormState.Blurred);
              }}
              onFocus={() => {
                this.updateCreationFormState(CreationFormState.Idle);
              }}
              onKeyUp={(e) => {
                if (e.key === KeyboardKey.Enter && !isSubmitDisabled) {
                  this.createFolder(treeItem);
                }
              }}
            />
          </div>
          <button
            className="defaultDmsObjectTreeItem_createNewFolderButton"
            disabled={isSubmitDisabled}
            onClick={() => {
              this.createFolder(treeItem);
            }}
          >
            {submitButtonText}
          </button>
        </div>
      );
      popoverProps.onMouseOut = () => {
        this.updateCreationFormState(CreationFormState.MousedOut);
      };
      popoverProps.onMouseOver = () => {
        this.updateCreationFormState(CreationFormState.Idle);
      };
      popoverProps.isOpen = true;
      popoverProps.showOn = undefined;
    }

    return (
      <Popover {...popoverProps}>
        <span
          className="defaultDmsObjectTreeItem_manageIcon defaultDmsObjectTreeItem_manageIcon--menu"
          style={{ display: this.state.isHovered ? 'inline-block' : 'none' }}
        >
          <DmsIcon icon={Icon.DropDownEllipsis} />
        </span>
      </Popover>
    );
  }

  private renderManageMenuOrBookmark(props: IDmsObjectTreeItemProps): JSX.Element {
    const treeItem = props.item;

    // Temporary items, like "New Document", cannot be bookmarked nor managed.
    if (treeItem.isTemp) {
      return;
    }

    if (this.shouldShowManageDropDown(treeItem)) {
      return this.renderManageDropDown(treeItem);
    } else if (this.shouldShowBookmark(treeItem)) {
      return this.renderBookmarkIcon(props);
    }
  }

  private shouldShowManageDropDown(treeItem: IDefaultDmsObjectTreeItem): boolean {
    if (!_.isArray(treeItem.manageDropDownList)) return false;

    const onlyItemIsNotBookmark =
      treeItem.manageDropDownList.length === 1 &&
      treeItem.manageDropDownList[0].icon &&
      treeItem.manageDropDownList[0].icon !== Icon.Bookmark &&
      treeItem.manageDropDownList[0].icon !== Icon.Unbookmark;

    const multipleManageOptionsExist = treeItem.manageDropDownList.length > 1;

    return multipleManageOptionsExist || onlyItemIsNotBookmark;
  }

  private shouldShowBookmark(treeItem: IDefaultDmsObjectTreeItem): boolean {
    return (
      _.isArray(treeItem.manageDropDownList) &&
      treeItem.manageDropDownList.length === 1 &&
      _.isObject(treeItem.bookmark) &&
      treeItem.bookmark.canShow &&
      (treeItem.bookmark.isBookmarked || (!treeItem.bookmark.isBookmarked && this.state.isHovered))
    );
  }

  private autoOpenAnimationAction(event) {
    if (event.propertyName === 'width' && this.props.item.isCollapsed) {
      this.props.item.onToggleCollapse();
    }
  }

  private updateCreationFormState(newState: CreationFormState) {
    this.setState((state) => {
      if (
        (state.creationFormState === CreationFormState.Blurred &&
          newState === CreationFormState.MousedOut) ||
        (state.creationFormState === CreationFormState.MousedOut &&
          newState === CreationFormState.Blurred)
      ) {
        this.props.item.cancelCreation();
        return {
          creationFormState: CreationFormState.Idle,
          newFolderName: state.newFolderName,
        };
      } else {
        return { creationFormState: newState, newFolderName: state.newFolderName };
      }
    });
  }
}

export const ObjectTreeIcon = ({
  item,
  isCompact,
}: {
  item: IDefaultDmsObjectTreeItem;
  isCompact: boolean;
}) => {
  const styles = useStyles({});

  return (
    <span className="defaultDmsObjectTreeItem_objectIcon">
      <Spinner
        spinnerClassName="defaultDmsObjectTreeItem_spinnerIcon"
        isSpinning={item.dragDropLoadingStatus === DataStatus.updating || item.isImporting}
        spinnerSize={isCompact ? SpinnerSizes.Medium : SpinnerSizes.Large}
      >
        {/**
         * When using a screenreader, these browsers don't announce the title content of the icon
         * when the item is focused. To make the experience consistent between browsers, we add
         * SR-only text for those browsers.
         */}
        {(bowser.msie || bowser.firefox) && (
          <span className={styles.screenReaderOnly} data-automation-id="dmsObjectIcon_srOnly">
            {getLocalizedText(item.objectType)}
          </span>
        )}

        <DmsObjectIcon {...item.objectIcon} />
      </Spinner>
    </span>
  );
};

export { DmsObjectTreeItem };

export class dmsObjectTreeDirective implements ng.IDirective {
  public static $inject = ['reactDirective'];

  constructor(reactDirective: ReactDirective) {
    return reactDirective(DmsObjectTreeItem as any);
  }
}
