'use strict';

import { Fibonacci } from 'modules/main/models';

export class FibonacciConnection {
  private fibonacciConnectionWait: Fibonacci;
  private connectionTimer: ng.IPromise<any>;
  private retryAmount: number = 0;
  private unregisterConnectionWatch: () => void;

  constructor(
    private $rootScope,
    private $timeout: ng.ITimeoutService,
    private isConnectedFunc: () => boolean,
    private connectFunc: () => void,
  ) {
    this.fibonacciConnectionWait = new Fibonacci();
    this.fibonacciConnectionWait.maxNumber = 150;

    this.enable();
  }

  public isConnected(): boolean {
    return this.isConnectedFunc();
  }
  public getRetryAmount(): number {
    return this.retryAmount;
  }
  public isEnabled(): boolean {
    return this.unregisterConnectionWatch !== undefined;
  }

  public enable() {
    if (this.isEnabled()) return;

    this.resetFibonacci();

    this.unregisterConnectionWatch = this.$rootScope.$watch(
      () => this.isConnected(),
      (isConnected) => {
        if (isConnected) {
          this.retryAmount == 0;
          this.resetFibonacci();
          this.$timeout.cancel(this.connectionTimer);
        } else {
          this.connectWithinNextFibonacciSequence();
        }
      },
    );
  }

  public disable() {
    if (this.unregisterConnectionWatch) this.unregisterConnectionWatch();
    this.$timeout.cancel(this.connectionTimer);
    this.unregisterConnectionWatch = undefined;
  }

  public connectIn(seconds: number) {
    if (!this.isEnabled() || this.isConnected()) return;

    this.$timeout.cancel(this.connectionTimer);
    this.connectionTimer = this.$timeout(() => {
      this.retryAmount++;
      this.connectFunc();

      if (!this.isConnected()) this.connectWithinNextFibonacciSequence();
    }, seconds);
  }

  public resetFibonacci() {
    this.fibonacciConnectionWait.reset();
    this.retryAmount = 0;
  }

  private connectWithinNextFibonacciSequence() {
    this.connectIn(this.fibonacciConnectionWait.getNext() * 1000);
  }
}
