export enum Icon {
  AccredAccount = 'AccredAccount',
  Add = 'Add',
  Assessment = 'Assessment',
  Bookmark = 'Bookmark',
  TraverseOut = 'TraverseOut',
  TraverseIn = 'TraverseIn',
  Cancel = 'Cancel',
  Certificate = 'Certificate',
  Chapter = 'Chapter',
  Clear = 'Clear',
  Cloud = 'Cloud',
  Compare = 'Compare',
  Copy = 'Copy',
  Course = 'Course',
  Delete = 'Delete',
  Discussion = 'Discussion',
  Document = 'Document',
  DocumentBlank = 'DocumentBlank',
  DocumentHtml = 'DocumentHtml',
  DocumentExcel = 'DocumentExcel',
  DocumentPDF = 'DocumentPDF',
  DocumentPowerPoint = 'DocumentPowerPoint',
  DocumentPublished = 'DocumentPublished',
  DocumentTemplate = 'DocumentTemplate',
  DocumentWord = 'DocumentWord',
  Download = 'Download',
  DropDownEllipsis = 'DropDownEllipsis',
  Edit = 'Edit',
  EditRevision = 'EditRevision',
  Enabled = 'Enabled',
  Error = 'Error',
  EventLog = 'EventLog',
  Excel = 'Excel',
  Exit = 'Exit',
  ExternalLink = 'ExternalLink',
  Files = 'Files',
  Folder = 'Folder',
  Gift = 'Gift',
  Google = 'Google',
  GoogleColor = 'GoogleColor',
  Group = 'Group',
  InfoCircle = 'InfoCircle',
  Information = 'Information',
  Image = 'Image',
  ImportDocument = 'ImportDocument',
  Imported = 'Imported',
  Inbox = 'Inbox',
  ImportedSuccess = 'ImportedSuccess',
  LinkedGroup = 'LinkedGroup',
  Manual = 'Manual',
  Message = 'Message',
  MoreInformation = 'MoreInformation',
  NewWindow = 'NewWindow',
  Notification = 'Notification',
  OneDrive = 'OneDrive',
  OneDriveColor = 'OneDriveColor',
  PasswordShow = 'PasswordShow',
  PasswordHide = 'PasswordHide',
  Print = 'Print',
  Plug = 'Plug',
  PowerDMS = 'PowerDMS',
  Refresh = 'Refresh',
  Reply = 'Reply',
  ReviewChanges = 'ReviewChanges',
  Revisions = 'Revisions',
  Save = 'Save',
  Search = 'Search',
  SearchSuggestion = 'SearchSuggestion',
  SignOut = 'SignOut',
  SpinnerLoading = 'SpinnerLoading',
  Standard = 'Standard',
  Stop = 'Stop',
  StorageProvider = 'StorageProvider',
  StorageProviderDocument = 'StorageProviderDocument',
  StorageProvidersRoot = 'StorageProvidersRoot',
  Success = 'Success',
  Survey = 'Survey',
  Tag = 'Tag',
  Test = 'Test',
  Text = 'Text',
  Trash = 'Trash',
  TreeItemCollapsed = 'TreeItemCollapsed',
  TreeItemExpanded = 'TreeItemExpanded',
  TripleDotLoading = 'TripleDotLoading',
  Unbookmark = 'Unbookmark',
  UpdateAvailable = 'UpdateAvailable',
  Upload = 'Upload',
  User = 'User',
  ViewObject = 'ViewObject',
  Warning = 'Warning',
  Workflow = 'Workflow',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  JobCompleted = 'JobCompleted',
  JobFailed = 'JobFailed',
}
