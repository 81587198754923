'use strict';

export class Fibonacci {
  private previousFibonacciNumber: number = 1;
  private currentFibonacciNumber: number = 0;

  public maxNumber: number = -1;

  public getCurrent(): number {
    return this.currentFibonacciNumber;
  }

  public getNext(): number {
    if (this.maxNumber < this.currentFibonacciNumber + this.previousFibonacciNumber)
      return this.currentFibonacciNumber;
    if (this.currentFibonacciNumber < 2) return ++this.currentFibonacciNumber;

    var prev = this.currentFibonacciNumber;

    this.currentFibonacciNumber += this.previousFibonacciNumber;
    this.previousFibonacciNumber = prev;

    return this.currentFibonacciNumber;
  }

  public reset() {
    this.previousFibonacciNumber = 1;
    this.currentFibonacciNumber = 0;
  }
}
