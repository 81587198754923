import { put, call, takeLeading } from 'redux-saga/effects';
import {
  DocumentsActionType,
  fetchDocsStart,
  fetchDocsSuccess,
  fetchDocsFail,
  TFetchDocs,
} from 'modules/pub-docs/actions/documentsActions';
import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';
import { getPubDocs } from 'modules/pub-docs/resources';

export function* documentsSaga() {
  yield takeLeading(DocumentsActionType.Fetch, fetchDocsAsync);
}

export function* fetchDocsAsync(action: TFetchDocs) {
  try {
    yield put(fetchDocsStart());
    const documents: TPublicDoc[] = yield call(getPubDocs, action.payload.siteKey);
    yield put(fetchDocsSuccess(documents));
    return documents;
  } catch (error) {
    yield put(fetchDocsFail(error));
  }
}
