import axios from 'axios';

import { TSiteInfo } from './models/TSiteInfo';
import { TPublicDoc } from './models/TPublicDoc';
import { API_BASE_URL } from './services/pubDocServices';

/**
 * We use axios instead of HttpService because we do NOT want to send cookies with the request.
 * Since pubDocs is supposed to be an unauthenticated endpoint, sending cookies along caused a
 * weird edge-case behavior: Logging into a diff site using the same browser (in dev or QA env)
 * causes another site's tree to return documents with empty breadcrumbs.
 */

export function getPubDocs(siteKey: string): Promise<TPublicDoc[]> {
  const url = `${API_BASE_URL}/${siteKey}/documents`;

  return axios.get<ICcmpResponse<TPublicDoc[]>>(url).then((response) => response.data.data);
}

export async function getPubDocContentType(documentUrl: string): Promise<string> {
  const data = await axios.head(documentUrl);

  return data && data.headers ? data.headers['content-type'] : null;
}

export function getSiteInfo(siteKey: string): Promise<TSiteInfo> {
  const url = `${API_BASE_URL}/${siteKey}/info`;

  return axios.get<ICcmpResponse<TSiteInfo>>(url).then((response) => response.data.data);
}
