import * as _ from 'lodash';
import * as React from 'react';
import { ReactDirective } from 'ngreact';
import * as clsx from 'clsx/dist/clsx';

import { Keys } from 'locales/keys';
import { ObjectStatus } from 'modules/dms-object/enums';
import { LocalizationService } from 'modules/main/services/localization-service';
import { DmsIcon } from 'modules/presentation/components/DmsIcon';
import { Icon } from 'modules/presentation/enums';
import { DmsIconMappings } from 'modules/presentation/services/DmsIconMappings';

export type TDmsObjectIconProps = {
  className?: string;
  hasUpdate?: boolean;
  icon: Icon;
  iconTitle?: string;
  isNewlyPublished?: boolean;
  objectStatus?: ObjectStatus;
  showArchivedStatus?: boolean;
  showDraftStatus?: boolean;
  showPublishedStatus?: boolean;
  useFontColor?: boolean;
};

const elementClass = 'pow-object-icon';

const statusIconClasses = {
  [ObjectStatus.Archived]: 'archived',
  [ObjectStatus.Draft]: 'draft',
  [ObjectStatus.Publication]: 'published',
};

const statusIconTitleKeys = {
  [ObjectStatus.Archived]: Keys.CommonTerms.Archived,
  [ObjectStatus.Draft]: Keys.CommonTerms.Draft.Noun,
  [ObjectStatus.Publication]: Keys.CommonTerms.Published,
};

const canShowStatusIcon = ({
  objectStatus,
  showArchivedStatus,
  showDraftStatus,
  showPublishedStatus,
}: TDmsObjectIconProps): boolean => {
  const showArchived = showArchivedStatus && objectStatus === ObjectStatus.Archived;
  const showDraft = showDraftStatus && objectStatus === ObjectStatus.Draft;
  const showPublished = showPublishedStatus && objectStatus === ObjectStatus.Publication;

  return (
    _.isNonEmptyString(objectStatus) &&
    objectStatus !== ObjectStatus.None &&
    (showArchived || showDraft || showPublished)
  );
};

const getDefaultTitle = (props: TDmsObjectIconProps): string => {
  const localize = LocalizationService.localize;

  let defaultTitle = localize(DmsIconMappings.iconTitleKeys[props.icon], {});

  if (_.isNonEmptyString(defaultTitle)) {
    if (canShowStatusIcon(props)) {
      defaultTitle += ` (${localize(statusIconTitleKeys[props.objectStatus], {})})`;
    }
  } else {
    defaultTitle = null;
  }

  return defaultTitle;
};

const getTitle = (props: TDmsObjectIconProps): string => {
  let title = props.iconTitle;

  // We want to set a default title if one was not provided.
  if (!_.isNonEmptyString(title)) {
    title = getDefaultTitle(props);
  }

  return title;
};

const getWrapperClass = (props: TDmsObjectIconProps): string => {
  const wrapperClasses = [elementClass];

  if (canShowStatusIcon(props) && props.objectStatus !== ObjectStatus.None) {
    wrapperClasses.push('pow-mod-group', statusIconClasses[props.objectStatus]);
  }

  if (props.className) {
    wrapperClasses.push(props.className);
  }

  return clsx(wrapperClasses);
};

const DmsObjectIcon: React.SFC<TDmsObjectIconProps> = (props: TDmsObjectIconProps): JSX.Element => {
  const statusIconClass = `pow-fc pow-mod pow-mod-${statusIconClasses[props.objectStatus]}`;
  const updateIconClass = `powObjectIcon_updateIcon powObjectIcon_updateIcon--${props.icon}`;
  const statusIcon = canShowStatusIcon(props) ? (
    <span className={statusIconClass}>
      <i />
    </span>
  ) : null;
  const updateIcon = props.hasUpdate ? <span className={updateIconClass} /> : null;

  return (
    <span className={getWrapperClass(props)} title={getTitle(props)}>
      {props.hasUpdate ? updateIcon : null}
      <DmsIcon icon={props.icon} useFontColor={props.useFontColor} />
      {statusIcon}
    </span>
  );
};

export { DmsObjectIcon };

DmsObjectIcon.defaultProps = {
  hasUpdate: false,
  objectStatus: ObjectStatus.None,
  showArchivedStatus: true,
  showDraftStatus: true,
  showPublishedStatus: false,
  useFontColor: false,
};

export class dmsObjectIconDirective implements ng.IDirective {
  public static $inject = ['reactDirective'];

  constructor(reactDirective: ReactDirective) {
    /**
     * TODO: Find a way to type this properly.
     * It should be "React.ComponentClass<TDmsObjectIconProps>"
     */
    return reactDirective(DmsObjectIcon as any);
  }
}
