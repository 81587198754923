import * as bowser from 'bowser';
import { isNil } from 'modules/main/services/lodash-extended';
import { IFlatListItem } from 'modules/presentation/services/dms-tree-service';
import {
  IDmsObjectTreeItem,
  isIDefaultDmsObjectTreeItem,
  IDefaultDmsObjectTreeItem,
} from '../models/IDmsObjectTreeItem';
import {
  IDmsObjectTreeRoot,
  isIDmsObjectTreeContainerItem,
  IDmsObjectTreeContainerItem,
} from '../models/IDmsObjectTreeRoot';
import { LocalizationService } from 'modules/main/services';
import { Keys } from 'locales/keys';

export function navigateTreeWithKeyboard({
  anchorRef,
  key,
  treeItemKey,
  flatList,
  tree,
  getParentTreeItem,
  treeItemContentRef,
}: {
  anchorRef: HTMLButtonElement | HTMLAnchorElement;
  key: string;
  treeItemKey: string;
  flatList: IFlatListItem<IDmsObjectTreeItem>[];
  tree: IDmsObjectTreeRoot;
  getParentTreeItem: (
    treeItem: IFlatListItem<IDmsObjectTreeItem>,
    tree: IDmsObjectTreeRoot,
  ) => IDmsObjectTreeContainerItem;
  treeItemContentRef: HTMLButtonElement | HTMLSpanElement;
}): string | void {
  const currentTreeItem = flatList.find((item) => item.data.key === treeItemKey);
  const currentIndex = flatList.map(({ data }) => data.key).indexOf(currentTreeItem.data.key);

  function toggleFolderNode() {
    function refIsButton(ref: HTMLButtonElement | HTMLSpanElement): ref is HTMLButtonElement {
      return !isNil((ref as HTMLButtonElement).value);
    }
    /**
     * We need to add this because firefox doesn't treat the
     * click event as the rest of the browsers do. For some
     * reason the click event is occurring twice and will
     * open/close the node when we expect only one to occur.
     */
    if (bowser.firefox && refIsButton(treeItemContentRef)) {
      treeItemContentRef.dispatchEvent(
        new KeyboardEvent('keydown', { key: 'Enter', code: 'Enter' }),
      );
    } else {
      treeItemContentRef.click();
    }
    return;
  }

  switch (key) {
    case ' ':
      const isFolderNode =
        isIDefaultDmsObjectTreeItem(currentTreeItem.data) &&
        isIDmsObjectTreeContainerItem(currentTreeItem.data);

      if (isFolderNode && !isNil(treeItemContentRef)) {
        return toggleFolderNode();
      }
      if (!isNil(anchorRef)) {
        return anchorRef.click();
      }
    case 'ArrowUp':
      return navigateToTreeItem('previous', currentIndex, currentTreeItem, flatList);
    case 'ArrowDown':
      return navigateToTreeItem('next', currentIndex, currentTreeItem, flatList);
    case 'ArrowRight':
      const isCollapsedFolderNode =
        isIDefaultDmsObjectTreeItem(currentTreeItem.data) &&
        isIDmsObjectTreeContainerItem(currentTreeItem.data) &&
        currentTreeItem.data.isCollapsed;

      if (isCollapsedFolderNode && !isNil(treeItemContentRef)) {
        return treeItemContentRef.click();
      }
      return navigateToTreeItem('next', currentIndex, currentTreeItem, flatList);
    case 'ArrowLeft':
      const isExpandedFolderNode =
        isIDefaultDmsObjectTreeItem(currentTreeItem.data) &&
        isIDmsObjectTreeContainerItem(currentTreeItem.data) &&
        !currentTreeItem.data.isCollapsed;

      if (isExpandedFolderNode && !isNil(treeItemContentRef)) {
        return treeItemContentRef.click();
      }
      return navigateToTreeItem('previous', currentIndex, currentTreeItem, flatList);
    case 'Home':
      return navigateToTreeItem('first', currentIndex, currentTreeItem, flatList);
    case 'End':
      return navigateToTreeItem('last', currentIndex, currentTreeItem, flatList);
    case '*':
      return openSiblingFolders(currentTreeItem, tree, getParentTreeItem);
    default:
      return navigateWithLetters(currentTreeItem, key, tree, getParentTreeItem);
  }
}

export function openSiblingFolders(
  treeItem: IFlatListItem<IDmsObjectTreeItem>,
  tree: IDmsObjectTreeRoot,
  getParentTreeItem: (
    treeItem: IFlatListItem<IDmsObjectTreeItem>,
    tree: IDmsObjectTreeRoot,
  ) => IDmsObjectTreeContainerItem,
) {
  if (isIDefaultDmsObjectTreeItem(treeItem.data)) {
    const parentContainer = getParentTreeItem(treeItem, tree);

    parentContainer.content.forEach((item) => {
      if (isIDefaultDmsObjectTreeItem(item) && isIDmsObjectTreeContainerItem(item)) {
        const shouldExpandFolder = item.isCollapsed && !isNil(item.onToggleCollapse);

        if (shouldExpandFolder) {
          return item.onToggleCollapse();
        }
      }
    });
  }
}

export function navigateToTreeItem(
  type: 'previous' | 'next' | 'first' | 'last',
  currentIndex: number,
  currentTreeItem: IFlatListItem<IDmsObjectTreeItem>,
  flatList: IFlatListItem<IDmsObjectTreeItem>[],
) {
  if (isIDefaultDmsObjectTreeItem(currentTreeItem.data)) {
    switch (type) {
      case 'previous':
        const prevIndex = currentIndex - 1;
        if (flatList[prevIndex]) {
          return flatList[prevIndex].data.key;
        }
      case 'next':
        const nextIndex = currentIndex + 1;
        if (flatList[nextIndex]) {
          return flatList[nextIndex].data.key;
        }
      case 'first':
        const firstIndex = 0;
        if (flatList[firstIndex]) {
          return flatList[firstIndex].data.key;
        }
      case 'last':
        const lastIndex = flatList.length - 1;
        if (flatList[lastIndex]) {
          return flatList[lastIndex].data.key;
        }
      default:
        return currentTreeItem.data.key;
    }
  }
}

export function navigateWithLetters(
  currentTreeItem: IFlatListItem<IDmsObjectTreeItem>,
  key: string,
  tree: IDmsObjectTreeRoot,
  getParentTreeItem: (
    treeItem: IFlatListItem<IDmsObjectTreeItem>,
    tree: IDmsObjectTreeRoot,
  ) => IDmsObjectTreeContainerItem,
): string {
  if (isIDefaultDmsObjectTreeItem(currentTreeItem.data)) {
    const folderToSearch = getFolderToSearch(
      // We just verified above that it's a DefaultDmsObjectTreeItem
      currentTreeItem as IFlatListItem<IDefaultDmsObjectTreeItem>,
      tree,
      getParentTreeItem,
    );

    const indexOfItemInFolder = folderToSearch
      .map((item) => item.key)
      .indexOf(currentTreeItem.data.key);

    const match: IDefaultDmsObjectTreeItem = getItemWithMatchingFirstChar({
      folderToSearch,
      char: key,
      // We want to start searching at the next item, not the current item
      startIndex: indexOfItemInFolder + 1,
    });

    if (!isNil(match)) {
      return match.key;
    }
  }
}

export function getFolderToSearch(
  treeItem: IFlatListItem<IDefaultDmsObjectTreeItem>,
  tree: IDmsObjectTreeRoot,
  getParentTreeItem: (
    treeItem: IFlatListItem<IDefaultDmsObjectTreeItem>,
    tree: IDmsObjectTreeRoot,
  ) => IDmsObjectTreeContainerItem,
): IDmsObjectTreeItem[] {
  // If the item is an open folder, use that; if not, we want to search in the parent folder
  if (isIDmsObjectTreeContainerItem(treeItem.data) && !treeItem.data.isCollapsed) {
    return treeItem.data.content;
  }

  const parentContainer = getParentTreeItem(treeItem, tree);

  return parentContainer.content;
}

export function getItemWithMatchingFirstChar({
  char,
  folderToSearch,
  startIndex,
}: {
  char: string;
  folderToSearch: IDmsObjectTreeItem[];
  startIndex: number;
}): IDefaultDmsObjectTreeItem {
  const match = findItemWithMatchingFirstChar(char, folderToSearch, startIndex);

  // If we don't find a match after the current item, search again starting from the top
  if (!match) {
    return findItemWithMatchingFirstChar(char, folderToSearch, 0);
  }

  return match;
}

function findItemWithMatchingFirstChar(
  char: string,
  folderToSearch: IDmsObjectTreeItem[],
  startIndex: number,
): IDefaultDmsObjectTreeItem {
  for (let i = startIndex; i < folderToSearch.length; i++) {
    const item = folderToSearch[i];

    if (isIDefaultDmsObjectTreeItem(item)) {
      if (char.toLowerCase() === item.objectName.name.charAt(0).toLowerCase()) {
        return item;
      }
    }
  }
}

export function useDefaultTabIndex(
  flatListFirstItemKey: string,
  treeItemKey: string,
  focusedItemKey: string,
) {
  /**
   * For accessibility, we are using the roving tabindex strategy to allow users to tab between the
   * tree and main content. We want the root/first item of the tree to still be reached by tab so
   * that users can enter the tree and navigate with the keyboard. However, we only want it to be
   * reachable by tab if there are no other items focused.
   * See https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex for more information.
   */
  return treeItemKey === flatListFirstItemKey && isNil(focusedItemKey);
}

export function getAriaLiveNotice(
  key: string,
  treeItemKey: string,
  flatList: IFlatListItem<IDmsObjectTreeItem>[],
): string {
  const currentTreeItem = flatList.find((item) => item.data.key === treeItemKey);

  if (isIDefaultDmsObjectTreeItem(currentTreeItem.data)) {
    const isFolder: boolean = isIDmsObjectTreeContainerItem(currentTreeItem.data);
    const objectName: string = currentTreeItem.data.objectName.name;

    switch (key) {
      case 'Click':
      case ' ':
      case 'Enter':
        if (!isFolder || currentTreeItem.data.isCollapsed) {
          return LocalizationService.localize(Keys.Navigation.SideMenu.OpenedItem, {
            itemName: objectName,
          });
        }

        return LocalizationService.localize(Keys.Navigation.SideMenu.ClosedItem, {
          itemName: objectName,
        });
      case 'Home':
        return LocalizationService.localize(Keys.Navigation.SideMenu.NavigatedToFirstItem, {});
      case 'End':
        return LocalizationService.localize(Keys.Navigation.SideMenu.NavigatedToLastItem, {});
      case '*':
        return LocalizationService.localize(Keys.Navigation.SideMenu.OpenedSiblingFolders, {});
    }
  }
}
