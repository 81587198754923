'use strict';

export enum SearchType {
  Assessments = 'assessments',
  Certificates = 'certificates',
  CertificateTypes = 'certificatetypes',
  ContentHub = 'ContentHub',
  Courses = 'courses',
  CourseTypes = 'coursetypes',
  Discussions = 'discussions',
  DocTypesAndDocuments = 'doctypesanddocuments',
  Documents = 'documents',
  DocumentTypes = 'documenttypes',
  EventLog = 'eventlog',
  Everything = 'everything',
  Folders = 'folders',
  FoldersAndDocs = 'foldersanddocs',
  FoldersAndGroups = 'foldersandgroups',
  Groups = 'groups',
  Locations = 'locations',
  JobTitles = 'jobtitles',
  Manuals = 'manuals',
  ManualsAndStandardsNodes = 'manualsandstandardsnodes',
  StandardsNodes = 'standardsnodes',
  Surveys = 'surveys',
  Tags = 'tags',
  TagsAndTypes = 'tagsandtypes',
  Tests = 'tests',
  Users = 'users',
  UsersAndGroups = 'usersandgroups',
  Workflows = 'workflows',
}
