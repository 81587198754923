import * as _ from 'lodash';
import * as React from 'react';

type TDmsObjectPlaceholderProps = {
  showCollapseIcon?: boolean;
  iconColorHex?: string;
  widthPercentage?: number;
};

const DmsObjectPlaceholder: React.SFC<TDmsObjectPlaceholderProps> = (
  props: TDmsObjectPlaceholderProps,
): JSX.Element => {
  const baseClass = 'dmsObjectPlaceholder';

  // NOTE: The structure of this component mimics DmsObjectTreeItem.
  return (
    <div className={`${baseClass}`}>
      {props.showCollapseIcon && <span className={`${baseClass}_collapseIcon`} />}
      {_.isNonEmptyString(props.iconColorHex) && (
        <span className={`${baseClass}_icon`} style={{ backgroundColor: props.iconColorHex }} />
      )}
      <span
        className={`${baseClass}_name`}
        style={
          !isNaN(props.widthPercentage) && {
            flexBasis: `calc(${props.widthPercentage}% - 10px)`,
          }
        }
      />
    </div>
  );
};

export { DmsObjectPlaceholder };
