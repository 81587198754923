import * as React from 'react';
import * as H from 'history';

import { DmsObjectTree } from 'modules/dms-object/components/DmsObjectTree/DmsObjectTree';
import { IDmsObjectTreeRoot } from 'modules/dms-object/models/IDmsObjectTreeRoot';

import { EllipsisLoader } from 'modules/presentation/components/EllipsisLoader';

import { Sidebar } from 'modules/presentation/components/Sidebar';

import { DataFetchState } from 'modules/pub-docs/enums/DataFetchState';
import { FileViewer } from 'modules/pub-docs/components/FileViewer';
import { TreeConverter } from 'modules/pub-docs/components/TreeConverter';
import { TPublicDoc } from 'modules/pub-docs/models/TPublicDoc';

import { PoweredByDms } from 'modules/pub-docs/components/PoweredByDms';
import { isNil } from 'modules/main/services/lodash-extended';
import { LocalizationService } from 'modules/main/services/localization-service';
import { Keys } from 'locales/keys';
import { Header } from '../Header/Header';

// TODO: Find a more appropriate place for these consts
export const SIDEBAR_COMPACT_MODE_THRESHOLD = 400;
export const SIDEBAR_DEFAULT_WIDTH = 435;
export const FILE_VIEWER_MIN_WIDTH = 1024;
export const PUBDOCS_TREE_VIEW_MIN_WIDTH = SIDEBAR_DEFAULT_WIDTH + FILE_VIEWER_MIN_WIDTH;
export const SIDEBAR_MIN_WIDTH = 300;
export const MOBILE_BREAKPOINT = 935;

// TODO: Since only used for this component, convert to a literal string union type for better API.
export enum ViewMode {
  Sidebar,
  Full,
}

export type TTreeViewProps = {
  headerIsLoading: boolean;
  history: H.History;
  onScrollEnd: () => void;
  selectDocument: (document: TPublicDoc, spaUrl: string, history: H.History) => void;
  siteKey: string;
  toggleFolder: (id: string) => void;
  scrollToKey?: string;
  selectedDocument?: TPublicDoc;
  siteName: string;
  sideBarIsLoading: boolean;
  topMessage?: React.ReactNode;
  openedFolders?: { [id: string]: boolean };
  documents?: TPublicDoc[];
  documentId?: string;
  hasDocumentsError?: boolean;
};

type TTreeViewState = {
  fileFetchState: DataFetchState;
  lastSelectedDocument: TPublicDoc;
  isResizing: boolean;
  sidebarVisibility: 'visible' | 'hidden';
  sidebarWidth: number;
  viewMode: ViewMode;
};

export function getSelectedDocumentInfo(
  viewMode: ViewMode,
  lastSelectedDocument: TPublicDoc,
  currentSelectedDocument?: TPublicDoc,
): {
  selectedDocumentId: string | undefined;
  shouldTriggerFocus: boolean;
} {
  if (viewMode === ViewMode.Sidebar && lastSelectedDocument && lastSelectedDocument.id) {
    return { selectedDocumentId: lastSelectedDocument.id, shouldTriggerFocus: true };
  }

  if (currentSelectedDocument && currentSelectedDocument.id) {
    return { selectedDocumentId: currentSelectedDocument.id, shouldTriggerFocus: false };
  }

  return { selectedDocumentId: undefined, shouldTriggerFocus: false };
}

export class TreeView extends React.Component<TTreeViewProps, TTreeViewState> {
  static getDerivedStateFromProps(nextProps: TTreeViewProps, prevState: TTreeViewState) {
    if (prevState.viewMode === ViewMode.Full && isNil(nextProps.selectedDocument)) {
      return {
        lastSelectedDocument: nextProps.selectedDocument,
        fileFetchState: DataFetchState.Empty,
      };
    }

    if (
      !isNil(nextProps.selectedDocument) &&
      prevState.lastSelectedDocument !== nextProps.selectedDocument
    ) {
      return {
        lastSelectedDocument: nextProps.selectedDocument,
        fileFetchState: DataFetchState.Loading,
      };
    }

    return null;
  }

  private refToSelf: React.RefObject<HTMLDivElement>;
  private currentSidebarWidth: number = SIDEBAR_DEFAULT_WIDTH;
  private maxSidebarWidth;
  private mediaQueryList: MediaQueryList;

  constructor(props) {
    super(props);

    this.state = {
      fileFetchState: DataFetchState.Empty,
      lastSelectedDocument: undefined,
      isResizing: false,
      sidebarVisibility: 'visible',
      sidebarWidth: this.currentSidebarWidth,
      viewMode: ViewMode.Full,
    };

    this.handleSidebarResize = this.handleSidebarResize.bind(this);
    this.handleSidebarResizeDone = this.handleSidebarResizeDone.bind(this);
    this.setFileFetchState = this.setFileFetchState.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.selectDocument = this.selectDocument.bind(this);

    this.TreeViewSideBar = this.TreeViewSideBar.bind(this);

    this.refToSelf = React.createRef();
  }

  componentDidMount() {
    /**
     * @TODO: Make max sidebar width responsive to window size
     * https://powerdmsbuild.visualstudio.com/Build/_workitems/edit/13526
     */
    this.setMaxSidebarWidth();
    this.mediaQueryList = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`);

    this.mediaQueryList.addListener(this.handleWindowResize);

    /**
     * We need to call the resize handler once to adjust the view mode based on the actual screen
     * width, since the state is initialized assuming the user is on a computer. Attaching the
     * listener above doesn't do an initial run of the callback. We have to coerce the type
     * because standard practice is to pass in the mediaQueryList itself, even though the
     * function will receive an event when the callback is run.
     */
    this.handleWindowResize((this.mediaQueryList as unknown) as MediaQueryListEvent);

    const shouldDisplayDocument =
      this.props.documentId &&
      this.state.sidebarVisibility === 'visible' &&
      this.props.history.action === 'POP';

    if (shouldDisplayDocument) {
      this.selectDocument({
        id: this.props.documentId,
        breadcrumbs: [],
        name: '',
        publicUrl: '',
      });

      this.setState(() => ({ sidebarVisibility: 'hidden' }));
    }
  }

  componentWillUnmount() {
    this.mediaQueryList.removeListener(this.handleWindowResize);
  }

  componentDidUpdate(prevProps: TTreeViewProps) {
    const nowHasNoDocument = !isNil(prevProps.documentId) && isNil(this.props.documentId);
    const nowHasDocument = isNil(prevProps.documentId) && !isNil(this.props.documentId);

    const shouldDisplaySidebar = nowHasNoDocument && this.state.sidebarVisibility === 'hidden';
    const shouldDisplayDocument = nowHasDocument && this.state.sidebarVisibility === 'visible';

    if (shouldDisplaySidebar) {
      this.setState(() => ({ sidebarVisibility: 'visible' }));
    } else if (shouldDisplayDocument) {
      this.setState(() => ({ sidebarVisibility: 'hidden' }));
    }

    if (!isNil(this.props.selectedDocument)) {
      document.title = `${this.props.selectedDocument.name} - ${this.props.siteName} - PowerDMS`;
    } else {
      document.title = `${LocalizationService.localize(
        Keys.PublicDocs.PublicDocsDirectory,
        {},
      )} - ${this.props.siteName} - PowerDMS`;
    }
  }

  private setFileFetchState(newState: DataFetchState) {
    if (this.state.fileFetchState !== newState) {
      this.setState({ fileFetchState: newState });
    }
  }

  private setMaxSidebarWidth() {
    const pubDocsTreeViewCurrentWidth = this.refToSelf.current.clientWidth;

    this.maxSidebarWidth =
      pubDocsTreeViewCurrentWidth < PUBDOCS_TREE_VIEW_MIN_WIDTH
        ? SIDEBAR_DEFAULT_WIDTH
        : pubDocsTreeViewCurrentWidth - FILE_VIEWER_MIN_WIDTH;
  }

  private handleSidebarResize(xDelta: number) {
    this.setState((prevState) => {
      let newState;

      if (!prevState.isResizing) {
        newState = { isResizing: true };
      }

      const proposedWidth = this.currentSidebarWidth + xDelta;

      if (proposedWidth === prevState.sidebarWidth) {
        return newState;
      }

      if (proposedWidth < SIDEBAR_MIN_WIDTH) {
        return { ...newState, sidebarWidth: SIDEBAR_MIN_WIDTH };
      }

      if (proposedWidth > this.maxSidebarWidth) {
        return { ...newState, sidebarWidth: this.maxSidebarWidth };
      }

      return { ...newState, sidebarWidth: proposedWidth };
    });
  }

  private handleSidebarResizeDone() {
    this.currentSidebarWidth = this.state.sidebarWidth;
    this.setState({ isResizing: false });
  }

  private handleWindowResize(maxWidth: MediaQueryListEvent) {
    if (maxWidth.matches) {
      this.setState({ viewMode: ViewMode.Sidebar });
    } else {
      this.setState({ viewMode: ViewMode.Full });
    }
  }

  private getSidebarWidth(): string | number {
    return this.state.viewMode === ViewMode.Full ? this.state.sidebarWidth : '100%';
  }

  private selectDocument(document: TPublicDoc): void {
    if (!this.documentIsAlreadySelected(document)) {
      this.props.selectDocument(
        document as TPublicDoc,
        `/${this.props.siteKey}/tree/documents/${document.id}`,
        this.props.history,
      );
    }
  }

  private documentIsAlreadySelected(document: TPublicDoc): boolean {
    return this.props.selectedDocument && this.props.selectedDocument.id === document.id;
  }

  private TreeViewSideBar = ({
    sideBarIsLoading,
    topMessage,
    showDocs,
    shouldBeCompact,
    siteName,
    onScrollEnd,
    scrollToKey,
  }) => {
    const { selectedDocumentId, shouldTriggerFocus } = getSelectedDocumentInfo(
      this.state.viewMode,
      this.state.lastSelectedDocument,
      this.props.selectedDocument,
    );

    return (
      <Sidebar
        onResize={this.handleSidebarResize}
        onResizeDone={this.handleSidebarResizeDone}
        isResizable={this.state.viewMode === ViewMode.Full}
      >
        {sideBarIsLoading && (
          <div
            className="pubDocsTreeView_treeLoading"
            aria-label={LocalizationService.localize(Keys.Navigation.SideMenu.LoadingAriaLabel, {})}
          >
            <EllipsisLoader />
          </div>
        )}

        {topMessage && <div className="pubDocsTreeView_topMessage">{topMessage}</div>}

        {showDocs && (
          <TreeConverter
            isCompact={shouldBeCompact}
            openedFolders={this.props.openedFolders}
            selectedDocumentId={selectedDocumentId}
            onDocumentClick={this.selectDocument}
            documents={this.props.documents}
            siteName={siteName}
            toggleFolder={this.props.toggleFolder}
          >
            {(tree: IDmsObjectTreeRoot) => (
              <div className="pubDocsTreeView_objectTree">
                <DmsObjectTree
                  canDragToRoot={false}
                  isCompact={shouldBeCompact}
                  loadingItemsCount={0}
                  onScrollEnd={onScrollEnd}
                  scrollToKey={scrollToKey}
                  selectedDocumentId={selectedDocumentId}
                  shouldTriggerFocus={shouldTriggerFocus}
                  tree={tree}
                  treeId="pubDocs"
                />
              </div>
            )}
          </TreeConverter>
        )}
      </Sidebar>
    );
  };

  render() {
    const {
      hasDocumentsError,
      siteKey,
      siteName,
      sideBarIsLoading,
      headerIsLoading,
      history,
      onScrollEnd,
      scrollToKey,
      selectedDocument = {} as TPublicDoc,
      topMessage,
    } = this.props;
    const { breadcrumbs, name, publicUrl } = selectedDocument;
    const trail = (breadcrumbs && breadcrumbs[0] && breadcrumbs[0].trail) || [];
    const shouldBeCompact = this.state.sidebarWidth <= SIDEBAR_COMPACT_MODE_THRESHOLD;
    const showDocs = !sideBarIsLoading && !hasDocumentsError;

    return (
      <div className="pubDocsTreeView" ref={this.refToSelf}>
        {this.state.viewMode === ViewMode.Full && (
          <>
            <nav className="pubDocsTreeView_tree" style={{ flexBasis: this.getSidebarWidth() }}>
              <this.TreeViewSideBar
                sideBarIsLoading={sideBarIsLoading}
                topMessage={topMessage}
                showDocs={showDocs}
                shouldBeCompact={shouldBeCompact}
                siteName={siteName}
                onScrollEnd={onScrollEnd}
                scrollToKey={scrollToKey}
              />
            </nav>
            <div className="pubDocsTreeView_fileViewer">
              <FileViewer
                breadcrumbTrail={trail}
                disablePointerEvents={this.state.isResizing}
                documentName={name}
                documentUrl={publicUrl}
                fileFetchState={this.state.fileFetchState}
                hasDocumentsError={this.props.hasDocumentsError}
                headerIsLoading={headerIsLoading}
                setFileFetchState={this.setFileFetchState}
                siteKey={siteKey}
                siteName={siteName}
              />
            </div>
          </>
        )}

        {this.state.viewMode === ViewMode.Sidebar && this.state.sidebarVisibility === 'visible' && (
          <main className="pubDocsTreeView_tree" style={{ flexBasis: this.getSidebarWidth() }}>
            {!hasDocumentsError && <Header siteKey={siteKey} siteName={siteName} />}

            <this.TreeViewSideBar
              sideBarIsLoading={sideBarIsLoading}
              topMessage={topMessage}
              showDocs={showDocs}
              shouldBeCompact={shouldBeCompact}
              siteName={siteName}
              onScrollEnd={onScrollEnd}
              scrollToKey={scrollToKey}
            />

            <div className="pubDocsTreeView_treeFooter">
              <PoweredByDms />
            </div>
          </main>
        )}
        {this.state.viewMode === ViewMode.Sidebar && this.state.sidebarVisibility === 'hidden' && (
          <div className="pubDocsTreeView_fileViewer">
            <FileViewer
              breadcrumbTrail={trail}
              disablePointerEvents={this.state.isResizing}
              documentName={name}
              documentUrl={publicUrl}
              fileFetchState={this.state.fileFetchState}
              hasDocumentsError={hasDocumentsError}
              headerIsLoading={headerIsLoading}
              history={history}
              setFileFetchState={this.setFileFetchState}
              siteKey={siteKey}
              siteName={siteName}
            />
          </div>
        )}
      </div>
    );
  }
}
