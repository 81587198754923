import * as _ from 'lodash';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { Keys } from 'locales/keys';
import { LocalizationService } from 'modules/main/services/localization-service';
import { DmsIcon } from 'modules/presentation/components/DmsIcon';
import { TMenuItem } from 'modules/presentation/models/TMenuItem';
import { Icon } from 'modules/presentation/enums';
import { DmsTreeService } from 'modules/presentation/services/dms-tree-service';

type TMenuProps = {
  menuItems: TMenuItem[];
};

type TMenuState = {
  menuIndices: number[];
};

type TMenuItemProps = {
  disabled?: boolean;
  icon: Icon;
  link: string;
  linkTarget: string;
  onClick: (event) => void;
  text: string;
  title: string;
  useFontColor?: boolean;
};

function MenuItem(props: TMenuItemProps): JSX.Element {
  const { disabled = false, icon, link, onClick, text, title, linkTarget, useFontColor } = props;

  return (
    <li
      className={`menu_menuItem ${disabled && 'menu_menuItem--disabled'}`}
      onClick={
        disabled
          ? (evt) => {
              evt.preventDefault();
            }
          : onClick
      }
      title={title}
    >
      {disabled || link === '#' ? (
        <span className="menu_menuItemContent">
          {_.isNonEmptyString(icon) && (
            <div className="menu_menuItemIcon">
              <DmsIcon icon={icon} useFontColor={useFontColor} />
            </div>
          )}
          {text}
        </span>
      ) : (
        <a className="menu_menuItemContent" href={link} target={linkTarget}>
          {_.isNonEmptyString(icon) && (
            <div className="menu_menuItemIcon">
              <DmsIcon icon={icon} useFontColor={useFontColor} />
            </div>
          )}
          {text}
        </a>
      )}
    </li>
  );
}

/**
 * This component is deprecated. Please use the menu component that wraps
 * material-ui's menu component. It is located in modules/presentation/Menu.
 */
export class Menu extends React.Component<TMenuProps, TMenuState> {
  private static defaultProps: TMenuProps = {
    menuItems: [],
  };

  private static propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuIndices: [],
    };

    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  public render(): JSX.Element {
    let visibleMenu = this.getMenu(this.state.menuIndices);

    // In the case that menuIndices contains an invalid/outdated trail.
    if (visibleMenu === undefined) {
      visibleMenu = this.props.menuItems;
    }

    return (
      <div className="menu">
        <ul className="menu_menuItemList">{this.createMenu(visibleMenu)}</ul>
      </div>
    );
  }

  private getIconKey(menuItem: TMenuItem): Icon {
    if (_.isNonEmptyString(menuItem.icon)) {
      return menuItem.icon;
    }

    /**
     * If no icon was provided for a menu item with children,
     * we'll provide a default.
     */
    if (_.isArray(menuItem.children) && menuItem.children.length > 0) {
      return Icon.TraverseIn;
    }
  }

  private getMenu(indices: number[]): TMenuItem[] {
    const menuItem = DmsTreeService.getTreeItem<TMenuItem>(
      this.props.menuItems,
      (menuItem) => menuItem.children,
      indices,
    );

    if (_.isObject(menuItem) && _.isArray(menuItem.children) && menuItem.children.length > 0) {
      return menuItem.children;
    }
  }

  private createMenu(visibleMenu: TMenuItem[]): JSX.Element[] {
    const menuItems = visibleMenu.map((menuItem: TMenuItem, index: number) => {
      return (
        <MenuItem
          disabled={menuItem.disabled}
          icon={this.getIconKey(menuItem)}
          useFontColor={menuItem.useFontColor}
          link={menuItem.link || '#'}
          linkTarget={menuItem.linkTarget}
          key={`${index.toString()}-${JSON.stringify(menuItem)}`}
          onClick={(event) => this.onMenuItemClick(menuItem, index, event)}
          text={menuItem.text}
          title={menuItem.title || menuItem.text}
        />
      );
    });

    // If we're inside a child menu, we want to add a "Go Back" menu item.
    if (this.state.menuIndices.length > 0) {
      menuItems.unshift(
        <MenuItem
          icon={Icon.TraverseOut}
          key={'go-back-button-1'}
          link={'#'}
          linkTarget={null}
          onClick={() => this.goBack()}
          text={LocalizationService.localize(Keys.CommonTerms.GoBack, {})}
          title={LocalizationService.localize(Keys.CommonTerms.GoBack, {})}
        />,
      );
    }

    return menuItems;
  }

  private goBack(): void {
    this.setState((prevState, prevProps) => {
      const parentItemIndices = prevState.menuIndices.slice(0, -1);
      const parentMenu = this.getMenu(parentItemIndices);

      /**
       * If getMenu() returns undefined, we should show the root (top-level) menu. Also set
       * the indices accordingly so the render function recognizes that it's the top-level
       * menu.
       */
      return { menuIndices: parentMenu === undefined ? [] : parentItemIndices };
    });
  }

  private onMenuItemClick(menuItem: TMenuItem, index: number, e: Event): void {
    if (_.isArray(menuItem.children) && menuItem.children.length > 0) {
      this.setState({
        menuIndices: [...this.state.menuIndices, index],
      });
    }

    if (_.isFunction(menuItem.onClick)) {
      menuItem.onClick(e);
    }
  }
}
